import { IRemapper } from "../../../../../Global/IRemapper";
import ScoresModel from "./ScoresModel";
import { ScoresType } from "../StatType";

export default class ScoresRemapper implements IRemapper<ScoresModel> {

  fromResponse(response: any): ScoresModel {
    try {
      const model = new ScoresModel();
      model.ftScore = this.statEntityFromResponse(response.ft_score);
      model.htScore = this.statEntityFromResponse(response.ht_score);
      model.aetScore = this.statEntityFromResponse(response.aet_score);
      model.aggScore = this.statEntityFromResponse(response.agg_score);
      model.penScore = this.statEntityFromResponse(response.pen_score);
      return model;
    } catch (e) {
      console.warn("There was a problem remapping the scores", e);
      throw e;
    }
  };

  private statEntityFromResponse = (entity: any): ScoresType => {
    return { homeGoals: entity.home_goals, awayGoals: entity.away_goals };
  };
}
