import MainFiltersBQ from "../../../../Global/Models/Filters/MainFiltersBQ";

export default class InvitationFilters extends MainFiltersBQ {
  type?: string = null;

  constructor(filters: any) {
    super(filters);
    this.type = filters && filters.type ? filters.type : null;
  }

  public constructMainFiltersUrl? = () => {
    let url = super.constructMainFiltersUrl();

    if (this.type) {
      url += `&type=${this.type}`;
    }

    return url;
  };
}
