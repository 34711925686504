import avatar from '../../../../assets/avatar.png';

import Albania from '../../../../assets/kits/Albania.svg';
import Austria from '../../../../assets/kits/Austria.svg';
import Belgium from '../../../../assets/kits/Belgium.svg';
import Croatia from '../../../../assets/kits/Croatia.svg';
import Czech from '../../../../assets/kits/Czech.svg';
import Denmark from '../../../../assets/kits/Denmark.svg';
import England from '../../../../assets/kits/England.svg';
import France from '../../../../assets/kits/France.svg';
import Georgia from '../../../../assets/kits/Georgia.svg';
import Germany from '../../../../assets/kits/Germany.svg';
import Hungary from '../../../../assets/kits/Hungary.svg';
import Italy from '../../../../assets/kits/Italy.svg';
import Netherlands from '../../../../assets/kits/Netherlands.svg';
import Poland from '../../../../assets/kits/Poland.svg';
import Portugal from '../../../../assets/kits/Portugal.svg';
import Romania from '../../../../assets/kits/Romania.svg';
import Scotland from '../../../../assets/kits/Scotland.svg';
import Serbia from '../../../../assets/kits/Serbia.svg';
import Slovakia from '../../../../assets/kits/Slovakia.svg';
import Slovenia from '../../../../assets/kits/Slovenia.svg';
import Spain from '../../../../assets/kits/Spain.svg';
import Switzerland from '../../../../assets/kits/Switzerland.svg';
import Turkiye from '../../../../assets/kits/Turkiye.svg';
import Ukraine from '../../../../assets/kits/Ukraine.svg';

export const getImageForTeam = (teamCode) => {
  const teamKits = {
    'ALB': Albania,
    'AUT': Austria,
    'BEL': Belgium,
    'CRO': Croatia,
    'CZE': Czech,
    'DEN': Denmark,
    'ENG': England,
    'FRA': France,
    'GEO': Georgia,
    'GER': Germany,
    'HUN': Hungary,
    'ITA': Italy,
    'NED': Netherlands,
    'POL': Poland,
    'POR': Portugal,
    'ROU': Romania,
    'SCO': Scotland,
    'SER': Serbia,
    'SVK': Slovakia,
    'SVN': Slovenia,
    'ESP': Spain,
    'SUI': Switzerland,
    'TUR': Turkiye,
    'UKR': Ukraine,
  }

  return teamKits[teamCode] ?? avatar;
}