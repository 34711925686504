import CompetitionBasicModel from "../../../Football/Models/Competition/CompetitionBasicModel";
import TeamBasicModel from "../../../Football/Models/Team/TeamBasicModel";

export default class SinglePredictionsBreakdown {
  public entityId: string = "";
  public entityType: string = "";
  public entitySource: string = "";
  public model: CompetitionBasicModel | TeamBasicModel = null;
  public predictionsMade: number = 0;
  public correct: number = 0;
  public points: number = 0;
}
