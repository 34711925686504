import InterestModel from "../Models/InterestModel";

export default class ProfileService {

    /**
     * Compares between current and updated profile and extracts properties from updated profile who contain new values.
     * Constructs new request body with these properties.
     * @param oldProfile Current profile model.
     * @param newProfile Updated profile model.
     * @returns Request body for PATCH request to Profile API.
     */

    public extractUpdatedProps = (oldProfile: any, newProfile: any) => {
        const nonSpecialProperties = ["name", "nickname", "gender", "avatar"];
        let profileToPatch: any = {};

        Object.keys(oldProfile).forEach((key: any) => {
            if (nonSpecialProperties.includes(key) && oldProfile[key] !== newProfile[key]) {
                profileToPatch[key] = newProfile[key];
            } else if (key === "birthDate") {
                if (newProfile[key] === null) {
                    profileToPatch.birth_date = newProfile[key];
                } else if ((oldProfile[key] === null && newProfile[key]) || oldProfile[key] !== newProfile[key]) {
                    profileToPatch.birth_date = newProfile[key];
                }
            } else if (key === "country") {
                if (newProfile[key] === null) {
                    profileToPatch.country_id = newProfile[key];
                } else if ((oldProfile[key] === null && newProfile[key]) || oldProfile[key].id !== newProfile[key].id) {
                    profileToPatch.country_id = newProfile[key].id;
                }
            } else if (key === "interests") {
                const oldInterests = oldProfile[key];
                const newInterests = newProfile[key];

                // When we have new interests in our new profile, we update them:
                if (newInterests.length && this.isNewInterest(oldInterests, newInterests)) {
                    profileToPatch[key] = this.updateInterests(oldInterests, newInterests);
                } // When updated profile has no interests (empty array) but the old profile had some interests:
                else if (!newInterests.length && oldInterests.length) {
                    profileToPatch[key] = newInterests;
                }
                // When new profile interest's are updated, but there ARE NOT any new interests.
                else if (oldInterests.length !== newInterests.length && !this.isNewInterest(oldInterests, newInterests)) {
                    profileToPatch[key] = newInterests;
                }
            }
        });

        return profileToPatch;
    };

    /**
     * Functions covers case when length of interests is the same, but their values are different.
     * @param oldInterests Interests from current profile.
     * @param newInterests Interests from updated profile.
     * @returns New interests array.
     */

    private updateInterests = (oldInterests: InterestModel[], newInterests: InterestModel[]) => {
        if (oldInterests.length === newInterests.length) {
            const [extractedInterests, sameInterests] = this.extractSameAndNewInterests(oldInterests, newInterests);

            return [...sameInterests, ...extractedInterests];
        } else {
            return newInterests;
        }
    };

    /**
     * Validation if there are any new interests from current and updated profile.
     * @param oldInterests Interests from current profile.
     * @param newInterests Interests from updated profile.
     * @returns True for existing new interests, false for not existing ones.
     */

    public isNewInterest = (oldInterests: InterestModel[], newInterests: InterestModel[]) => {
        let isNewInterest = false;
        const [extractedNewInterests] = this.extractSameAndNewInterests(oldInterests, newInterests);

        if (extractedNewInterests.length) {
            isNewInterest = true;

            return isNewInterest;
        }

        return isNewInterest;
    };

    /**
     * Function will filter out two types of interests:
     * 1. Repeating interests from current and new profile
     * 2. New interests who do NOT exist in both current and new profiles.
     * @param oldInterests Interests from current profile.
     * @param newInterests Interests from updated profile.
     * @returns Array containing two types of arrays - one with same interests and the other with new ones.
     */

    private extractSameAndNewInterests = (oldInterests: InterestModel[], newInterests: InterestModel[]) => {
        const sameInterests = newInterests.filter((newInterest: InterestModel) => oldInterests.some((oldInterest: InterestModel) => {
            return newInterest.id === oldInterest.id
                && newInterest.type === oldInterest.type
                && newInterest.source === oldInterest.source
                && newInterest.favourite === oldInterest.favourite;
        }));

        const extractedNewInterests = newInterests.filter((newInterest: InterestModel) => !oldInterests.some((oldInterest: InterestModel) => {
            return newInterest.id === oldInterest.id
                && newInterest.type === oldInterest.type
                && newInterest.source === oldInterest.source
                && newInterest.favourite === oldInterest.favourite;
        }));

        return [extractedNewInterests, sameInterests];
    };

    public isAvatarValidURL = (avatar: string) => {
        try {
            new URL(avatar);

            return true;
        } catch (_) {
            return false;
        }
    };
}