import {IRemapper} from "../../../../../Global/IRemapper";
import FootballCountryRemapper from "../../Country/Remappers/FootballCountryRemapper";
import PlayerSquadModel from "../PlayerSquadModel";

export default class PlayerBasicRemapper implements IRemapper<PlayerSquadModel> {
    private countryRemapper: FootballCountryRemapper = null;

    constructor() {
        this.countryRemapper = new FootballCountryRemapper();
    }

    fromResponse(response: any): PlayerSquadModel {
        try {
            const model = new PlayerSquadModel();
            model.id = response.id;
            model.startDate = response.start_date;
            model.endDate = response.end_date;
            model.shirtNumber = response.shirt_number;
            model.loan = response.loan;
            model.position = response.position;
            model.country = this.countryRemapper.countryFromResponse(response.country);
            model.name = response.name;
            model.assets = response.assets;
            model.birthDate = response.birth_date;

            return model;
        } catch (e) {
            console.warn("There was a problem remapping the player from squad", e);
            throw e;
        }
    };

}