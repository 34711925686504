export const getAuthTokenRequest = async (userId) => {
  return fetch('https://europe-west1-marine-defender-313307.cloudfunctions.net/betway-token-issuer', {
    method: 'POST',
    body: JSON.stringify({ uid: userId }),
  });
}

export const getProfileRequest = (fansUnitedSDK) => {
  return fansUnitedSDK.profile.getOwn().getInfo();
}

export const updateProfileRequest = (fansUnitedSDK, name) => {
  return fansUnitedSDK.profile.getOwn().setName(name).update();
}