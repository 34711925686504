export default class FollowFilters {
    public limit: number = 0;
    public startAfter: string = null;

    constructor(filters: any) {
        this.limit = filters.limit ? filters.limit : 20;
        this.startAfter = filters.startAfter ? filters.startAfter : null;
    }

    public constructFilterUrl = () => {
        let url = '';
        if (this.limit) {
            url = url + '&';
            url = url + `limit=${this.limit}`
        }
        if (this.startAfter) {
            url = url + '&';
            url = url + `start_after=${this.startAfter}`;
        }

        return url;
    };

}