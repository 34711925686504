import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './CalendarHeader.module.css';

const CalendarHeader = ({ selectedDate, onDateSelect, calendarMatches }) => {
  const [isCentered, setIsCentered] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!isCentered && document.getElementById(selectedDate.setHours(0, 0, 0, 0))) {
      setIsCentered(true);
      document.getElementById(selectedDate.setHours(0, 0, 0, 0))?.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'center' });
    }
  });
  
  const handleDateSelect = (date, id) => {
    onDateSelect(date);
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  };

  return (
    <div className={styles.calendar}>
      {Object.keys(calendarMatches).sort().map((date) => {
        const isSelected = calendarMatches[date].value === selectedDate.setHours(0, 0, 0, 0);

        return (
          <div
            id={date}
            key={date}
            className={[styles.dateContainer, isSelected ? styles.dateContainerSelected : ''].join(' ')} 
            onClick={() => handleDateSelect(new Date(calendarMatches[date].value), date)}
          >
            <div className={[styles.date, isSelected ? styles.dateSelected : ''].join(' ')}>{t(calendarMatches[date].dayString)}</div>
            <div className={[styles.date, isSelected ? styles.dateSelected : ''].join(' ')}>{calendarMatches[date].dateString}</div>
          </div>
        )
      })}
    </div>
  )
}

export default CalendarHeader;