import {typeConvert} from "./TraversalHelper";

export default class TreeTraversalReplace {
    private replaceObjIdsIndex: any = null;
    private remappedIdsObj: any = {};

    public traverse = (obj: any, type: string, remappedIdsObj: any) => {
        type = typeConvert(type);
        if (this.replaceObjIdsIndex === null) {
            this.initReplaceObjIdsIndex(remappedIdsObj);
            this.remappedIdsObj = remappedIdsObj;
        }

        if (Array.isArray(obj)) {
            this.traverseArrReplace(obj, type);
        } else if (obj && typeof obj === 'object') {
            this.traverseObjReplace(obj, type);
        }

        return obj;
    };

    private initReplaceObjIdsIndex = (remappedIdsObj: any) => {
        this.replaceObjIdsIndex = {};
        Object.keys(remappedIdsObj).forEach((key: string) => {
            this.replaceObjIdsIndex[key] = 0;
        })
    };

    private traverseArrReplace = (objs: any[], type: string) => {
        if (objs && objs.length > 0) {
            objs.forEach((obj: any) => {
                this.traverseObjReplace(obj, type)
            });
        }
    };

    private traverseObjReplace = (obj: any, type: string) => {
        if (obj && Object.keys(obj).length > 0) {
            Object.keys(obj).forEach((key: string) => {
                if (key === 'id' || key === 'teamId') {
                    if (obj[key]) {
                        obj[key] = this.remappedIdsObj[type][this.replaceObjIdsIndex[type]];
                        this.replaceObjIdsIndex[type] = this.replaceObjIdsIndex[type] + 1;
                    }
                } else if (obj[key]) {
                    this.traverse(obj[key], key, this.remappedIdsObj);
                }
            });
        }
    };
}
