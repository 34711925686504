import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './LeaguesModal.module.css';

const LeaguesModal = ({ onSubmit, isJoin }) => {
  const { t } = useTranslation();

  const [leagueName, setLeagueName] = useState('');

  return (
    <>
      <input 
        className={styles.input}
        value={leagueName} 
        onChange={(e) => { setLeagueName(e.target.value) }} 
        placeholder={isJoin ? t('Enter invitation code') : t('Enter league name')}
      />
      <button 
        className={[styles.button, leagueName === '' ? styles.buttonDisabled : ''].join(' ')} 
        onClick={() => onSubmit(leagueName)}
        disabled={leagueName === ''}
      >
          {isJoin ? t('Join league') : t('Create league')}
      </button>
    </>
  )
}

export default LeaguesModal;