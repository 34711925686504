import {IRemapper} from "../../../../../Global/IRemapper";
import FootballCountryRemapper from "../../Country/Remappers/FootballCountryRemapper";
import PlayerBasicModel from "../PlayerBasicModel";
import TeamBasicRemapper from "../../Team/Remappers/TeamBasicRemapper";

export default class PlayerBasicRemapper implements IRemapper<PlayerBasicModel> {
    private countryRemapper: FootballCountryRemapper = null;
    private teamRemapper: TeamBasicRemapper = null;

    constructor() {
        this.countryRemapper = new FootballCountryRemapper();
        this.teamRemapper = new TeamBasicRemapper();
    }

    fromResponse(response: any): PlayerBasicModel {
        try {
            const model = new PlayerBasicModel();
            model.id = response.id;
            model.firstName = response.first_name ? response.first_name : null;
            model.lastName = response.last_name ? response.last_name : null;
            model.name = response.name ? response.name : null;
            model.birthDate = response.birth_date;
            model.country = this.countryRemapper.countryFromResponse(response.country);
            model.assets = response.assets;
            model.teams = response.teams ? response.teams.map((team: any) => this.teamRemapper.fromResponse(team)) : [];
            model.position = response.position;
            model.isDeleted = response.is_deleted;

            return model;
        } catch (e) {
            console.warn("There was a problem remapping players response", e);
            throw e;
        }
    };

}