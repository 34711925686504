import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';

import InfoHeader from "../InfoHeader";
import ScorePrediction from "../ScorePrediction";
import CommonPredictions from "../CommonPredictions";
import BonusFooter from "../BonusFooter";

import styles from './PredictionCard.module.css';
import Modal from "../../../../common/Modal";
import PlayersModal from "../PlayersModal";
import { MarketType, getMarketPredictions } from "../../../../Utils";
import CornersModal from "../CornersModal";
import { getModalInfo } from "./constants";
import HalftimeModal from "../HalftimeModal";

const PredictionCard = ({ 
  markets, 
  match, 
  summary, 
  userPredictions,
  onScoreSet,  
  onHalftimePrediction,
  onCornersPrediction,
  onGoalscorerSelected,
  fansUnitedSDK,
}) => {
  const { t } = useTranslation();

  const [selectedMarket, setSelectedMarket] = useState(null)
  const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
  const [marketsInfo, setMarketsInfo] = useState([]);
  const [currentScore, setCurrentScore] = useState({ homeTeamScore: null, awayTeamScore: null });

  const modalInfo = getModalInfo(selectedMarket);

  useEffect(() => {
    let scorePrediction = userPredictions.filter((prediction) => prediction.market === MarketType.CORRECT_SCORE_ADVANCED)?.[0]?.prediction?.value;
    
    if (scorePrediction) {
      setCurrentScore({homeTeamScore: Number(scorePrediction.split(':')[0]), awayTeamScore: Number(scorePrediction.split(':')[1])})
    }

    setMarketsInfo(getMarketPredictions(markets, userPredictions))
  }, [userPredictions])

  const onScoreSelected = (score) => {
    setCurrentScore(score);
    onScoreSet(match.id, score, false);
  }

  const onMarketSelected = (market) => {
    setSelectedMarket(market)
    setIsBonusModalOpen(true)
  }

  const onCornersSelected = (option) => {
    setIsBonusModalOpen(false)
    onCornersPrediction(match.id, option)
  }

  const onHalftimeSignSelected = (option) => {
    setIsBonusModalOpen(false)
    onHalftimePrediction(match.id, option)
  }

  const onPlayerSelected = (option) => {
    setIsBonusModalOpen(false)
    onGoalscorerSelected(match.id, option)
  }

  const renderPlayersModal = (prediction) => (
    <PlayersModal 
      homeTeam={match.homeTeam} 
      awayTeam={match.awayTeam}
      onPlayerSelect={onPlayerSelected}
      playerPrediction={prediction?.id}
      fansUnitedSDK={fansUnitedSDK}
    />
  )

  const renderCornersModal = (prediction) => (
    <CornersModal
      onOptionSelected={onCornersSelected}
      optionPrediction={prediction}
    />
  )

  const renderHalftimeModal = (prediction) => (
    <HalftimeModal
      homeTeam={match.homeTeam} 
      awayTeam={match.awayTeam}
      onOptionSelected={onHalftimeSignSelected}
      teamPrediction={prediction}
    />
  )

  const renderModal = () => {
    switch (selectedMarket?.type) {
      case MarketType.HT_1X2:
        return renderHalftimeModal(selectedMarket.prediction)
      case MarketType.OVER_CORNERS_8_5:
        return renderCornersModal(selectedMarket.prediction)
      case MarketType.PLAYER_SCORE_FIRST_GOAL:
        return renderPlayersModal(selectedMarket.prediction)
      default:
        break;
    }
  }

  return (
    <div className={styles.container}>
      <InfoHeader match={match} fansUnitedSDK={fansUnitedSDK}/>
      <div className={styles.separatorTop}/>
      <ScorePrediction
        score={currentScore}
        match={match}
        onScoreSet={(score) => onScoreSet(match.id, score, true)}
      />
      {!match.undecided &&
        <>
          { Object.values(summary?.[0]?.predictions?.correctScore ?? {}).length > 0 && 
              <CommonPredictions 
                match={match}
                scorePredictions={summary[0].predictions.correctScore} 
                userPrediction={userPredictions.find((prediction) => prediction.market === MarketType.CORRECT_SCORE_ADVANCED)?.prediction} 
                onScoreSelected={onScoreSelected}
              />
          }
          <div className={styles.separatorBottom}/> 
          <BonusFooter 
            match={match}
            markets={marketsInfo} 
            onMarketSelected={onMarketSelected}
          />
          <Modal
            isOpen={isBonusModalOpen} 
            onClose={() => {
              setSelectedMarket(null)
              setIsBonusModalOpen(false)
            }}
            title={t(modalInfo?.title)}
            subtitle={t(modalInfo?.subtitle)}
            points={modalInfo?.points}
          >
            {renderModal()}
          </Modal>
        </>
      }
    </div>
  )
}

export default PredictionCard;