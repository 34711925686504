import FootballCountryModel from "../FootballCountryModel";

export default class FootballCountryRemapper {

    public countriesFromResponse = (response: any) => {
        if (response) {
            return response.map((countryResp: any) => this.countryFromResponse(countryResp))
        }
    };

    public countryFromResponse = (response: any): FootballCountryModel => {
        try {
            if (response) {
                const country = new FootballCountryModel();
                country.id = response.id;
                country.alias = response.alias;
                country.assets = response.assets;
                country.countryCode = response.country_code;
                country.name = response.name;
                return country;
            }
        } catch (e) {
            throw e;
        }

        return null;
    };
}
