export default class MyChallengesFilters {
  public type?: string = "ONE_VS_ONE";
  public userRole?: string = null;

  constructor(filters: any) {
    this.userRole = filters?.userRole || null;
  }

  constructFiltersUrl? = () => {
    let url = `&type=${this.type}`;

    if (this.userRole) {
      url += `&user_role=${this.userRole}`;
    }

    return url;
  };
}
