import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import { transformIdsToBeUnique } from "../../../Global/Helper";
import FootballHttps from "../../../Https/FootballHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import MatchBasicModel from "../Models/Match/MatchBasicModel";
import MatchFilters from "../Models/Match/MatchFilters";
import MatchFullModel from "../Models/Match/MatchFullModel";
import FootballPaginationModel from "../Models/Pagination/FootballPaginationModel";
import FootballService from "../Service/FootballService";

export default class MatchFacade {
    private https: FootballHttps = null;
    private idMapping: IdMappingService = null;
    private footballService: FootballService = null;

    constructor(config: SDKConfigurationModel, https: FootballHttps, idMapping: IdMappingService) {
        this.https = https;
        this.idMapping = idMapping;
        this.footballService = new FootballService(config);
    };

    public getMatchesIdMapping = async (filters?: MatchFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        try {
            if (filters) {
                filters = new MatchFilters(filters);
                if (filters.countryIds && filters.countryIds.length > 0) {
                    const countryIds = await this.idMapping.getEntityIdsBySchemaId(filters.countryIds, "country", "native");
                    filters.countryIds = countryIds.country;
                }
                if (filters.competitionIds && filters.competitionIds.length > 0) {
                    const competitionIds = await this.idMapping.getEntityIdsBySchemaId(filters.competitionIds, "competition", "native");
                    filters.competitionIds = competitionIds.competition;
                }
                if (filters.matchIds && filters.matchIds.length > 0) {
                    const matchIds = await this.idMapping.getEntityIdsBySchemaId(filters.matchIds, "match", "native");
                    filters.matchIds = matchIds.match;
                }
                if (filters.teamIds && filters.teamIds.length > 0) {
                    const teamIds = await this.idMapping.getEntityIdsBySchemaId(filters.teamIds, "team", "native");
                    filters.teamIds = teamIds.team;
                }
            }
            const matches = await this.getMatches(filters, disableCache);
            matches.data = await this.footballService.setAvailableMarketsForMatch([...matches.data]) as MatchBasicModel[];
            matches.data = await this.idMapping.remapEntities(matches.data, "match");

            return matches;
        } catch (e) {
            console.warn(`There was a problem browsing the matches`, e);
            throw e;
        }
    };

    private getMatchesWithNativeIds = async (matchIds: string[]) => {
        const limitOfMatches = matchIds.length;
        const filters = new MatchFilters({ matchIds, limit: limitOfMatches });
        const matches = await this.getMatches(filters);
        matches.data.sort((a: MatchBasicModel, b: MatchBasicModel) => a.id.localeCompare(b.id));

        return await this.idMapping.remapEntities(matches.data, "match");
    };

    public getMatchesMapWithNativeIds = async (matchIds: string[]) => {
        const matchesMap: any = {};

        let uniqueIds = transformIdsToBeUnique(matchIds);

        uniqueIds.sort((a: string, b: string) => a.localeCompare(b));
        const matches = await this.getMatchesWithNativeIds(uniqueIds);

        if (uniqueIds.length !== matches.length) {
            // When match is not returned from Football API
            const matchFilters = new MatchFilters({ matchIds: uniqueIds, limit: uniqueIds.length});
            // Fetch matches with native ids (no remapping to idSchema)
            const matchesWithNativeIds = await this.getMatches(matchFilters);
            // Create a list of match ids who API didn't return a model
            const missingMatchIds = uniqueIds.filter((id: string) => {
                if (matchesWithNativeIds.data.every((match: MatchBasicModel) => match.id !== id)) {
                    return id;
                }
            })

            // Assign new list of ids with for which we have models
            uniqueIds = uniqueIds.filter((id: string) => {
                if (!missingMatchIds.includes(id)) return id;
            })
        }

        uniqueIds.forEach((id: string, idx: number) => matchesMap[id] = matches[idx]);

        return matchesMap;
    };

    private getMatches = async (filters?: MatchFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.https.getMatches(filters, disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving the matches`, e);
            throw e;
        }
    };

    public getMatchIdMapping = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        try {
            const nativeId = await this.idMapping.getEntityIdsBySchemaId([id], "match", "native");
            const match = await this.getMatchById(nativeId.match[0], disableCache);
            const newMatch = await this.footballService.setAvailableMarketsForMatch(match) as MatchFullModel;

            return await this.idMapping.remapEntities(newMatch, "match");
        } catch (e) {
            console.warn(`There was a problem browsing the following match: ${id}`, e);
            throw e;
        }
    };

    private getMatchById = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.https.getMatchById(id, disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving the following match: ${id}`, e);
            throw e;
        }
    };
}