import React from 'react';

import { useTranslation } from 'react-i18next';

import placeholderIcon from '../../../assets/badge.png';

import styles from './BadgeCard.module.css';

const BadgeCard = ({ badge }) => {
  const { t } = useTranslation();

	return (
		<div className={styles.container}>
      <img src={badge.unlocked ? (badge.assets?.mainImageUrl ?? placeholderIcon) : placeholderIcon} className={styles.icon} alt='badge-icon'/>
      <div className={styles.info}>
        <div className={styles.title}>{t(badge.label)}</div>
        <div className={styles.description}>{t(badge.description)}</div>
      </div>
		</div>
	);
}

export default BadgeCard;