import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import MainFiltersBQ from "../../../Global/Models/Filters/MainFiltersBQ";
import VotingHttps from "../../../Https/VotingHttps";
import PollsFilters from "../Models/Polls/Filters/PollsFilters";
import VotingService from "../Service/VotingService";

export default class VotingFacade {
  private https: VotingHttps = null;
  private service: VotingService = null;
  private idSchema: string = null;

  constructor(config: SDKConfigurationModel) {
    this.idSchema = config.idSchema;
    this.https = new VotingHttps(config);
    this.service = new VotingService(config);
  }

  public getPolls = async (filters?: PollsFilters, disableCache?: boolean) => {
    if (filters) {
      filters = new PollsFilters(filters);
      await this.service.finalizePollsFilters(filters);
    }

    const response = await this.https.getPolls(filters, disableCache);

    for (let value of response.data) {
      await this.service.remapTags(value, IdSchemaEnum.NATIVE, this.idSchema);
    }

    return response;
  };

  public getPollById = async (pollId: string, disableCache?: boolean) => {
    const data = await this.https.getPollById(pollId, disableCache);
    await this.service.remapTags(data, IdSchemaEnum.NATIVE, this.idSchema);

    return data;
  };

  public voteForPoll = async (pollId: string, optionId: string) => {
    return await this.https.voteForPoll(pollId, optionId);
  };

  public deleteVoteForPoll = async (pollId: string) => {
    return await this.https.deleteVoteForPoll(pollId);
  };

  public getMyPollVote = async (pollId: string, showModels?: boolean) => {
    const data = await this.https.getMyPollVote(pollId);

    if (showModels) {
      const polls = await this.getPolls(
        new PollsFilters({ pollIds: [pollId] }),
        true
      );
      data.pollModel = polls.data.find((poll) => poll.id === pollId) || null;
      data.optionModel =
        polls.data
          .find((poll) => poll.id === pollId)
          ?.options.find((option) => option.id === data.optionId) || null;
    }

    return data;
  };

  public getMyPollsVotes = async (filters?: MainFiltersBQ) => {
    if (filters) {
      filters = new MainFiltersBQ(filters);
    }

    const data = await this.https.getMyPollsVotes(filters);
    const pollIds = data.data.map((poll) => poll.id);
    const polls = await this.getPolls(new PollsFilters({ pollIds }), true);
    data.data = data.data.map((value) => {
      value.pollModel =
        polls.data.find((poll) => poll.id === value.pollId) || null;
      value.optionModel =
        value.pollModel?.options.find(
          (option) => option.id === value.optionId
        ) || null;

      return value;
    });

    return data;
  };
}
