export default class Progress {
  private KEY_PREFIX = "fu-progress-";

  public get = (type: string, id: string) => {
    const progress = localStorage.getItem(`${this.KEY_PREFIX}${type}-${id}`);

    if (progress) {
      return JSON.parse(progress);
    }

    return progress;
  };

  public set = (type: string, id: string, value: any) => {
    localStorage.setItem(
      `${this.KEY_PREFIX}${type}-${id}`,
      JSON.stringify(value)
    );

    return JSON.stringify(value);
  };

  public delete = (type: string, id: string) => {
    localStorage.removeItem(`${this.KEY_PREFIX}${type}-${id}`);
  };
}
