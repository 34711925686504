import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import { initDisableCache, isDisabledCache, setBaseUrl } from "../Global/Helper";
import ActivityResponseBody from "../Namespaces/Activity/Models/ActivityResponseBody";
import PaginationActivities from "../Namespaces/Activity/Models/PaginationActivities";
import CreateActivityRequestBody from "../Namespaces/Activity/Models/RequestBody/CreateActivityRequestBody";
import ActivityRemapper from "../Namespaces/Activity/Remapper/ActivityRemapper";
import ProfileStatsModel from "../Namespaces/Profile/Models/Stats/ProfileStatsModel";
import OwnActivityFilters from "../Namespaces/Activity/Models/Filters/OwnActivityFilters";
import UserActivityFilters from "../Namespaces/Activity/Models/Filters/UserActivityFilters";
import Https from "./Https";

export default class ActivityHttps extends Https {
    private activityRemapper: ActivityRemapper = null;

    constructor(config: SDKConfiguraitonModel) {
        super(config, setBaseUrl(config.environment, "loyalty"));
        this.activityRemapper = new ActivityRemapper();
    }

    public createActivity = async (requestBody: CreateActivityRequestBody): Promise<ActivityResponseBody> => {
        const url = `/activities${this.apiSignInUrl}`;
        const warnMessage = "There was a problem with creating activity";
        const crudMethod = "POST";
        const data = await this.fetchWithAuth(url, warnMessage, crudMethod, requestBody);

        return this.activityRemapper.remapSingleResponse(data);
    };

    public deleteActivity = async (activityId: string): Promise<boolean> => {
        const url = `/activities/${activityId}${this.apiSignInUrl}`;
        const warnMessage = `There was a problem deleting the following activity: ${activityId}`;
        const crudMethod = "DELETE";
        await this.fetchWithAuth(url, warnMessage, crudMethod);

        return true;
    };

    public getOwnActivities = async (filters: OwnActivityFilters, disableCache: boolean): Promise<PaginationActivities> => {
        let url = `/activities${this.apiSignInUrl}`;
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with fetching own activities";

        if (filters) {
            url += `${filters.constructFilterUrl()}`;
        }

        if (disableCache) {
            url += `disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithAuth(url, warnMessage);

        return this.activityRemapper.remapActivitiesResponse(data);
    };

    public getActivitiesForUser = async (userId: string, filters: UserActivityFilters, disableCache: boolean): Promise<PaginationActivities> => {
        let url = `/users/${userId}/activities${this.apiSignInUrl}`;
        const timestampCache = Date.now();
        const warnMessage = `There was a problem with fetching activities for user: ${userId}`;

        if (filters) {
            url += `${filters.constructFilterUrl()}`;
        }

        if (disableCache) {
            url += `disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.activityRemapper.remapActivitiesResponse(data);
    };

    public getStats = async (userId?: string, disableCache?: boolean): Promise<ProfileStatsModel> => {
        let url = "";
        let warnMessage = "";
        let data: any = null;

        if (userId) {
            url += isDisabledCache(disableCache) ?
            `/users/${userId}/statistics${this.apiSignInUrl}${initDisableCache()}` :
            `/users/${userId}/statistics${this.apiSignInUrl}`;
            warnMessage = `There was a problem fetching statistics for profile: ${userId}`;
            data = await this.fetchWithoutAuth(url, warnMessage);
        } else {
            url += `/statistics${this.apiSignInUrl}`;
            warnMessage = "There was a problem fetching profile`s statistics";
            data = await this.fetchWithAuth(url, warnMessage);
        }

        return this.activityRemapper.remapStatsResponse(data.data);
    };
}