import { FiltersInterface } from "../../../../Global/Interfaces/GlobalInterfaces";

export default class PrivateLeaguePredictionsFilters
  implements FiltersInterface
{
  limit?: number = null;
  page?: number = null;
  profileIds?: string[] = null;

  constructor(filters: any) {
    this.limit = filters && filters.limit ? filters.limit : null;
    this.page = filters && filters.page ? filters.page : null;
    this.profileIds = filters && filters.profileIds ? filters.leagueId : null;
  }

  constructFiltersUrl? = () => {
    let url = "";

    if (this.limit) {
      url += `&limit=${this.limit}`;
    }

    if (this.page) {
      url += `&page=${this.page}`;
    }

    if (this.profileIds) {
      url += `&profile_ids=${this.profileIds.join(",")}`;
    }

    return url;
  };
}
