import FootballCountryModel from "../Country/FootballCountryModel";
import CompetitionBasicModel from "../Competition/CompetitionBasicModel";
import TeamBasicModel from "../Team/TeamBasicModel";

export default class PlayerFullModel {
    public id: string = '';
    public country: FootballCountryModel = null;
    public birthDate: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public name: string = '';
    public position: string = '';
    public assets: {} = null;
    public isDeleted: boolean = false;
    public competitions: CompetitionBasicModel[] = [];
    public teams: TeamBasicModel[] = [];
}
