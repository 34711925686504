import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import { IdSchemaType } from "../../../Configurator/Types/ConfiguratorTypes";
import IdMappingService from "../../../IdMapping/IdMappingService";
import TagsModel from "../Models/RequestBody/Context/TagsModel";

export default class ActivityService {
    private idSchema: string;
    private idMapping: IdMappingService;

    constructor(config: SDKConfigurationModel) {
        this.idSchema = config.idSchema;
        this.idMapping = new IdMappingService(config);
    }

    /**
     * Initializing object typeIds with key type and value id from tags.
     * Passing that object to function getEntitiesByIds in IdMappingFacade.
     * For replacing the new ids there is a object tagsByType with key type and
     * value tags. The order in tagsByType is same as typeIds.
     * @param tags
     * @returns Remapped tags from idSchema to native.
     */

    public remapTagsIds = async (tags: TagsModel[], fromSchema: IdSchemaType, toSchema: IdSchemaType): Promise<TagsModel[]> => {
        if (!tags || !tags.length) return tags;

        let typeIds: any = {};
        let tagsByType: any = {};
        let remappedTags: TagsModel[] = [];

        //Extracting tags by type ids and tags type -> {type: [ids]}, {type: [tags]}
        tags.forEach((element: TagsModel) => {
            if (!typeIds.hasOwnProperty(element.type)) {
                typeIds[element.type] = [];
                tagsByType[element.type] = [];
            }
            typeIds[element.type].push(element.id);
            tagsByType[element.type].push(element);
        });

        // Remap ids from idSchema to native
        const remappedIds = await this.idMapping.idMappingFacade.getEntitiesByIds(typeIds, fromSchema, toSchema);

        // Create new tags with remapped native ids
        for (const [type, tagsType] of Object.entries(tagsByType)) {
            //@ts-ignore
            tagsType.forEach((element: TagsModel, idx: number) => {
                element.id = remappedIds[type][idx];
                remappedTags.push(element);
            });
        }

        return remappedTags;
    }
}