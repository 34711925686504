import EitherOrParticipationStepsOptionModel from "./EitherOrParticipationStepsOptionModel";

export default class EitherOrParticipationStepsModel {
  public pairId: string = "";
  public userSelection: string = "";
  public correct: boolean = null;
  public played: boolean = null;
  public timestamp: string = "";
  public optionOne: EitherOrParticipationStepsOptionModel =
    new EitherOrParticipationStepsOptionModel();
  public optionTwo: EitherOrParticipationStepsOptionModel =
    new EitherOrParticipationStepsOptionModel();
}
