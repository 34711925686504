import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { getUserCountry } from '../../redux/user';

import styles from './PrizesFragment.module.css';

const PrizesFragment = () => {
	const { t } = useTranslation();

	const country = useSelector(getUserCountry);

	const renderAfricanPrizes = () => (
		<div>
			<b>Stand a chance to win up to R5000.00 in cash, deposited directly into your Betway account!</b>
			<p/>1st = R5 000.00
			<br/>2nd = R3 500.00
			<br/>3rd = R1 000.00
			<p/>The player who finishes at the top of the leaderboard at the end of Euro 2024 will win <b>R5000.00</b> in cash deposited directly into their Betway South Africa account!
			<p/>The person in 2nd will win <b>R3500.00</b> and third place will win <b>R1000.00</b> deposited into their Betway South Africa account.
			<br/><br/>Please note that if there is a tie for 1st, 2nd or 3rd, the participants in those positions will go in a draw to determine the winner of each prize.
			<p/>Winners will have their Betway accounts credited within 7 days of the completion of the tournament, or will be contacted within 7 days.
			<p/>For more information please see the full <a href='https://storage.googleapis.com/st_bw_africa/score_predict_tc.html' target='_blank'>Terms & Conditions</a>.
		</div>
	)

	const renderPrizes = () => (
		<div>
			<b>{t('Win up to €500.00 in free bets deposited directly into your Betway account!')}</b>
			<p/>{t('1st = €500.00')}
			<br/>{t('2nd = €200.00')}
			<br/>{t('3rd = €100.00')}
			<p/>{t('The player who finishes at the top of the leaderboard at the end of Euro 2024 will win')} <b>€500.00</b> {t('(or their local currency equivalent) in free bets deposited directly into their Betway account!')}
			<p/>{t('The person in 2nd will win')} <b>€200.00</b> {t('in free bets and third place will win')} <b>€100.00</b> {t('(or their local currency equivalent) in free bets deposited into their Betway account.')}
			<br/><br/>{t('Please note that if there is a tie for 1st, 2nd or 3rd, the participants in those positions will go in a draw to determine the winner of each prize.')}
			<p/>{t('Winners will have their Betway accounts credited within 7 days of the completion of the tournament, or will be contacted within 7 days.')}
			<p/>{t('You must have a valid active Betway account prior to participating in the contest.')}
			<p/>{t('For more information please see the full')} <a href='https://storage.googleapis.com/st_bw_africa/score_predict_tc.html' target='_blank'>{t('Terms & Conditions')}</a>.
		</div>
	)
	
	return (
		<div className={styles.container}>
			<div className={styles.prizesContainer}>
				{country?.toUpperCase() === 'ZA' ? renderAfricanPrizes() : renderPrizes()}
			</div>
		</div>
	);
}

export default PrizesFragment;