import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import { MarketEnum, playerMarketNobodyPrediction } from "../Enums/MarketEnum";
import ClientHttps, { FeatureConfigType } from "../../../Https/ClientHttps";
import PredictorHttps from "../../../Https/PredictorHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import MatchSummaryModel from "../Models/Summary/MatchSummaryModel";
import PredictorService from "../Service/PredictorService";
import PredictionRequestModel from "../Models/Predictions/PredictionRequestModel";
import PlayerFixtureModel from "../Models/Fixtures/Markets/PlayerFixtureModel";
import FixturesResponseModel from "../Models/Fixtures/FixturesResponseModel";
import PredictionsFilters from "../Models/Predictions/PredictionsFilters";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import PaginationModel from "../../../Global/Models/Pagination/PaginationModel";
import { FeaturesConfigModels } from "../../../Global/Types/GlobalTypes";
import PredictorValidator from "../Validator/PredictorValidator";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import { ErrorMessages } from "../../../Global/Messages/Messages";
import { ErrorHandlingModeType } from "../../../Configurator/Types/ConfiguratorTypes";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import PredictionResponseModel from "../Models/Predictions/PredictionResponseModel";

export default class PredictorFacade {
    readonly predictorHttps: PredictorHttps = null;
    readonly clientHttps: ClientHttps = null;
    readonly idMapping: IdMappingService = null;
    readonly predictorService: PredictorService = null;
    private predictorValidator: PredictorValidator = null;
    private errorHandlingMode: ErrorHandlingModeType = null;

    constructor(config: SDKConfigurationModel, predictorHttps: PredictorHttps, clientHttps: ClientHttps, idMapping: IdMappingService) {
        this.predictorHttps = predictorHttps;
        this.idMapping = idMapping;
        this.clientHttps = clientHttps;
        this.errorHandlingMode = config.errorHandlingMode;
        this.predictorValidator = new PredictorValidator(config.errorHandlingMode);
        this.predictorService = new PredictorService(config, idMapping);
    }

     /**
     * Exposing PredictorService for two operations:
     * 1.Validation of fixture - returns correct model.
     * 2.Perform id mapping for matchId and playerId in request body.
     * @param fixture
     * @returns Fixture response from Prediction API. Ids are remapped if idSchema is different from native.
     */

    public makeFootballPrediction = async (matchId: string, market: MarketEnum, value: any, playerId?: string) => {
        const validatedFixture = this.predictorValidator.validateFixture(matchId, market, value, playerId);

        let predictionRequest = new PredictionRequestModel();

        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
            const originalMatchId = validatedFixture.matchId;
            let originalPlayerId = "";
            const nativeMatchId = await this.predictorService.remapMatchIdToNative([originalMatchId]);
            validatedFixture.matchId = nativeMatchId[0];

            if (this.errorHandlingMode === "default" && !validatedFixture.matchId) {
                throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_FIELD, ErrorMessages.INVALID_MATCH_ID).errorMessage();
            } else if (this.errorHandlingMode === "standard" && !validatedFixture.matchId) {
                throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_FIELD, ErrorMessages.INVALID_MATCH_ID);
            }

            if (validatedFixture instanceof PlayerFixtureModel && validatedFixture.prediction.playerId !== "OWN_GOAL") {
                if (validatedFixture.prediction.playerId) {
                    originalPlayerId = validatedFixture.prediction.playerId;
                    validatedFixture.prediction.playerId = await this.predictorService.remapPlayerIdToNative([originalPlayerId]);
                }

                if (this.errorHandlingMode === "default" && validatedFixture.market !== playerMarketNobodyPrediction && !validatedFixture.prediction.playerId) {
                    throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_FIELD, ErrorMessages.INVALID_PLAYER_ID).errorMessage();
                } else if (this.errorHandlingMode === "standard" && validatedFixture.market !== playerMarketNobodyPrediction && !validatedFixture.prediction.playerId) {
                    throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_FIELD, ErrorMessages.INVALID_PLAYER_ID);
                }
            }

            predictionRequest.fixtures = [validatedFixture];
            const predictionResponse = await this.predictorHttps.makeFootballPrediction(predictionRequest);

            predictionResponse.fixtures.forEach((fixtureResponse: FixturesResponseModel) => {
                if (fixtureResponse.prediction.playerId && fixtureResponse.prediction.playerId !== "OWN_GOAL") {
                    fixtureResponse.prediction.playerId = originalPlayerId;
                }
                fixtureResponse.matchId = originalMatchId;
            });

            return predictionResponse;

        } else {
            predictionRequest.fixtures = [validatedFixture];

            return await this.predictorHttps.makeFootballPrediction(predictionRequest);
        }
    };

    /**
     * Using PredictorService to perform id mapping for matchId in request body. The response body contains all markets in property predictions.
     * In case there is prediction with player market, it property's name is the player id itself so it will be remaped to idSchema.
     * @param matchId
     * @param disableCache
     * @returns Match summary with all predictions for it. Ids are remapped if idSchema is different from native.
     */

    public getMatchSummary = async (matchId: string, disableCache: boolean): Promise<MatchSummaryModel> => {
        const matchSummaryResponse = await this.getMatchSummaryBase(matchId, disableCache);
        const predictionsToRemap = JSON.parse(JSON.stringify(matchSummaryResponse.predictions));
        const remappedIdsPredictions = await this.predictorService.remapPlayerIdToIdSchema(predictionsToRemap);
        matchSummaryResponse.predictions = remappedIdsPredictions;

        return matchSummaryResponse;
    };

    public getMarketSummary = async (matchId: string, market: MarketEnum, playerId?: string, disableCache?: boolean): Promise<any> => {
        try {
            const matchSummary = await this.getMatchSummaryBase(matchId, disableCache);

            return await this.predictorService.getMarketSummary(matchSummary, market, playerId);
        } catch (e) {
            throw e;
        }
    };

    private getMatchSummaryBase = async (matchId: string, disableCache: boolean): Promise<MatchSummaryModel> => {
        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
            const nativeId = await this.predictorService.remapMatchIdToNative([matchId]);
            const id = nativeId[0];

            const matchSummaryResponse = await this.predictorHttps.getMatchSummary(id, disableCache);
            matchSummaryResponse.matchId = matchId;

            return matchSummaryResponse;
        } else {
            return await this.predictorHttps.getMatchSummary(matchId, disableCache);
        }
    };

    public getConfig = async (): Promise<FeaturesConfigModels> => {
        const config = await this.clientHttps.getConfig(FeatureConfigType.PREDICTOR);

        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
            return await this.predictorService.remapCompetitionsFromConfig(config);
        }

        return config;
     };

    public deleteFootballPrediction = async (predictionId: string): Promise<boolean> => {
        return await this.predictorHttps.deleteFootballPrediction(predictionId);
    };

    public getMyPredictions = async (filters?: PredictionsFilters): Promise<PaginationModel> => {
        const newFilters = this.predictorService.initPredictionsFilters(filters, 'user');

        return await this.predictorHttps.getMyPredictions(newFilters);
    };

    public getMyPredictionsForMatches = async (matchIds: string[], filters: PredictionsFilters): Promise<PaginationModel> => {
        if (this.errorHandlingMode === "default" && this.predictorService.areIdsExceeded(matchIds)) {
            throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.EXCEEDED_LENGTH, ErrorMessages.QUERY_PARAM_MATCH_IDS_EXCEEDED).errorMessage();
        } else if (this.errorHandlingMode === "standard" && this.predictorService.areIdsExceeded(matchIds)) {
            throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.EXCEEDED_LENGTH, ErrorMessages.QUERY_PARAM_MATCH_IDS_EXCEEDED);
        }

        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
            matchIds = await this.predictorService.remapMatchIdToNative([...matchIds]);
        }

        const newFilters = this.predictorService.initPredictionsFilters(filters, 'matches', matchIds);

        return await this.predictorHttps.getMyPredictions(newFilters);
    };

    public getMyCurrentPredictions = async (filters?: PredictionsFilters): Promise<PaginationModel> => {
        const newFilters = this.predictorService.initPredictionsFilters(filters, 'current');

        return await this.predictorHttps.getMyPredictions(newFilters);
    };

    public getMyPastPredictions = async (filters?: PredictionsFilters): Promise<PaginationModel> => {
        const newFilters = this.predictorService.initPredictionsFilters(filters, 'past');

        return await this.predictorHttps.getMyPredictions(newFilters);
    };

    public getUserPredictions = async (userId: string, filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel> => {
        const newFilters = this.predictorService.initPredictionsFilters(filters, 'user');

        return await this.predictorHttps.getUserPredictions(userId, disableCache, newFilters);
    };

    public getUserCurrentPredictions = async (userId: string, filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel> => {
        const newFilters = this.predictorService.initPredictionsFilters(filters, 'current');

        return await this.predictorHttps.getUserPredictions(userId, disableCache, newFilters);
    };

    public getUserPastPredictions = async (userId: string, filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel> => {
        const newFilters = this.predictorService.initPredictionsFilters(filters, 'past');

        return await this.predictorHttps.getUserPredictions(userId, disableCache, newFilters);
    };

    public getUserPredictionsForMatches = async (userId: string, matchIds: string[], filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel> => {
        if (this.errorHandlingMode === "default" && this.predictorService.areIdsExceeded(matchIds)) {
            throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.EXCEEDED_LENGTH, ErrorMessages.QUERY_PARAM_MATCH_IDS_EXCEEDED).errorMessage();
        } else if (this.errorHandlingMode === "standard" && this.predictorService.areIdsExceeded(matchIds)) {
            throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.EXCEEDED_LENGTH, ErrorMessages.QUERY_PARAM_MATCH_IDS_EXCEEDED);
        }

        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
            matchIds = await this.predictorService.remapMatchIdToNative([...matchIds]);
        }

        const newFilters = this.predictorService.initPredictionsFilters(filters, 'matches', matchIds);

        return await this.predictorHttps.getUserPredictions(userId, disableCache, newFilters);
    };

    public getPredictionById = async (predictionId: string): Promise<PredictionResponseModel> => {
        return await this.predictorHttps.getPredictionById(predictionId);
    };

    public getPredictionsByIds = async (predictionIds: string[]): Promise<PredictionResponseModel[]> => {
        return await this.predictorHttps.getPredictionsByIds(predictionIds);
    };
}