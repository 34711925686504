import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { getRules } from '../../redux/predictor';

import rulesIcon from '../../assets/rules.png';
import rulesEspIcon from '../../assets/rules-esp.png';

import styles from './RulesFragment.module.css';

const RulesFragment = () => {
	const { t, i18n } = useTranslation();

	const rules = useSelector(getRules);
	
	return (
		<div className={styles.container}>
			<div className={styles.rulesContainer}>
				<div className={styles.rulesTitle}>{t('How to play the Predictor Game?')}</div>
				<img src={i18n.language === 'es' ? rulesEspIcon : rulesIcon} className={styles.rulesIcon} alt='rules-icon'/>
				{/* {rules && <div className={styles.separator}/>}
				<div dangerouslySetInnerHTML={{ __html: rules }}/> */}
				<div className={styles.rulesContent}>
					<b>{t('The rules are:')}</b>
					<p/>{t('You need to guess the correct score of matches in Euro 2024. Each prediction earns points.')}
					<p/>{t('You earn 50 points for getting the correct score. But if you don\'t get it quite right, you can still earn points:')}
					<br/>{t('5 points for guessing the winner correctly (1X2)')}
					<br/>{t('5 points for getting the home goals correct')}
					<br/>{t('5 points for getting the away goals correct')}
					<br/>{t('5 points for guessing the goal difference correct')}
					<p/>{t('You can also earn bonus points on the other markets:')}
					<br/>{t('10 points for getting the halftime result (1X2) correct')}
					<br/>{t('15 points for getting the number of corners correct')}
					<br/>{t('20 points for getting the first goalscorer correct')}
					<p/>{t('You can make changes to your predictions at any point, up until 15 minutes before kick off.')}
				</div>
			</div>
		</div>
	);
}

export default RulesFragment;