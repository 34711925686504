import MainFiltersBQ from "../../../../Global/Models/Filters/MainFiltersBQ";

const sortValues = ["CREATED_AT_ASC", "CREATED_AT_DESC", "POSTS_COUNT_ASC", "POSTS_COUNT_DESC"];
const statuses = ["ACTIVE", "INACTIVE"];

export default class DiscussionsFilters extends MainFiltersBQ {
  public discussionIds?: string[];
  public label?: string;
  public fromDate?: string;
  public toDate?: string;
  public fromCount?: string;
  public toCount?: string;
  public sort?: string;
  public status?: string;
  public skipDeleted?: boolean;

  constructor(filters: any) {
    super(filters);
    this.discussionIds =
      filters && filters.discussionIds ? filters.discussionIds : null;
    this.label = filters && filters.label ? filters.label : null;
    this.fromDate = filters && filters.fromDate ? filters.fromDate : null;
    this.toDate = filters && filters.toDate ? filters.toDate : null;
    this.fromCount = filters && filters.fromCount ? filters.fromCount : null;
    this.toCount = filters && filters.toCount ? filters.toCount : null;
    this.sort = filters && filters.sort ? filters.sort : null;
    this.status = filters?.status || null;
    this.skipDeleted =
      filters && filters.skipDeleted !== undefined ? filters.skipDeleted : null;
  }

  constructMainFiltersUrl?(): string {
    let url = super.constructMainFiltersUrl();

    if (this.discussionIds !== null) {
      url += `&discussion_ids=${this.discussionIds.join(",")}`;
    }

    if (this.label !== null) {
      url += `&label=${this.label}`;
    }

    if (this.fromDate !== null) {
      url += `&from_date=${this.fromDate}`;
    }

    if (this.toDate !== null) {
      url += `&to_date=${this.toDate}`;
    }

    if (this.fromCount !== null) {
      url += `&from_count=${this.fromCount}`;
    }

    if (this.toCount !== null) {
      url += `&to_count=${this.toCount}`;
    }

    if (this.sort && sortValues.includes(this.sort.toUpperCase())) {
      url += `&sort=${this.sort.toUpperCase()}`;
    }

    if (this.status && statuses.includes(this.status.toUpperCase())) {
      url += `&status=${this.status.toUpperCase()}`;
    }

    if (this.skipDeleted !== null) {
      url += `&skip_deleted=${this.skipDeleted}`;
    }

    return url;
  }
}
