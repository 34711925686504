import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import avatarIcon from "../../../../assets/avatar.png";
import backIcon from "../../../../assets/chevron-left.png";
import copyIcon from "../../../../assets/copy.png";
import infoIcon from "../../../../assets/info.png";
import leaveIcon from "../../../../assets/leave.png";
import moreIcon from "../../../../assets/more.png";

import { getLeagueInfoRequest } from "../../../../redux/leagues";

import ActionSheet from "../../../../common/ActionSheet";
import Loader from "../../../../common/Loader";
import Modal from "../../../../common/Modal";
import DeleteModal from "../DeleteModal";
import EditModal from "../EditModal";

import styles from "./LeagueInfo.module.css";

const LeagueInfo = ({
  fansUnitedSDK,
  leagueId,
  currentUser,
  onCopy,
  onBack,
  onEdit,
  onDelete,
  onLeave,
}) => {
  const { t } = useTranslation();
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isIOS = /iphone|ipod|ipad/.test(userAgent);
  const [isOptionsSheetOpen, setIsOptionsSheetOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [data, setData] = useState({
    leagueInfo: {},
    members: [],
    leaderboard: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const { leagueInfo, members, leaderboard } = data;

  const membersInfo = members.map((member) => {
    const statistics = leaderboard.find(
      (profile) => profile.profileId === member.id
    );

    return {
      ...member,
      points: statistics?.points || 0,
      position: statistics?.position || 0,
    };
  });

  const userInfo = membersInfo.find((member) => member.id === currentUser);

  const isAdmin = leagueInfo?.administrators?.includes(currentUser);

  const share = (invitationCode) => {
    if (navigator?.share) {
      navigator
        .share({
          title: "Join my Score Predict league",
          text: `Download the app and use invitation code '${invitationCode}' to join my league`,
          url: isIOS
            ? "https://apps.apple.com/app/id1552348455"
            : "https://play.google.com/store/apps/details?id=com.betway.scores.android&pli=1",
        })
        .then(() => console.log("Successfully shared"))
        .catch((error) => console.log("Error sharing:", error));
    } else if (!isIOS) {
      try {
        Android.shareData(
          "Join my Score Predict league",
          `Download the app and use invitation code '${invitationCode}' to join my league`,
          "https://play.google.com/store/apps/details?id=com.betway.scores.android&pli=1"
        );
      } catch (error) {
        console.error("Error sharing on android:", error);
      }
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    dispatch(getLeagueInfoRequest(fansUnitedSDK, leagueId))
      .then((response) => {
        setIsLoading(false);
        setData(response);
      })
      .catch((error) => console.log("Error: ", error));
  }, []);

  const LeagueHeader = () => (
    <div className={styles.row}>
      <img
        className={styles.rowBack}
        src={backIcon}
        onClick={() => onBack()}
        alt={"back-icon"}
      />
      <div className={styles.rowTitle}>
        <div className={styles.rowName}>{leagueInfo.name}</div>
        <div className={styles.rowInfo}>{`${membersInfo.length} ${
          membersInfo.length > 1 ? t("members") : t("member")
        }`}</div>
      </div>
      <img
        className={isAdmin ? styles.moreIcon : styles.leaveIcon}
        src={isAdmin ? moreIcon : leaveIcon}
        onClick={() =>
          isAdmin ? setIsOptionsSheetOpen(true) : setIsDeleteModalOpen(true)
        }
        alt={"more-icon"}
      />
    </div>
  );

  const InvitationCode = () => (
    <div className={styles.codeInfoContainer}>
      <div className={styles.codeTitle}>
        {t("Your friends can join your league by using this code:")}
      </div>
      <div
        className={styles.codeContainer}
        onClick={() => onCopy(leagueInfo.invitationCode?.toUpperCase())}
      >
        <div className={styles.code}>
          {leagueInfo.invitationCode?.toUpperCase()}
        </div>
        <img className={styles.codeIcon} src={copyIcon} alt={"copy-icon"} />
      </div>
      <div>
        <button
          className={styles.shareCode}
          onClick={() => {
            share(leagueInfo.invitationCode?.toUpperCase());
          }}
        >
          {t("Share code")}
        </button>
      </div>
    </div>
  );

  const InfoMessage = () => (
    <div className={styles.messageRow}>
      <img className={styles.messageIcon} src={infoIcon} alt={"info-icon"} />
      <div className={styles.messageText}>
        The points are being recalculated.
      </div>
    </div>
  );

  const UserRanking = () => (
    <div className={styles.userRanking}>
      <img className={styles.avatarIcon} src={avatarIcon} alt={"user-icon"} />
      <div className={styles.memberName}>
        {userInfo?.name ?? userInfo?.nickname}
      </div>
      <div className={styles.memberPoints}>{userInfo?.points || "-"}</div>
      <div className={styles.memberRank}>{userInfo?.position || "-"}</div>
    </div>
  );

  const MembersList = () => (
    <div className={styles.membersList}>
      <div className={styles.membersListHeader}>
        <div>{t("Members")}</div>
        <div className={styles.memberPointsHeader}>{t("Points")}</div>
        <div className={styles.memberRank}>{t("Rank")}</div>
      </div>
      <div className={styles.separator} />
      {membersInfo
        .sort((a, b) => b.points - a.points)
        .map((member) => (
          <div className={styles.memberRow} key={member.id}>
            <img
              className={styles.avatarIcon}
              src={avatarIcon}
              alt={"user-icon"}
            />
            <div className={styles.memberName}>
              {member.name ?? member.nickname}
            </div>
            <div className={styles.memberPoints}>
              {member.position ? member.points : "-"}
            </div>
            <div className={styles.memberRank}>{member.position || "-"}</div>
          </div>
        ))}
    </div>
  );

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <LeagueHeader />
      <InvitationCode />
      {/* <InfoMessage/> */}
      {members.length > 10 && <UserRanking />}
      <MembersList />
      <ActionSheet
        isOpen={isOptionsSheetOpen}
        onClose={() => setIsOptionsSheetOpen(false)}
        onEdit={() => setIsEditModalOpen(true)}
        onDelete={() => setIsDeleteModalOpen(true)}
        title={leagueInfo.name}
      />
      <Modal
        isOpen={isDeleteModalOpen || isEditModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setIsEditModalOpen(false);
        }}
        title={
          isEditModalOpen
            ? t("Edit league")
            : `${isAdmin ? t("Delete") : t("Leave")} ${leagueInfo.name}?`
        }
        subtitle={
          isDeleteModalOpen &&
          (isAdmin
            ? t("All the history and members will be permanently deleted.")
            : t("Your prediction points and ranking will be deleted."))
        }
      >
        {isDeleteModalOpen && (
          <DeleteModal
            isLeave={!isAdmin}
            onSubmit={() => {
              isAdmin ? onDelete() : onLeave();
              setIsDeleteModalOpen(false);
              setIsOptionsSheetOpen(false);
            }}
            onCancel={() => setIsDeleteModalOpen(false)}
          />
        )}
        {isEditModalOpen && (
          <EditModal
            league={leagueInfo}
            members={members}
            currentUser={currentUser}
            onSubmit={(leagueName, bannedUsers) => {
              onEdit({ ...leagueInfo, name: leagueName }, bannedUsers);
              setIsEditModalOpen(false);
              setIsOptionsSheetOpen(false);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default LeagueInfo;
