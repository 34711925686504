import MainFiltersBQ from "../../../../Global/Models/Filters/MainFiltersBQ";
import { SortOrder } from "../../../../Global/Types/GlobalTypes";
import { GameStatusEnum } from "../../Enums/GameStatusEnum";
import { TypeGames } from "../../types/types";

export default class GamesFilters extends MainFiltersBQ {
    public status?: GameStatusEnum = null;
    public type?: TypeGames = null;
    public gameIds?: string[] = null;
    public sortOrder?: SortOrder = null;

    constructor(filters: any) {
        super(filters);
        this.status = filters?.status || null;
        this.type = filters?.type || null;
        this.gameIds = filters?.gameIds || null;
        this.sortOrder = filters?.sortOrder || null;
    }

    public constructFiltersUrl? = (): string => {
        let url = super.constructMainFiltersUrl();

        if (this.status && Object.values(GameStatusEnum).includes(this.status)) {
            url += '&';
            url += `status=${this.status}`;
        }
        if (this.type) {
            url += '&';
            url += `type=${this.type}`;
        }
        if (this.gameIds && this.gameIds.length > 0) {
            url += '&';
            url += `game_ids=${this.gameIds.join(',')}`;
        }
        if (this.sortOrder) {
            url += '&';
            url += `sort_order=${this.sortOrder}`;
        }

        return url;
    };
}