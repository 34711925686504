import React from "react";

import { useTranslation } from "react-i18next";

import closeIcon from "../../../../assets/close.png";
import infoImage from "../../../../assets/how-to-play.png";

import styles from "./InfoCard.module.css";

const InfoCard = ({ onClose, onButtonPress, onPrizesButtonPress }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <img
        src={closeIcon}
        className={styles.closeButton}
        onClick={onClose}
        alt="close-icon"
      />
      <div className={styles.title}>
        {t("Predict every European Championship result")}
      </div>
      <div className={styles.info}>
        {t(
          "Which team will come out on top? Who will net the first goal? Test your prediction skills now!"
        )}
      </div>
      <img src={infoImage} className={styles.infoImage} alt="info-img" />
      <div className={styles.buttonsWrapper}>
        <button className={styles.rulesButton} onClick={onButtonPress}>
          {t("Read how to play")}
        </button>
        <button className={styles.rulesButton} onClick={onPrizesButtonPress}>
          {t("See Prizes")}
        </button>
      </div>
    </div>
  );
};

export default InfoCard;
