import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import PaginationModel from "../../../Global/Models/Pagination/PaginationModel";
import ClientHttps, { FeatureConfigType } from "../../../Https/ClientHttps";
import PredictorHttps from "../../../Https/PredictorHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import PlayerFixtureModel from "../../Predictor/Models/Fixtures/Markets/PlayerFixtureModel";
import PredictionRequestModel from "../../Predictor/Models/Predictions/PredictionRequestModel";
import PredictionResponseModel from "../../Predictor/Models/Predictions/PredictionResponseModel";
import PredictionsFilters from "../../Predictor/Models/Predictions/PredictionsFilters";
import PredictorService from "../../Predictor/Service/PredictorService";
import { FeaturesConfigModels } from "../../../Global/Types/GlobalTypes";
import { GameStatusEnum } from "../../TopX/Enums/GameStatusEnum";
import GameModel from "../../TopX/Models/Games/GameModel";
import GamesFilters from "../../TopX/Models/Games/GamesFilters";
import GameMarketsResults from "../Models/GameMarketsResults/GameMarketsResults";
import PredictorValidator from "../../Predictor/Validator/PredictorValidator";
import MainFiltersBQ from "../../../Global/Models/Filters/MainFiltersBQ";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import { ErrorMessages } from "../../../Global/Messages/Messages";
import ContestWinners from "../../TopX/Models/Games/Winners/ContestWinners";
import { ErrorHandlingModeType } from "../../../Configurator/Types/ConfiguratorTypes";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import GameByIdModel from "../../TopX/Models/Games/GameByIdModel";

export default class MatchQuizFacade {
    private predictorHttps: PredictorHttps = null;
    private predictorService: PredictorService = null;
    private idMapping: IdMappingService = null;
    private clientHttps: ClientHttps = null;
    private predictorValidator: PredictorValidator = null;
    private errorHandlingMode: ErrorHandlingModeType = null;

    constructor(config: SDKConfigurationModel) {
        this.predictorValidator = new PredictorValidator(config.errorHandlingMode);
        this.idMapping = new IdMappingService(config);
        this.predictorHttps = new PredictorHttps(config, this.idMapping)
        this.predictorService = new PredictorService(config, this.idMapping);
        this.clientHttps = new ClientHttps(config);
        this.errorHandlingMode = config.errorHandlingMode;
    }

    public getConfig = async (): Promise<FeaturesConfigModels> => {
        const config = await this.clientHttps.getConfig(FeatureConfigType.MATCH_QUIZ);

        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
            return await this.predictorService.remapCompetitionsFromConfig(config);
        }

        return config;
    };

    public play = async (matchQuizPrediction: PredictionRequestModel): Promise<PredictionResponseModel> => {
        matchQuizPrediction = this.predictorValidator.validateBodyFields("MATCH_QUIZ", matchQuizPrediction);
        const validatedFixtures = matchQuizPrediction.fixtures.map((fixture: any) => {
            return this.predictorValidator.validateFixture(fixture.matchId, fixture.market, fixture.prediction.value, fixture.prediction.playerId);
        });

        if (validatedFixtures && validatedFixtures.length) {
            if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
                let originalMatchIds: string[] = [];
                let originalPlayerIds: string[] = [];

                validatedFixtures.forEach((fixture: any) => {
                    originalMatchIds.push(fixture.matchId);
                    if (fixture instanceof PlayerFixtureModel && fixture.prediction.playerId && fixture.prediction.playerId !== "OWN_GOAL") {
                        originalPlayerIds.push(fixture.prediction.playerId);
                    }
                });

                const nativeMatchIds = await this.predictorService.remapMatchIdToNative(originalMatchIds);
                let nativePlayerIds: any = {}

                if (originalPlayerIds && originalPlayerIds.length > 0) {
                    nativePlayerIds = await this.predictorService.remapPlayerIdMapToNative(originalPlayerIds);
                }

                validatedFixtures.forEach((fixture: any, idx: number) => {
                    fixture.matchId = nativeMatchIds[idx];
                    if (fixture instanceof PlayerFixtureModel && fixture.prediction.playerId !== "OWN_GOAL") {
                        fixture.prediction.playerId = nativePlayerIds[fixture.prediction.playerId];
                    }
                });
            }
        }

        matchQuizPrediction.fixtures = validatedFixtures;

        return await this.predictorHttps.makeFootballPrediction(matchQuizPrediction);
    };

    public delete = async (gameId: string): Promise<boolean> => {
        return await this.predictorHttps.deleteFootballPrediction(gameId);
    }

    public getGames = async (filters?: GamesFilters, disableCache?: boolean): Promise<PaginationModel> => {
        disableCache = !disableCache ? false : disableCache;

        const newFilters = this.predictorService.initGameFilters("MATCH_QUIZ", filters);
        const gamesList = await this.predictorHttps.getGames(newFilters, disableCache);

        return await this.predictorService.remapMatchIdsFixtures(gamesList);
    };

    public getGameById = async (gameId: string, disableCache?: boolean): Promise<GameByIdModel> => {
        disableCache = !disableCache ? false : disableCache;
        const game = await this.predictorHttps.getGameById(gameId, disableCache);

        return await this.predictorService.remapMatchIdsFixtures(game);
    };

    public getGamePredictions = async (gameId: string, filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel>=> {
        disableCache = !disableCache ? false : disableCache;

        if (filters) {
            if (filters.type) {
                delete filters.type;
            }

            if (filters.status) {
                delete filters.status;
            }

            filters = new PredictionsFilters(filters);
        }

        return await this.predictorHttps.getGamePredictions(gameId, disableCache, filters);
    };

    public getGameResults = async (gameId: string, filters?: MainFiltersBQ, disableCache?:boolean): Promise<PaginationModel> => {
        disableCache = !disableCache ? false : disableCache;

        if (filters) {
            filters = new MainFiltersBQ(filters);
        }

        const gameResults = await this.predictorHttps.getGameResults(gameId, disableCache, filters);

        return await this.predictorService.remapMatchIdsFixtures(gameResults);
    };

    public getCurrentGameResults = async (disableCache?: boolean): Promise<GameModel> => {
        disableCache = !disableCache ? false : disableCache;
        const newFilters = new GamesFilters(null);
        newFilters.status = GameStatusEnum.OPEN;
        newFilters.type = "MATCH_QUIZ";
        const openGames = await this.predictorHttps.getGames(newFilters as GamesFilters, disableCache);
        const gameId = this.predictorService.getCurrentGameId(openGames);
        const activeGameResults = await this.getGameResults(gameId, null, disableCache);

        return activeGameResults.data[0];
    };

    public getMyGameEditions = async (filters?: MainFiltersBQ, disableCache?: boolean): Promise<PaginationModel> => {
        if (filters && filters.limit) this.validateLimitFilterOnGames(filters.limit);

        disableCache = !disableCache ? false : disableCache;
        const predictionFilters = this.predictorService.initGameTypePredictions("MATCH_QUIZ", filters);
        let myGamesIds: string[] = [];

        const myPredictions = await this.predictorHttps.getMyPredictionsNoRemap(predictionFilters);

        if (myPredictions.data.length > 0) {
            myPredictions.data.forEach((prediction: any) => {
                if (prediction.game_instance_id) {
                    myGamesIds.push(prediction.game_instance_id);
                }
            });

            const newGameFilters = this.predictorService.initGameFilters("MATCH_QUIZ", null, myGamesIds);
            const myGames = await this.predictorHttps.getUserGameEditions(newGameFilters, disableCache);

            return await this.predictorService.addPredictionProp(myPredictions, myGames);
        } else {
            return new PaginationModel();
        }
    };

    public getMyGamePrediction = async (gameId: string): Promise<PredictionResponseModel> => {
        const predictionFilters = this.predictorService.initGameTypePredictions("MATCH_QUIZ");
        const myPredictions = await this.predictorHttps.getMyPredictions(predictionFilters);

        if (myPredictions.data.length > 0) {
            const myGamePrediction = myPredictions.data.filter((prediction: PredictionResponseModel) => prediction.gameInstanceId === gameId);

            return myGamePrediction.length ? myGamePrediction[0] : null;
        }

        return null;
    };

    public getUserGameEditions = async (userId: string, filters?: MainFiltersBQ, disableCache?: boolean): Promise<PaginationModel> => {
        if (filters && filters.limit) this.validateLimitFilterOnGames(filters.limit);

        disableCache = !disableCache ? false : disableCache;
        const predictionFilters = this.predictorService.initGameTypePredictions("MATCH_QUIZ", filters);
        let userGamesIds: string[] = [];

        const userPredictions = await this.predictorHttps.getUserPredictionsNoRemap(userId, predictionFilters);

        if (userPredictions.data.length > 0) {
            userPredictions.data.forEach((prediction: any) => {
                if (prediction.game_instance_id) {
                    userGamesIds.push(prediction.game_instance_id);
                }
            });

            const newGameFilters = this.predictorService.initGameFilters("MATCH_QUIZ", null, userGamesIds);
            const userGames = await this.predictorHttps.getUserGameEditions(newGameFilters, disableCache);

            return await this.predictorService.addPredictionProp(userPredictions, userGames);
        } else {
            return new PaginationModel();
        }
    };

    public getUserGamePrediction = async (userId: string, gameId: string, disableCache?: boolean): Promise<PredictionResponseModel> => {
        disableCache = !disableCache ? false : disableCache;
        const predictionFilters = this.predictorService.initGameTypePredictions("MATCH_QUIZ");
        const userPredictions = await this.predictorHttps.getUserPredictions(userId, disableCache, predictionFilters);

        if (userPredictions.data.length > 0) {
            const userGamePrediction = userPredictions.data.filter((prediction: PredictionResponseModel) => prediction.gameInstanceId === gameId);

            return userGamePrediction.length ? userGamePrediction[0] : null;
        } else {
            return null;
        }
    };

    public getMarketsResultsForGame = async (gameId: string, disableCache?: boolean): Promise<GameMarketsResults> => {
        const gameMarketResults = await this.predictorHttps.getMarketResultsForGame(gameId, disableCache);
        const completedGameMarketResults = await this.predictorService.completeGameMarketResults(gameMarketResults, 'MATCH_QUIZ');

        return completedGameMarketResults;
    };

    public getGameWinners = async (gameId: string, disableCache?: boolean): Promise<ContestWinners> => {
        const gameWinners = await this.predictorHttps.getContestWinners(gameId, disableCache);
        const matchQuizGame = await this.getGameById(gameId);
        gameWinners.contestModel = matchQuizGame;

        return this.predictorService.completeContestWinners(gameWinners);
    };

    /**
     * To prevent passing too long URL for fetching data from Predictor API, the maximum limit is set to 50
     */

     private validateLimitFilterOnGames = (limit: number) => {
        const limitByApi = 50;

        if (this.errorHandlingMode === "default" && limit > limitByApi)
        throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.EXCEEDED_LENGTH, ErrorMessages.QUERY_PARAM_FILTERS_LIMIT_EXCEEDED)
        else if (this.errorHandlingMode === "standard" && limit > limitByApi)
        throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.EXCEEDED_LENGTH, ErrorMessages.QUERY_PARAM_FILTERS_LIMIT_EXCEEDED)
    };
}