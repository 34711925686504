import MatchBasicModel from "../../../Football/Models/Match/MatchBasicModel";
import MatchStatus from "../../../Football/Models/Match/Status/MatchStatus";
import { MarketEnum } from "../../../Predictor/Enums/MarketEnum";
import PredictionMatchQuizModel from "../Prediction/PredictionMatchQuizModel";

export default class FixturesMatchQuizModel {
    public matchId: string = null;
    public matchType: string = null;
    public market: MarketEnum = null;
    public matchModel: MatchBasicModel = null;
    public matchStatus: MatchStatus = new MatchStatus();
    public prediction: PredictionMatchQuizModel = new PredictionMatchQuizModel();
}