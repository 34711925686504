import PrivateLeagueBasicModel from "../../PrivateLeagues/Models/PrivateLeagueModel";
import ProfileModel from "../../Profile/Models/ProfileModel";

export default class AcceptChallengeModel {
  public profileId: string = "";
  public profileModel: ProfileModel = null;
  public challengeId: string = "";
  public challengeModel: PrivateLeagueBasicModel = null;
  public message: string = "";
}
