import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import InvitationFilters from "../PrivateLeagues/Models/Filters/InvitationFilters";
import PrivateLeaguePostsFilters from "../PrivateLeagues/Models/Filters/PrivateLeaguePostsFilters";
import PrivateLeaguePredictionsFilters from "../PrivateLeagues/Models/Filters/PrivateLeaguePredictionsFilters";
import PrivateLeagueParamCreateBody from "../PrivateLeagues/Models/PrivateLeagueParamCreateBody";
import PrivateLeagueParamUpdateBody from "../PrivateLeagues/Models/PrivateLeagueParamUpdateBody";
import ChallengesFacade from "./Facade/ChallengesFacade";
import ChallengeFilters from "./Models/Filters/ChallengeFilters";
import MyChallengesFilters from "./Models/Filters/MyChallengesFilters";

export default class Challenges {
  private facade: ChallengesFacade = null;

  constructor(config: SDKConfigurationModel) {
    this.facade = new ChallengesFacade(config);
  }

  public issue = async (body: PrivateLeagueParamCreateBody, userId: string) => {
    return await this.facade.issue(body, userId);
  };

  public update = async (
    challengeId: string,
    body: PrivateLeagueParamUpdateBody
  ) => {
    return await this.facade.update(challengeId, body);
  };

  public updateTemplate = async (
    challengeId: string,
    newTemplateId: string,
    oldTemplateName: string
  ) => {
    return await this.facade.updateTemplate(
      challengeId,
      newTemplateId,
      oldTemplateName
    );
  };

  public delete = async (challengeId: string) => {
    return await this.facade.delete(challengeId);
  };

  public getById = async (challengeId: string, disableCache?: boolean) => {
    return await this.facade.getById(challengeId, disableCache);
  };

  public getMyChallenges = async (filters?: MyChallengesFilters) => {
    return await this.facade.getMyChallenges(filters);
  };

  public accept = async (challengeId: string) => {
    return await this.facade.accept(challengeId);
  };

  public reject = async (challengeId: string) => {
    return await this.facade.reject(challengeId);
  };

  public leave = async (challengeId: string) => {
    return await this.facade.leave(challengeId);
  };

  public getMyChallengesStandings = async (filters?: ChallengeFilters) => {
    return await this.facade.getMyChallengesStandings(filters);
  };

  public getInvitations = async (filters?: InvitationFilters) => {
    return await this.facade.getInvitations(filters);
  };

  public getReportedPosts = async (
    challengeId: string,
    filters?: PrivateLeaguePostsFilters
  ) => {
    return await this.facade.getReportedPosts(challengeId, filters);
  };

  public getPredictions = async (
    challengeId: string,
    filters?: PrivateLeaguePredictionsFilters
  ) => {
    return await this.facade.getPredictions(challengeId, filters);
  };

  public moderatePost = async (postId: string, moderationReason: string) => {
    return await this.facade.moderatePost(postId, moderationReason);
  };
}
