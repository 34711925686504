import SDKConfigurationModel from "../../../../Configurator/Models/SDKConfiguraitonModel";
import IdMappingService from "../../../../IdMapping/IdMappingService";
import PredictionRemapper from "../Predictions/PredictionRemapper";
import MatchSummaryModel from "./MatchSummaryModel";

export default class MatchSummaryRemapper {
    private remapPredictions: PredictionRemapper = null;

    constructor(config: SDKConfigurationModel, idMapping: IdMappingService) {
        this.remapPredictions = new PredictionRemapper(config, idMapping);
    }

    public remapResponse = (response: any): MatchSummaryModel => {
        let matchSummary = new MatchSummaryModel();

        matchSummary.matchId = response.match_id;
        matchSummary.matchType = response.match_type;
        matchSummary.predictions = this.remapPredictions.remapPredictionModel(response.predictions);

        return matchSummary;
     };
}