import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import avatarIcon from '../../../../assets/avatar.png';

import styles from './EditModal.module.css';

const EditModal = ({ league, members, currentUser, onSubmit }) => {
  const { t } = useTranslation();

  const [leagueName, setLeagueName] = useState(league?.name);
  const [bannedUsers, setBannedUsers] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  const currentName = league.name;

  useEffect(() => {
    setHasChanges(bannedUsers.length > 0 || currentName !== leagueName);
  }, [leagueName, bannedUsers])

  const addUserForBan = (userId) => {
    const users = [...bannedUsers];
    users.unshift(userId);
    setBannedUsers(users);
  }

  const MemberRow = ({ member }) => (
    <div key={member.id} className={styles.row}>
      <div className={styles.user}>
        <img className={styles.avatar} src={avatarIcon} alt={'user-icon'}/>
        <div className={styles.name}>{member.name ?? member.nickname}</div>
      </div>
      {member.id !== currentUser && 
        <div 
          className={styles.removeButton} 
          onClick={() => addUserForBan(member.id)}
        >
          {t('Ban')}
        </div>
      }
    </div>
  )

  return (
    <>
      <input 
        className={styles.input}
        value={leagueName} 
        onChange={(e) => setLeagueName(e.target.value)} 
      />
      <div className={styles.title}>{t('Members')}</div>
      <div className={styles.list}>
        <MemberRow member={members.find((member) => member.id === currentUser)}/>
        {members.filter((user) => user.id !== currentUser && !bannedUsers.includes(user.id)).map((member) => 
          <MemberRow key={member.id} member={member}/>
        )}
      </div>
      <button 
        className={hasChanges ? styles.submitButton : styles.disabledButton} 
        onClick={() => onSubmit(leagueName, bannedUsers)}
        disabled={!hasChanges}
      >
        {t('Save')}
      </button>
    </>
  )
}

export default EditModal;