import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import MiniGamesHttps from "../../../Https/MiniGamesHttps";
import ActivityService from "../../Activity/Service/ActivityService";
import { IEitherOrParticipationCallback } from "../Interfaces/MiniGamesInterfaces";
import ClassicQuizBasicModel from "../Models/ClassicQuiz/ClassicQuizBasicModel";
import ClassicQuizFilters from "../Models/ClassicQuiz/ClassicQuizFilters";
import ClassicQuizParticipationBodyModel from "../Models/ClassicQuiz/ClassicQuizParticipationBodyModel";
import EitherOrBasicModel from "../Models/EitherOr/EitherOrBasicModel";
import EitherOrFilters from "../Models/EitherOr/EitherOrFilters";
import EitherOrParticipationRequestModel from "../Models/EitherOr/Participation/EitherOrParticipationRequestModel";
import MiniGamesService from "../Service/MiniGamesService";

export default class MiniGamesFacade {
  readonly https: MiniGamesHttps = null;
  readonly service: MiniGamesService = null;
  readonly activityService: ActivityService = null;
  readonly idSchema: string = null;

  constructor(config: SDKConfigurationModel) {
    this.https = new MiniGamesHttps(config);
    this.service = new MiniGamesService(config);
    this.activityService = new ActivityService(config);
    this.idSchema = config.idSchema;
  }

  public getClassicQuizzes = async (
    filters?: ClassicQuizFilters,
    showFullContextData?: boolean,
    disableCache?: boolean
  ) => {
    if (filters) {
      filters = new ClassicQuizFilters(filters);
      await this.service.finalizeMiniGamesFilters(filters);
    }

    const response = await this.https.getClassicQuizzes(filters, disableCache);

    if (showFullContextData) {
      await this.service.setContextTagsModels(response.data);
    } else {
      // No need to show entity models for context tags. Remap only the context tag ids.
      for (let value of response.data) {
        await this.remapTags(value, IdSchemaEnum.NATIVE, this.idSchema);
      }
    }

    return response;
  };

  public getClassicQuizById = async (
    classicQuizId: string,
    includeCorrectOptionIds?: boolean,
    disableCache?: boolean
  ) => {
    const data = await this.https.getClassicQuizById(
      classicQuizId,
      includeCorrectOptionIds,
      disableCache
    );
    await this.service.setContextTagsModels([data]);

    return data;
  };

  public participateInClassicQuiz = async (
    classicQuizId: string,
    questions: ClassicQuizParticipationBodyModel[],
    includeCorrectOptionIds?: boolean
  ) => {
    return await this.https.participateInClassicQuiz(
      classicQuizId,
      questions,
      includeCorrectOptionIds
    );
  };

  public getMyClassicQuizParticipations = async (classicQuizIds: string[]) => {
    return await this.https.getMyClassicQuizParticipations(classicQuizIds);
  };

  public getEitherOrs = async (
    filters?: EitherOrFilters,
    showFullContextData?: boolean,
    disableCache?: boolean
  ) => {
    if (filters) {
      filters = new EitherOrFilters(filters);
      await this.service.finalizeMiniGamesFilters(filters);
    }

    const response = await this.https.getEitherOrs(filters, disableCache);

    if (showFullContextData) {
      await this.service.setContextTagsModels(response.data);
    } else {
      // No need to show entity models for context tags. Remap only the context tag ids.
      for (let value of response.data) {
        await this.remapTags(value, IdSchemaEnum.NATIVE, this.idSchema);
      }
    }

    return response;
  };

  public getEitherOrById = async (
    eitherOrId: string,
    disableCache?: boolean
  ) => {
    const data = await this.https.getEitherOrById(eitherOrId, disableCache);
    await this.service.setContextTagsModels([data]);

    return data;
  };

  public getMyEitherOrStats = async (eitherOrId: string) => {
    return await this.https.getMyEitherOrStats(eitherOrId);
  };

  public getEitherOrResults = async (
    eitherOrId: string,
    disableCache?: boolean
  ) => {
    const response = await this.https.getEitherOrResults(
      eitherOrId,
      disableCache
    );

    return await this.service.setProfileModelsForEitherOrStandings(response);
  };

  public participateInEitherOr = async (
    eitherOrId: string,
    participation: EitherOrParticipationRequestModel | null,
    onEitherOrParticipationCallbacks?: IEitherOrParticipationCallback
  ) => {
    if (!onEitherOrParticipationCallbacks) {
      return await this.service.defaultEitherOrParticipation(
        eitherOrId,
        participation
      );
    }

    return await this.service.handleEitherOrParticipationWithCallback(
      eitherOrId,
      participation,
      onEitherOrParticipationCallbacks
    );
  };

  private remapTags = async (
    object: ClassicQuizBasicModel | EitherOrBasicModel,
    fromSchema,
    toSchema
  ) => {
    if (
      this.idSchema != IdSchemaEnum.NATIVE &&
      object.context &&
      object.context.tags &&
      object.context.tags.length
    ) {
      const copyTags = JSON.parse(JSON.stringify(object.context.tags));
      const remapIdsTags = await this.activityService.remapTagsIds(
        copyTags,
        fromSchema,
        toSchema
      );
      //@ts-ignore
      object.context.tags = remapIdsTags;
    }
  };
}
