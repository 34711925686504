import PaginationModel from "../../../../Global/Models/Pagination/PaginationModel";
import FollowingModel from "../Following/FollowingModel";

export default class FollowingRemapper  {

    public followResponse = (response: any) => {
        return response.map((resp: any) => this.remapResponse(resp));
    };

    public followingFromResponse = (response: any) => {
        const paginatedModel = new PaginationModel();

        paginatedModel.meta.pagination.nextPageStartsAfter = response.meta.pagination.next_page_starts_after;
        paginatedModel.meta.pagination.itemsPerPage = response.meta.pagination.items_per_page;
        paginatedModel.data = response.data.map((resp: any) => this.remapResponse(resp));

        return paginatedModel;
    };

    private remapResponse = (response: any) => {
        const model = new FollowingModel();

        model.avatar = response.avatar;
        model.followingId = response.following_id;
        model.name = response.name;
        model.profileId = response.profile_id;
        model.nickname = response.nickname;

        return model;
    };
}