import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import { ErrorMessages } from "../../../Global/Messages/Messages";
import IdMappingService from "../../../IdMapping/IdMappingService";
import Football from "../../Football/Football";
import CompetitionBasicModel from "../../Football/Models/Competition/CompetitionBasicModel";
import MatchFilters from "../../Football/Models/Match/MatchFilters";
import FootballPaginationModel from "../../Football/Models/Pagination/FootballPaginationModel";
import TeamBasicModel from "../../Football/Models/Team/TeamBasicModel";
import MatchQuiz from "../../MatchQuiz/MatchQuiz";
import MiniGames from "../../MiniGames/MiniGames";
import ProfileModel from "../../Profile/Models/ProfileModel";
import Profile from "../../Profile/Profile";
import GamesListModel from "../../TopX/Models/Games/GamesListModel";
import ContestWinners from "../../TopX/Models/Games/Winners/ContestWinners";
import UserListWinners from "../../TopX/Models/Games/Winners/UserListWinners";
import TopX from "../../TopX/TopX";
import BadgesModel from "../Models/Badges/BadgesModel";
import BadgesValue from "../Models/Badges/BadgesValue";
import ClientBadges from "../Models/Badges/ClientBadges";
import BadgesModelV2 from "../Models/Badges/v2/BadgesModelV2";
import BadgesValueV2 from "../Models/Badges/v2/BadgesValueV2";
import ClientBadgesV2 from "../Models/Badges/v2/ClientBadgesV2";
import FootballBadgeRequirements from "../Models/Badges/v2/Football/FootballBadgeRequirements";
import GamesBadgesValue from "../Models/Badges/v2/Games/GamesBadgesValue";
import VotingBadgeRequirements from "../Models/Badges/v2/Voting/VotingBadgeRequirements";
import EntitiesFollows, {
  EntitiesFollowsBreakdown,
} from "../Models/EntitiesFollows/EntitiesFollows";
import EntitiesFollowsFilters from "../Models/Filters/EntitiesFollowsFilters";
import LeagueLeaderboardFilters from "../Models/Filters/LeagueLeaderboardFilters";
import LoyaltyFilters from "../Models/Filters/LoyaltyFilters";
import RankingsFilters from "../Models/Filters/RankingsFilters";
import HighestSuccessRateModel from "../Models/HighestSuccessRate/HighestSuccessRateModel";
import LeaderboardModel from "../Models/Leaderboard/LeaderboardModel";
import RankingsModel from "../Models/Rankings/RankingsModel";
import TemplateByIdModel from "../Models/Template/TemplateByIdModel";
import TemplateGroups from "../Models/Template/TemplateGroups";
import TemplateModel from "../Models/Template/TemplateModel";
import { FiltersType } from "../Types/LoyaltyTypes";
export default class LoyaltyService {
  private idMappingService: IdMappingService = null;
  private profileNamespace: Profile = null;
  private footballNamespace: Football = null;
  private topXNamespace: TopX = null;
  private matchQuizNamespace: MatchQuiz = null;
  private miniGamesNamespace: MiniGames = null;
  private errorHandlingMode: string = null;

  constructor(config: SDKConfigurationModel) {
    this.idMappingService = new IdMappingService(config);
    this.profileNamespace = new Profile(config);
    this.footballNamespace = new Football(config);
    this.topXNamespace = new TopX(config);
    this.matchQuizNamespace = new MatchQuiz(config);
    this.miniGamesNamespace = new MiniGames(config);
    this.errorHandlingMode = config.errorHandlingMode;
  }

  public remapTemplatesIds = async (
    templates: TemplateModel[]
  ): Promise<TemplateModel[] | TemplateByIdModel[]> => {
    if (templates.length > 0) {
      let teamIds: string[] = [];
      let remappedTeamIds: string[] = [];

      let matchIds: string[] = [];
      let remappedMatchIds: string[] = [];

      let competitionIds: string[] = [];
      let remappedCompetitionIds: string[] = [];

      templates.forEach((template: TemplateModel) => {
        if (template.teamIds && template.teamIds.length > 0) {
          teamIds = [...teamIds, ...template.teamIds];
        }

        if (template.matchIds && template.matchIds.length > 0) {
          matchIds = [...matchIds, ...template.matchIds];
        }

        if (template.competitionIds && template.competitionIds.length > 0) {
          competitionIds = [...competitionIds, ...template.competitionIds];
        }
      });

      const entitiesToRemap = {
        team: teamIds,
        match: matchIds,
        competition: competitionIds,
      };

      const response =
        await this.idMappingService.idMappingFacade.getEntitiesByIds(
          entitiesToRemap,
          "native",
          this.idMappingService.idSchema
        );

      remappedTeamIds = [...response.team];
      remappedMatchIds = [...response.match];
      remappedCompetitionIds = [...response.competition];

      templates.forEach((template: TemplateModel) => {
        if (template.teamIds && template.teamIds.length > 0) {
          template.teamIds = template.teamIds.map(
            (id: string) => (id = response.team.shift())
          );
        }

        if (template.matchIds && template.matchIds.length > 0) {
          template.matchIds = template.matchIds.map(
            (id: string) => (id = response.match.shift())
          );
        }

        if (template.competitionIds && template.competitionIds.length > 0) {
          template.competitionIds = template.competitionIds.map(
            (id: string) => (id = response.competition.shift())
          );
        }
      });

      return templates;
    }

    return templates;
  };

  public remapTemplateGroupMatchIds = async (groups: TemplateGroups[]) => {
    if (groups && groups.length > 0) {
      for (let group of groups) {
        if (!group.filters.matchIds) {
          continue;
        }

        // The group matchIds can be array with empty string (matchIds: [""])
        const isFulfilled = group.filters.matchIds.every((matchId) => matchId);

        if (!isFulfilled) {
          continue;
        }

        if (group.filters.matchIds.length > 0) {
          const entitiesToRemap = {
            match: Array.from(group.filters.matchIds),
          };
          const response =
            await this.idMappingService.idMappingFacade.getEntitiesByIds(
              entitiesToRemap,
              "native",
              this.idMappingService.idSchema
            );
          group.filters.matchIds = Array.from(response.match);
        }
      }
    }
  };

  public addProfileModelToLeaderboard = async (
    leaderboard: any[]
  ): Promise<LeaderboardModel[]> => {
    if (leaderboard.length > 0) {
      let newLeaderboard: LeaderboardModel[] = [];

      const profileIds = leaderboard.map(
        (leaderboard: LeaderboardModel) => leaderboard.profileId
      );

      if (!profileIds.length) return leaderboard;

      const profiles = await this.profileNamespace.getByIds(profileIds);

      leaderboard.forEach((leaderboard: LeaderboardModel) => {
        profiles.forEach((profile: ProfileModel) => {
          if (profile.id === leaderboard.profileId) {
            delete profile.interests;
            leaderboard.profileModel = profile;
          }
        });

        if (!leaderboard.profileModel) {
          leaderboard.profileModel = null;
        }

        newLeaderboard.push(leaderboard);
      });

      return newLeaderboard;
    }

    return leaderboard;
  };

  public buildOldClientBadges = (badges: BadgesModel): ClientBadges => {
    let clientBadges: ClientBadges = new ClientBadges();

    clientBadges.general = this.extractEnabledBadgesId(badges.general);
    clientBadges.predictor = this.extractEnabledBadgesId(badges.predictor);
    clientBadges.topX = this.extractEnabledBadgesId(badges.topX);
    clientBadges.matchQuiz = this.extractEnabledBadgesId(badges.matchQuiz);

    return clientBadges;
  };

  public buildNewClientBadges = (badges: BadgesModelV2): ClientBadgesV2 => {
    let clientBadges: ClientBadgesV2 = new ClientBadgesV2();

    clientBadges.predictor = this.extractEnabledBadgesId(badges.predictor);
    clientBadges.topX = this.extractEnabledBadgesId(badges.topX);
    clientBadges.matchQuiz = this.extractEnabledBadgesId(badges.matchQuiz);
    clientBadges.games = this.extractEnabledBadgesId(badges.games);
    clientBadges.football = this.extractEnabledBadgesId(badges.football);
    clientBadges.classicQuizzes = this.extractEnabledBadgesId(
      badges.classicQuizzes
    );
    clientBadges.eitherOr = this.extractEnabledBadgesId(badges.eitherOr);
    clientBadges.discussions = this.extractEnabledBadgesId(badges.discussions);
    clientBadges.potm = this.extractEnabledBadgesId(badges.voting.potm);
    clientBadges.poll = this.extractEnabledBadgesId(badges.voting.poll);

    return clientBadges;
  };

  public initFilters = (filters: any, type: FiltersType) => {
    switch (type) {
      case "loyalty":
        if (filters) {
          filters = new LoyaltyFilters(filters);
        }
        break;
      case "rankings":
        if (filters) {
          filters = new RankingsFilters(filters);
        }
        break;
      case "league_leaderboard":
        if (filters) {
          filters = new LeagueLeaderboardFilters(filters);
        }
    }

    return filters;
  };

  public addProfileModelToHighestSuccessRate = async (
    highestSuccessRate: HighestSuccessRateModel[]
  ): Promise<HighestSuccessRateModel[]> => {
    const profileIds = highestSuccessRate.map(
      (value: HighestSuccessRateModel) => value.profileId
    );

    if (!profileIds.length) return highestSuccessRate;

    const profiles = await this.profileNamespace.getByIds(profileIds);

    return highestSuccessRate.map((value: HighestSuccessRateModel) => {
      profiles.forEach((profile: ProfileModel) => {
        if (profile.id === value.profileId) {
          delete profile.interests;
          value.profileModel = profile;
        }
      });

      return value;
    });
  };

  public extractRankingsIdsMap = (rankings: RankingsModel[]) => {
    let topXGameIds: string[] = [];
    let matchQuizGameIds: string[] = [];
    let templateIds: string[] = [];
    rankings.forEach((value: RankingsModel) => {
      if (value.rankType === "GAME" && value.gameType === "TOP_X")
        topXGameIds.push(value.id);
      else if (value.rankType === "GAME" && value.gameType === "MATCH_QUIZ")
        matchQuizGameIds.push(value.id);
      else if (value.rankType === "TEMPLATE") templateIds.push(value.id);
    });

    return [topXGameIds, matchQuizGameIds, templateIds];
  };

  public setModelsForUserRankings = (
    topXGames: GamesListModel[],
    matchQuizGames: GamesListModel[],
    templates: TemplateModel[],
    userRankings: FootballPaginationModel
  ): FootballPaginationModel => {
    userRankings.data.forEach((userRanking: RankingsModel) => {
      if (userRanking.gameType === "MATCH_QUIZ" && matchQuizGames) {
        userRanking.model = matchQuizGames.filter(
          (game: GamesListModel) => game.id === userRanking.id
        )[0];
      } else if (userRanking.gameType === "TOP_X" && topXGames) {
        userRanking.model = topXGames.filter(
          (game: GamesListModel) => game.id === userRanking.id
        )[0];
      } else if (userRanking.rankType === "TEMPLATE" && templates) {
        userRanking.model = templates.filter(
          (template: TemplateModel) => template.id === userRanking.id
        )[0];
      }
    });

    return userRankings;
  };

  public completeContestWinners = async (
    contestWinners: ContestWinners
  ): Promise<ContestWinners> => {
    if (contestWinners.userList && contestWinners.userList.length) {
      const profileIds = contestWinners.userList.map(
        (value: UserListWinners) => value.profileId
      );
      const profiles = await this.profileNamespace.getByIds(profileIds);
      contestWinners.userList.forEach((userWinner: UserListWinners) => {
        const profileModel = profiles.filter(
          (profileModel: ProfileModel) =>
            profileModel.id === userWinner.profileId
        )[0];
        delete profileModel.interests;
        userWinner.profileModel = profileModel;
      });
    }

    return contestWinners;
  };

  public validateEntitiesFollowsFilters = (filters: EntitiesFollowsFilters) => {
    const maxLimitOfEntities = 24;

    if (
      !filters ||
      ((!filters.footballCompetitions ||
        !filters.footballCompetitions.length) &&
        (!filters.footballTeams || !filters.footballTeams.length) &&
        (!filters.footballPlayers || !filters.footballPlayers.length))
    ) {
      if (this.errorHandlingMode === "default") {
        throw new FansUnitedSdkException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_FILTERS,
          ErrorMessages.ENTITIES_NEEDED
        );
      }

      throw new StandardFansUnitedException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_FILTERS,
        ErrorMessages.ENTITIES_NEEDED
      );
    }

    if (
      (filters.footballCompetitions &&
        filters.footballCompetitions.length > maxLimitOfEntities) ||
      (filters.footballTeams &&
        filters.footballTeams.length > maxLimitOfEntities) ||
      (filters.footballPlayers &&
        filters.footballPlayers.length > maxLimitOfEntities)
    ) {
      if (this.errorHandlingMode === "default") {
        throw new FansUnitedSdkException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.EXCEEDED_LENGTH,
          ErrorMessages.ENTITIES_FOLLOWS_FILTERS_EXCEEDED
        );
      }

      throw new StandardFansUnitedException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.EXCEEDED_LENGTH,
        ErrorMessages.ENTITIES_FOLLOWS_FILTERS_EXCEEDED
      );
    }
  };

  public remapEntitiesFollowsFilters = async (
    filters: EntitiesFollowsFilters
  ) => {
    const copyFilters = JSON.parse(
      JSON.stringify(filters)
    ) as EntitiesFollowsFilters;
    const remappedFitlers: any = {};
    const objIds: any = {};

    if (
      copyFilters.footballCompetitions &&
      copyFilters.footballCompetitions.length
    ) {
      objIds.competition = copyFilters.footballCompetitions;
    }

    if (copyFilters.footballTeams && copyFilters.footballTeams.length) {
      objIds.team = copyFilters.footballTeams;
    }

    if (copyFilters.footballPlayers && copyFilters.footballPlayers.length) {
      objIds.player = copyFilters.footballPlayers;
    }

    const nativeIdsObj =
      await this.idMappingService.idMappingFacade.getEntitiesByIds(
        objIds,
        this.idMappingService.idSchema,
        IdSchemaEnum.NATIVE
      );

    Object.keys(nativeIdsObj).forEach((entity: string) => {
      if (entity === "competition") {
        remappedFitlers.footballCompetitions = nativeIdsObj[entity];
      } else if (entity === "team") {
        remappedFitlers.footballTeams = nativeIdsObj[entity];
      } else if (entity === "player") {
        remappedFitlers.footballPlayers = nativeIdsObj[entity];
      }
    });

    return remappedFitlers;
  };

  public addModelsToEntitiesFollows = async (
    entitiesFollows: EntitiesFollows
  ) => {
    const competitionIds: string[] = [];
    const competitionIdStandard = "fb:c:";
    let competitionsMap = new Map<string, CompetitionBasicModel>();

    const teamIds: string[] = [];
    const teamIdStandard = "fb:t:";
    let teamsMap = new Map<string, TeamBasicModel>();

    const playerIds: string[] = [];
    const playerIdStandard = "fb:p:";
    let playersMap: any = null;

    // Extract entity ids
    entitiesFollows.breakdown.forEach((value: EntitiesFollowsBreakdown) => {
      if (value.id.includes(competitionIdStandard)) {
        competitionIds.push(value.id);
      } else if (value.id.includes(teamIdStandard)) {
        teamIds.push(value.id);
      } else if (value.id.includes(playerIdStandard)) {
        playerIds.push(value.id);
      }
    });

    if (competitionIds.length) {
      competitionsMap =
        await this.footballNamespace.footballFacade.competitionsFacade.getCompetitionsMapWithNativeIds(
          competitionIds
        );
    }

    if (teamIds.length) {
      teamsMap =
        await this.footballNamespace.footballFacade.teamsFacade.getTeamsMapWithNativeIds(
          teamIds
        );
    }

    if (playerIds.length) {
      playersMap =
        await this.footballNamespace.footballFacade.playerFacade.getPlayersMapWithNativeIds(
          playerIds
        );
    }

    // Set new remapped entity ids
    entitiesFollows.breakdown.forEach((value: EntitiesFollowsBreakdown) => {
      if (value.id.includes(competitionIdStandard)) {
        value.model = competitionsMap.get(value.id);
        value.id = value.model.id;
      } else if (value.id.includes(teamIdStandard)) {
        value.model = teamsMap.get(value.id);
        value.id = value.model.id;
      } else if (value.id.includes(playerIdStandard)) {
        value.model = playersMap[value.id];
        value.id = value.model.id;
      }
    });

    return entitiesFollows;
  };

  public initMatchFiltersForTemplates = (
    template: TemplateByIdModel,
    groupId?: string
  ): MatchFilters => {
    let competitionIds: string[] = [];
    let teamIds: string[] = [];
    let matchIds: string[] = [];
    let fromDate = this.reformatDate(template.fromDate);
    let toDate = this.reformatDate(template.toDate, true);
    const limit = 500;

    if (template.competitionIds && template.competitionIds.length > 0) {
      competitionIds = Array.from(template.competitionIds);
    }

    if (template.teamIds && template.teamIds.length > 0) {
      teamIds = Array.from(template.teamIds);
    }

    if (template.matchIds && template.matchIds.length > 0) {
      matchIds = Array.from(template.matchIds);
    }

    if (groupId) {
      // When client provides a groupId, we need to return only the match models for the specified group
      const group = template.groups.find((group) => group.groupId === groupId);

      if (!group) {
        if (this.errorHandlingMode === "default") {
          throw new FansUnitedSdkException(
            ErrorCodes.BAD_METHOD_CALL,
            ErrorStatuses.INVALID_TEMPLATE_GROUP_ID,
            ErrorMessages.INVALID_TEMPLATE_GROUP_ID
          );
        }

        throw new StandardFansUnitedException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_TEMPLATE_GROUP_ID,
          ErrorMessages.INVALID_TEMPLATE_GROUP_ID
        );
      }

      if (!group.filters) {
        if (this.errorHandlingMode === "default") {
          throw new FansUnitedSdkException(
            ErrorCodes.BAD_METHOD_CALL,
            ErrorStatuses.INVALID_TEMPLATE_GROUP_FILTERS,
            ErrorMessages.INVALID_TEMPLATE_GROUP_FILTERS
          );
        }

        throw new StandardFansUnitedException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_TEMPLATE_GROUP_FILTERS,
          ErrorMessages.INVALID_TEMPLATE_GROUP_FILTERS
        );
      }

      // We need to apply the from date and to date from the group
      fromDate = this.reformatDate(group.filters.fromDate);
      toDate = this.reformatDate(group.filters.toDate, true);
      // The group matchIds can be array with empty string (matchIds: [""])
      const isFulfilled = group.filters.matchIds.every((matchId) => matchId);

      if (isFulfilled) {
        matchIds = Array.from(group.filters.matchIds);
      }
    }

    const filters = new MatchFilters({
      competitionIds,
      teamIds,
      matchIds,
      fromDate,
      toDate,
      limit,
    });

    return filters;
  };

  private reformatDate = (date: string, isEndOfDay?: boolean) => {
    const dateObj = new Date(date);

    if (isEndOfDay) {
      dateObj.setUTCHours(23, 59, 59);
    }

    let formattedDate = dateObj.toISOString();
    // Slice the milliseconds and add the 'Z' at the end
    formattedDate = formattedDate.slice(0, -5) + "Z";

    return formattedDate;
  };

  private extractEnabledBadgesId = (
    badges: BadgesValue[] | BadgesValueV2[]
  ): string[] => {
    let badgesIds: string[] = [];

    badges.forEach((badgeValue: BadgesValue | BadgesValueV2) => {
      if (badgeValue.enabled) {
        badgesIds.push(badgeValue.id);
      }
    });

    return badgesIds;
  };

  public addEntityModelToFootballBadges = async (badges: BadgesModelV2) => {
    let allCompetitionIds: string[] = [];
    let allTeamIds: string[] = [];
    let allPlayerIds: string[] = [];
    let allMatchIds: string[] = [];

    if (badges.football && badges.football.length > 0) {
      allCompetitionIds = badges.football
        .filter(
          (badge) =>
            badge.requirements.entityType.toLocaleLowerCase() === "competition"
        )
        .map((badge) => badge.requirements.entityId);
      allTeamIds = badges.football
        .filter(
          (badge) =>
            badge.requirements.entityType.toLocaleLowerCase() === "team"
        )
        .map((badge) => badge.requirements.entityId);
      allPlayerIds = badges.football
        .filter(
          (badge) =>
            badge.requirements.entityType.toLocaleLowerCase() === "player"
        )
        .map((badge) => badge.requirements.entityId);
      allMatchIds = badges.football
        .filter(
          (badge) =>
            badge.requirements.entityType.toLocaleLowerCase() === "match"
        )
        .map((badge) => badge.requirements.entityId);
    }

    if (badges.voting && badges.voting.poll && badges.voting.poll.length > 0) {
      const [competitionIds, teamIds, playerIds, matchIds] =
        this.extractEntityIdsFromBadges(badges.voting.poll);
      allCompetitionIds = allCompetitionIds.concat(competitionIds);
      allCompetitionIds = Array.from(new Set(allCompetitionIds));
      allTeamIds = allTeamIds.concat(teamIds);
      allTeamIds = Array.from(new Set(allTeamIds));
      allPlayerIds = allPlayerIds.concat(playerIds);
      allPlayerIds = Array.from(new Set(allPlayerIds));
      allMatchIds = allMatchIds.concat(matchIds);
      allMatchIds = Array.from(new Set(allMatchIds));
    }

    if (badges.voting && badges.voting.potm && badges.voting.potm.length > 0) {
      const [competitionIds, teamIds, playerIds, matchIds] =
        this.extractEntityIdsFromBadges(badges.voting.potm);
      allCompetitionIds = allCompetitionIds.concat(competitionIds);
      allCompetitionIds = Array.from(new Set(allCompetitionIds));
      allTeamIds = allTeamIds.concat(teamIds);
      allTeamIds = Array.from(new Set(allTeamIds));
      allPlayerIds = allPlayerIds.concat(playerIds);
      allPlayerIds = Array.from(new Set(allPlayerIds));
      allMatchIds = allMatchIds.concat(matchIds);
      allMatchIds = Array.from(new Set(allMatchIds));
    }

    const entities = await this.fetchFootballEntities(
      allCompetitionIds,
      allTeamIds,
      allPlayerIds,
      allMatchIds
    );

    if (badges.football && badges.football.length > 0) {
      badges.football.forEach((badge) => {
        this.setEntityModelsFromMap(
          badge.requirements,
          "competition",
          entities.competitions
        );
        this.setEntityModelsFromMap(badge.requirements, "team", entities.teams);
        this.setEntityModelsFromObject(
          badge.requirements,
          "player",
          entities.players
        );
        this.setEntityModelsFromObject(
          badge.requirements,
          "match",
          entities.matches
        );
      });
    }

    if (badges.voting && badges.voting.poll && badges.voting.poll.length > 0) {
      badges.voting.poll.forEach((badge) => {
        this.setEntityModelsFromMap(
          badge.requirements,
          "competition",
          entities.competitions
        );
        this.setEntityModelsFromMap(badge.requirements, "team", entities.teams);
        this.setEntityModelsFromObject(
          badge.requirements,
          "player",
          entities.players
        );
        this.setEntityModelsFromObject(
          badge.requirements,
          "match",
          entities.matches
        );
      });
    }

    if (badges.voting && badges.voting.potm && badges.voting.potm.length > 0) {
      badges.voting.potm.forEach((badge) => {
        this.setEntityModelsFromMap(
          badge.requirements,
          "competition",
          entities.competitions
        );
        this.setEntityModelsFromMap(badge.requirements, "team", entities.teams);
        this.setEntityModelsFromObject(
          badge.requirements,
          "player",
          entities.players
        );
        this.setEntityModelsFromObject(
          badge.requirements,
          "match",
          entities.matches
        );
      });
    }
  };

  private setEntityModelsFromMap = (
    requirements: FootballBadgeRequirements | VotingBadgeRequirements,
    entityType: string,
    entities: Map<string, any>
  ) => {
    if (requirements.entityType === entityType) {
      requirements.entityModel = entities
        ? entities.get(requirements.entityId) || null
        : null;
      if (requirements.entityModel) {
        requirements.entityId = requirements.entityModel.id;
      }
    }
  };

  private setEntityModelsFromObject = (
    requirements: FootballBadgeRequirements | VotingBadgeRequirements,
    entityType: string,
    entities: Object
  ) => {
    if (requirements.entityType === entityType) {
      requirements.entityModel = entities[requirements.entityId]
        ? entities[requirements.entityId]
        : null;
      if (requirements.entityModel) {
        requirements.entityId = requirements.entityModel.id;
      }
    }
  };

  private fetchFootballEntities = async (
    competitionIds: string[],
    teamIds: string[],
    playerIds: string[],
    matchIds: string[]
  ) => {
    const entities = {
      competitions: null,
      teams: null,
      players: null,
      matches: null,
    };

    if (competitionIds.length) {
      const competitions =
        await this.footballNamespace.footballFacade.getCompetitionsMapWithNativeIds(
          competitionIds
        );
      entities.competitions = competitions;
    }

    if (teamIds.length) {
      const teams =
        await this.footballNamespace.footballFacade.getTeamsMapWithNativeIds(
          teamIds
        );
      entities.teams = teams;
    }

    if (playerIds.length) {
      const players =
        await this.footballNamespace.footballFacade.getPlayersMapWithNativeIds(
          playerIds
        );
      entities.players = players;
    }

    if (matchIds.length) {
      const matches =
        await this.footballNamespace.footballFacade.getMatchesMapWithNativeIds(
          matchIds
        );
      entities.matches = matches;
    }

    return entities;
  };

  private extractEntityIdsFromBadges = (badges: any[]) => {
    const competitionIds = badges
      .filter(
        (badge) =>
          badge.requirements.entityType.toLocaleLowerCase() === "competition"
      )
      .map((badge) => badge.requirements.entityId);
    const teamIds = badges
      .filter(
        (badge) => badge.requirements.entityType.toLocaleLowerCase() === "team"
      )
      .map((badge) => badge.requirements.entityId);
    const playerIds = badges
      .filter(
        (badge) =>
          badge.requirements.entityType.toLocaleLowerCase() === "player"
      )
      .map((badge) => badge.requirements.entityId);
    const matchIds = badges
      .filter(
        (badge) => badge.requirements.entityType.toLocaleLowerCase() === "match"
      )
      .map((badge) => badge.requirements.entityId);

    return [competitionIds, teamIds, playerIds, matchIds];
  };

  public addGameModelToGamesBadges = async (badges: BadgesModelV2) => {
    if (!badges.games || badges.games.length === 0) return;

    const topXIds = this.getGameIdsFromGamesBadge(badges.games, "top_x");
    const matchQuizIds = this.getGameIdsFromGamesBadge(
      badges.games,
      "match_quiz"
    );
    const classicQuizIds = this.getGameIdsFromGamesBadge(
      badges.games,
      "classic_quiz"
    );
    const eitherOrIds = this.getGameIdsFromGamesBadge(
      badges.games,
      "either_or"
    );
    const games = await this.fetchGamesEntities(
      topXIds,
      matchQuizIds,
      classicQuizIds,
      eitherOrIds
    );

    badges.games.forEach((badge) => {
      if (badge.requirements.specificGames) {
        badge.requirements.specificGames.forEach((specificGame) => {
          specificGame.gameModel =
            games.find((game) => game.id === specificGame.gameId) || null;
        });
      }
    });
  };

  private getGameIdsFromGamesBadge = (
    gamesBadges: GamesBadgesValue[],
    gameType: string
  ) => {
    const ids = gamesBadges
      .filter((badge) => {
        if (!badge.requirements.specificGames) return false;

        return badge.requirements.specificGames.some(
          (specificGame) =>
            specificGame.gameType.toLocaleLowerCase() === gameType
        );
      })
      .map((badge) =>
        badge.requirements.specificGames.map((game) => game.gameId)
      );

    return Array.prototype.concat.apply([], ids);
  };

  private fetchGamesEntities = async (
    topXIds: string[],
    matchQuizIds: string[],
    classicQuizIds: string[],
    eitherOrIds: string[]
  ) => {
    let games = [];

    if (topXIds.length) {
      const topX = await this.topXNamespace.getGames({
        gameIds: topXIds,
      });
      games = [...games, ...topX.data];
    }

    if (matchQuizIds.length) {
      const matchQuiz = await this.matchQuizNamespace.getGames({
        gameIds: matchQuizIds,
      });
      games = [...games, ...matchQuiz.data];
    }

    if (classicQuizIds.length) {
      const classicQuizzes = await this.miniGamesNamespace.getClassicQuizzes({
        classicQuizIds,
      });
      games = [...games, ...classicQuizzes.data];
    }

    if (eitherOrIds.length) {
      const eitherOrs = await this.miniGamesNamespace.getEitherOrs({
        eitherOrIds,
      });
      games = [...games, ...eitherOrs.data];
    }

    return games;
  };
}
