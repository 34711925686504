import FixturesModel from "../FixturesModel";
import ValueOneXTwo from "../../Value/ValueOneXTwo";
import { MarketEnum } from "../../../Enums/MarketEnum";
import { OneXTwoEnum } from "../../../Enums/ValueEnums";


export default class OneXTwoFixtureModel implements FixturesModel {
    public matchId: string = null;
    public market: MarketEnum = null;
    public matchType: string = "FOOTBALL";
    public prediction: ValueOneXTwo = null;

    constructor(matchId: string, market: MarketEnum, value: OneXTwoEnum) {
        this.matchId = matchId;
        this.market = market;
        this.prediction = new ValueOneXTwo();
        this.prediction.value = value;
    }

}