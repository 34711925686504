import {StatType} from "../StatType";

export default class StatsModel {
    public corners: StatType = null;
    public cornersFirstHalf: StatType = null;
    public cornersSecondHalf: StatType = null;
    public cornersExtraTime: StatType = null;
    public crosses: StatType = null;
    public crossesFirstHalf: StatType = null;
    public crossesSecondHalf: StatType = null;
    public crossesExtraTime: StatType = null;
    public offside: StatType = null;
    public offsideFirstHalf: StatType = null;
    public offsideSecondHalf: StatType = null;
    public offsideExtraTime: StatType = null;
    public shotsOn: StatType = null;
    public shotsOnFirstHalf: StatType = null;
    public shotsOnSecondHalf: StatType = null;
    public shotsOnExtraTime: StatType = null;
    public throwIn: StatType = null;
    public throwInFirstHalf: StatType = null;
    public throwInSecondHalf: StatType = null;
    public throwInExtraTime: StatType = null;
    public redCards: StatType = null;
    public redCardsFirstHalf: StatType = null;
    public redCardsSecondHalf: StatType = null;
    public redCardsExtraTime: StatType = null;
    public shotsOff: StatType = null;
    public shotsOffFirstHalf: StatType = null;
    public shotsOffSecondHalf: StatType = null;
    public shotsOffExtraTime: StatType = null;
    public goalKicks: StatType = null;
    public goalKicksFirstHalf: StatType = null;
    public goalKicksSecondHalf: StatType = null;
    public goalKicksExtraTime: StatType = null;
    public possession: StatType = null;
    public possessionFirstHalf: StatType = null;
    public possessionSecondHalf: StatType = null;
    public possessionExtraTime: StatType = null;
    public treatments: StatType = null;
    public treatmentsFirstHalf: StatType = null;
    public treatmentsSecondHalf: StatType = null;
    public treatmentsExtraTime: StatType = null;
    public yellowCards: StatType = null;
    public yellowCardsFirstHalf: StatType = null;
    public yellowCardsSecondHalf: StatType = null;
    public yellowCardsExtraTime: StatType = null;
    public shotsBlocked: StatType = null;
    public shotsBlockedFirstHalf: StatType = null;
    public shotsBlockedSecondHalf: StatType = null;
    public shotsBlockedExtraTime: StatType = null;
    public substitutions: StatType = null;
    public substitutionsFirstHalf: StatType = null;
    public substitutionsSecondHalf: StatType = null;
    public substitutionsExtraTime: StatType = null;
    public counterAttacks: StatType = null;
    public counterAttacksFirstHalf: StatType = null;
    public counterAttacksSecondHalf: StatType = null;
    public counterAttacksExtraTime: StatType = null;
    public foulsCommitted: StatType = null;
    public foulsCommittedFirstHalf: StatType = null;
    public foulsCommittedSecondHalf: StatType = null;
    public foulsCommittedExtraTime: StatType = null;
}