import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import DiscussionsFacade from "./Facade/DiscussionsFacade";
import CreatePostParamBody from "./Models/CreatePostParamBody";
import DiscussionsFilters from "./Models/Filters/DiscussionsFilters";
import PostsFilters from "./Models/Filters/PostsFilters";
import TopDiscussionsFilters from "./Models/Filters/TopDiscussionsFilters";
import { ReactionPostType, ReportReasonType } from "./Types/types";

export default class Discussions {
  private facade: DiscussionsFacade = null;

  constructor(config: SDKConfigurationModel) {
    this.facade = new DiscussionsFacade(config);
  }

  public getAll = async (
    filters?: DiscussionsFilters,
    disableCache?: boolean
  ) => {
    return await this.facade.getAll(filters, disableCache);
  };

  public getById = async (discussionId: string) => {
    return await this.facade.getById(discussionId);
  };

  public createPost = async (
    discussionId: string,
    body: CreatePostParamBody
  ) => {
    return await this.facade.createPost(discussionId, body);
  };

  public getPostById = async (postId: string) => {
    return await this.facade.getPostById(postId);
  };

  public updatePost = async (postId: string, content: string) => {
    return await this.facade.updatePost(postId, content);
  };

  public deletePost = async (postId: string) => {
    return await this.facade.deletePost(postId);
  };

  public getPosts = async (discussionId: string, filters?: PostsFilters) => {
    return await this.facade.getPosts(discussionId, filters);
  };

  public getPostReplies = async (postId: string, filters?: PostsFilters) => {
    return await this.facade.getPostReplies(postId, filters);
  };

  public react = async (postId: string, reaction: ReactionPostType) => {
    return await this.facade.reactToPost(postId, reaction);
  };

  public reportPost = async (
    postId: string,
    reason: ReportReasonType,
    reasonDetails?: string
  ) => {
    return await this.facade.reportPost(postId, reason, reasonDetails);
  };

  public getOwnPosts = async (filters?: PostsFilters) => {
    return await this.facade.getOwnPosts(filters);
  };

  public getUserPosts = async (
    userId: string,
    filters?: PostsFilters,
    disableCache?: boolean
  ) => {
    return await this.facade.getUserPosts(userId, filters, disableCache);
  };

  public getConfig = async () => {
    return await this.facade.getConfig();
  };

  public getPostsCount = async (
    discussionIds: string[],
    disableCache?: boolean
  ) => {
    return await this.facade.getPostsCount(discussionIds, disableCache);
  };

  public getTopDiscussions = async (
    filters?: TopDiscussionsFilters,
    disableCache?: boolean
  ) => {
    return await this.facade.getTopDiscussions(filters, disableCache);
  };
}
