import { TEMPLATE_ID } from "./constants";

export const getOverallRanking = (fansUnitedSDK) => {
  return fansUnitedSDK.loyalty.getLeaderboard(TEMPLATE_ID, { limit: 50 }, true);
};

export const getMyRanking = (fansUnitedSDK) => {
  return fansUnitedSDK.loyalty.getOwnRankings({
    leaderboardIds: [TEMPLATE_ID],
  });
};
