import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import IdMappingService from "../../../IdMapping/IdMappingService";
import MiniGamesValidator from "../../MiniGames/Validator/MiniGamesValidator";
import ProfileModel from "../../Profile/Models/ProfileModel";
import Profile from "../../Profile/Profile";
import TopDiscussionsFilters from "../Models/Filters/TopDiscussionsFilters";
import PostByIdFullModel from "../Models/PostByIdFullModel";

export default class DiscussionsService {
  private idSchema: string = null;
  private readonly profile: Profile = null;
  readonly validator: MiniGamesValidator = null;
  readonly idMapping: IdMappingService = null;

  constructor(config: SDKConfigurationModel) {
    this.idSchema = config.idSchema;
    this.profile = new Profile(config);
    this.validator = new MiniGamesValidator(config);
    this.idMapping = new IdMappingService(config);
  }

  public finalizePostFullModel = async (posts: PostByIdFullModel[]) => {
    const userIds = posts.map((post: PostByIdFullModel) => post.userId);

    const profiles = await this.profile.getByIds(userIds);

    return posts.map((post: PostByIdFullModel) => {
      post.userModel = this.extractModelById(profiles, post.userId);

      return post;
    });
  };

  public finalizeTopDiscussionsFilters = async (filters: TopDiscussionsFilters) => {
    if (
      this.idSchema !== IdSchemaEnum.NATIVE &&
      filters.entityIds &&
      filters.entityIds.length > 0
    ) {
      this.validator.validateFilters(filters);
      const idsObj = await this.idMapping.getEntityIdsBySchemaId(
        Array.from(filters.entityIds),
        filters.entityType,
        IdSchemaEnum.NATIVE
      );
      filters.entityIds = idsObj[filters.entityType];
    }
  }

  private extractModelById = (profiles: ProfileModel[], id: string) => {
    if (profiles.length) {
      const profile = profiles.find((profile: ProfileModel) => profile.id === id);

      if (profile) {
        delete profile.interests;

        return profile
      }

      return null;
    }

    return null;
  };
}
