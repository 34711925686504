import TemplateModel from "../../../../Loyalty/Models/Template/TemplateModel";
import GamesListModel from "../GamesListModel";
import UserListWinners from "./UserListWinners";

export default class ContestWinners {
  public contestId: string = "";
  public contestType: "GAME" | "TEMPLATE" = "GAME";
  public contestModel: GamesListModel | TemplateModel = null;
  public description: string = "";
  public userList: UserListWinners[] = [];
}
