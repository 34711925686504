import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Provider } from 'react-redux';

import store from './store';

import MainScreen from './screens/main/MainScreen';

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainScreen />,
    },
  ]);

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
