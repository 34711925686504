import { MarketEnum } from "../../../Enums/MarketEnum";
import ValueBoolean from "../../Value/ValueBoolean";
import FixturesModel from "../FixturesModel";

export default class RedCardFixtureModel implements FixturesModel {
    public matchId: string = null;
    public market: MarketEnum = MarketEnum.RED_CARD_MATCH;
    public matchType: string = "FOOTBALL";
    public prediction: ValueBoolean = null;

    constructor(matchId: string, value: boolean) {
        this.matchId = matchId;
        this.prediction = new ValueBoolean();
        this.prediction.value = value;
    }
}