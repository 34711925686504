import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import useFansUnitedSDK from "../../hooks/useFansUnitedSDK";

import TabsNavigation from "./components/TabsNavigation";

import BadgesFragment from "../../fragments/BadgesFragment";
import LeaguesFragment from "../../fragments/LeaguesFragment";
import PredictionsFragment from "../../fragments/PredictionsFragment";
import PrizesFragment from "../../fragments/PrizesFragment";
import RankingFragment from "../../fragments/RankingFragment";
import RulesFragment from "../../fragments/RulesFragment";

import { getTokenRequest, getUserRequest } from "../../redux/user";
import { getUserToken } from "../../redux/user/selectors";

const MainScreen = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const { i18n } = useTranslation();

  const userToken = useSelector(getUserToken);

  const fansUnitedSDK = useFansUnitedSDK();

  const dispatch = useDispatch();

  useEffect(() => {
    const userId = searchParams.get("uid");
    const country = searchParams.get("country");
    const language = searchParams.get("language") || "en";

    if (!userId) return;

    dispatch(getTokenRequest(userId, country, language));

    if (language !== "en") {
      i18n.changeLanguage(language);
      return;
    }

    if (country && ["es", "mx", "pa"].includes(country.toLowerCase())) {
      i18n.changeLanguage("es");
    }
  }, [searchParams]);

  useEffect(() => {
    if (!userToken || !fansUnitedSDK) return;

    const userName = searchParams.get("name");

    dispatch(getUserRequest(fansUnitedSDK, userName));
  }, [userToken, fansUnitedSDK]);

  const goToRules = () => {
    setSelectedTab(3);
  };

  const goToPrizes = () => {
    setSelectedTab(2);
  };

  const screens = [
    <PredictionsFragment goToRules={goToRules} goToPrizes={goToPrizes} />,
    <RankingFragment />,
    <PrizesFragment />,
    <RulesFragment />,
    <LeaguesFragment />,
    <BadgesFragment />,
  ];

  return (
    <div>
      <TabsNavigation selectedTab={selectedTab} onTabSelect={setSelectedTab} />
      {screens[selectedTab]}
    </div>
  );
};

export default MainScreen;
