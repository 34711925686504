import ContextModel from "./RequestBody/Context/ContextModel";


export default class ActivityResponseBody {
    public id: string = null;
    public profileId: string = null;
    public property: string = null;
    public value: string = null;
    public action: string = null;
    public context: ContextModel = new ContextModel();
    public points: number = 0;
}