import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import avatar from '../../../../assets/avatar.png';

import Loader from '../../../../common/Loader';

import { getImageForTeam } from './utils';

import styles from './PlayersModal.module.css';

const PlayersModal = ({ homeTeam, awayTeam, onPlayerSelect, playerPrediction, fansUnitedSDK }) => {
  const { t } = useTranslation();

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(playerPrediction);
  const [homeTeamPlayers, setHomeTeamPlayers] = useState([])
  const [awayTeamPlayers, setAwayTeamPlayers] = useState([])

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPlayers();
  }, []);
  
  const getPlayers = async () => {
    setIsLoading(true);

    const homeTeamResponse = await fansUnitedSDK.football.getTeamById(homeTeam.id);
    setHomeTeamPlayers(homeTeamResponse.squad.map((player) => { 
      return {
        id: player.id, 
        image: getImageForTeam(homeTeam.code), 
        name: player.name, //.split(' ')[1] ?? player.name, 
        position: player.position
      }
    }))

    const awayTeamResponse = await fansUnitedSDK.football.getTeamById(awayTeam.id);
    setAwayTeamPlayers(awayTeamResponse.squad.map((player) => {
      return {
        id: player.id, 
        image: getImageForTeam(awayTeam.code),
        name: player.name, //.split(' ')[1] ?? player.name, 
        position: player.position
      }
    }))

    setIsLoading(false);
  }

  const TeamButton = ({ team }) => (
    <button
      className={styles.button} 
      style={team.id === selectedTeam ? { backgroundColor: '#00A826', color: '#FFFFFF', fontWeight: 500 } : null}
      onClick={() => setSelectedTeam(team.id)}
    >
      <img className={styles.icon} src={team.assets?.logo} alt={`team-logo`}/> 
      {t(team.name)}
    </button>
  )

  const PlayersSection = ({ title, players = [] }) => (
    <>
      <div className={styles.sectionTitle}>{title}</div>
      <div className={styles.playersContainer}>
        {players.map((player) => {
          return (
            <div style={{textAlign: 'center'}} key={player.id} onClick={() => {
              setSelectedPlayer(player.id)
            }}>
              <div className={styles.player}>
                <img
                  className={selectedPlayer === player.id ? styles.avatarSelected : styles.avatar} 
                  src={player.image ?? avatar} 
                  alt={'avatar'}
                />
              </div>
              <div className={styles.name}>{player.name}</div>
            </div>
          )
        })}
      </div>
    </>
  )

  const players = !!selectedTeam 
    ? (selectedTeam === homeTeam.id) ? homeTeamPlayers : awayTeamPlayers 
    : homeTeamPlayers.concat(awayTeamPlayers)

  return ( isLoading ? <Loader/> :
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        <TeamButton team={homeTeam}/>
        <TeamButton team={awayTeam}/>
      </div>
      <div className={styles.list}>
        <PlayersSection title={t('Forwards')} players={players.filter((player) => player.position === 'forward')}/>
        <PlayersSection title={t('Midfielders')} players={players.filter((player) => player.position === 'midfielder')}/>
        <PlayersSection title={t('Defenders')} players={players.filter((player) => player.position === 'defender')}/>
        <PlayersSection title={t('Goalkeepers')} players={players.filter((player) => player.position === 'keeper')}/>
      </div>
      <button 
        className={styles.submit}
        style={!selectedPlayer ? {backgroundColor: '#CCC'} : null}
        disabled={!selectedPlayer}
        onClick={() => onPlayerSelect(selectedPlayer)}
      >
        {t('Save')}
      </button>
    </div>
  );
}

export default PlayersModal;