import { FiltersInterface } from "../../../../Global/Interfaces/GlobalInterfaces";

export default class HighestSuccessRateFilters implements FiltersInterface {
  market?: string;
  fromDate?: string;
  toDate?: string;
  usersCount?: number;
  minimumPredictions?: number;

  constructor(filters: any) {
    this.market = filters.market ? filters.market : null;
    this.fromDate = filters.fromDate ? filters.fromDate : null;
    this.toDate = filters.toDate ? filters.toDate : null;
    this.usersCount = filters.usersCount ? filters.usersCount : null;
    this.minimumPredictions = filters.minimumPredictions
      ? filters.minimumPredictions
      : null;
  }

  constructFiltersUrl?() {
    let url = "";

    if (this.market) {
      url += "&";
      url += `market=${this.market}`;
    }

    if (this.fromDate) {
      url += "&";
      url += `from_date=${this.fromDate}`;
    }

    if (this.toDate) {
      url += "&";
      url += `to_date=${this.toDate}`;
    }

    if (this.usersCount) {
      url += "&";
      url += `users_count=${this.usersCount}`;
    }

    if (this.minimumPredictions) {
      url += "&";
      url += `minimum_predictions=${this.minimumPredictions}`;
    }

    return url;
  }
}
