import SDKConfigurationModel from "../Configurator/Models/SDKConfiguraitonModel";
import { IdSchemaEnum } from "../Configurator/Enums/IdSchemaEnum";
import IdMappingFacade from "./IdMappingFacade";
import TreeTraversalExtract from "./TreeTraversalExtract";
import TreeTraversalReplace from "./TreeTraversalReplace";

export default class IdMappingService {
    readonly idSchema: string = '';
    readonly idMappingFacade: IdMappingFacade = null;

    constructor(configuration: SDKConfigurationModel) {
        this.idSchema = configuration.idSchema
        this.idMappingFacade = new IdMappingFacade(configuration);
    }

    public getEntityIdBySchemaId = (type: string, id: string) => {
        return this.idMappingFacade.getEntityById(id, type, this.idSchema);
    };

    public remapEntities = async (entities: any, type: string) => {
        const entitiesCopy = JSON.parse(JSON.stringify(entities));
        try {
            if (this.idSchema !== IdSchemaEnum.NATIVE) {
                const traverserExtract = new TreeTraversalExtract();
                const extractedIds = traverserExtract.traverse(entitiesCopy, type);
                return this.idMappingFacade.getEntitiesByIds(extractedIds, 'native', this.idSchema).then((localObjIds: any) => {
                    const traverserReplace = new TreeTraversalReplace();
                    return traverserReplace.traverse(entitiesCopy, type, localObjIds)
                });
            } else {
                return entities;
            }
        } catch (e) {
            console.warn(`There was a problem remapping entity: ${type} with inner entity `, e);
            return [];
        }
    };

    public getEntityIdsBySchemaId = async (ids: string[], type: string, toSchema?: string) => {
        const idsObj: any = {};
        idsObj[type] = ids;

        if (this.idSchema !== IdSchemaEnum.NATIVE) {
            return await this.idMappingFacade.getEntitiesByIds(idsObj, this.idSchema, toSchema);
        } else {
            return idsObj;
        }
    };

}
