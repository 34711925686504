import React from 'react';

import { TailSpin } from 'react-loader-spinner';

import styles from './Loader.module.css';

const Loader = () => {
  return (
    <div style={{ height: 200 }}>
      <TailSpin
        visible={true}
        height={100}
        width={100}
        wrapperClass={styles.loader}
        color={'#00A826'}
      />
    </div>
  )
};

export default Loader;