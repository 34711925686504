import FootballHttps from "../../../Https/FootballHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import TopPlayerModel from "../Models/Player/TopPlayerModel";
import PlayerBasicModel from "../Models/Player/PlayerBasicModel";
import PlayerFullModel from "../Models/Player/PlayerFullModel";
import MatchFullModel from "../Models/Match/MatchFullModel";
import PlayerFilters from "../Models/Player/PlayerFilters";
import { transformIdsToBeUnique } from "../../../Global/Helper";
import FootballPaginationModel from "../Models/Pagination/FootballPaginationModel";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import FootballService from "../Service/FootballService";

export default class PlayerFacade {
    private https: FootballHttps = null;
    private footballService: FootballService = null;
    private idMapping: IdMappingService = null;

    constructor(config: SDKConfigurationModel, https: FootballHttps, idMapping: IdMappingService) {
        this.idMapping = idMapping;
        this.https = https;
        this.footballService = new FootballService(config);
    }

    public getPlayersIdMapping = async (filters?: PlayerFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        try {
            let isEmpty = false
            if (filters) {
                filters = new PlayerFilters(filters);
                if (filters.countryId) {
                    const countryId = await this.idMapping.getEntityIdsBySchemaId([filters.countryId], 'country', 'native');
                    if (countryId.country.length === 0) {
                        isEmpty = true;
                    }
                    filters.countryId = countryId.country[0];
                }
                if (filters.playerIds) {
                    const playerIds = await this.idMapping.getEntityIdsBySchemaId(filters.playerIds, 'player', 'native');
                    if (playerIds.player.length === 0) {
                        isEmpty = true;
                    }
                    filters.playerIds = playerIds.player;
                }
                if (filters.scope) {
                    const competitionIds = await this.idMapping.getEntityIdsBySchemaId(filters.scope, 'competition', 'native');
                    if (competitionIds.competition.length === 0) {
                        isEmpty = true;
                    }
                    filters.scope = competitionIds.competition;
                }
                if (isEmpty) {
                    //@ts-ignore
                    return [];
                }
            }
            const players = await this.getPlayers(filters, disableCache);
            players.data = await this.idMapping.remapEntities(players.data, 'player');

            return players;
        } catch (e) {
            console.warn('There was a problem browsing the players', e);
            throw e;
        }
    };

    public getPlayersWithNativeIds = async (playerIds: string[]) => {
        const limit = playerIds.length;
        const filters = new PlayerFilters({ playerIds, limit });
        const players = await this.getPlayers(filters);
        players.data.sort((a: PlayerBasicModel, b: PlayerBasicModel) => a.id.localeCompare(b.id));

        return await this.idMapping.remapEntities(players.data, 'player');
    };

    public getPlayersMapWithNativeIds = async (playerIds: string[]) => {
        const playersMap: any = {};

        const uniqueIds = transformIdsToBeUnique(playerIds);

        uniqueIds.sort((a: string, b: string) => a.localeCompare(b));
        const players = await this.getPlayersWithNativeIds(uniqueIds);

        uniqueIds.forEach((id: string, idx: number) => {
            return playersMap[id] = players[idx];
        });

        return playersMap;
    };

    private getPlayers = async (filters?: PlayerFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.https.getPlayers(filters, disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving the players`, e);
            throw e;
        }
    };

    public getPlayerIdMapping = async (id: string, disableCache?: boolean): Promise<PlayerFullModel> => {
        try {
            const nativeId = await this.idMapping.getEntityIdsBySchemaId([id], 'player', 'native');
            const player = await this.getPlayerById(nativeId.player[0], disableCache);
            return await this.idMapping.remapEntities(player, 'player');
        } catch (e) {
            console.warn(`There was a problem browsing the following player:${id}`, e);
            throw e;
        }
    };

    private getPlayerById = async (id: string, disableCache?: boolean): Promise<PlayerFullModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.https.getPlayerById(id, disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving the following player: ${id}`, e);
            throw e;
        }
    };

    public getTopPlayersIdMapping = async (disableCache?: boolean): Promise<TopPlayerModel[]> => {
        try {
            const topPlayers = await this.getTopPlayers(disableCache);
            return await this.idMapping.remapEntities(topPlayers, 'player');
        } catch (e) {
            console.warn('There was a problem browsing the top players', e);
            throw e;
        }
    };

    private getTopPlayers = async (disableCache?: boolean): Promise<TopPlayerModel[]> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.https.getTopPlayers(disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving the top players`, e);
            throw e;
        }
    };

    public getNextMatchForPlayerIdMapping = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        try {
            const nativeId = await this.idMapping.getEntityIdsBySchemaId([id], 'player', 'native');
            id = nativeId['player'][0];
            const match = await this.getNextMatchForPlayer(id, disableCache);
            return await this.idMapping.remapEntities(match, 'match');
        } catch (e) {
            console.warn(`There was a problem browsing the next match for player:${id}`, e);
            throw e;
        }
    };

    private getNextMatchForPlayer = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;
            const nextMatchForPlayer = await this.https.getNextMatchForPlayer(id, disableCache);

            return await this.footballService.setAvailableMarketsForMatch(nextMatchForPlayer) as MatchFullModel;
        } catch (e) {
            console.warn(`There was a problem retrieving the next match for player: ${id}`, e);
            throw e;
        }
    };

    public getPrevMatchForPlayerIdMapping = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        try {
            const nativeId = await this.idMapping.getEntityIdsBySchemaId([id], 'player', 'native');
            id = nativeId['player'][0];
            const match = await this.getPrevMatchForPlayer(id, disableCache);
            return await this.idMapping.remapEntities(match, 'match');
        } catch (e) {
            console.warn(`There was a problem browsing the previous match for player:${id}`, e);
            throw e;
        }
    };

    private getPrevMatchForPlayer = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;
            const prevMatchForPlayer = await this.https.getPrevMatchForPlayer(id, disableCache);

            return await this.footballService.setAvailableMarketsForMatch(prevMatchForPlayer) as MatchFullModel;
        } catch (e) {
            console.warn(`There was a problem retrieving the previous match for player: ${id}`, e);
            throw e;
        }
    };
}