export default class PrivateLeagueBasicUpdateRequestBody {
  public name: string = "";
  public description: string = "";
  public invitation_code: string = "";
  public template_id: string = "";
  public users_can_invite_users: boolean = false;
  public administrators: string[] = [];
  public pinned_posts: string[] = [];
  public scoring_starts_at: string = "";
}
