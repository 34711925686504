import FootballHttps from "../../../Https/FootballHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import SearchModel from "../Models/Search/SearchModel";
import SearchFilters from "../Models/Search/SearchFilters";

export default class SearchFacade {
    private https: FootballHttps = null;
    private idMapping: IdMappingService = null;

    constructor(https: FootballHttps, idMapping: IdMappingService) {
        this.https = https;
        this.idMapping = idMapping;
    };

    public searchIdMapping = async (filters: SearchFilters, disableCache?: boolean): Promise<SearchModel> => {
        try {
            filters = new SearchFilters(filters);
            if (filters.scope) {
                const competitionIds = await this.idMapping.getEntityIdsBySchemaId(filters.scope, "competition", "native");
                filters.scope = competitionIds.competition;
            }
            const searchResult = await this.search(filters, disableCache);
            if (searchResult.teams && searchResult.teams.length > 0) {
                const teams = await this.idMapping.remapEntities(searchResult.teams, "team");
                searchResult.teams = teams;
            }
            if (searchResult.players && searchResult.players.length > 0) {
                const players = await this.idMapping.remapEntities(searchResult.players, "player");
                searchResult.players = players;
            }
            if (searchResult.competitions && searchResult.competitions.length > 0) {
                const competitions = await this.idMapping.remapEntities(searchResult.competitions, "competition");
                searchResult.competitions = competitions;
            }

            return searchResult;

        } catch (e) {
            console.warn(`There was a problem with browsing the search result`, e);
            throw e;
        }
    };

    private search = async (filters: SearchFilters, disableCache?: boolean): Promise<SearchModel> => {
        try {
            return await this.https.search(filters, disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving the search response`, e);
            throw e;
        }
    };

}