import { format } from 'date-fns';

export const resultSign = (match, selectedTeamId) => {
  const teamScore = match.homeTeam.id === selectedTeamId ? match.scores.ftScore.homeGoals : match.scores.ftScore.awayGoals;
  const opponentScore = match.homeTeam.id === selectedTeamId ? match.scores.ftScore.awayGoals : match.scores.ftScore.homeGoals;

  if (teamScore > opponentScore) {
    return { label: 'W', color: '#00A826' }
  }

  if (teamScore === opponentScore) {
    return { label: 'D', color: '#F69400' }
  }
  
  if (teamScore < opponentScore) {
    return { label: 'L', color: '#ED1C24' }
  }
}

export const matchDate = (date) => {
  const matchDate = date ? new Date(date) : Date.now()
  return format(matchDate, 'EEE dd MMM')
}