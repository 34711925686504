import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './OtherLeagues.module.css';

const OtherLeagues = ({ leagues, standings, onSelect }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {leagues?.length > 0 && 
        <div className={styles.list}>
          <div className={styles.listHeader}>
            <div>{t('League')}</div>
            <div>{t('Rank')}</div>
          </div>
          <div className={styles.separator}/>
          {leagues?.map((league) => {
            const members = `${league.membersCount} ${league.membersCount > 1 ? t('members') : t('member')}`
            const ranking = standings?.find((leagueInfo) => leagueInfo.privateLeagueId === league.id)?.position;

            return (
              <div className={styles.row} key={league.id} onClick={() => onSelect(league)}>
                <div className={styles.rowTitle}>
                  <div className={styles.rowName}>{league.name}</div>
                  <div className={styles.rowRank}>{ranking || '-'}</div>
                </div>
                <div className={styles.rowInfo}>{members}</div>
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default OtherLeagues;