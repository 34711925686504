import FootballCountryModel from "../Country/FootballCountryModel";

export default class CompetitionBasicModel {
    public id: string = '';
    public country: FootballCountryModel = null;
    public gender: string = '';
    public assets: {} = null;
    public type: string = '';
    public name: string = '';
}
