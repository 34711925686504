import { createSlice } from "@reduxjs/toolkit";
import { 
  getTemplateRequest,
  getMatchesRequest, 
  getUserPredictionsRequest, 
  getMatchSummaryRequest,
  makePredictionRequest,
  getConfigRequest,
 } from "../../api/predictorService";

const predictorSlice = createSlice({
  name: 'Predictor',
  initialState: {
    matches: [],
    markets: [],
    predictions: [],
    summary: [],
    rules: null,
  },
  reducers: {
    setMatches(state, action) {
      state.matches = action.payload;
    },
    setMarkets(state, action) {
      state.markets = action.payload;
    },
    setUserPredictions(state, action) {
      state.predictions = action.payload;
    },
    updateUserPrediction(state, action) {
      if (state.predictions.filter((prediction) => prediction.id === action.payload.id).length === 0) {
        state.predictions = [...state.predictions, action.payload]
      } else {
        state.predictions = state.predictions.map((prediction) => {
          return prediction.id === action.payload.id ? action.payload : prediction
        });
      }
    },
    setMatchesSummary(state, action) {
      state.summary = action.payload
    },
    setRules(state, action) {
      state.rules = action.payload
    }
  },
})

export const { 
  setMatches, 
  setMarkets, 
  setUserPredictions, 
  updateUserPrediction, 
  setMatchesSummary,
  setRules,
} = predictorSlice.actions;

export const getMatchesInfoRequest = (fansUnitedSDK) => async (dispatch) => {
  try {
    const templateResponse = await getTemplateRequest(fansUnitedSDK);
    if (!templateResponse) return;
    dispatch(setRules(templateResponse.rules));

    const configResponse = await getConfigRequest(fansUnitedSDK);
    if (!configResponse) return;
    
    const markets = templateResponse.markets.map((market) => {
      return {
        'type': market, 
        'points': configResponse.rewards.points.predictor.find((reward) => reward.id === market)?.points
      }
    })
    dispatch(setMarkets(markets));

    const userPredictionsResponse = await getUserPredictionsRequest(fansUnitedSDK);
    if (!userPredictionsResponse?.data) return;
    dispatch(setUserPredictions(userPredictionsResponse.data));

    const matchesResponse = await getMatchesRequest(
      fansUnitedSDK,
      templateResponse.competitionIds[0], 
      templateResponse.fromDate, 
      templateResponse.toDate
    );
    if (!matchesResponse?.data) return;
    dispatch(setMatches(matchesResponse.data));

    const matchesSummaryResponse = await Promise.all(
      matchesResponse.data.map(async match =>
        await getMatchSummaryRequest(fansUnitedSDK, match.id)
      )
    )
    if (!matchesSummaryResponse) return;
    dispatch(setMatchesSummary(matchesSummaryResponse));
  } catch (error) {
    throw error;
  }
};

export const createPredictionRequest = (fansUnitedSDK, matchId, market, prediction, playerId) => async (dispatch) => {
  try {
    const predictionResponse = await makePredictionRequest(fansUnitedSDK, matchId, market, prediction, playerId);
    if (!predictionResponse) return;
    dispatch(updateUserPrediction(predictionResponse));
  } catch (error) {
    throw error;
  }
};

export default predictorSlice.reducer;