import { BadgeAssets } from "./BadgesValue";
import GeneralRequirementModel from "./GeneralRequirementModel";

export default class GeneralBadgesValue {
  public id: string;
  public label: string;
  public assets: BadgeAssets = { mainImageUrl: "" };
  public enabled: boolean;
  public requirements: GeneralRequirementModel = new GeneralRequirementModel();
}
