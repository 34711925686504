import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useFansUnitedSDK from '../../hooks/useFansUnitedSDK';
import { useToast } from '../../hooks/useToast';

import BadgeCard from './BadgeCard';
import Loader from '../../common/Loader';
import Toast from '../../common/Toast';

import { getBadges, getBadgesRequest } from '../../redux/badges';

import styles from './BadgesFragment.module.css';

const BadgesFragment = () => {
	const userAgent = window.navigator.userAgent.toLowerCase()
  const isIOS = /iphone|ipod|ipad/.test(userAgent);

	const [isLoading, setIsLoading] = useState(false);
	const [toastMessage, setToastMessage] = useState(null);

	const badges = useSelector(getBadges);

	const fansUnitedSDK = useFansUnitedSDK();
	const showToast = useToast(() => setToastMessage(null), toastMessage);

	const dispatch = useDispatch();

	useEffect(() => {
    if (!fansUnitedSDK) return;
      
    getData();
  }, [fansUnitedSDK])

	const getData = () => {
    setIsLoading(true);
    dispatch(getBadgesRequest(fansUnitedSDK))
    .then(() => { setIsLoading(false) })
    .catch((error) => {
			setToastMessage({ text: error.data?.error?.message || 'Error!', type: 'error' })
			if (error.status === 401) { isIOS ? window.location.reload() : window.location.href = window.location.href }
		});
  }

	return (
		isLoading 
		? <Loader/> 
		: <div className={styles.container}>
				<div className={styles.cardsContainer}>
				{badges.map((badge) => (
					<BadgeCard key={badge.label} badge={badge}/>
				))}
				</div>
				{showToast && <Toast message={toastMessage}/>}
			</div>
	);
}

export default BadgesFragment;