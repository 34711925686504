import { IRemapper } from "../../../../Global/IRemapper";
import TeamBasicRemapper from "../Team/Remappers/TeamBasicRemapper";
import PlayerBasicRemapper from "../Player/Remappers/PlayerBasicRemapper";
import CompetitionBasicRemapper from "../Competition/Remappers/CompetitionBasicRemapper";
import SearchModel from "./SearchModel";
import TeamBasicModel from "../Team/TeamBasicModel";
import PlayerBasicModel from "../Player/PlayerBasicModel";
import CompetitionBasicModel from "../Competition/CompetitionBasicModel";

export default class SearchRemapper implements IRemapper<SearchModel> {
    private teamRemapper: TeamBasicRemapper = null;
    private playerRemapper: PlayerBasicRemapper = null;
    private competitionremapper: CompetitionBasicRemapper = null;


    constructor() {
        this.teamRemapper = new TeamBasicRemapper();
        this.playerRemapper = new PlayerBasicRemapper();
        this.competitionremapper = new CompetitionBasicRemapper();
    }

    fromResponse(response: any): SearchModel {
        try {
            const model = new SearchModel();
            model.teams = response.teams ? response.teams.map((team: TeamBasicModel) => this.teamRemapper.fromResponse(team)) : [];
            model.players = response.players ? response.players.map((player : PlayerBasicModel) => this.playerRemapper.fromResponse(player)) : [];
            model.competitions = response.competitions ? response.competitions.map((competition: CompetitionBasicModel) => this.competitionremapper.fromResponse(competition)) : [];
            
            return model;
        } catch (e) {
            console.warn("There was a problem remapping search response", e);
            throw e;
        }
    };

}