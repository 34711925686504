import {LangEnum} from "../Enums/LangEnum";
import {ValidationResponseEnum} from "../Enums/ValidationResponseEnum";
import IValidator from "./IValidator";
import {ValidationMessageEnum} from "../Enums/ValidationMessageEnum";
import {LogEnum} from "../Enums/LogEnum";
import MessageLogModel from "../Models/MessageLogModel";
import SDKConfigurationModel from "../Models/SDKConfiguraitonModel";
import ValidationItemModel from "../Models/ValidationItemModel";

export default class LangValidator implements IValidator {

    public validate = (config: SDKConfigurationModel): ValidationItemModel => {
        let validation: ValidationResponseEnum = this.validateField(config.lang);
        let message: MessageLogModel = this.generateMessage(validation);
        return {validation, message};
    };

    private validateField = (lang: string): ValidationResponseEnum => {
        const langValues = Object.values(LangEnum);
        let validation: ValidationResponseEnum = null;

        if (lang !== undefined && lang !== null && lang.length > 0) {
            if (langValues.includes(lang as LangEnum)) {
                validation = ValidationResponseEnum.VALID_VALUE;
            } else {
                validation = ValidationResponseEnum.INVALID_VALUE;
            }
        } else {
            validation = ValidationResponseEnum.MISSING_VALUE;
        }

        return validation;
    };

    private generateMessage = (validation: ValidationResponseEnum): MessageLogModel => {
        let text: ValidationMessageEnum = null;
        let type: LogEnum = LogEnum.WARNING;

        if (validation === ValidationResponseEnum.MISSING_VALUE) {
            text = ValidationMessageEnum.LANG_MISSING;
        }
        if (validation === ValidationResponseEnum.INVALID_VALUE) {
            text = ValidationMessageEnum.LANG_INVALID;
        }
        if (validation === ValidationResponseEnum.VALID_VALUE) {
            text = null;
            type = null;
        }


        return {text: text, type: type};
    };

}