import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import { limitIds, setBaseUrl } from "../Global/Helper";
import ProfileModel from "../Namespaces/Profile/Models/ProfileModel";
import ProfileCountryModel from "../Namespaces/Profile/Models/ProfileCountryModel";
import ProfileRemapper from "../Namespaces/Profile/Models/Remappers/ProfileRemapper";
import FollowingRemapper from "../Namespaces/Profile/Models/Remappers/FollowingRemapper";
import FollowersRemapper from "../Namespaces/Profile/Models/Remappers/FollowersRemapper";
import FollowingModel from "../Namespaces/Profile/Models/Following/FollowingModel";
import FollowModel from "../Namespaces/Profile/Models/Following/FollowModel";
import FollowFilters from "../Namespaces/Profile/Models/FollowFilters";
import PaginationModel from "../Global/Models/Pagination/PaginationModel";
import { LangType } from "../Configurator/Types/ConfiguratorTypes";
import Https from "./Https";

const COUNTRIES_REQUEST = '/countries';
const PROFILE_REQUEST = '/profile';
const PROFILES_REQUEST = '/profiles';

export default class ProfileHttps extends Https {
    private profileRemapper: ProfileRemapper = null;
    private followingRemapper: FollowingRemapper = null;
    private followersRemapper: FollowersRemapper = null;
    private lang: LangType = null;
    private limitIds: number = 100;

    constructor(configuration: SDKConfiguraitonModel) {
        super(configuration, setBaseUrl(configuration.environment, "profile"));
        this.lang = configuration.lang;
        this.profileRemapper = new ProfileRemapper();
        this.followingRemapper = new FollowingRemapper();
        this.followersRemapper = new FollowersRemapper();
    }

    public getCountries = async (disableCache: boolean): Promise<ProfileCountryModel[]> => {
        const langParam = `&lang=${this.lang}`;
        let url = `${COUNTRIES_REQUEST}${this.apiSignInUrl}${langParam}`;
        const timestampCache = Date.now();
        const warnMessage = "There was a problem fetching profile countries";

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return data.data;
    };

    public getProfile = async (id?: string): Promise<ProfileModel> => {
        let url = `${PROFILE_REQUEST}${this.apiSignInUrl}`;
        let warnMessage = "";

        if (id) {
            url = `${PROFILES_REQUEST}/${id}${this.apiSignInUrl}`;
            warnMessage = `There was a problem fetching the following profile: ${id}`;
            const data = await this.fetchWithoutAuth(url, warnMessage);

            return this.profileRemapper.fullProfileFromResponse(data.data);
        } else {
            warnMessage = "There was a problem fetching your profile from API";
            const data = await this.fetchWithAuth(url, warnMessage);

            return this.profileRemapper.fullProfileFromResponse(data.data);
        }
    };

    public getProfiles = async (profiles?: string[], search?: string,  disableCache?: boolean): Promise<ProfileModel[]> => {
        const timestampCache = Date.now();
        let warnMessage = `There was a problem fetching the following profiles: ${profiles.join(",")}`;

        if (profiles && profiles.length) {
            if (profiles.length > this.limitIds) {
                let profileModels: ProfileModel[] = [];
                const limitedIds = limitIds(profiles, this.limitIds);

                for (const ids of limitedIds) {
                    let url = `${PROFILES_REQUEST}${this.apiSignInUrl}&profile_ids=${ids.join(',')}`;
                    warnMessage = `There was a problem fetching the following profiles: ${ids.join(",")}`;

                    if (disableCache) {
                        url += `&disable_cache=${timestampCache}`;
                    }

                    const data = await this.fetchWithoutAuth(url, warnMessage);
                    profileModels.push(...this.profileRemapper.profilesFromResponse(data.data));
                }

                return profileModels;
            }
            let url = `${PROFILES_REQUEST}${this.apiSignInUrl}&profile_ids=${profiles.join(',')}`;

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const data = await this.fetchWithoutAuth(url, warnMessage);

            return this.profileRemapper.profilesFromResponse(data.data);
        }

        let url = `${PROFILES_REQUEST}${this.apiSignInUrl}&search=${search}`;

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.profileRemapper.profilesFromResponse(data.data);
    };

    public getFollowers = async (filters?: FollowFilters, id?: string): Promise<PaginationModel> => {
        let url = `${PROFILE_REQUEST}/followers${this.apiSignInUrl}`;
        let warnMessage = "There was a problem fetching the followers for own profile";

        if (filters && !id) {
            url = `${PROFILE_REQUEST}/followers${this.apiSignInUrl}${filters.constructFilterUrl()}`;
            const data = await this.fetchWithAuth(url, warnMessage);

            return this.followersRemapper.followersFromResponse(data);
        } else if (!filters && !id) {
            const data = await this.fetchWithAuth(url, warnMessage);

            return this.followersRemapper.followersFromResponse(data);
        }

        if (filters && id) {
            url = `${PROFILE_REQUEST}/${id}/followers${this.apiSignInUrl}${filters.constructFilterUrl()}`;
        } else if (!filters && id) {
            url = `${PROFILE_REQUEST}/${id}/followers${this.apiSignInUrl}`;
        }

        warnMessage = `There was a problem fetching the followers for profile: ${id}`;
        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.followersRemapper.followersFromResponse(data);
    };

    public getFollowing = async (filters?: FollowFilters, id?: string): Promise<PaginationModel> => {
        let url = `${PROFILE_REQUEST}/following${this.apiSignInUrl}`;
        let warnMessage = "There was a problem fetching the followers of own profile";

        if (filters && !id) {
            url = `${PROFILE_REQUEST}/following${this.apiSignInUrl}${filters.constructFilterUrl()}`;
            const data = await this.fetchWithAuth(url, warnMessage);

            return this.followingRemapper.followingFromResponse(data);
        } else if (!filters && !id) {
            const data = await this.fetchWithAuth(url, warnMessage);

            return this.followingRemapper.followingFromResponse(data);
        }

        if (filters && id) {
            url = `${PROFILE_REQUEST}/${id}/following${this.apiSignInUrl}${filters.constructFilterUrl()}`;
        } else if (!filters && id) {
            url = `${PROFILE_REQUEST}/${id}/following${this.apiSignInUrl}`;
        }

        warnMessage = `There was a problem fetching the followers of the following profile: ${id}`;
        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.followingRemapper.followingFromResponse(data);
    };

    public follow = async (profiles: string[]): Promise<FollowingModel[]> => {
        const followIds = new FollowModel();
        followIds.profile_ids = profiles;
        const url = PROFILE_REQUEST + `/follow${this.apiSignInUrl}`;
        const warnMessage = `There was a problem with following profiles: ${profiles.join(",")}`;
        const method = "POST";
        const data = await this.fetchWithAuth(url, warnMessage, method, followIds);

        return this.followingRemapper.followResponse(data.data);
    };

    public unfollow = async (profiles: string[]): Promise<boolean> => {
        const unfollowIds = new FollowModel();
        unfollowIds.profile_ids = profiles;
        const url = PROFILE_REQUEST + `/follow${this.apiSignInUrl}`;
        const warnMessage = `There was a problem with unfollowing profiles: ${profiles.join(",")}`;
        const method = "DELETE";
        await this.fetchWithAuth(url, warnMessage, method, unfollowIds);

        return true;
    };

    public getBadges = async (profileId?: string): Promise<string[]> => {
        let url = `${PROFILE_REQUEST}/badges${this.apiSignInUrl}`;
        let warnMessage = "There was a problem with fetching own badges";

        if (profileId) {
            url = `${PROFILE_REQUEST}/${profileId}/badges${this.apiSignInUrl}`;
            warnMessage = "There was a problem with fetching the badges";
            const data = await this.fetchWithoutAuth(url, warnMessage);

            return data.data;
        }

        const data = await this.fetchWithAuth(url, warnMessage);

        return data.data;
    };

    public updateProfile = async (profile: ProfileModel): Promise<ProfileModel> => {
        const url = PROFILE_REQUEST + this.apiSignInUrl;
        const warnMessage = "There was a problem updating the own profile";
        const method = "PATCH";
        const data = await this.fetchWithAuth(url, warnMessage, method, profile);

        return this.profileRemapper.fullProfileFromResponse(data.data);
    };
}
