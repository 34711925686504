import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import { ErrorMessages } from "../../../Global/Messages/Messages";
import LoyaltyHttps from "../../../Https/LoyaltyHttps";
import PrivateLeaguesHttps from "../../../Https/PrivateLeaguesHttps";
import PostByIdFullModel from "../../Discussions/Models/PostByIdFullModel";
import DiscussionsService from "../../Discussions/Service/DiscussionsService";
import Profile from "../../Profile/Profile";
import PrivateLeaguesFactory from "../Factory/PrivateLeaguesFactory";
import AcceptPrivateLeagueModel from "../Models/AcceptPrivateLeagueModel";
import InvitationFilters from "../Models/Filters/InvitationFilters";
import MyLeaguesFilters from "../Models/Filters/MyLeaguesFilters";
import PrivateLeagueFilters from "../Models/Filters/PrivateLeagueFilters";
import PrivateLeaguePostsFilters from "../Models/Filters/PrivateLeaguePostsFilters";
import PrivateLeaguePredictionsFilters from "../Models/Filters/PrivateLeaguePredictionsFilters";
import PrivateLeagueParamCreateBody from "../Models/PrivateLeagueParamCreateBody";
import PrivateLeagueParamUpdateBody from "../Models/PrivateLeagueParamUpdateBody";
import PrivateLeagueService from "../Service/PrivateLeagueService";

export default class PrivateLeaguesFacade {
  private factory: PrivateLeaguesFactory = null;
  private privateLeagueHttps: PrivateLeaguesHttps = null;
  private loyaltyHttps: LoyaltyHttps = null;
  private profile: Profile = null;
  private service: PrivateLeagueService = null;
  private discussionsService: DiscussionsService = null;

  constructor(config: SDKConfigurationModel) {
    this.factory = new PrivateLeaguesFactory();
    this.privateLeagueHttps = new PrivateLeaguesHttps(config);
    this.loyaltyHttps = new LoyaltyHttps(config);
    this.profile = new Profile(config);
    this.service = new PrivateLeagueService(config);
    this.discussionsService = new DiscussionsService(config);
  }

  public create = async (body: PrivateLeagueParamCreateBody) => {
    const requestBody = this.factory.createRequestBodyFromParam(
      body,
      "PRIVATE"
    );
    const privateLeague = await this.privateLeagueHttps.create(
      requestBody,
      "PRIVATE_LEAGUE"
    );
    await this.service.setTemplateModels(privateLeague);

    return privateLeague;
  };

  public getById = async (leagueId: string, disableCache?: boolean) => {
    const privateLeague = await this.privateLeagueHttps.getById(
      leagueId,
      "PRIVATE_LEAGUE",
      disableCache
    );
    await this.service.setTemplateModels(privateLeague);

    return privateLeague;
  };

  public getMyLeagues = async (filters: MyLeaguesFilters) => {
    filters = new MyLeaguesFilters(filters);
    const data = await this.privateLeagueHttps.getMyLeagues(
      filters,
      "PRIVATE_LEAGUE"
    );

    await this.service.setTemplateModelsInLeagues(data);

    return data;
  };

  public update = async (
    leagueId: string,
    body: PrivateLeagueParamUpdateBody
  ) => {
    const requestBody = this.factory.createUpdateRequestBodyFromParam(
      body,
      "PRIVATE"
    );
    const privateLeague = await this.privateLeagueHttps.update(
      leagueId,
      requestBody,
      "PRIVATE_LEAGUE"
    );
    await this.service.setTemplateModels(privateLeague);

    return privateLeague;
  };

  public updateTemplate = async (
    leagueId: string,
    newTemplateId: string,
    oldTemplateName: string
  ) => {
    const privateLeague = await this.privateLeagueHttps.updateLeagueTemplate(
      leagueId,
      newTemplateId,
      oldTemplateName,
      "PRIVATE_LEAGUE"
    );
    await this.service.setTemplateModels(privateLeague);

    return privateLeague;
  };

  public delete = async (leagueId: string) => {
    return await this.privateLeagueHttps.delete(leagueId, "PRIVATE_LEAGUE");
  };

  public invite = async (leagueId: string, profiles: string[]) => {
    return await this.privateLeagueHttps.invite(leagueId, profiles);
  };

  public deleteInvitation = async (leagueId: string, profiles: string[]) => {
    return await this.privateLeagueHttps.deleteInvitation(leagueId, profiles);
  };

  public accept = async (leagueId: string) => {
    const isAccepting = true;
    const acceptModel = await this.privateLeagueHttps.accept(
      leagueId,
      isAccepting,
      "PRIVATE_LEAGUE"
    );

    return await this.finalizeAcceptModel(
      acceptModel as AcceptPrivateLeagueModel,
      isAccepting
    );
  };

  public reject = async (leagueId: string) => {
    const isAccepting = false;
    const acceptModel = await this.privateLeagueHttps.accept(
      leagueId,
      isAccepting,
      "PRIVATE_LEAGUE"
    );

    return await this.finalizeAcceptModel(
      acceptModel as AcceptPrivateLeagueModel,
      isAccepting
    );
  };

  public banUsers = async (leagueId: string, profiles: string[]) => {
    return await this.privateLeagueHttps.ban(leagueId, profiles);
  };

  public unbanUsers = async (leagueId: string, profiles: string[]) => {
    return await this.privateLeagueHttps.unban(leagueId, profiles);
  };

  public join = async (code: string) => {
    const acceptModel = await this.privateLeagueHttps.join(code);

    return await this.finalizeAcceptModel(
      acceptModel as AcceptPrivateLeagueModel,
      true
    );
  };

  public leave = async (leagueId: string) => {
    return await this.privateLeagueHttps.leave(leagueId, "PRIVATE_LEAGUE");
  };

  private finalizeAcceptModel = async (
    acceptModel: AcceptPrivateLeagueModel,
    isAccepted: boolean
  ) => {
    // When a private league invitation is rejected, the user can't get the league by ID, because he is not a member
    if (isAccepted) {
      const challengeById = await this.getById(acceptModel.leagueId);
      acceptModel.leagueModel = challengeById;
    }

    await this.service.setProfileModel(acceptModel);

    return acceptModel;
  };

  public getMyLeaguesStandings = async (filters: PrivateLeagueFilters) => {
    if (filters) {
      filters = new PrivateLeagueFilters(filters);
    }

    const ownProfile = await this.profile.getOwn().getInfo();
    const privateLeaguesRankings =
      await this.loyaltyHttps.getUserRankingsForPrivateLeagueAndChallenges(
        ownProfile.id,
        "PRIVATE_LEAGUE",
        filters
      );

    for (const ranking of privateLeaguesRankings.data) {
      const privateLeagueModel = await this.getById(ranking.privateLeagueId);
      ranking.privateLeagueModel = privateLeagueModel;
    }

    this.service.setTemplateModelsFromLeagueModels(
      privateLeaguesRankings.data,
      "PRIVATE_LEAGUE"
    );

    return privateLeaguesRankings;
  };

  public getInvitations = async (filters?: InvitationFilters) => {
    filters = new InvitationFilters(filters);
    filters.type = "PRIVATE";

    const data = await this.privateLeagueHttps.getInvitations(
      "PRIVATE_LEAGUE",
      filters
    );

    await this.service.setTemplateModelsInLeagues(data.data);

    return data;
  };

  public getReportedPosts = async (
    leagueId: string,
    filters?: PrivateLeaguePostsFilters
  ) => {
    if (filters) {
      filters = new PrivateLeaguePostsFilters(filters);
    }

    const response = await this.privateLeagueHttps.getReportedPosts(
      leagueId,
      filters
    );
    response.data = await this.discussionsService.finalizePostFullModel(
      response.data as PostByIdFullModel[]
    );

    return response;
  };

  public getPredictions = async (
    leagueId: string,
    filters?: PrivateLeaguePredictionsFilters
  ) => {
    if (filters) {
      const maxLimit = 20;

      if (filters.limit && filters.limit > maxLimit) {
        // Prediction API is limited with fetching predictions by ids (max ids are 20)
        throw new StandardFansUnitedException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_LIMIT,
          ErrorMessages.INVALID_LIMIT
        );
      }

      filters = new PrivateLeaguePredictionsFilters(filters);
    }

    const response = await this.privateLeagueHttps.getPredictions(
      leagueId,
      filters
    );

    return await this.service.setModelsForPredictions(response);
  };

  public moderatePost = async (postId: string, moderationReason: string) => {
    return await this.privateLeagueHttps.moderatePost(postId, moderationReason);
  };
}
