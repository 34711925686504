import React, { useEffect, useState } from 'react';

import chevronUp from '../../../../assets/chevron-up.png';
import chevronDown from '../../../../assets/chevron-down.png';
import cvehronDownDisabled from '../../../../assets/chevron-down-disabled.png';

import styles from './ScorePrediction.module.css';
import { MatchStatus } from '../../../../Utils';

const ScorePrediction = ({ match, score, onScoreSet }) => {
  const [homeTeamScore, setHomeTeamScore] = useState(null);
  const [awayTeamScore, setAwayTeamScore] = useState(null);

  const canAddPrediction = !match.undecided && match.status.type !== MatchStatus.FINISHED && match.status.type !== MatchStatus.IN_PROGRESS;

  useEffect(() => {
    setHomeTeamScore(score.homeTeamScore)
    setAwayTeamScore(score.awayTeamScore)
  }, [score])

  const TeamLogo = ({ team }) => (
    <div className={styles.logoContainer}>
      <div className={styles.logo}> 
        <img className={styles.icon} src={team?.assets?.logo} alt={`${team?.id}-team-logo`}/> 
      </div>
      <div className={styles.name}>{team?.code}</div>
    </div>
  )

  const ScorePicker = ({ score, onChange }) => {
    return (
      <div className={styles.pickerContainer}>
        {canAddPrediction && 
          <div className={styles.buttonContainer} onClick={() => onChange(score === null ? 0 : score+1)}>
            <img className={styles.button} src={chevronUp} alt={'chevron-up'}/>
          </div> 
        }
        <input 
          disabled={!canAddPrediction}
          inputMode='numeric'
          className={styles.input}
          maxLength={2}
          value={score ?? '-'} 
          onChange={(e) => {
            if (!/[0-9, null]/.test(e.nativeEvent.data)) {
              e.preventDefault();
              return
            }
            const score = e.nativeEvent.data === null ? null : parseInt(e.nativeEvent.data)
            onChange(score)
          }} 
        />
        {canAddPrediction && 
          <div className={styles.buttonContainer} onClick={() => score > 0 ? onChange(score-1) : {}}>
            <img className={styles.button} src={score > 0 ? chevronDown : cvehronDownDisabled} alt={'chevron-down'}/>
          </div>
        }
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.predictionContainer}>
        <TeamLogo team={match?.homeTeam}/>
        <ScorePicker 
          score={homeTeamScore}
          onChange={(newScore) => {
            setHomeTeamScore(newScore)
            if (parseInt(newScore) >= 0 && parseInt(awayTeamScore) >= 0) {
              onScoreSet({homeTeamScore: newScore, awayTeamScore: awayTeamScore})
            }
          }}
        />
        <div className={styles.dash}>{'-'}</div>
        <ScorePicker 
          score={awayTeamScore}
          onChange={(newScore) => {
            setAwayTeamScore(newScore)
            if (parseInt(newScore) >= 0 && parseInt(homeTeamScore) >= 0) {
              onScoreSet({homeTeamScore: homeTeamScore, awayTeamScore: newScore})
            }
          }}
        />
        <TeamLogo team={match?.awayTeam}/>
      </div>
      {match.status.type === MatchStatus.FINISHED && 
        <div className={styles.result}>
          {`FT: ${match.scores.ftScore.homeGoals}-${match.scores.ftScore.awayGoals}`}
        </div>
      }
    </div>
  )
}

export default ScorePrediction;