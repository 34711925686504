import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { matchDate, resultSign } from './utils';
import { MatchStatus } from '../../../../Utils';

import Loader from '../../../../common/Loader';

import styles from './StatisticsModal.module.css';

const StatisticsModal = ({ homeTeam, awayTeam, fansUnitedSDK }) => {
  const { t } = useTranslation();

  const MATCHES_COUNT = 5;

  const [selectedTeam, setSelectedTeam] = useState(homeTeam.id);
  const [homeTeamMatches, setHomeTeamMatches] = useState([]);
  const [awayTeamMatches, setAwayTeamMatches] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getMatchesStatistics();
  }, []);

  const getMatchesStatistics = async () => {
    setIsLoading(true);

    const homeTeamMatchesResponse = await fansUnitedSDK.football.getMatches({ teamIds: [homeTeam.id], status: MatchStatus.FINISHED, limit: MATCHES_COUNT, sortField: 'date', sortOrder: 'desc' });
    setHomeTeamMatches(homeTeamMatchesResponse.data);

    const awayTeamMatchesResponse = await fansUnitedSDK.football.getMatches({ teamIds: [awayTeam.id], status: MatchStatus.FINISHED, limit: MATCHES_COUNT, sortField: 'date', sortOrder: 'desc' });
    setAwayTeamMatches(awayTeamMatchesResponse.data);

    setIsLoading(false)
  }

  const TeamButton = ({ team }) => (
    <button
      className={styles.button} 
      style={team.id === selectedTeam ? { backgroundColor: '#00A826', color: '#FFFFFF', fontWeight: 500 } : null}
      onClick={() => setSelectedTeam(team.id)}
    >
      <img className={styles.icon} src={team.assets?.logo} alt={`team-logo`}/> 
      {t(team.name)}
    </button>
  )

  const ResultRow = ({ match }) => {
    const sign = resultSign(match, selectedTeam);
    const date = matchDate(match.finishedAt);
    const result = `${t(match.homeTeam.name)} ${match.scores.ftScore.homeGoals} - ${match.scores.ftScore.awayGoals} ${t(match.awayTeam.name)} `;

    return (
      <div className={styles.row}>
        <div className={styles.row}>
          <div className={styles.resultIcon} style={{backgroundColor: `${sign.color}`}}>{sign.label}</div>
          <div className={styles.column}>
            <div className={styles.label}>{result}</div>
            <div className={styles.date}>{date}</div>
          </div>
        </div>
      </div>
    )
  }

  return ( isLoading ? <Loader/> :
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        <TeamButton team={homeTeam}/>
        <TeamButton team={awayTeam}/>
      </div>
      <div className={styles.list}>
        { 
          selectedTeam === homeTeam.id ?
          homeTeamMatches.map((match) => <ResultRow key={match.id} match={match}/>) :
          awayTeamMatches.map((match) => <ResultRow key={match.id} match={match}/>)
        }
      </div>
    </div>
  );
}

export default StatisticsModal;