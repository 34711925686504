import { ErrorHandlingModeEnum } from "../Enums/ErrorHandlingModeEnum";
import { LogEnum } from "../Enums/LogEnum";
import { ValidationMessageEnum } from "../Enums/ValidationMessageEnum";
import { ValidationResponseEnum } from "../Enums/ValidationResponseEnum";
import MessageLogModel from "../Models/MessageLogModel";
import SDKConfigurationModel from "../Models/SDKConfiguraitonModel";
import ValidationItemModel from "../Models/ValidationItemModel";
import IValidator from "./IValidator";

export default class ErrorHandlingModeValidator implements IValidator {
  public validate = (config: SDKConfigurationModel): ValidationItemModel => {
    const validation: ValidationResponseEnum = this.validateField(
      config.errorHandlingMode
    );
    const message: MessageLogModel = this.generateMessage(validation);

    return { validation, message };
  };

  private validateField = (
    errorHandlingMode: string
  ): ValidationResponseEnum => {
    const errorHandlingModeValues = [
      ErrorHandlingModeEnum.DEFAULT,
      ErrorHandlingModeEnum.STANDARD,
    ];
    let validation: ValidationResponseEnum = null;

    if (errorHandlingMode) {
      if (
        errorHandlingModeValues.includes(
          errorHandlingMode as ErrorHandlingModeEnum
        )
      ) {
        validation = ValidationResponseEnum.VALID_VALUE;
      } else {
        validation = ValidationResponseEnum.INVALID_VALUE;
      }
    } else {
      validation = ValidationResponseEnum.MISSING_VALUE;
    }

    return validation;
  };

  private generateMessage = (
    validation: ValidationResponseEnum
  ): MessageLogModel => {
    let text: ValidationMessageEnum = null;
    let type: LogEnum = LogEnum.WARNING;

    if (validation === ValidationResponseEnum.MISSING_VALUE) {
      text = ValidationMessageEnum.ERROR_HANDLING_MODE_MISSING;
    }
    if (validation === ValidationResponseEnum.INVALID_VALUE) {
      text = ValidationMessageEnum.ERROR_HANDLING_MODE_INVALID;
    }
    if (validation === ValidationResponseEnum.VALID_VALUE) {
      text = null;
      type = null;
    }

    return { text, type };
  };
}
