import { useEffect, useState } from 'react';

export const useToast = (callback, trigger, time = 2000) => {
  const [shouldShowToast, setShouldShowToast] = useState(false);

  useEffect(() => {
    if (!trigger) return;

    setShouldShowToast(true);

    const errorTimer = setTimeout(() => {
      callback?.();
      setShouldShowToast(false);
    }, time);

    return () => {
      clearTimeout(errorTimer);
    };
  }, [callback, trigger]);

  return shouldShowToast;
};
