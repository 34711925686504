import TiebreakerModel from "../../../TopX/Models/Games/TiebreakerModel";
import FixturesResponseModel from "../Fixtures/FixturesResponseModel";

export default class PredictionResponseModel {
    public id: string = null;
    public gameInstanceId: string = null;
    public gameType: string = null;
    public wager: number = null;
    public totalFixtures: number = null;
    public settledFixtures: number = null;
    public userId: string = null;
    public fixtures: FixturesResponseModel[] = [];
    public status: string = null;
    public tiebreaker: TiebreakerModel = new TiebreakerModel();
    public points: number = null;
    public createdAt: string = null;
    public updatedAt: string = null;
}