import Points from "./Points";
import PointsValueModel from "./PointsValueModel";

export default class PointsModel {
    public general: Points[] = null;
    public predictor: PointsValueModel[] = null
    public activity: PointsValueModel[] = null;
    public topX: PointsValueModel[] = null;
    public matchQuiz: PointsValueModel[] = null;
    public discussionPost: PointsValueModel[] = null;
}