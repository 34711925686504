import { createSlice } from "@reduxjs/toolkit";
import {
  getAuthTokenRequest,
  getProfileRequest,
  updateProfileRequest,
} from "../../api/userService";

const userSlice = createSlice({
  name: "User",
  initialState: {
    token: null,
    country: null,
    language: "en",
    profile: {},
  },
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setCountry(state, action) {
      state.country = action.payload;
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
    setUser(state, action) {
      state.profile = action.payload;
    },
  },
});

export const { setToken, setCountry, setLanguage, setUser } = userSlice.actions;

export const getTokenRequest =
  (userId, userCountry, userLanguage) => async (dispatch) => {
    try {
      if (!!userCountry) {
        dispatch(setCountry(userCountry));
      }
      if (!!userLanguage) {
        dispatch(setLanguage(userLanguage));
      }

      const tokenResponse = await getAuthTokenRequest(userId);
      if (!tokenResponse) return;

      const tokenResponseJSON = await tokenResponse.json();
      if (!tokenResponseJSON) return;

      dispatch(setToken(tokenResponseJSON.id_token));
      // dispatch(setToken('eyJhbGciOiJSUzI1NiIsImtpZCI6IjVkNjE3N2E5Mjg2ZDI1Njg0NTI2OWEzMTM2ZDNmNjY0MjZhNGQ2NDIiLCJ0eXAiOiJKV1QifQ.eyJwcm92aWRlcl9pZCI6ImFub255bW91cyIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9mYW5zLXVuaXRlZC1kZW1vIiwiYXVkIjoiZmFucy11bml0ZWQtZGVtbyIsImF1dGhfdGltZSI6MTcxMTM2OTIwNSwidXNlcl9pZCI6ImpXZjRyQ29sQXZaeFFzblZpQ0hnQjczM0NESzIiLCJzdWIiOiJqV2Y0ckNvbEF2WnhRc25WaUNIZ0I3MzNDREsyIiwiaWF0IjoxNzE3NDk5NTI2LCJleHAiOjE3MTc1MDMxMjYsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnt9LCJzaWduX2luX3Byb3ZpZGVyIjoiYW5vbnltb3VzIn19.ct50qdFK34u5Z5PhwaKThOn7dJhLd-ErTOECHmFn4O7q0d4xtWpZO7lgoqjGwEHvtQTtyPQN6cZssQHIHoEaFtN7-hPINVZ1i7LPYHrjwda1cdkLEGKRaVxjLN6s9jzIoXxOd89F8zuAl-cneMYLvRrhHsxfznO13bkxnlyc3jWppQALch6AHAOcT5E_W2HlfMB8QSWa4eOxY2wPX1j9TbZF-l0dr3752gsqnO7FPttD9EZShewV0Lgbe63KX-fh0VlDDFoiA3JyQed-WvS5fgv6kQAb3kgplRsEE3nm4AP7P8SMsZJNzcRz1UCXT8EzKAGG25AJumPXU56hatrlmg'));
    } catch (error) {
      throw error;
    }
  };

export const getUserRequest =
  (fansUnitedSDK, name) => async (dispatch, getState) => {
    try {
      const state = getState();
      if (state.user?.profile?.name === name) {
        return;
      }

      const profileResponse = await getProfileRequest(fansUnitedSDK);
      if (!profileResponse) return;

      const userResponse = await updateProfileRequest(fansUnitedSDK, name);
      if (!userResponse) return;

      dispatch(setUser(userResponse));
    } catch (error) {
      throw error;
    }
  };

export default userSlice.reducer;
