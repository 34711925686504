import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import MiniGamesHttps from "../../../Https/MiniGamesHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import Football from "../../Football/Football";
import Profile from "../../Profile/Profile";
import { IEitherOrParticipationCallback } from "../Interfaces/MiniGamesInterfaces";
import EitherOrParticipationModel from "../Models/EitherOr/Participation/EitherOrParticipationModel";
import EitherOrParticipationRequestFullModel from "../Models/EitherOr/Participation/EitherOrParticipationRequestFullModel";
import EitherOrParticipationRequestModel from "../Models/EitherOr/Participation/EitherOrParticipationRequestModel";
import EitherOrResultsModel from "../Models/EitherOr/Results/EitherOrResultsModel";
import MiniGamesFilters from "../Models/Filters/MiniGamesFilters";
import MiniGamesContextTag from "../Models/MiniGamesContextTag";
import MiniGamesValidator from "../Validator/MiniGamesValidator";

export default class MiniGamesService {
  private idSchema: string = null;
  readonly idMapping: IdMappingService = null;
  readonly validator: MiniGamesValidator = null;
  readonly https: MiniGamesHttps = null;
  readonly entityTypes = ["competition", "team", "player"];
  private readonly profile: Profile = null;
  private readonly football: Football = null;
  private eitherOrTime: number = 0;
  // Used to estimate how long it took for the user to answer the question
  private participationStartTimestamp: number = 0;
  // Used to calculate the second remaining for user to answer the question
  // Returned as a argument to the onTimerTick callback
  private countdownTimer: number = 0;
  private intervalId: NodeJS.Timeout = null;
  private currentPairId: string = "";

  constructor(config: SDKConfigurationModel) {
    this.idSchema = config.idSchema;
    this.idMapping = new IdMappingService(config);
    this.validator = new MiniGamesValidator(config);
    this.profile = new Profile(config);
    this.football = new Football(config);
    this.https = new MiniGamesHttps(config);
  }

  public finalizeMiniGamesFilters = async (filters: MiniGamesFilters) => {
    if (
      this.idSchema !== IdSchemaEnum.NATIVE &&
      filters.entityIds &&
      filters.entityIds.length > 0
    ) {
      this.validator.validateFilters(filters);
      const idsObj = await this.idMapping.getEntityIdsBySchemaId(
        Array.from(filters.entityIds),
        filters.entityType,
        IdSchemaEnum.NATIVE
      );
      filters.entityIds = idsObj[filters.entityType];
    }
  };

  public setProfileModelsForEitherOrStandings = async (
    eitherOrResults: EitherOrResultsModel
  ) => {
    const profileIds = eitherOrResults.standings.map(
      (standing) => standing.userId
    );

    if (!profileIds.length) {
      return eitherOrResults;
    }

    const profiles = await this.profile.getByIds(profileIds);
    eitherOrResults.standings.forEach(
      (standing) =>
        (standing.userModel =
          profiles.find((profile) => profile.id === standing.userId) || null)
    );

    return eitherOrResults;
  };

  public defaultEitherOrParticipation = async (
    eitherOrId: string,
    participation: EitherOrParticipationRequestModel | null
  ) => {
    // Make request for Either/Or to get the time (only on first participation)
    if (!this.eitherOrTime && !participation) {
      const eitherOr = await this.https.getEitherOrById(eitherOrId, true);
      this.eitherOrTime = eitherOr.time;
      const response = await this.https.participateInEitherOr(eitherOrId, null);
      // Start initial countdown
      this.participationStartTimestamp = Date.now();

      return response;
    }

    let elapsedTime: number = 0;

    if (!this.participationStartTimestamp) {
      // Time and timer are set 0 when the class has been reinitialized.
      if (!this.eitherOrTime) {
        const eitherOr = await this.https.getEitherOrById(eitherOrId, true);
        this.eitherOrTime = eitherOr.time;
      }

      elapsedTime = this.eitherOrTime + 1;
    } else {
      elapsedTime = (Date.now() - this.participationStartTimestamp) / 1000;
    }

    const newParticipation = this.createNewParticipation(
      elapsedTime,
      participation
    );

    try {
      const response = await this.https.participateInEitherOr(
        eitherOrId,
        newParticipation
      );

      if (!response.currentLives || !response.remainingSteps) {
        // Game over
        this.resetDefaultParticipationTimers();
      } else {
        this.participationStartTimestamp = Date.now();
      }

      return response;
    } catch (e) {
      this.resetDefaultParticipationTimers();
      throw e;
    }
  };

  public handleEitherOrParticipationWithCallback = async (
    eitherOrId: string,
    participation: EitherOrParticipationRequestModel | null,
    onEitherOrParticipationCallbacks: IEitherOrParticipationCallback
  ) => {
    if (!participation) {
      return await this.initialEitherOrParticipationWithCallback(
        eitherOrId,
        onEitherOrParticipationCallbacks
      );
    }

    return await this.eitherOrParticipationWithCallback(
      eitherOrId,
      participation,
      onEitherOrParticipationCallbacks
    );
  };

  private initialEitherOrParticipationWithCallback = async (
    eitherOrId: string,
    onEitherOrParticipationCallbacks: IEitherOrParticipationCallback
  ) => {
    const eitherOr = await this.https.getEitherOrById(eitherOrId, true);
    this.eitherOrTime = eitherOr.time;
    const participation = await this.https.participateInEitherOr(
      eitherOrId,
      null
    );
    this.participationStartTimestamp = Date.now();
    this.countdownTimer = this.eitherOrTime;
    this.setCurrentPairId(participation);
    await this.handleEitherOrParticipationCallbackInterface(
      eitherOrId,
      onEitherOrParticipationCallbacks
    );

    return participation;
  };

  private eitherOrParticipationWithCallback = async (
    eitherOrId: string,
    participation: EitherOrParticipationRequestModel,
    onEitherOrParticipationCallbacks: IEitherOrParticipationCallback
  ) => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    let elapsedTime: number = 0;

    if (!this.participationStartTimestamp) {
      /**
       * Important instance variable like eitherOrTime and participationStartTimestamp can be set
       * to 0 when user refresh his browser. Then we need to set values for these variables again.
       * But then we will send an expired participation (he will loose a live or game over).
       * */
      if (!this.eitherOrTime) {
        const eitherOr = await this.https.getEitherOrById(eitherOrId, true);
        this.eitherOrTime = eitherOr.time;
      }

      elapsedTime = this.eitherOrTime + 1;
    } else {
      elapsedTime = (Date.now() - this.participationStartTimestamp) / 1000;
    }

    const newParticipation = this.createNewParticipation(
      elapsedTime,
      participation
    );

    try {
      const response = await this.https.participateInEitherOr(
        eitherOrId,
        newParticipation
      );

      if (!response.currentLives || !response.remainingSteps) {
        // Game over
        this.resetCallbackParticipationTimers();
        onEitherOrParticipationCallbacks.onElapsedResponse(response);
        clearInterval(this.intervalId);
      } else {
        // Set new timestamp
        this.participationStartTimestamp = Date.now();
        // Return timer to initial seconds
        this.countdownTimer = this.eitherOrTime;
        this.setCurrentPairId(response);
        await this.handleEitherOrParticipationCallbackInterface(
          eitherOrId,
          onEitherOrParticipationCallbacks
        );
      }

      return response;
    } catch (e) {
      this.resetCallbackParticipationTimers();
      clearInterval(this.intervalId);
      throw e;
    }
  };

  private setCurrentPairId = (participation: EitherOrParticipationModel) => {
    this.currentPairId =
      participation.steps.find((step) => !step.played).pairId || "";
  };

  private handleEitherOrParticipationCallbackInterface = async (
    eitherOrId: string,
    onEitherOrParticipationCallbacks: IEitherOrParticipationCallback
  ) => {
    this.intervalId = setInterval(async () => {
      if (this.countdownTimer > 0) {
        this.countdownTimer--;
        onEitherOrParticipationCallbacks.onTimerTick(this.countdownTimer);
      } else {
        clearInterval(this.intervalId);
        const elapsedTime =
          (Date.now() - this.participationStartTimestamp) / 1000;
        const participation = {
          pair: this.currentPairId,
          answer: this.currentPairId.split("_")[0],
        };
        const newParticipation = this.createNewParticipation(
          elapsedTime,
          participation
        );
        const response = await this.eitherOrParticipationWithCallback(
          eitherOrId,
          newParticipation,
          onEitherOrParticipationCallbacks
        );
        onEitherOrParticipationCallbacks.onElapsedResponse(response);
      }
    }, 1000);
  };

  private createNewParticipation = (
    elapsedTime: number,
    participation: EitherOrParticipationRequestModel
  ) => {
    const newParticipation = new EitherOrParticipationRequestFullModel();

    newParticipation.expired = elapsedTime > this.eitherOrTime;
    newParticipation.time = Number(elapsedTime.toFixed(3));
    newParticipation.pair = participation.pair;
    newParticipation.answer = participation.answer;

    return newParticipation;
  };

  private resetCallbackParticipationTimers = () => {
    this.resetDefaultParticipationTimers();
    this.countdownTimer = 0;
    this.currentPairId = "";
  };

  private resetDefaultParticipationTimers = () => {
    this.eitherOrTime = 0;
    this.participationStartTimestamp = 0;
  };

  public setContextTagsModels = async (miniGames: any[]) => {
    let entityIds = {
      competitions: [],
      teams: [],
      players: [],
    };

    miniGames.forEach((miniGame) => {
      if (
        miniGame.context &&
        miniGame.context.tags &&
        miniGame.context.tags.length > 0
      ) {
        entityIds.competitions = [
          ...entityIds.competitions,
          ...this.extractEntityIdsFromTags(
            miniGame.context.tags,
            "competition"
          ),
        ];
        entityIds.competitions = Array.from(new Set(entityIds.competitions));
        entityIds.teams = [
          ...entityIds.teams,
          ...this.extractEntityIdsFromTags(miniGame.context.tags, "team"),
        ];
        entityIds.teams = Array.from(new Set(entityIds.teams));
        entityIds.players = [
          ...entityIds.players,
          ...this.extractEntityIdsFromTags(miniGame.context.tags, "player"),
        ];
        entityIds.players = Array.from(new Set(entityIds.players));
      }
    });

    if (entityIds.competitions.length) {
      const data =
        await this.football.footballFacade.getCompetitionsMapWithNativeIds(
          entityIds.competitions
        );
      this.setModelsForContextTags(miniGames, data, "competition");
    }

    if (entityIds.teams.length) {
      const data = await this.football.footballFacade.getTeamsMapWithNativeIds(
        entityIds.teams
      );
      this.setModelsForContextTags(miniGames, data, "team");
    }

    if (entityIds.players.length) {
      const data =
        await this.football.footballFacade.getPlayersMapWithNativeIds(
          entityIds.players
        );
      this.setModelsForContextTags(miniGames, data, "player");
    }
  };

  private extractEntityIdsFromTags = (
    tags: MiniGamesContextTag[],
    type: "competition" | "team" | "player"
  ) => {
    return tags.filter((tag) => tag.type === type).map((tag) => tag.id);
  };

  private setModelsForContextTags = (
    miniGames: any[],
    data: Map<string, any>,
    type: "competition" | "team" | "player"
  ) => {
    miniGames.forEach((miniGame) => {
      if (
        miniGame.context &&
        miniGame.context.tags &&
        miniGame.context.tags.length > 0
      ) {
        miniGame.context.tags.forEach((tag) => {
          if (tag.type === type) {
            // The player data is not a map
            tag.model = type === "player" ? data[tag.id] : data.get(tag.id);
            tag.id = tag.model.id;
          }
        });
      }
    });
  };
}
