export default class ClientBadgesV2 {
  public predictor: string[];
  public topX: string[];
  public matchQuiz: string[];
  public games: string[];
  public football: string[];
  public classicQuizzes: string[];
  public eitherOr: string[];
  public discussions: string[];
  public potm: string[];
  public poll: string[];
}
