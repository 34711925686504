import { MarketEnum } from "../../../Enums/MarketEnum";
import ValueAndPlayerId from "../../Value/ValueAndPlayerId";
import FixturesModel from "../FixturesModel";

export default class PlayerFixtureModel implements FixturesModel {
    public matchId: string = null;
    public market: MarketEnum = null;
    public matchType: string = "FOOTBALL";
    public prediction: ValueAndPlayerId = null;

    constructor(matchId: string, market: MarketEnum, value: boolean, playerId: string) {
        this.matchId = matchId;
        this.market = market;
        this.prediction = new ValueAndPlayerId();
        this.prediction.value = value;
        this.prediction.playerId = playerId;
    }
}