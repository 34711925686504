import BrandingColorsModel from "../Models/Branding/BrandingColorsModel";
import BrandingImagesModel from "../Models/Branding/BrandingImagesModel";
import BrandingModel from "../Models/Branding/BrandingModel";
import BrandingUrlsModel from "../Models/Branding/BrandingUrlsModel";

export default class BaseRemapper {
  protected remapBranding = (data: any) => {
    const model = new BrandingModel();

    if (data.colors) {
      model.colors = new BrandingColorsModel();
      model.colors.primaryColor = data.colors.primary_color;
      model.colors.secondaryColor = data.colors.secondary_color;
      model.colors.contentColor = data.colors.content_color;
      model.colors.backgroundColor = data.colors.background_color;
      model.colors.borderColor = data.colors.border_color;
      model.colors.additionalColor = data.colors.additional_color;
    }

    if (data.urls) {
      model.urls = new BrandingUrlsModel();
      model.urls.primaryUrl = data.urls.primary_url;
      model.urls.secondaryUrl = data.urls.secondary_url;
      model.urls.privacyPolicyUrl = data.urls.privacy_policy_url;
      model.urls.termsAndConditionsUrl = data.urls.terms_and_conditions_url;
      model.urls.additionalUrl = data.urls.additional_url;
    }

    if (data.images) {
      model.images = new BrandingImagesModel();
      model.images.mainLogo = data.images.main_logo;
      model.images.mobileLogo = data.images.mobile_logo;
      model.images.backgroundImage = data.images.background_image;
      model.images.mobileBackgroundImage = data.images.mobile_background_image;
      model.images.additionalImage = data.images.additional_image;
    }

    return model;
  };
}
