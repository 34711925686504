import { createSlice } from "@reduxjs/toolkit";
import { getMyRanking, getOverallRanking } from "../../api/rankingService";

const rankingSlice = createSlice({
  name: "Ranking",
  initialState: {
    overallRanking: [],
    myRanking: [],
  },
  reducers: {
    setRanking(state, action) {
      state.overallRanking = action.payload;
    },
    setMyRanking(state, action) {
      state.myRanking = action.payload;
    },
  },
});

export const { setRanking } = rankingSlice.actions;
export const { setMyRanking } = rankingSlice.actions;

export const getRankingRequest = (fansUnitedSDK) => async (dispatch) => {
  try {
    const rankingResponse = await getOverallRanking(fansUnitedSDK);
    if (!rankingResponse.data) return;

    dispatch(setRanking(rankingResponse.data));
  } catch (error) {
    throw error;
  }
};

export const getMyRankingRequest = (fansUnitedSDK) => async (dispatch) => {
  try {
    const rankingResponse = await getMyRanking(fansUnitedSDK);
    if (!rankingResponse.data) return;

    dispatch(setMyRanking(rankingResponse.data));
  } catch (error) {
    throw error;
  }
};

export default rankingSlice.reducer;
