export default class FansUnitedSdkException extends Error {
  code: number = 0;
  status: string = null;
  message: string = null;

  constructor(code: number, status: string, message: string) {
    super();
    this.code = code;
    this.status = status;
    this.message = message;
  }

  public errorMessage = () => {
    return this.message;
  };
}
