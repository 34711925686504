import {IRemapper} from "../../../../../Global/IRemapper";
import FootballCountryRemapper from "../../Country/Remappers/FootballCountryRemapper";
import TeamFullModel from "../TeamFullModel";
import PlayerSquadRemapper from "../../Player/Remappers/PlayerSquadRemapper";
import CompetitionBasicRemapper from "../../Competition/Remappers/CompetitionBasicRemapper";

export default class TeamFullRemapper implements IRemapper<TeamFullModel> {
    private countryRemapper: FootballCountryRemapper = null;
    private competitionRemapper: CompetitionBasicRemapper = null;
    private playerRemapper: PlayerSquadRemapper = null;

    constructor() {
        this.countryRemapper = new FootballCountryRemapper();
        this.competitionRemapper = new CompetitionBasicRemapper();
        this.playerRemapper = new PlayerSquadRemapper();
    }

    fromResponse(response: any): TeamFullModel {
        try {
            const model = new TeamFullModel();

            model.id = response.id;
            model.country = this.countryRemapper.countryFromResponse(response.country);
            model.gender = response.gender;
            model.assets = response.assets;
            model.code = response.code;
            model.national = response.national;
            model.fullName = response.full_name;
            model.shortName = response.short_name;
            model.name = response.name;
            model.isDeleted = response.is_deleted;
            model.undecided = response.undecided;
            model.competitions = response.competitions ? response.competitions.map((competition: any) => this.competitionRemapper.fromResponse(competition)) : [];
            model.colors = response.colors ? response.colors : model.colors;
            model.squad = response.squad ? response.squad.map((team: any) => this.playerRemapper.fromResponse(team)) : [];

            return model;
        } catch (e) {
            console.warn("There was a problem remapping team response", e);
            throw e;
        }
    };

}
