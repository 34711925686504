import MainFiltersBQ from "../../../../../Global/Models/Filters/MainFiltersBQ";

export default class PollsFilters extends MainFiltersBQ {
  flags?: string[] = null;
  status?: string = null;
  entityIds?: string[] = null;
  entityType?: string = null;
  pollIds?: string[] = null;
  sortOrder?: string = null;
  minVotes?: number = null;

  constructor(filters: any) {
    super(filters);
    this.flags = filters?.flags || null;
    this.status = filters?.status || null;
    this.entityIds = filters?.entityIds || null;
    this.entityType = filters?.entityType || null;
    this.pollIds = filters?.pollIds || null;
    this.sortOrder = filters?.sortOrder || null;
    this.minVotes = filters && filters.minVotes !== undefined ? filters.minVotes : null;
  }

  constructMainFiltersUrl?() {
    let url = super.constructMainFiltersUrl();

    if (this.flags && this.flags.length > 0) {
      url += `&flags=${this.flags.join(",")}`;
    }

    if (this.status) {
      url += `&status=${this.status}`;
    }

    if (this.entityIds && this.entityIds.length > 0) {
      url += `&entity_ids=${this.entityIds.join(",")}`;
    }

    if (this.pollIds && this.pollIds.length > 0) {
      url += `&poll_ids=${this.pollIds.join(",")}`;
    }

    if (this.sortOrder) {
      url += `&sort_order=${this.sortOrder}`;
    }

    if (this.minVotes) {
      url += `&min_votes=${this.minVotes}`;
    }

    return url;
  }
}
