import GamesListModel from "../../../TopX/Models/Games/GamesListModel";
import { GameType, RankingsType } from "../../Types/LoyaltyTypes";
import LeaderboardModel from "../Leaderboard/LeaderboardModel";
import TemplateModel from "../Template/TemplateModel";

export default class RankingsModel extends LeaderboardModel {
    public id: string = null;
    public rankType: RankingsType = null;
    public model: TemplateModel | GamesListModel = null;
    public gameType: GameType = null;
}