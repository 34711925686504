import DiscussionsBadgesValue from "./Discussions/DiscussionsBadgesValue";
import FootballBadgesValue from "./Football/FootballBadgesValue";
import GamesBadgesValue from "./Games/GamesBadgesValue";
import GameBadgesValue from "./Game/GameBadgesValue";
import MiniGamesBadgesValue from "./MiniGames/MiniGamesBadgesValue";
import PredictorBadgesValue from "./Predictor/PredictorBadgesValue";
import VotingBadgesValue from "./Voting/VotingBadgesValue";

export default class BadgesModelV2 {
  public predictor: PredictorBadgesValue[] = [];
  public topX: GameBadgesValue[] = [];
  public matchQuiz: GameBadgesValue[] = [];
  public games: GamesBadgesValue[] = [];
  public football: FootballBadgesValue[] = [];
  public classicQuizzes: MiniGamesBadgesValue[] = [];
  public eitherOr: MiniGamesBadgesValue[] = [];
  public discussions: DiscussionsBadgesValue[] = [];
  public voting: VotingBadgesValue = new VotingBadgesValue();
}
