import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import MainFiltersBQ from "../../Global/Models/Filters/MainFiltersBQ";
import VotingFacade from "./Facade/VotingFacade";
import PollsFilters from "./Models/Polls/Filters/PollsFilters";

export default class Voting {
  private facade: VotingFacade = null;

  constructor(config: SDKConfigurationModel) {
    this.facade = new VotingFacade(config);
  }

  public getPolls = async (filters?: PollsFilters, disableCache?: boolean) => {
    return await this.facade.getPolls(filters, disableCache);
  };

  public getPollById = async (pollId: string, disableCache?: boolean) => {
    return await this.facade.getPollById(pollId, disableCache);
  };

  public voteForPoll = async (pollId: string, optionId: string) => {
    return await this.facade.voteForPoll(pollId, optionId);
  };

  public deleteVoteForPoll = async (pollId: string) => {
    return await this.facade.deleteVoteForPoll(pollId);
  };

  public getMyPollVote = async (pollId: string, showModels?: boolean) => {
    return await this.facade.getMyPollVote(pollId, showModels);
  };

  public getMyPollsVotes = async (filters?: MainFiltersBQ) => {
    return await this.facade.getMyPollsVotes(filters);
  };
}
