import FixturesRemapper from "../Fixtures/FixturesRemapper";
import PredictionRequestModel from "./PredictionRequestModel";
import PredictionResponseModel from "./PredictionResponseModel";
import PredictionsModel from "./PredictionsModel";
import SDKConfigurationModel from "../../../../Configurator/Models/SDKConfiguraitonModel";
import IdMappingService from "../../../../IdMapping/IdMappingService";
import FootballHttps from "../../../../Https/FootballHttps";
import MatchFacade from "../../../Football/Facades/MatchFacade";
import PlayerFacade from "../../../Football/Facades/PlayerFacade";
import FixturesResponseModel from "../Fixtures/FixturesResponseModel";
import PredictorService from "../../Service/PredictorService";
import TopXPredictionRequestModel from "../../../TopX/Models/Prediction/TopXPredictionRequestModel";

export default class PredictionRemapper {
    private fixturesRemapper: FixturesRemapper = null;
    private footballHttps: FootballHttps = null;
    private matchFacade: MatchFacade = null;
    private playerFacade: PlayerFacade = null;
    private predictorService: PredictorService = null;

    constructor(config: SDKConfigurationModel, idMapping: IdMappingService) {
        this.footballHttps = new FootballHttps(config);
        this.matchFacade = new MatchFacade(config, this.footballHttps, idMapping);
        this.playerFacade = new PlayerFacade(config, this.footballHttps, idMapping);
        this.predictorService = new PredictorService(config, idMapping)
        this.fixturesRemapper = new FixturesRemapper(this.matchFacade, this.playerFacade, this.predictorService);
    }

    public remapRequestBody = (prediction: PredictionRequestModel | TopXPredictionRequestModel) => {
        let requestBody: any = {};

        requestBody.game_instance_id = prediction.gameInstanceId;
        requestBody.fixtures = prediction.fixtures.map((fixture: any) => this.fixturesRemapper.remapFixturesRequest(fixture));

        if (prediction instanceof TopXPredictionRequestModel && prediction.tiebreaker) {
            requestBody.tiebreaker = {};
            requestBody.tiebreaker.golden_goal = prediction.tiebreaker.goldenGoal ? prediction.tiebreaker.goldenGoal : null;
        }

        return requestBody;
    };

    public remapResponseArrayBody = async (predictions: any[]): Promise<PredictionResponseModel[]> => {
        if (predictions.length) {
            const ownGoal = "OWN_GOAL";
            let matchIds: string[] = [];
            let playerIds: string[] = [];
            let playersMap: any = {};
            let remappedPredictions: PredictionResponseModel[] = [];

            predictions.forEach((prediction: any) => {
                prediction.fixtures.forEach((fixture: any) => {
                    matchIds.push(fixture.match_id);

                    if (fixture.player_id && fixture.player_id !== ownGoal) {
                        playerIds.push(fixture.player_id);
                    }
                });
            });

            const matchesMap = await this.matchFacade.getMatchesMapWithNativeIds(matchIds);

            if (playerIds.length > 0) {
                playersMap = await this.playerFacade.getPlayersMapWithNativeIds(playerIds);
            }

            predictions.forEach((prediction: any) => {
                let predictionResponse = new PredictionResponseModel();
                let remappedFixtures: FixturesResponseModel[] = [];

                predictionResponse.id = prediction.id;
                predictionResponse.gameInstanceId = prediction.game_instance_id;
                predictionResponse.gameType = prediction.game_type;
                predictionResponse.wager = prediction.wager;
                predictionResponse.totalFixtures = prediction.total_fixtures;
                predictionResponse.settledFixtures = prediction.settled_fixtures;
                predictionResponse.userId = prediction.user_id;

                prediction.fixtures.forEach((fixture: any) => {
                    let fixturesResponse = new FixturesResponseModel();

                    if (fixture.target) {
                        //@ts-ignore
                        fixturesResponse.market = this.predictorService.convertMarketForResponseModel(fixture.market, fixture.target);
                    } else {
                        fixturesResponse.market = fixture.market;
                    }

                    fixturesResponse.matchId = matchesMap[fixture.match_id].id;
                    fixturesResponse.matchType = fixture.match_type;
                    fixturesResponse.matchModel = matchesMap[fixture.match_id];
                    delete fixturesResponse.matchModel.availableMarkets;

                    if (fixture.goals_home !== undefined && fixture.goals_away !== undefined) {
                        fixturesResponse.prediction.value = fixture.goals_home.toString() + ":" + fixture.goals_away.toString();
                    } else {
                        fixturesResponse.prediction.value = fixture.prediction;
                    }

                    fixturesResponse.prediction.playerId = fixture.player_id ? fixture.player_id : null;

                    if (fixturesResponse.prediction.playerId && fixturesResponse.prediction.playerId !== ownGoal) {
                        fixturesResponse.prediction.playerModel = playersMap[fixturesResponse.prediction.playerId];
                        fixturesResponse.prediction.playerId = playersMap[fixturesResponse.prediction.playerId].id;
                    }

                    fixturesResponse.result = this.fixturesRemapper.remapResult(fixture.result);

                    remappedFixtures.push(fixturesResponse);
                });

                predictionResponse.fixtures = remappedFixtures;
                predictionResponse.status = prediction.status;

                if (prediction.tiebreaker) {
                    predictionResponse.tiebreaker.goldenGoal = prediction.tiebreaker.golden_goal;
                } else {
                    predictionResponse.tiebreaker = null;
                }

                predictionResponse.points = prediction.points;
                predictionResponse.createdAt = prediction.created_at;
                predictionResponse.updatedAt = prediction.updated_at;

                remappedPredictions.push(predictionResponse);
            });

            return remappedPredictions;
        }

        return predictions;
    };

    public remapResponseObjBody = async (response: any): Promise<PredictionResponseModel> => {
        let predictionResponse = new PredictionResponseModel();

        predictionResponse.id = response.id;
        predictionResponse.gameInstanceId = response.game_instance_id;
        predictionResponse.gameType = response.game_type;
        predictionResponse.wager = response.wager;
        predictionResponse.totalFixtures = response.total_fixtures;
        predictionResponse.settledFixtures = response.settled_fixtures;
        predictionResponse.userId = response.user_id;
        predictionResponse.fixtures = await this.fixturesRemapper.remapFixturesResponse(response.fixtures);
        predictionResponse.status = response.status;

        if (response.tiebreaker) {
            predictionResponse.tiebreaker.goldenGoal = response.tiebreaker.golden_goal;
        } else {
            predictionResponse.tiebreaker = null;
        }

        predictionResponse.points = response.points;
        predictionResponse.createdAt = response.created_at;
        predictionResponse.updatedAt = response.updated_at;

        return predictionResponse;
    };

    public remapPredictionModel = (response: any): PredictionsModel => {
        let predictionsModel = new PredictionsModel();

        predictionsModel.fullTimeOneXTwo = response.FT_1X2;
        predictionsModel.halfTimeOneXTwo = response.HT_1X2;
        predictionsModel.bothTeamsScore = response.BOTH_TEAMS_SCORE;
        predictionsModel.overZeroPointFiveGoals = response.OVER_GOALS_0_5;
        predictionsModel.overOnePointFiveGoals = response.OVER_GOALS_1_5;
        predictionsModel.overTwoPointFiveGoals = response.OVER_GOALS_2_5;
        predictionsModel.overThreePointFiveGoals = response.OVER_GOALS_3_5;
        predictionsModel.overFourPointFiveGoals = response.OVER_GOALS_4_5;
        predictionsModel.overFivePointFiveGoals = response.OVER_GOALS_5_5;
        predictionsModel.overSixPointFiveGoals = response.OVER_GOALS_6_5;
        predictionsModel.overSixPointFiveCorners = response.OVER_CORNERS_6_5;
        predictionsModel.overSevenPointFiveCorners = response.OVER_CORNERS_7_5;
        predictionsModel.overEightPointFiveCorners = response.OVER_CORNERS_8_5;
        predictionsModel.overNinePointFiveCorners = response.OVER_CORNERS_9_5;
        predictionsModel.overTenPointFiveCorners = response.OVER_CORNERS_10_5;
        predictionsModel.overElevenPointFiveCorners = response.OVER_CORNERS_11_5;
        predictionsModel.overTwelvePointFiveCorners = response.OVER_CORNERS_12_5;
        predictionsModel.overThirteenPointFiveCorners = response.OVER_CORNERS_13_5;
        predictionsModel.doubleChance = response.DOUBLE_CHANCE;
        predictionsModel.halfTimeFullTime = response.HT_FT;
        predictionsModel.playerScore = response.PLAYER_SCORE;
        predictionsModel.playerYellowCard = response.PLAYER_YELLOW_CARD;
        predictionsModel.playerRedCard = response.PLAYER_RED_CARD;
        predictionsModel.redCardMatch = response.RED_CARD_MATCH;
        predictionsModel.penaltyMatch = response.PENALTY_MATCH;
        predictionsModel.playerScoreFirstGoal = response.PLAYER_SCORE_FIRST_GOAL;
        predictionsModel.cornersMatch = response.CORNERS_MATCH;
        predictionsModel.correctScore = response.CORRECT_SCORE;
        predictionsModel.correctScoreHalfTime = response.CORRECT_SCORE_HT;
        predictionsModel.correctScoreAdvanced = response.CORRECT_SCORE_ADVANCED;
        predictionsModel.playerScoreHattrick = response.PLAYER_SCORE_HATTRICK;
        predictionsModel.playerScoreTwice = response.PLAYER_SCORE_TWICE;

        return predictionsModel;
     };

}