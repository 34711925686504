import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import PaginationModel from "../../../Global/Models/Pagination/PaginationModel";
import ClientHttps, { FeatureConfigType } from "../../../Https/ClientHttps";
import LoyaltyHttps from "../../../Https/LoyaltyHttps";
import LoyaltyFilters from "../Models/Filters/LoyaltyFilters";
import LoyaltyConfigModel from "../Models/ClientFeatures/LoyaltyConfigModel";
import LoyaltyService from "../Service/LoyaltyService";
import TemplateModel from "../Models/Template/TemplateModel";
import { FeaturesConfigModels } from "../../../Global/Types/GlobalTypes";
import RankingsFilters from "../Models/Filters/RankingsFilters";
import FootballPaginationModel from "../../Football/Models/Pagination/FootballPaginationModel";
import ProfileHttps from "../../../Https/ProfileHttps";
import ProfileFacade from "../../Profile/Facade/ProfileFacade";
import HighestSuccessRateModel from "../Models/HighestSuccessRate/HighestSuccessRateModel";
import LoyaltyValidator from "../Validator/LoyaltyValidator";
import TopXFacade from "../../TopX/Facade/TopXFacade";
import IdMappingService from "../../../IdMapping/IdMappingService";
import MatchQuizFacade from "../../MatchQuiz/Facade/MatchQuizFacade";
import GamesFilters from "../../TopX/Models/Games/GamesFilters";
import GamesListModel from "../../TopX/Models/Games/GamesListModel";
import ContestWinners from "../../TopX/Models/Games/Winners/ContestWinners";
import PredictorHttps from "../../../Https/PredictorHttps";
import { ErrorHandlingModeType } from "../../../Configurator/Types/ConfiguratorTypes";
import EntitiesFollowsFilters from "../Models/Filters/EntitiesFollowsFilters";
import EntitiesFollows from "../Models/EntitiesFollows/EntitiesFollows";
import TemplateByIdModel from "../Models/Template/TemplateByIdModel";
import HighestSuccessRateFilters from "../Models/Filters/HighestSuccessRateFilters";
import { LocalCacheInterface } from "../../../Global/Interfaces/GlobalInterfaces";
import TemplateFilters from "../Models/Filters/TemplateFilters";
import Football from "../../Football/Football";
import LeagueLeaderboardFilters from "../Models/Filters/LeagueLeaderboardFilters";
import ClientBadgesV2 from "../Models/Badges/v2/ClientBadgesV2";
import ClientBadges from "../Models/Badges/ClientBadges";

export default class LoyaltyFacade {
  private config: SDKConfigurationModel = null;
  private clientHttps: ClientHttps = null;
  private loyaltyHttps: LoyaltyHttps = null;
  private loyaltyService: LoyaltyService = null;
  private profileFacade: ProfileFacade = null;
  private loyaltyValidator: LoyaltyValidator = null;
  private idMapping: IdMappingService = null;
  private topXFacade: TopXFacade = null;
  private matchQuizFacade: MatchQuizFacade = null;
  private predictorHttps: PredictorHttps = null;
  private errorHandlingMode: ErrorHandlingModeType = null;
  private football: Football = null;

  constructor(
    config: SDKConfigurationModel,
    clientHttps: ClientHttps,
    loyaltyHttps: LoyaltyHttps,
    profileHttps: ProfileHttps,
    localCache: LocalCacheInterface
  ) {
    this.config = config;
    this.clientHttps = clientHttps;
    this.loyaltyHttps = loyaltyHttps;
    this.profileFacade = new ProfileFacade(localCache, profileHttps);
    this.loyaltyService = new LoyaltyService(this.config);
    this.loyaltyValidator = new LoyaltyValidator();
    this.idMapping = new IdMappingService(config);
    this.topXFacade = new TopXFacade(config, this.idMapping);
    this.matchQuizFacade = new MatchQuizFacade(config);
    this.predictorHttps = new PredictorHttps(config, this.idMapping);
    this.errorHandlingMode = config.errorHandlingMode;
    this.football = new Football(config);
  }

  public getConfig = async (): Promise<FeaturesConfigModels> => {
    return await this.clientHttps.getConfig(FeatureConfigType.LOYALTY);
  };

  public getConfigV2 = async (disableCache: boolean) => {
    const config = await this.clientHttps.getNewLoyaltyConfig(disableCache);
    await this.loyaltyService.addEntityModelToFootballBadges(
      config.rewards.badges
    );
    await this.loyaltyService.addGameModelToGamesBadges(config.rewards.badges);

    return config;
  };

  public getClientBadges = async (): Promise<ClientBadges> => {
    const config = (await this.getConfig()) as LoyaltyConfigModel;

    return this.loyaltyService.buildOldClientBadges(config.rewards.badges);
  };

  public getClientBadgesV2 = async (
    disableCache: boolean
  ): Promise<ClientBadgesV2> => {
    const config = await this.clientHttps.getNewLoyaltyConfig(disableCache);

    return this.loyaltyService.buildNewClientBadges(config.rewards.badges);
  };

  public getTemplates = async (
    filters?: TemplateFilters,
    disableCache?: boolean
  ): Promise<PaginationModel> => {
    if (filters) {
      filters = new TemplateFilters(filters);
    }

    if (this.config.idSchema === IdSchemaEnum.NATIVE) {
      return (await this.loyaltyHttps.getTemplates(
        filters,
        disableCache
      )) as PaginationModel;
    }

    let templates = (await this.loyaltyHttps.getTemplates(
      filters,
      disableCache
    )) as PaginationModel;
    templates.data = await this.loyaltyService.remapTemplatesIds([
      ...templates.data,
    ]);

    for (let template of templates.data) {
      await this.loyaltyService.remapTemplateGroupMatchIds(template.groups);
    }

    return templates;
  };

  public getTemplateById = async (
    templateId: string,
    disableCache?: boolean
  ): Promise<TemplateByIdModel> => {
    const templateById = await this.loyaltyHttps.getTemplateById(
      templateId,
      disableCache
    );

    if (this.config.idSchema === IdSchemaEnum.NATIVE) {
      return templateById;
    }

    const remapedTemplate = await this.loyaltyService.remapTemplatesIds([
      templateById,
    ]);
    await this.loyaltyService.remapTemplateGroupMatchIds(
      remapedTemplate[0].groups
    );

    return remapedTemplate[0] as TemplateByIdModel;
  };

  public getLeaderboard = async (
    templateId: string,
    filters?: LoyaltyFilters,
    disableCache?: boolean
  ): Promise<PaginationModel> => {
    filters = this.loyaltyService.initFilters(filters, "loyalty");

    const leaderboardTemplate = await this.loyaltyHttps.getLeaderboard(
      templateId,
      disableCache,
      filters
    );
    leaderboardTemplate.data =
      await this.loyaltyService.addProfileModelToLeaderboard(
        leaderboardTemplate.data
      );

    return leaderboardTemplate;
  };

  public getLeaderboardForGame = async (
    gameId: string,
    filters: LoyaltyFilters,
    disableCache?: boolean
  ): Promise<PaginationModel> => {
    filters = this.loyaltyService.initFilters(filters, "loyalty");

    const leaderboardGame = await this.loyaltyHttps.getLeaderboardForGame(
      gameId,
      disableCache,
      filters
    );
    leaderboardGame.data =
      await this.loyaltyService.addProfileModelToLeaderboard(
        leaderboardGame.data
      );

    return leaderboardGame;
  };

  public getLeaderboardForLeague = async (
    leagueId: string,
    filters: LeagueLeaderboardFilters
  ) => {
    filters = this.loyaltyService.initFilters(filters, "league_leaderboard");
    const leaderboardLeague = await this.loyaltyHttps.getLeaderboardForLeague(
      leagueId,
      filters
    );
    leaderboardLeague.data =
      await this.loyaltyService.addProfileModelToLeaderboard(
        leaderboardLeague.data
      );

    return leaderboardLeague;
  };

  public getOwnRankings = async (
    filters?: RankingsFilters,
    disableCache?: boolean
  ) => {
    const { id } = await this.profileFacade.getOwn();

    return this.getUserRankings(id, filters, disableCache);
  };

  public getUserRankings = async (
    userId: string,
    filters?: RankingsFilters,
    disableCache?: boolean
  ): Promise<FootballPaginationModel> => {
    filters = this.loyaltyService.initFilters(filters, "rankings");
    const userRankings = await this.loyaltyHttps.getUserRankings(
      userId,
      filters,
      disableCache
    );

    return await this.getModelsForUserRankings(userRankings);
  };

  public getHighestSuccessRate = async (
    filters: HighestSuccessRateFilters,
    disableCache: boolean
  ): Promise<HighestSuccessRateModel[]> => {
    if (filters) {
      filters = new HighestSuccessRateFilters(filters);
      this.loyaltyValidator.validate(filters, this.errorHandlingMode);
    }

    const highestSuccessRate = await this.loyaltyHttps.getHighestSuccessRate(
      filters,
      disableCache
    );

    return this.loyaltyService.addProfileModelToHighestSuccessRate(
      highestSuccessRate
    );
  };

  public getTemplateWinners = async (
    templateId: string,
    disableCache?: boolean
  ): Promise<ContestWinners> => {
    const templateWinners = await this.predictorHttps.getContestWinners(
      templateId,
      disableCache
    );
    const template = await this.getTemplateById(templateId);
    templateWinners.contestModel = template;

    return this.loyaltyService.completeContestWinners(templateWinners);
  };

  public getEntitiesFollows = async (
    filters: EntitiesFollowsFilters,
    disableCache: boolean
  ): Promise<EntitiesFollows> => {
    this.loyaltyService.validateEntitiesFollowsFilters(filters);

    if (this.config.idSchema !== IdSchemaEnum.NATIVE) {
      filters = await this.loyaltyService.remapEntitiesFollowsFilters(filters);
    }

    const entitiesFollows = await this.loyaltyHttps.getEntitiesFollows(
      filters,
      disableCache
    );

    return await this.loyaltyService.addModelsToEntitiesFollows(
      entitiesFollows
    );
  };

  private getModelsForUserRankings = async (
    userRankings: FootballPaginationModel
  ) => {
    let templates: TemplateModel[] = null;
    let topXGames: GamesListModel[] = null;
    let matchQuizGames: GamesListModel[] = null;
    const [topXGameIds, matchQuizGameIds, templateIds] =
      this.loyaltyService.extractRankingsIdsMap(userRankings.data);

    if (templateIds.length) {
      const allTemplates = await this.getTemplates();
      templates = allTemplates.data.filter((template: TemplateModel) =>
        templateIds.includes(template.id)
      );
    }

    if (topXGameIds.length) {
      const topXFilters = { gameIds: topXGameIds };
      const paginatedTopXGames = await this.topXFacade.getGames(
        topXFilters as GamesFilters
      );
      topXGames = paginatedTopXGames.data;
    }

    if (matchQuizGameIds.length) {
      const matchQuizFilters = { gameIds: matchQuizGameIds };
      const paginatedMatchQuizGames = await this.matchQuizFacade.getGames(
        matchQuizFilters as GamesFilters
      );
      matchQuizGames = paginatedMatchQuizGames.data;
    }

    return this.loyaltyService.setModelsForUserRankings(
      topXGames,
      matchQuizGames,
      templates,
      userRankings
    );
  };

  public getTemplateMatches = async (
    templateId: string,
    groupId?: string,
    disableCache?: boolean
  ) => {
    const template = await this.getTemplateById(templateId, disableCache);

    if (
      (!template.competitionIds || template.competitionIds.length === 0) &&
      (!template.teamIds || template.teamIds.length === 0) &&
      (!template.matchIds || template.matchIds.length === 0) &&
      (!template.groups || template.groups.length === 0)
    ) {
      // The template is 'empty' - no competition, team, match or group ids are set
      return [];
    }

    const filters = this.loyaltyService.initMatchFiltersForTemplates(
      template,
      groupId
    );
    const response = await this.football.getMatches(filters, disableCache);

    return response.data;
  };
}
