import { FiltersInterface } from "../../../../Global/Interfaces/GlobalInterfaces";
import { SortOrder } from "../../../../Global/Types/GlobalTypes";
import { GameType, RankingsType } from "../../Types/LoyaltyTypes";
import LoyaltyFilters from "./LoyaltyFilters";


export default class RankingsFilters extends LoyaltyFilters implements FiltersInterface {
    public sortOrder?: SortOrder = null;
    public rankType?: RankingsType = null;
    public leaderboardIds?: string[] = null;
    public gameType?: GameType = null;

    constructor(filters: any) {
        super(filters);

        this.sortOrder = filters.sortOrder;
        this.rankType = filters.rankType;
        this.leaderboardIds = filters.leaderboardIds;
        // Let the client use game type filter only when type is provided
        this.gameType = filters.type && filters.type.toLowerCase() === "game" ? filters.gameType : null;
    }

    public constructFiltersUrl = (): string  => {
        let url = super.constructFiltersUrl();

        if (this.sortOrder) {
            url += '&';
            url += `sort_order=${this.sortOrder}`;
        }
        if (this.rankType) {
            url += '&';
            url += `rank_type=${this.rankType}`;
        }
        if (this.leaderboardIds) {
            url += '&';
            url += `type_ids=${this.leaderboardIds.join(",")}`;
        }
        if (this.gameType) {
            url += '&';
            url += `game_type=${this.gameType}`;
        }

        return url;
    };
}