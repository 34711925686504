import MiniGameImages from "../MiniGameImages";
import ClassicQuizOptionModel from "./ClassicQuizOptionModel";

export default class ClassicQuizQuestionModel {
  public questionId: number = null;
  public question: string = null;
  public images: MiniGameImages = null;
  public embedCode: string = null;
  public options: ClassicQuizOptionModel[] = [];
}
