import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './CornersModal.module.css';

export const CornersModal = ({ onOptionSelected, optionPrediction }) => {
  const { t } = useTranslation();

  const [toggled, setToggled] = useState(optionPrediction ?? true);

  return (
    <div className={styles.container}>
      <button className={styles.switchButton} onClick={() => setToggled(!toggled)}>
        <div className={[styles.text, toggled ? styles.textToggled : ''].join(' ')}>{t('yes')}</div>
        <div className={[styles.text, !toggled ? styles.textToggled : ''].join(' ')}>{t('no')}</div>
      </button>
      <button className={styles.submit} onClick={() => onOptionSelected(toggled)}>{t('Save')}</button>
    </div>
  )
}

export default CornersModal;