import FootballCountryModel from "../Country/FootballCountryModel";


export default class PlayerSquadModel {
    public id: string = "";
    public startDate: string = "";
    public endDate: string = "";
    public shirtNumber: number;
    public loan: boolean = false;
    public position: string = "";
    public country: FootballCountryModel = null;
    public name: string = "";
    public assets: {} = null;
    public birthDate: string = "";

}