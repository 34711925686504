import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './DeleteModal.module.css';

const DeleteModal = ({ isLeave, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  return (
    <>
      <button 
        className={styles.submitButton}
        onClick={onSubmit}
      >
        {isLeave ? t('Yes, leave') : t('Yes, delete')}
      </button>
      <button className={styles.cancelButton} onClick={onCancel}>{t('Cancel')}</button>
    </>
  )
}

export default DeleteModal;