import {ValidationResponseEnum} from "../Enums/ValidationResponseEnum";
import {ValidationMessageEnum} from "../Enums/ValidationMessageEnum";
import {LogEnum} from "../Enums/LogEnum";
import AuthProviderConfigModel from "../Models/AuthProviderConfigModel";
import AuthProviderValidationModel from "../Models/AuthProviderValidationModel";


export default class AuthProviderValidator {

    validate(arg: any): any {
        let authProviderValidation = this.validateAuthProvider(arg.authProvider);
        if (authProviderValidation.validation !== ValidationResponseEnum.MISSING_VALUE) {
            authProviderValidation = this.validateAuthProviderConfigFields(arg.authProvider, authProviderValidation);
            authProviderValidation = this.generateAuthProviderConfigMessages(authProviderValidation);
            authProviderValidation = this.checkAuthProviderConfigMessagesForErrors(authProviderValidation);
        }

        return authProviderValidation;
    };

    private validateAuthProvider = (authProvider: any) => {
        const authProviderConfigurationKeys = Object.keys(new AuthProviderConfigModel());

        const authProviderValidationModel = new AuthProviderValidationModel();

        if (authProvider) {
            const authProviderConfigKeys = Object.keys(authProvider);
            const incorrectKeys = authProviderConfigKeys.filter((key: string) => !authProviderConfigurationKeys.includes(key));

            if (incorrectKeys.length > 0) {
                authProviderValidationModel.validation = ValidationResponseEnum.INVALID_VALUE;
                authProviderValidationModel.message.type = LogEnum.WARNING;
                authProviderValidationModel.message.text = `${ValidationMessageEnum.AUTH_PROVIDER_CONFIG_INVALID_PROP} ${incorrectKeys.join(',')}`;
            } else {
                authProviderValidationModel.validation = ValidationResponseEnum.VALID_VALUE;
            }

        } else {
            authProviderValidationModel.validation = ValidationResponseEnum.MISSING_VALUE;
            authProviderValidationModel.message.type = LogEnum.ERROR;
            authProviderValidationModel.message.text = ValidationMessageEnum.AUTH_PROVIDER_CONFIG_MISSING;
        }

        return authProviderValidationModel;

    };

    private validateAuthProviderConfigFields = (authProvider: any, authProviderValidation: any): any => {
        const supportedKeys: string[] = Object.keys(new AuthProviderConfigModel());
        supportedKeys.forEach((key: string) => {
            if (authProvider[key]) {
                authProviderValidation[key].validation = ValidationResponseEnum.VALID_VALUE
            } else {
                authProviderValidation[key].validation = ValidationResponseEnum.MISSING_VALUE
            }
            });

        return authProviderValidation;
    };

    private generateAuthProviderConfigMessages = (authProviderValidation: AuthProviderValidationModel): any => {
        Object.keys(authProviderValidation).filter((key: string) => key !== 'validation' && key !== 'message').forEach((key: string) => {
            //@ts-ignore
            const obj = authProviderValidation[key];

            if (obj.validation === ValidationResponseEnum.MISSING_VALUE) {
                obj.message.text = `${ValidationMessageEnum.AUTH_PROVIDER_CONFIG_PROP_MISSING} ${key}`;
                obj.message.type = LogEnum.ERROR;
            }
        });

        return authProviderValidation;
    };

    private checkAuthProviderConfigMessagesForErrors = (authProviderValidation: AuthProviderValidationModel) => {
        Object.keys(authProviderValidation).filter((key: string) => key !== 'validation' && key !== 'message').forEach((key: string) => {
            //@ts-ignore
            const obj = authProviderValidation[key];

            if (obj.validation === ValidationResponseEnum.MISSING_VALUE) {
                if (authProviderValidation.validation === ValidationResponseEnum.VALID_VALUE) {
                    authProviderValidation.validation = ValidationResponseEnum.INVALID_VALUE;
                    authProviderValidation.message.type = LogEnum.ERROR;
                    authProviderValidation.message.text = ValidationMessageEnum.AUTH_PROVIDER_CONFIG_MISSING_CONFIG;
                }
            }
        });

        return authProviderValidation;
    };
}
