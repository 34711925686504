import {IRemapper} from "../../../../../Global/IRemapper";
import MatchBasicModel from "../MatchBasicModel";
import TeamBasicRemapper from "../../Team/Remappers/TeamBasicRemapper";
import CompetitionBasicRemapper from "../../Competition/Remappers/CompetitionBasicRemapper";
import ScoresRemapper from "../Scores/ScoresRemapper";

export default class MatchBasicRemapper implements IRemapper<MatchBasicModel> {
    private teamRemapper: TeamBasicRemapper = null;
    private competitionRemapper: CompetitionBasicRemapper = null;
    private scoresRemapper: ScoresRemapper = null;

    constructor() {
        this.teamRemapper = new TeamBasicRemapper();
        this.competitionRemapper = new CompetitionBasicRemapper();
        this.scoresRemapper = new ScoresRemapper();
    }

    fromResponse(response: any): MatchBasicModel {
        try {
            const model = new MatchBasicModel();
            model.id = response.id;
            model.kickoffAt = response.kickoff_at;
            model.finishedAt = response.finished_at;
            model.updatedAt = response.updated_at;
            model.isDeleted = response.is_deleted;
            model.undecided = response.undecided;
            model.homeTeam = this.teamRemapper.fromResponse(response.home_team);
            model.awayTeam = this.teamRemapper.fromResponse(response.away_team);
            model.lineupsConfirmed = response.lineups_confirmed;
            model.startedAt = response.started_at;
            model.minute = response.minute;
            model.scores = this.scoresRemapper.fromResponse(response.scores);
            model.context = { competition: response.context.competition ? this.competitionRemapper.fromResponse(response.context.competition) : null };
            model.status = {type: response.status.type, subType: response.status.sub_type};
            return model;
        } catch (e) {
            console.warn('There was a problem remapping the match response', e);
            throw e;
        }
    };

}