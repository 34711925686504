export enum OneXTwoEnum {
    ONE = "1",
    X = "x",
    TWO = "2"
};

export enum DoubleChanceEnum {
    ONE_X = "1x",
    X_TWO = "x2",
    ONE_TWO = "12",
};

export enum HalfTimeFullTimeEnum {
    ONE_ONE = "1/1",
    ONE_X = "1/x",
    ONE_TWO = "1/2",
    X_ONE = "x/1",
    X_X = "x/x",
    X_TWO = "x/2",
    TWO_ONE = "2/1",
    TWO_X = "2/x",
    TWO_TWO = "2/2"
};