import CompletedModel from "./CompletedModel";

export default class BreakdownModel {

    public teamFollowed: CompletedModel = new CompletedModel(10);
    public teamFavourite: CompletedModel = new CompletedModel(15);
    public playerFollowed: CompletedModel = new CompletedModel(10);
    public playerFavourite: CompletedModel = new CompletedModel(10);
    public competitionFollowed: CompletedModel = new CompletedModel(10);
    public competitionFavourite: CompletedModel = new CompletedModel(5);
    public addedBirthdate: CompletedModel = new CompletedModel(10);
    public addedGender: CompletedModel = new CompletedModel(10);
    public addedCountry: CompletedModel = new CompletedModel(10);
    public addedAvatar: CompletedModel = new CompletedModel(10);

}
