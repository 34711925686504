import {ConfigFieldEnum} from "./Enums/ConfigFieldEnum";
import {LogEnum} from "./Enums/LogEnum";
import LangValidator from "./Validators/LangValidator";
import EnvironmentValidator from "./Validators/EnvironmentValidator";
import ApiKeyValidator from "./Validators/ApiKeyValidator";
import IdSchemaValidator from "./Validators/IdSchemaValidator";
import AuthProviderValidator from "./Validators/AuthProviderValidator";
import SDKConfigurationModel from "./Models/SDKConfiguraitonModel";
import ConfigValidationModel from "./Models/ConfigValidationModel";
import ClientIdValidator from "./Validators/ClientIdValidator";
import ErrorHandlingModeValidator from "./Validators/ErrorHandlingModeValidator";

export default class ConfigurationValidator {

    /**
     * Validates the SDK configuration and generates a validation model of type @ConfigValidationModel.
     * The validation model contains information about which field is valid and the appropriate message for each field
     * which must be displayed to the developer.
     * @param config
     */
    public validate = (config: SDKConfigurationModel | any): ConfigValidationModel => {
        let configValidation = this.checkFieldsForValues(config);
        configValidation.isValid = this.containsErrorMessage(configValidation);

        return configValidation;
    };

    /**
     * Loops through the different config values and adds the proper validation for each one.
     * @param config
     */
    private checkFieldsForValues = (config: SDKConfigurationModel): ConfigValidationModel => {
        let configValidation: ConfigValidationModel = new ConfigValidationModel();
        const validators = {
            apiKey: new ApiKeyValidator(),
            environment: new EnvironmentValidator(),
            idSchema: new IdSchemaValidator(),
            clientId: new ClientIdValidator(),
            lang: new LangValidator(),
            errorHandlingMode: new ErrorHandlingModeValidator(),
            authProvider: new AuthProviderValidator(),
        };

        for (let [key, value] of Object.entries(configValidation)) {
            if (key !== 'isValid') {
                value = validators[key as ConfigFieldEnum].validate(config);
            }
        }

        Object.keys(validators).forEach((key) => {
            //@ts-ignore
            configValidation[key] = validators[key].validate(config);

        })

        return configValidation;
    };

    /**
     * Checks the messages if there is one of type "error" which indicates that the SDK cannot work without it.
     * @param configValidation
     */
    private containsErrorMessage = (configValidation: ConfigValidationModel): boolean => {
        let isValid = true;

        for (const [key, value] of Object.entries(configValidation)) {
            if (key !== ConfigFieldEnum.authProvider) {
                if (key !== 'isValid' && value.message.type === LogEnum.ERROR) {
                    isValid = false;
                    return isValid;
                } else if (key !== "isValid" && value.message.type === LogEnum.WARNING) {
                    isValid = true;
                    return isValid;
                }
            } else {
                if (value.message.type === LogEnum.ERROR) {
                    isValid = false;
                    return isValid;
                }
            }
        }

        return isValid;

    }
}
