import CreatePostParamBody from "../Models/CreatePostParamBody";
import PostCreateModel from "../Models/PostCreateModel";

export default class DiscussionsFactory {
  public createRequestBodyFromParam = (param: CreatePostParamBody) => {
    const model = new PostCreateModel();

    model.content = param.content;
    model.reply_id = param.replyId;
    model.anonymous_nickname = param.userNickname
    model.context = param.context;

    return model;
  };
}
