import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import MiniGamesFacade from "./Facade/MiniGamesFacade";
import { IEitherOrParticipationCallback } from "./Interfaces/MiniGamesInterfaces";
import ClassicQuizFilters from "./Models/ClassicQuiz/ClassicQuizFilters";
import ClassicQuizParticipationBodyModel from "./Models/ClassicQuiz/ClassicQuizParticipationBodyModel";
import EitherOrFilters from "./Models/EitherOr/EitherOrFilters";
import EitherOrParticipationRequestModel from "./Models/EitherOr/Participation/EitherOrParticipationRequestModel";

export default class MiniGames {
  private facade: MiniGamesFacade = null;

  constructor(config: SDKConfigurationModel) {
    this.facade = new MiniGamesFacade(config);
  }

  public getClassicQuizzes = async (
    filters?: ClassicQuizFilters,
    showFullContextData?: boolean,
    disableCache?: boolean
  ) => {
    return await this.facade.getClassicQuizzes(
      filters,
      showFullContextData,
      disableCache
    );
  };

  public getClassicQuizById = async (
    classicQuizId: string,
    includeCorrectOptionIds?: boolean,
    disableCache?: boolean
  ) => {
    return await this.facade.getClassicQuizById(classicQuizId, includeCorrectOptionIds, disableCache);
  };

  public participateInClassicQuiz = async (
    classicQuizId: string,
    questions: ClassicQuizParticipationBodyModel[],
    includeCorrectOptionIds?: boolean
  ) => {
    return await this.facade.participateInClassicQuiz(
      classicQuizId,
      questions,
      includeCorrectOptionIds
    );
  };

  public getMyClassicQuizParticipations = async (classicQuizIds: string[]) => {
    return await this.facade.getMyClassicQuizParticipations(classicQuizIds);
  };

  public getEitherOrs = async (
    filters?: EitherOrFilters,
    showFullContextData?: boolean,
    disableCache?: boolean
  ) => {
    return await this.facade.getEitherOrs(
      filters,
      showFullContextData,
      disableCache
    );
  };

  public getEitherOrById = async (
    eitherOrId: string,
    disableCache?: boolean
  ) => {
    return await this.facade.getEitherOrById(eitherOrId, disableCache);
  };

  public getMyEitherOrStats = async (eitherOrId: string) => {
    return await this.facade.getMyEitherOrStats(eitherOrId);
  };

  public getEitherOrResults = async (
    eitherOrId: string,
    disableCache?: boolean
  ) => {
    return await this.facade.getEitherOrResults(eitherOrId, disableCache);
  };

  public participateInEitherOr = async (
    eitherOrId: string,
    participation: EitherOrParticipationRequestModel | null,
    onEitherOrParticipationCallbacks?: IEitherOrParticipationCallback
  ) => {
    return await this.facade.participateInEitherOr(
      eitherOrId,
      participation,
      onEitherOrParticipationCallbacks
    );
  };
}
