export default class FiltersMetaModel {
  public flags: string = "";
  public status: string = "";
  public type: string = "";
  public activeFrom: string = "";
  public activeTo: string = "";
  public createdFrom: string = "";
  public createdTo: string = "";
  public updatedFrom: string = "";
  public updatedTo: string = "";
}
