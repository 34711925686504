import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import statisticsIcon from'../../../../assets/statistics.png';
import { MatchStatus, parsedDate } from '../../../../Utils';

import styles from './InfoHeader.module.css';
import Modal from '../../../../common/Modal';
import StatisticsModal from '../StatisticsModal';

const InfoHeader = ({ match, fansUnitedSDK }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isLive = match.status.type === MatchStatus.IN_PROGRESS;

  const StatisticsButton = () => (
    <div 
      className={styles.statisticsContainer} 
      onClick={()=> { setIsModalOpen(!isModalOpen) }}
    >
      <span className={styles.statisticsText}>{t('View statistics')}</span>
      <img src={statisticsIcon} className={styles.statisticsIcon} alt={`statistics-icon`}/> 
    </div>
  )

  return (
    <div className={styles.container}>
      {isLive 
        ? <div className={styles.live}>LIVE</div>
        : <div className={styles.date}>{parsedDate(match?.kickoffAt)}</div>
      }
      {!match.undecided && <StatisticsButton/>}
      <Modal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        title={t('Statistics')}
        subtitle={t('Last 5 matches of each team')}
      >
        <StatisticsModal homeTeam={match.homeTeam} awayTeam={match.awayTeam} fansUnitedSDK={fansUnitedSDK}/>
      </Modal>
    </div>
  )
}

export default InfoHeader;