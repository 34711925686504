export default class TeamFilters {
    public name?: string = null;
    public countryId?: string = null;
    public national?: boolean = false;
    public gender?: string = null;
    public limit?: number = null;
    public page?: number = null;
    public scope?: string[] = null;
    public sortField?: string = null;
    public sortOrder?: string = null;
    public teamIds?: string[] = null;

    constructor(filters: any) {
        this.name = filters.name ? filters.name : null;
        this.countryId = filters.countryId ? `${filters.countryId}` : null;
        this.national = filters.national ? filters.national : false;
        this.gender = filters.gender ? filters.gender : null;
        this.limit = filters.limit ? filters.limit : null;
        this.page = filters.page ? filters.page : null;
        this.scope = filters.scope ? filters.scope : null;
        this.sortField = filters.sortField ? `${filters.sortField}` : null;
        this.sortOrder = filters.sortOrder ? `${filters.sortOrder}` : null;
        this.teamIds = filters.teamIds ? filters.teamIds : null;
    }

    public constructFilterUrl? = () => {
        let url = '';
        if (this.name) {
            url = url + '&';
            url = url + `name=${this.name}`;
        }
        if (this.countryId) {
            url = url + '&';
            url = url + `country_id=${this.countryId}`;
        }
        if (this.national) {
            url = url + '&';
            url = url + `national=${this.national}`;
        }
        if (this.gender) {
            url = url + '&';
            url = url + `gender=${this.gender}`;
        }
        if (this.limit) {
            url = url + '&';
            url = url + `limit=${this.limit}`;
        }
        if (this.scope && this.scope.length > 0) {
            url = url + '&';
            url = url + `scope=${this.scope.join(",")}`;
        }
        if (this.page) {
            url = url + '&';
            url = url + `page=${this.page}`;
        }
        if (this.sortField) {
            url = url + '&';
            url = url + `sort_field=${this.sortField}`;
        }
        if (this.sortOrder) {
            url = url + '&';
            url = url + `sort_order=${this.sortOrder}`;
        }
        if (this.teamIds && this.teamIds.length > 0) {
            url = url + '&';
            url = url + `team_ids=${this.teamIds.join(",")}`;
        }

        return url;
    };

}