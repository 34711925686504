import PaginationModel from "../../../../Global/Models/Pagination/PaginationModel";
import FollowersModel from "../Followers/FollowersModel";

export default class FollowersRemapper {
    public followersFromResponse = (response: any) => {
        const paginatedModel = new PaginationModel();

        paginatedModel.meta.pagination.nextPageStartsAfter = response.meta.pagination.next_page_starts_after;
        paginatedModel.meta.pagination.itemsPerPage = response.meta.pagination.items_per_page;
        paginatedModel.data = response.data.map((resp: any) => this.remapResponse(resp))

        return paginatedModel;
    }

    private remapResponse = (response: any) => {
        const model = new FollowersModel();

        model.avatar = response.avatar;
        model.followerId = response.follower_id;
        model.name = response.name;
        model.profileId = response.profile_id;

        return model;
    };
}