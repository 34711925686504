import AchievementsStats from "./AchievementsStats";
import DiscussionStats from "./DiscussionStats";
import PredictionsStats from "./PredictionsStats";
import SuccessRates from "./SuccessRates";

export default class ProfileStatsModel {
  public profileId: string;
  public tier: string;
  public points: number;
  public predictionsMade: number;
  public successRates: SuccessRates = new SuccessRates();
  public predictions: PredictionsStats = new PredictionsStats();
  public tiers: AchievementsStats[] = [];
  public badges: AchievementsStats[] = [];
  public discussions: DiscussionStats = new DiscussionStats();
}
