import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import {
  initDisableCache,
  isDisabledCache,
  setBaseUrl,
} from "../Global/Helper";
import DiscussionsFilters from "../Namespaces/Discussions/Models/Filters/DiscussionsFilters";
import PostsFilters from "../Namespaces/Discussions/Models/Filters/PostsFilters";
import TopDiscussionsFilters from "../Namespaces/Discussions/Models/Filters/TopDiscussionsFilters";
import PostCreateModel from "../Namespaces/Discussions/Models/PostCreateModel";
import DiscussionRemapper from "../Namespaces/Discussions/Models/Remapper/DiscussionRemapper";
import {
  ReactionPostType,
  ReportReasonType,
} from "../Namespaces/Discussions/Types/types";
import Https from "./Https";

export default class DiscussionsHttps extends Https {
  private remapper: DiscussionRemapper = null;

  constructor(config: SDKConfiguraitonModel) {
    super(config, setBaseUrl(config.environment, "discussions"));
    this.remapper = new DiscussionRemapper();
  }

  public getAll = async (
    filters: DiscussionsFilters,
    disableCache?: boolean
  ) => {
    let url = `/discussions` + this.apiSignInUrl;

    if (filters) {
      url += `${filters.constructMainFiltersUrl()}`;
    }

    if (isDisabledCache(disableCache)) {
      url += initDisableCache();
    }

    const warnMessage = "There was a problem getting discussions";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.remapper.remapToDiscussions(data);
  };

  public getById = async (discussionId: string) => {
    const url = `/discussions/${discussionId}` + this.apiSignInUrl;
    const warnMessage = "There was a problem getting discussion";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.remapper.remapToDiscussionById(data.data);
  };

  public createPost = async (
    discussionId: string,
    requestBody: PostCreateModel
  ) => {
    const url = `/discussions/${discussionId}/posts` + this.apiSignInUrl;
    const warnMessage = "There was a problem creating post in discussion";
    const method = "POST";
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return this.remapper.remapToPostByIdBasic(data.data);
  };

  public getPostById = async (postId: string) => {
    const url = `/posts/${postId}` + this.apiSignInUrl;
    const warnMessage = "There was a problem getting post by ID in discussion";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.remapper.remapToPostByIdFull(data.data);
  };

  public updatePost = async (postId: string, content: string) => {
    const url = `/posts/${postId}` + this.apiSignInUrl;
    const warnMessage = "There was a problem editing post in discussion";
    const method = "PUT";
    const requestBody = { content };
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return this.remapper.remapToPostByIdBasic(data.data);
  };

  public reactToPost = async (postId: string, reaction: ReactionPostType) => {
    const url = `/posts/${postId}/reaction` + this.apiSignInUrl;
    const warnMessage = "There was a problem reacting to post in discussion";
    const method = "PUT";
    const requestBody = { reaction: reaction.toLocaleLowerCase() };
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return this.remapper.remapToPostByIdBasic(data.data);
  };

  public deletePost = async (postId: string) => {
    const url = `/posts/${postId}` + this.apiSignInUrl;
    const warnMessage = "There was a problem deleting post in discussion";
    const method = "DELETE";
    await this.fetchWithAuth(url, warnMessage, method);

    return true;
  };

  public getPosts = async (discussionId: string, filters?: PostsFilters) => {
    let url = `/discussions/${discussionId}/posts` + this.apiSignInUrl;

    if (filters) {
      url += `${filters.constructMainFiltersUrl()}`;
    }

    const warnMessage = "There was a problem getting posts in discussion";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.remapper.remapToPostsForDiscussion(data, "full");
  };

  public getPostReplies = async (postId: string, filters?: PostsFilters) => {
    let url = `/posts/${postId}/replies` + this.apiSignInUrl;

    if (filters) {
      url += `${filters.constructMainFiltersUrl()}`;
    }

    const warnMessage =
      "There was a problem getting post's replies in discussion";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.remapper.remapToPostsForDiscussion(data, "full");
  };

  public reportPost = async (
    postId: string,
    reason: ReportReasonType,
    reasonDetails: string
  ) => {
    const url = `/posts/${postId}/report` + this.apiSignInUrl;
    const warnMessage = "There was a problem reporting post in discussion";
    const method = "POST";
    const requestBody = {
      reason: reason.toLocaleUpperCase(),
      reason_details: reasonDetails ? reasonDetails : null,
    };
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return this.remapper.remapToPostByIdBasic(data.data);
  };

  public getOwnPosts = async (filters?: PostsFilters) => {
    let url = `/posts` + this.apiSignInUrl;

    if (filters) {
      url += `${filters.constructMainFiltersUrl()}`;
    }

    const warnMessage = "There was a problem getting own posts";
    const data = await this.fetchWithAuth(url, warnMessage);

    return this.remapper.remapToPosts(data);
  };

  public getUserPosts = async (
    userId: string,
    filters?: PostsFilters,
    disableCache?: boolean
  ) => {
    let url = `/posts/users/${userId}` + this.apiSignInUrl;

    if (filters) {
      url += `${filters.constructMainFiltersUrl()}`;
    }

    if (isDisabledCache(disableCache)) {
      url += initDisableCache();
    }

    const warnMessage = "There was a problem getting user's posts";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.remapper.remapToPosts(data);
  };

  public getPostsCount = async (
    discussionIds: string[],
    disableCache: boolean
  ) => {
    let url = `/discussions/posts/count` + this.apiSignInUrl + `&discussion_ids=${discussionIds.join(",")}`;

    if (isDisabledCache(disableCache)) {
      url += initDisableCache();
    }

    const warnMessage =
      "There was a problem getting posts count for discussions";
    const response = await this.fetchWithoutAuth(url, warnMessage);

    return this.remapper.remapToPostCount(response.data);
  };

  public getTopDiscussions = async (
    filters: TopDiscussionsFilters,
    disableCache: boolean
  ) => {
    let url = `/discussions/top` + this.apiSignInUrl;

    if (filters) {
      url += `${filters.constructMainFiltersUrl()}`;
    }

    if (isDisabledCache(disableCache)) {
      url += initDisableCache();
    }

    const warnMessage = "There was a problem getting top discussions";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.remapper.remapToDiscussions(data);
  };
}
