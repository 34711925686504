import MetaProperties from "../../../../Global/Models/Pagination/MetaProperties";
import EitherOrBasicModel from "../EitherOr/EitherOrBasicModel";
import EitherOrFullModel from "../EitherOr/EitherOrFullModel";
import EitherOrsModel from "../EitherOr/EitherOrsModel";
import EitherOrPointsModel from "../EitherOr/EitherOrPointsModel";
import EitherOrStatsModel from "../EitherOr/EitherOrStatsModel";
import EitherOrParticipationModel from "../EitherOr/Participation/EitherOrParticipationModel";
import EitherOrParticipationStepsModel from "../EitherOr/Participation/EitherOrParticipationStepsModel";
import EitherOrResultsBreakdownModel from "../EitherOr/Results/EitherOrResultsBreakdownModel";
import EitherOrResultsModel from "../EitherOr/Results/EitherOrResultsModel";
import EitherOrResultsStandingsModel from "../EitherOr/Results/EitherOrResultsStandingsModel";
import MiniGamesContext from "../MiniGamesContext";
import MiniGamesContextTag from "../MiniGamesContextTag";
import BaseRemapper from "../../../../Global/Remapper/BaseRemapper";

export default class EitherOrRemapper extends BaseRemapper {
  public remapEitherOrs = (response: any): EitherOrsModel => {
    response.meta.pagination = this.remapMetaPagination(
      response.meta.pagination
    );
    response.data = response.data.map((value) =>
      this.remapBasicEitherOr(value)
    );

    return response;
  };

  public remapEitherOrById = (data: any) => {
    const model = new EitherOrFullModel();

    model.id = data.id;
    model.title = data.title;
    model.description = data.description;
    model.type = data.type;
    model.images = data.images;
    model.status = data.status;
    model.winningCondition = data.winning_condition;
    model.lives = data.lives;
    model.flags = data.flags;
    model.customFields = data.custom_fields;
    model.branding = data.branding ? this.remapBranding(data.branding) : null;
    model.labels = data.labels;
    model.time = data.time;
    model.context = data.context
      ? this.remapContext(data.context)
      : data.context;
    model.points = data.points
      ? data.points.map((v) => this.remapPoints(v))
      : data.points;
    model.rules = data.rules;
    model.adContent = data.ad_content;
    model.createdAt = data.created_at;
    model.updatedAt = data.updated_at;

    return model;
  };

  public remapEitherOrOwnStats = (data: any) => {
    const model = new EitherOrStatsModel();

    model.eitherOrId = data.either_or_id;
    model.personalBest = data.personal_best;

    return model;
  };

  public remapEitherOrResults = (data: any) => {
    const model = new EitherOrResultsModel();

    model.standings = data.standings
      ? data.standings.map((v) => this.remapResultsStandings(v))
      : data.standings;
    model.breakdown = data.breakdown
      ? data.breakdown.map((v) => this.remapResultsBreakdown(v))
      : data.breakdown;

    return model;
  };

  public remapEitherOrParticipation = (data: any) => {
    const model = new EitherOrParticipationModel();

    model.instanceId = data.instance_id;
    model.eitherOrId = data.either_or_id;
    model.gameStartedAt = data.game_started_at;
    model.currentStep = data.current_step;
    model.currentStreak = data.current_streak;
    model.remainingSteps = data.remaining_steps;
    model.currentLives = data.current_lives;
    model.currentPoints = data.current_points;
    model.personalBest = data.personal_best;
    model.steps = data.steps
      ? data.steps.map((v) => this.remapParticipationSteps(v))
      : data.steps;

    return model;
  };

  private remapBasicEitherOr = (data: any) => {
    const model = new EitherOrBasicModel();

    model.id = data.id;
    model.title = data.title;
    model.description = data.description;
    model.type = data.type;
    model.images = data.images;
    model.status = data.status;
    model.winningCondition = data.winning_condition;
    model.lives = data.lives;
    model.flags = data.flags;
    model.customFields = data.custom_fields;
    model.branding = data.branding ? this.remapBranding(data.branding) : null;
    model.labels = data.labels;
    model.adContent = data.ad_content;
    model.context = data.context
      ? this.remapContext(data.context)
      : data.context;
    model.time = data.time;
    model.points = data.points
      ? data.points.map((v) => this.remapPoints(v))
      : data.points;
    model.createdAt = data.created_at;
    model.updatedAt = data.updated_at;

    return model;
  };

  private remapMetaPagination = (pagination: any) => {
    const model = new MetaProperties();

    model.itemsPerPage = pagination.items_per_page;
    model.nextPageStartsAfter = pagination.next_page_starts_after;

    return model;
  };

  private remapPoints = (data: any) => {
    const model = new EitherOrPointsModel();

    model.correctSteps = data.correct_steps;
    model.score = data.score;

    return model;
  };

  private remapResultsStandings = (data: any) => {
    const model = new EitherOrResultsStandingsModel();

    model.userId = data.user_id;
    model.personalBest = data.personal_best;

    return model;
  };

  private remapResultsBreakdown = (data: any) => {
    const model = new EitherOrResultsBreakdownModel();

    model.rangeStartInclusive = data.range_start_inclusive;
    model.rangeEndInclusive = data.range_end_inclusive;
    model.totalUsers = data.total_users;

    return model;
  };

  private remapParticipationSteps = (data: any) => {
    const model = new EitherOrParticipationStepsModel();

    model.pairId = data.pair_id;
    model.userSelection = data.user_selection;
    model.correct = data.correct;
    model.played = data.played;
    model.timestamp = data.timestamp;
    model.optionOne = data.option_one;
    model.optionTwo = data.option_two;

    return model;
  };

  private remapContext = (data: any) => {
    const model = new MiniGamesContext();

    model.content = data.content;
    model.tags = data.tags
      ? data.tags.map((value) => {
          const newTag = new MiniGamesContextTag();

          newTag.id = value.id;
          newTag.type = value.type;
          newTag.source = value.source;

          return newTag;
        })
      : null;
    model.campaign = data.campaign;

    return model;
  };
}
