import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import { ErrorMessages } from "../../../Global/Messages/Messages";
import ClientHttps, { FeatureConfigType } from "../../../Https/ClientHttps";
import MarketsConfigModel from "../../Predictor/Models/Config/MarketsConfigModel";
import PredictorConfigModel from "../../Predictor/Models/Config/PredictorConfigModel";
import MatchBasicModel from "../Models/Match/MatchBasicModel";
import MatchFilters from "../Models/Match/MatchFilters";
import MatchFullModel from "../Models/Match/MatchFullModel";
import MetaFootballModel from "../Models/Pagination/MetaFootballModel";

type MatchData = MatchBasicModel[] | MatchFullModel;
type Match = MatchBasicModel | MatchFullModel;

export default class FootballService {
  private clientHttps: ClientHttps = null;
  private errorHandlingMode: string = null;

  constructor (config: SDKConfigurationModel) {
    this.clientHttps = new ClientHttps(config);
    this.errorHandlingMode = config.errorHandlingMode;
  }

  public setAvailableMarketsForMatch = async (matchData: MatchData): Promise<MatchData> => {
    const { fullCoverageCompetitions, markets } = await this.clientHttps.getConfig(FeatureConfigType.PREDICTOR) as PredictorConfigModel;

    if (Array.isArray(matchData)) {
      return matchData.map((match: MatchBasicModel) => {
        return this.setAvailableMarkets(match, fullCoverageCompetitions, markets);
      });
    } else if (typeof matchData === 'object' && Object.keys(matchData).length) {
      return this.setAvailableMarkets(matchData, fullCoverageCompetitions, markets) as MatchFullModel;
    }
  };

  private setAvailableMarkets = (matchData: Match, fullCoverageCompetitions: string[], markets: MarketsConfigModel): Match  => {
    const competitionId = matchData.context.competition?.id;

    if (competitionId && this.isMatchInCoverageCompetition(competitionId, fullCoverageCompetitions)) {
      matchData.availableMarkets = markets.all;

      return matchData;
    }

    matchData.availableMarkets = markets.main;

    return matchData;
  };

  public setMetaDataBatchRequest = (totalItems: number, limit: number, page: number) => {
    const meta = new MetaFootballModel();

    meta.pagination.totalItems = totalItems;
    meta.pagination.itemsPerPage = limit;
    meta.pagination.numberOfPages = totalItems ? Math.ceil(totalItems / limit) : 0;
    meta.pagination.currentPage = page;

    if (meta.pagination.numberOfPages > 0 && page > meta.pagination.numberOfPages) {
      if (this.errorHandlingMode === "standard") {
        throw new StandardFansUnitedException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_PAGE_NUMBER,
          ErrorMessages.INVALID_PAGE_NUMBER
        );
      }

      throw new FansUnitedSdkException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_PAGE_NUMBER,
        ErrorMessages.INVALID_PAGE_NUMBER
      );
    }

    return meta;
  };

  public finalizeMatchesDataBatchRequest = (matches: MatchBasicModel[], filters: MatchFilters) => {
    matches = matches.sort((a, b) => new Date(a.kickoffAt).getTime() - new Date(b.kickoffAt).getTime());
    const limit = filters.limit || 20;

    // Check for page, if passed then slice appropriate matches
    if (filters.page) {
      const start = filters.page > 1 ? limit * (filters.page - 1) : 0;
      matches = matches.slice(start, start + limit);
    }

    // When no page is passed but a limit is applied, then slice the matches by the limit
    if (!filters.page && matches.length > limit) {
      matches = matches.slice(0, limit);
    }

    return matches;
  };

  private isMatchInCoverageCompetition = (competitionId: string, fullCoverageCompetitions: string[]): boolean => {
    let isInCoverage = false;

    if (fullCoverageCompetitions.includes(competitionId)) {
      isInCoverage = true;

      return isInCoverage;
    }

    return isInCoverage;
  }
}
