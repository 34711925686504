import MetaModel from "../MetaModel";

export default class MetaRemapper {
    public remapMetaData = (meta: any) => {
        let metaModel = new MetaModel();

        metaModel.pagination.nextPageStartsAfter = meta.pagination.next_page_starts_after;
        metaModel.pagination.itemsPerPage = meta.pagination.items_per_page;

        return metaModel;
    };
}