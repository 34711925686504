import { TEMPLATE_ID } from "./constants";

export const getUserLeaguesRequest = (fansUnitedSDK) => {
  return fansUnitedSDK.privateLeagues.getMyLeagues({ limit: 100 });
}

export const getLeagueByIdRequest = (fansUnitedSDK, leagueId) => {
  return fansUnitedSDK.privateLeagues.getById(leagueId, true);
}

export const getLeagueMembersByIdsRequest = (fansUnitedSDK, membersIds) => {
  return fansUnitedSDK.profile.getByIds(membersIds, undefined, true);
}

export const getMyLeaguesStandingsRequest = (fansUnitedSDK) => {
  return fansUnitedSDK.privateLeagues.getMyLeaguesStandings({ limit: 200 });
}

export const getLeagueLeaderboardRequest = (fansUnitedSDK, leagueId) => {
  const options = { 
    templateId: TEMPLATE_ID
  }

  return fansUnitedSDK.loyalty.getLeaderboardForLeague(leagueId, options);
}

export const createPrivateLeagueRequest = (fansUnitedSDK, name, description, templateId = TEMPLATE_ID) => {
  const options = {
    name,
    description,
    templateId,
    usersCanInviteUsers: true,
  };

  return fansUnitedSDK.privateLeagues.create(options);
}

export const joinPrivateLeagueRequest = (fansUnitedSDK, invitationCode) => {
  return fansUnitedSDK.privateLeagues.join(invitationCode);
}

export const leavePrivateLeagueRequest = (fansUnitedSDK, leagueId) => {
  return fansUnitedSDK.privateLeagues.leave(leagueId);
}

export const updatePrivateLeagueRequest = (fansUnitedSDK, league) => {
  const body = {...league, pinnedPosts: []};

  return fansUnitedSDK.privateLeagues.update(league.id, body)
}

export const deletePrivateLeagueRequest = (fansUnitedSDK, leagueId) => {
  return fansUnitedSDK.privateLeagues.delete(leagueId);
}

export const banLeagueUsersRequest = (fansUnitedSDK, leagueId, userIds) => {
  return fansUnitedSDK.privateLeagues.banUsers(leagueId, userIds);
}