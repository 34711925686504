import { LocalCacheInterface } from "../../../Global/Interfaces/GlobalInterfaces";
import FootballHttps from "../../../Https/FootballHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import FootballCountryModel from "../Models/Country/FootballCountryModel"

export default class CountriesFacade {
    private localCache: LocalCacheInterface = null;
    private https: FootballHttps = null;
    private idMapping: IdMappingService = null;

    constructor(localCache: LocalCacheInterface, https: FootballHttps, idMapping: IdMappingService) {
        this.localCache = localCache;
        this.idMapping = idMapping;
        this.https = https;
    }

    public getCountriesWithIdMapping = async (disableCache? : boolean): Promise<FootballCountryModel[]> => {
        try {
            const countries = await this.getCountries(disableCache);
            return this.idMapping.remapEntities(countries, 'country')
        } catch (e) {
            console.warn('There was a problem browsing countries as football entities', e);
            throw e;
        }
    };

    private getCountries = async (disableCache?: boolean): Promise<FootballCountryModel[]> => {
        disableCache = !disableCache ? false : disableCache;
        const countries = this.localCache.getFootballCountries();

        if (countries && countries.length > 0) {
            return countries;
        }

        try {
            const footballCountries = await this.https.getCountries(disableCache);
            this.localCache.setFootballCountries(footballCountries);

            return footballCountries;
        } catch (e) {
            console.warn('There was a problem retrieving countries as football entities', e);
            throw e;
        }
    };
}
