import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './LeaguesHeader.module.css';

const LeaguesHeader = ({ showInfo = true, onCreateLeague, onJoinLeague }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {showInfo && 
        <>
          <div className={styles.title}>{t('Compete against your friends or join public leagues!')}</div>
          <div className={styles.subtitle}>{t('Who\'s got the best prediction skills? Create or join a league to find out!')}</div>
        </>
      }
      <button className={styles.createButton} onClick={onCreateLeague}>{t('Create a league')}</button>
      <button className={styles.joinButton} onClick={onJoinLeague}>{t('Join a league')}</button>
    </div>
  );
}

export default LeaguesHeader;