import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import debounce from "lodash.debounce";

import {
  MarketType,
  getCalendarMatches,
  getInitialDate,
  isSameDay,
} from "../../Utils";
import Loader from "../../common/Loader";
import Toast from "../../common/Toast";
import { useToast } from "../../hooks/useToast";
import {
  createPredictionRequest,
  getMarkets,
  getMatches,
  getMatchesInfoRequest,
  getMatchesSummary,
  getUserPredictions,
} from "../../redux/predictor";
import { InfoCard, PredictionCard } from "./components";
import CalendarHeader from "./components/CalendarHeader/CalendarHeader";

import useFansUnitedSDK from "../../hooks/useFansUnitedSDK";

import { useTranslation } from "react-i18next";

import styles from "./PredictionsFragment.module.css";

const PredictionsFragment = ({ goToRules, goToPrizes }) => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isIOS = /iphone|ipod|ipad/.test(userAgent);
  // const isAndroid = /android/.test(userAgent);

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [toastMessage, setToastMessage] = useState(null);
  const [isInfoShown, setIsInfoShown] = useState(
    !localStorage.getItem("infoCardHidden")
  );

  const matches = useSelector(getMatches);
  const availableMarkets = useSelector(
    getMarkets,
    (oldValue, newValue) =>
      JSON.stringify(oldValue) === JSON.stringify(newValue)
  );
  const userPredictions = useSelector(
    getUserPredictions,
    (oldValue, newValue) =>
      JSON.stringify(oldValue) === JSON.stringify(newValue)
  );
  const summary = useSelector(getMatchesSummary);

  const dispatch = useDispatch();

  const calendarMatches = getCalendarMatches(matches) ?? {};

  const showSuccessToast = useToast(() => setToastMessage(null), toastMessage);
  const fansUnitedSDK = useFansUnitedSDK();

  useEffect(() => {
    if (matches.length > 0) return;

    if (!fansUnitedSDK) return;

    getData();
  }, [fansUnitedSDK]);

  useEffect(() => {
    if (isSameDay(selectedDate, new Date())) {
      setSelectedDate(getInitialDate(calendarMatches));
    }
  }, [matches]);

  const getData = () => {
    setIsLoading(true);
    dispatch(getMatchesInfoRequest(fansUnitedSDK))
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) =>
        setToastMessage({
          text: error.data?.error?.message || "Error!",
          type: "error",
        })
      );
  };

  const makeDebouncedPrediction = debounce((matchId, market, prediction) => {
    makePrediction(matchId, market, prediction);
  }, 1000);

  const makePrediction = (matchId, market, prediction, playerId = null) => {
    dispatch(
      createPredictionRequest(
        fansUnitedSDK,
        matchId,
        market,
        prediction,
        playerId
      )
    )
      .then(() => {
        setToastMessage({ text: t("Prediction saved"), type: "success" });
      })
      .catch((error) => {
        setToastMessage({
          text: error.data?.error?.message || "Error!",
          type: "error",
        });
        if (error.status === 401) {
          isIOS
            ? window.location.reload()
            : (window.location.href = window.location.href);
        }
      });
  };

  const handleMatchScorePrediction = (matchId, score, debounced) => {
    debounced
      ? makeDebouncedPrediction(
          matchId,
          MarketType.CORRECT_SCORE_ADVANCED,
          `${score.homeTeamScore}:${score.awayTeamScore}`
        )
      : makePrediction(
          matchId,
          MarketType.CORRECT_SCORE_ADVANCED,
          `${score.homeTeamScore}:${score.awayTeamScore}`
        );
  };

  const handleHalftimeScorePrediction = (matchId, prediction) => {
    makePrediction(matchId, MarketType.HT_1X2, prediction);
  };

  const handleFirstScorerPrediction = (matchId, goalscorer) => {
    makePrediction(
      matchId,
      MarketType.PLAYER_SCORE_FIRST_GOAL,
      true,
      goalscorer
    );
  };

  const handleCornersPrediction = (matchId, prediction) => {
    makePrediction(matchId, MarketType.OVER_CORNERS_8_5, prediction);
  };

  const share = () => {
    if (navigator?.share) {
      navigator
        .share({
          title: "Betway Scores",
          url: isIOS
            ? "https://apps.apple.com/app/id1552348455"
            : "https://play.google.com/store/apps/details?id=com.betway.scores.android&pli=1",
        })
        .then(() => console.log("Successfully shared"))
        .catch((error) => console.log("Error sharing:", error));
    } else if (!isIOS) {
      try {
        Android.shareData(
          "Betway Scores",
          "Download the app",
          "https://play.google.com/store/apps/details?id=com.betway.scores.android&pli=1"
        );
      } catch (error) {
        console.error("Error sharing on android:", error);
      }
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <CalendarHeader
        selectedDate={selectedDate}
        onDateSelect={(date) => setSelectedDate(date)}
        calendarMatches={calendarMatches}
      />
      <div className={styles.cardsContainerSafari}>
        {isInfoShown &&
          calendarMatches[selectedDate.setHours(0, 0, 0, 0)]?.matches?.length >
            0 && (
            <InfoCard
              onClose={() => {
                setIsInfoShown(false);
                localStorage.setItem("infoCardHidden", true);
              }}
              onButtonPress={() => goToRules()}
              onPrizesButtonPress={() => goToPrizes()}
            />
          )}
        {calendarMatches[selectedDate.setHours(0, 0, 0, 0)]?.matches.map(
          (match) => (
            <PredictionCard
              key={match.id}
              match={match}
              markets={availableMarkets}
              summary={summary?.filter(
                (summary) => summary.matchId === match.id
              )}
              userPredictions={userPredictions?.filter(
                (prediction) => prediction.matchId === match.id
              )}
              onScoreSet={handleMatchScorePrediction}
              onHalftimePrediction={handleHalftimeScorePrediction}
              onGoalscorerSelected={handleFirstScorerPrediction}
              onCornersPrediction={handleCornersPrediction}
              fansUnitedSDK={fansUnitedSDK}
            />
          )
        )}
        {calendarMatches[selectedDate.setHours(0, 0, 0, 0)]?.matches?.length >
          0 && (
          <button className={styles.shareButton} onClick={share}>
            {t("Share")}
          </button>
        )}
      </div>
      {showSuccessToast && <Toast message={toastMessage} />}
    </div>
  );
};

export default PredictionsFragment;
