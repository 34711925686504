import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import PaginationModel from "../../Global/Models/Pagination/PaginationModel";
import PredictionRequestModel from "../Predictor/Models/Predictions/PredictionRequestModel";
import PredictionResponseModel from "../Predictor/Models/Predictions/PredictionResponseModel";
import PredictionsFilters from "../Predictor/Models/Predictions/PredictionsFilters";
import { FeaturesConfigModels } from "../../Global/Types/GlobalTypes";
import GameModel from "../TopX/Models/Games/GameModel";
import GamesFilters from "../TopX/Models/Games/GamesFilters";
import MatchQuizFacade from "./Facade/MatchQuizFacade";
import GameMarketsResults from "./Models/GameMarketsResults/GameMarketsResults";
import MainFiltersBQ from "../../Global/Models/Filters/MainFiltersBQ";
import ContestWinners from "../TopX/Models/Games/Winners/ContestWinners";
import GameByIdModel from "../TopX/Models/Games/GameByIdModel";

export default class MatchQuiz {
    private matchQuizFacade: MatchQuizFacade = null;

    constructor(config: SDKConfigurationModel) {
        this.matchQuizFacade = new MatchQuizFacade(config);
    }

    public getConfig = async (): Promise<FeaturesConfigModels> => {
        return await this.matchQuizFacade.getConfig();
    };

    public play = async (matchQuizPrediction: PredictionRequestModel):  Promise<PredictionResponseModel> => {
        return await this.matchQuizFacade.play(matchQuizPrediction);
    };

    public delete = async (gameId: string): Promise<boolean> => {
        return await this.matchQuizFacade.delete(gameId);
    };

    public getGames = async (filters?: GamesFilters, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.matchQuizFacade.getGames(filters, disableCache);
    };

    public getGameById = async (gameId: string, disableCache?:boolean): Promise<GameByIdModel> => {
        return await this.matchQuizFacade.getGameById(gameId, disableCache);
    };

    public getGamePredictions = async (gameId: string, filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.matchQuizFacade.getGamePredictions(gameId, filters, disableCache);
    };

    public getGameResults = async (gameId: string, filters?: MainFiltersBQ, disableCache?:boolean): Promise<PaginationModel> => {
        return await this.matchQuizFacade.getGameResults(gameId, filters, disableCache);
    };

    public getCurrentGameResults = async (disableCache?:boolean): Promise<GameModel> => {
        return await this.matchQuizFacade.getCurrentGameResults(disableCache);
    };

    public getMyGameEditions = async (filters?: MainFiltersBQ, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.matchQuizFacade.getMyGameEditions(filters, disableCache);
    };

    public getMyGamePrediction = async (gameId: string): Promise<PredictionResponseModel> => {
        return await this.matchQuizFacade.getMyGamePrediction(gameId);
    };

    public getUserGameEditions = async (userId: string, filters?: MainFiltersBQ, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.matchQuizFacade.getUserGameEditions(userId, filters, disableCache);
    };

    public getUserGamePrediction = async (userId: string, gameId: string, disableCache?: boolean): Promise<PredictionResponseModel> => {
        return await this.matchQuizFacade.getUserGamePrediction(userId, gameId, disableCache);
    };

    public getMarketsResultsForGame = async (gameId: string, disableCache?: boolean): Promise<GameMarketsResults> => {
        return await this.matchQuizFacade.getMarketsResultsForGame(gameId, disableCache);
    };

    public getGameWinners = async (gameId: string, disableCache?: boolean): Promise<ContestWinners> => {
        return await this.matchQuizFacade.getGameWinners(gameId, disableCache);
    };
}