import MainFiltersBQ from "../../../../Global/Models/Filters/MainFiltersBQ";

export default class TopDiscussionsFilters extends MainFiltersBQ {
  public fromDate?: string;
  public toDate?: string;
  public entityIds?: string[];
  public entityType?: string;
  public contentType?: string;

  constructor(filters: any) {
    super(filters);
    this.fromDate = filters?.fromDate || null;
    this.toDate = filters?.toDate || null;
    this.entityIds = filters?.entityIds || null;
    this.entityType = filters?.entityType || null;
    this.contentType = filters?.contentType || null;
  }

  constructMainFiltersUrl?(): string {
    let url = super.constructMainFiltersUrl();

    if (this.fromDate !== null) {
      url += `&from_date=${this.fromDate}`;
    }

    if (this.toDate !== null) {
      url += `&to_date=${this.toDate}`;
    }

    if (this.entityIds !== null && this.entityIds.length > 0) {
      url += `&entity_ids=${this.entityIds}`;
    }

    if (this.contentType !== null) {
      url += `&content_type=${this.contentType}`;
    }

    return url;
  }
}
