import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import drawIcon from '../../../../assets/draw.png'
import checkmarkIcon from '../../../../assets/checkmark.png'

import styles from './HalftimeModal.module.css';

export const HalftimeModal = ({ homeTeam, awayTeam, onOptionSelected, teamPrediction }) => {
  const { t } = useTranslation();

  const [selectedTeam, setSelectedTeam] = useState(teamPrediction === '1' ? homeTeam : teamPrediction === '2' ? awayTeam : 3);

  const onSubmit = () => {
    const selectedOption = selectedTeam === homeTeam ? '1' : selectedTeam === awayTeam ? '2' : 'x';

    onOptionSelected(selectedOption);
  }

  const TeamLogo = ({ team }) => {
    const isSelected = team === selectedTeam || (!team && selectedTeam === 3);

    return (
      <div 
        className={styles.logoContainer} 
        onClick={() => {
          setSelectedTeam(team ?? 3)
        }}
      >
        <div className={[styles.logo, isSelected ? styles.selectedLogo : ''].join(' ')}> 
          <img className={styles.icon} src={team?.assets?.logo ?? drawIcon} alt={`${team?.id}-team-logo`}/>
          {isSelected && <img className={styles.checkmark} src={checkmarkIcon} alt='checkmark-icon'/>}
        </div>
        <div className={styles.name}>{t(team?.name)}</div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.teamsContainer}>
        <TeamLogo team={homeTeam}/>
        <TeamLogo/>
        <TeamLogo team={awayTeam}/>
      </div>
      <button 
        className={styles.submit}
        style={!selectedTeam ? {backgroundColor: '#CCC'} : null}
        disabled={!selectedTeam}
        onClick={onSubmit}
      >
        {t('Save')}
      </button>
    </div>
  )
}

export default HalftimeModal;