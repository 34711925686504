import MatchBasicModel from "../../../Football/Models/Match/MatchBasicModel";
import MatchStatus from "../../../Football/Models/Match/Status/MatchStatus";
import PlayerBasicModel from "../../../Football/Models/Player/PlayerBasicModel";
import { MarketEnum } from "../../../Predictor/Enums/MarketEnum";

export type MarketsResults = {
  matchId: string;
  market: MarketEnum;
  result: string | string[] | boolean;
  matchModel: MatchBasicModel;
  matchStatus: MatchStatus;
  playerModels?: PlayerBasicModel[];
};

export type TieBreakersType = {
  goldenGoals: GoldenGoalsType[];
};

export type GoldenGoalsType = {
  minute: number;
  matchId: string;
  matchModel: MatchBasicModel;
  playerId: string;
  playerModel: PlayerBasicModel;
  goalType: string;
};
export default class GameMarketsResults {
  public gameId: string = null;
  public results: MarketsResults[] = null;
  public tieBreakers: TieBreakersType = null;
}
