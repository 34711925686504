import MiniGamesFilters from "../Filters/MiniGamesFilters";

export default class ClassicQuizFilters extends MiniGamesFilters {
  classicQuizIds?: string[] = null;

  constructor(filters: any) {
    super(filters);
    this.classicQuizIds =
      filters && filters.classicQuizIds ? filters.classicQuizIds : null;
  }

  public constructMainFiltersUrl?() {
    let url = super.constructMainFiltersUrl();

    if (this.classicQuizIds && this.classicQuizIds.length > 0) {
      url += `&classic_quiz_ids=${this.classicQuizIds.join(",")}`;
    }

    return url;
  }
}
