import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import LeaguesHeader from './components/LeaguesHeader';
import OtherLeagues from './components/OtherLeagues';
import Modal from '../../common/Modal';
import LeaguesModal from './components/LeaguesModal';
import { useToast } from '../../hooks/useToast';
import { 
	getLeagues, 
	createLeagueRequest, 
	getLeaguesRequest, 
	joinLeagueRequest, 
	leaveLeagueRequest,
	updateLeagueRequest,
	deleteLeagueRequest,
	getStandings,
} from '../../redux/leagues';
import { getUser } from '../../redux/user';
import Toast from '../../common/Toast';
import MyLeagues from './components/MyLeagues';
import LeagueInfo from './components/LeagueInfo';
import Loader from '../../common/Loader';
import useFansUnitedSDK from '../../hooks/useFansUnitedSDK';

import styles from './LeaguesFragment.module.css';

const LeaguesFragment = () => {
	const { t } = useTranslation();

	const userAgent = window.navigator.userAgent.toLowerCase()
  const isIOS = /iphone|ipod|ipad/.test(userAgent);

	const [selectedLeague, setSelectedLeague] = useState(null);
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
	const [toastMessage, setToastMessage] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();

	const leagues = useSelector(getLeagues);
	const standings = useSelector(getStandings);
	const user = useSelector(getUser);

  const showSuccessToast = useToast(() => setToastMessage(null), toastMessage);
	const fansUnitedSDK = useFansUnitedSDK();

	useEffect(() => {
    if (!fansUnitedSDK) return;
      
    getData();
  }, [fansUnitedSDK])

	const getData = () => {
    setIsLoading(true);
    dispatch(getLeaguesRequest(fansUnitedSDK))
    .then(() => { setIsLoading(false) })
    .catch((error) => {
			setToastMessage({ text: error.data?.error?.message || 'Error!', type: 'error' })
			if (error.status === 401) { window.location.href = window.location.href }
		});
  }

	const reloadPage = () => {
		isIOS ? window.location.reload() : window.location.href = window.location.href
	}

	const handleLeagueSelect = (league) => {
		setSelectedLeague(league);
	}

	const handleBackButton = () => {
		setSelectedLeague(null);
	}

	const handleCreateLeague = (name) => {
		setIsCreateModalOpen(false);
		
		dispatch(createLeagueRequest(fansUnitedSDK, name, name))
		.then((response) => {
			setToastMessage({ text: response?.message || 'The league was created', type: 'success' })
		})
		.catch((error) => {
			setToastMessage({ text: error.data?.error?.message || 'Error!', type: 'error' })
			if (error.status === 401) { reloadPage() }
		});
	}

	const handleJoinLeague = (code) => {
		setIsJoinModalOpen(false);

		dispatch(joinLeagueRequest(fansUnitedSDK, code.toLowerCase()))
		.then((response) => {
			setToastMessage({ text: response?.message || 'Successfully joined', type: 'success' })
		})
		.catch((error) => { 
			setToastMessage({ text: error.data?.error?.message || 'Error!', type: 'error' })
			if (error.status === 401) { reloadPage() }
		});
	}

	const handleEditLeague = (league, bannedUsers) => {
		dispatch(updateLeagueRequest(fansUnitedSDK, league, bannedUsers))
		.then((response) => {
			setSelectedLeague(null);
			setToastMessage({ text: response?.message || 'League updated', type: 'success' })
		})
		.catch((error) => {
			setToastMessage({ text: error.data?.error?.message || 'Error!', type: 'error' })
			if (error.status === 401) { reloadPage() }
		});
	}

	const handleLeaveLeague = () => {
		dispatch(leaveLeagueRequest(fansUnitedSDK, selectedLeague.id))
		.then((response) => {
			setSelectedLeague(null);
			setToastMessage({ text: response?.message || 'Successfully left', type: 'success' })
		})
		.catch((error) => { 
			setToastMessage({ text: error.data?.error?.message || 'Error!', type: 'error' })
			if (error.status === 401) { reloadPage() }
		});
	}

	const handleDeleteLeague = () => {
		dispatch(deleteLeagueRequest(fansUnitedSDK, selectedLeague.id))
		.then((response) => {
			setSelectedLeague(null);
			setToastMessage({ text: response?.message || 'League deleted', type: 'success' })
		})
		.catch((error) => { setToastMessage({ text: error.data?.error?.message || 'Error!', type: 'error' })
		if (error.status === 401) { reloadPage() }
	});
	}

	const handleCopyLeagueCode = (code) => {
		navigator?.clipboard?.writeText(code)
		.then(() => {
			setToastMessage({ text: t('Invitation code copied'), type: 'success' })
		})
		.catch((error) => setToastMessage({ text: error.data?.error?.message || 'Error!', type: 'error' }));
	}

	const LeaguesList = () => {
		return (
			<div className={styles.container}>
				<LeaguesHeader 
					onCreateLeague={() => setIsCreateModalOpen(true)}
					onJoinLeague={() => setIsJoinModalOpen(true)}
				/>
				<MyLeagues 
					leagues={leagues.filter((league) => league?.administrators?.includes(user?.id))}
					onSelect={handleLeagueSelect}
				/>
				<OtherLeagues 
					leagues={leagues.filter((league) => !league?.administrators?.includes(user?.id))}
					standings={standings}
					onSelect={handleLeagueSelect}
				/>
				<Modal
					isOpen={isCreateModalOpen || isJoinModalOpen} 
					onClose={() => {
						setIsCreateModalOpen(false)
						setIsJoinModalOpen(false)
					}}
					title={isJoinModalOpen ? t('Join a league') : t('Create a league')}
				>
					<LeaguesModal 
						onSubmit={isJoinModalOpen ? handleJoinLeague : handleCreateLeague}
						isJoin={isJoinModalOpen}
					/>
				</Modal>
			</div>
		);
	}

  return (
		isLoading 
		? <Loader/> 
		: <>
				{selectedLeague 
					? <LeagueInfo 
							fansUnitedSDK={fansUnitedSDK}
							currentUser={user.id}
							leagueId={selectedLeague.id}
							onBack={handleBackButton}
							onEdit={handleEditLeague}
							onDelete={handleDeleteLeague}
							onCopy={handleCopyLeagueCode}
							onLeave={handleLeaveLeague}
						/> 
					: <LeaguesList/>
				}
				{showSuccessToast && <Toast message={toastMessage}/>}
			</>
	)
};

export default LeaguesFragment;