import NoYesModel from "./NoYesModel";

export default class PredictionsModel {
    public fullTimeOneXTwo: any = {};
    public halfTimeOneXTwo: any = {};
    public bothTeamsScore: NoYesModel = new NoYesModel();
    public doubleChance: any = {};
    public halfTimeFullTime: any = {};
    public playerScore: any = {};
    public playerYellowCard: any = {};
    public playerRedCard: any = {};
    public redCardMatch: NoYesModel = new NoYesModel();
    public penaltyMatch: NoYesModel = new NoYesModel();
    public playerScoreFirstGoal: any = {};
    public cornersMatch: any = {};
    public correctScore: any = {};
    public correctScoreHalfTime: any = {};
    public correctScoreAdvanced: any = {};
    public playerScoreHattrick: any = {};
    public playerScoreTwice: any = {};
    public overZeroPointFiveGoals: NoYesModel = new NoYesModel();
    public overOnePointFiveGoals: NoYesModel = new NoYesModel();
    public overTwoPointFiveGoals: NoYesModel = new NoYesModel();
    public overThreePointFiveGoals: NoYesModel = new NoYesModel();
    public overFourPointFiveGoals: NoYesModel = new NoYesModel();
    public overFivePointFiveGoals: NoYesModel = new NoYesModel();
    public overSixPointFiveGoals: NoYesModel = new NoYesModel();
    public overSixPointFiveCorners: NoYesModel = new NoYesModel();
    public overSevenPointFiveCorners: NoYesModel = new NoYesModel();
    public overEightPointFiveCorners: NoYesModel = new NoYesModel();
    public overNinePointFiveCorners: NoYesModel = new NoYesModel();
    public overTenPointFiveCorners: NoYesModel = new NoYesModel();
    public overElevenPointFiveCorners: NoYesModel = new NoYesModel();
    public overTwelvePointFiveCorners: NoYesModel = new NoYesModel();
    public overThirteenPointFiveCorners: NoYesModel = new NoYesModel();

}