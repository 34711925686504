import React from 'react';

import closeIcon from '../../assets/close.png';

import styles from './Modal.module.css';

const Modal = ({ isOpen, onClose, title, subtitle = null, points = null, children }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.background} onClick={onClose}>
      <div className={styles.container} onClick={e => e.stopPropagation()}>
        <img src={closeIcon} className={styles.closeButton} onClick={onClose} alt='close-icon'/>
        <div className={styles.title}>{title}</div>
        {subtitle && 
          <div className={styles.subtitle}>
            <span>{subtitle}</span>
            {points && <span className={styles.points}>{` +${points}pts`}</span>}
          </div>
        }
        {children}
      </div>
    </div>
  );
}

export default Modal;