import IValidator from "./IValidator";
import { ValidationResponseEnum } from "../Enums/ValidationResponseEnum";
import { ValidationMessageEnum } from "../Enums/ValidationMessageEnum";
import { LogEnum } from "../Enums/LogEnum";
import { IdSchemaEnum } from "../Enums/IdSchemaEnum";
import SDKConfigurationModel from "../Models/SDKConfiguraitonModel";
import MessageLogModel from "../Models/MessageLogModel";
import ValidationItemModel from "../Models/ValidationItemModel";

export default class IdSchemaValidator implements IValidator {
  validate(config: SDKConfigurationModel): ValidationItemModel {
    const validation: ValidationResponseEnum = this.validateField(
      config.idSchema
    );
    const message: MessageLogModel = this.generateMessage(validation);

    return { validation, message };
  }

  private validateField = (idSchema: string): ValidationResponseEnum => {
    const idSchemaValues = Object.values(IdSchemaEnum);
    let validation: ValidationResponseEnum = null;

    if (idSchema !== undefined && idSchema !== null && idSchema.length > 0) {
      if (idSchemaValues.includes(idSchema as IdSchemaEnum)) {
        validation = ValidationResponseEnum.VALID_VALUE;
      } else {
        validation = ValidationResponseEnum.INVALID_VALUE;
      }
    } else {
      validation = ValidationResponseEnum.MISSING_VALUE;
    }

    return validation;
  };

  private generateMessage = (
    validation: ValidationResponseEnum
  ): MessageLogModel => {
    let text: ValidationMessageEnum = null;
    let type: LogEnum = LogEnum.WARNING;

    if (validation === ValidationResponseEnum.MISSING_VALUE) {
      text = ValidationMessageEnum.ID_SCHEMA_MISSING;
    }
    if (validation === ValidationResponseEnum.INVALID_VALUE) {
      text = ValidationMessageEnum.ID_SCHEMA_INVALID;
    }
    if (validation === ValidationResponseEnum.VALID_VALUE) {
      text = null;
      type = null;
    }

    return { text: text, type: type };
  };
}
