import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import { ErrorHandlingModeType } from "../../../Configurator/Types/ConfiguratorTypes";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import { ErrorMessages } from "../../../Global/Messages/Messages";
import MiniGamesFilters from "../Models/Filters/MiniGamesFilters";

export default class MiniGamesValidator {
  private errorHandlingModel: ErrorHandlingModeType = null;
  readonly entityTypes = ["competition", "team", "player"];

  constructor(config: SDKConfigurationModel) {
    this.errorHandlingModel = config.errorHandlingMode;
  }

  public validateFilters = (filters: MiniGamesFilters) => {
    if (!filters.entityIds && !filters.entityType) {
      return;
    }

    if (filters.entityIds && !filters.entityType) {
      this.throwException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_FILTERS,
        ErrorMessages.FILTER_COMBINATION_ERROR
      );
    }

    if (!filters.entityIds && filters.entityType) {
      this.throwException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_FILTERS,
        ErrorMessages.FILTER_COMBINATION_ERROR
      );
    }

    if (!this.entityTypes.includes(filters.entityType)) {
      this.throwException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_FILTERS,
        ErrorMessages.INVALID_ENTITY_TYPE
      );
    }
  };

  private throwException = (code: number, status: string, message: string) => {
    if (this.errorHandlingModel === "standard") {
      throw new StandardFansUnitedException(code, status, message);
    }

    throw new FansUnitedSdkException(code, status, message);
  };
}
