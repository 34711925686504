import FootballCountryModel from "../Country/FootballCountryModel";

export default class TopPlayerModel {
    public id: string = '';
    public country: FootballCountryModel = null;
    public birthDate: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public name: string = '';
    public position: string = '';
    public assets: {} = null;
}
