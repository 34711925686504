import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import useFansUnitedSDK from "../../hooks/useFansUnitedSDK";
import { useToast } from "../../hooks/useToast";

import Loader from "../../common/Loader";
import Toast from "../../common/Toast";

import {
  getMyOverallRanking,
  getMyRankingRequest,
  getOverallRanking,
  getRankingRequest,
} from "../../redux/ranking";
import { getUser } from "../../redux/user";

import { TEMPLATE_ID } from "../../api/constants";
import avatarIcon from "../../assets/avatar.png";
import infoIcon from "../../assets/info.png";
import noMembersImage from "../../assets/no-members.png";
import headerIcon from "../../assets/ranking.png";
import styles from "./RankingFragment.module.css";

const RankingFragment = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const overallRankingList = useSelector(getOverallRanking);
  const myRankingList = useSelector(getMyOverallRanking);
  const user = useSelector(getUser);

  const userRanking =
    myRankingList.filter((template) => template.id === TEMPLATE_ID)[0]
      ?.position || "-";
  const userPoints =
    myRankingList.filter((template) => template.id === TEMPLATE_ID)[0]
      ?.points || "-";

  const fansUnitedSDK = useFansUnitedSDK();
  const showToast = useToast(() => setToastMessage(null), toastMessage);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!fansUnitedSDK) return;

    getData();
    getPersonalRankingData();
  }, [fansUnitedSDK]);

  const getData = () => {
    setIsLoading(true);
    dispatch(getRankingRequest(fansUnitedSDK))
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) =>
        setToastMessage({
          text: error.data?.error?.message || "Error!",
          type: "error",
        })
      );
  };

  const getPersonalRankingData = () => {
    setIsLoading(true);
    dispatch(getMyRankingRequest(fansUnitedSDK))
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) =>
        setToastMessage({
          text: error.data?.error?.message || "Error!",
          type: "error",
        })
      );
  };

  const RankingHeader = () => (
    <div className={styles.row}>
      <img
        className={styles.headerIcon}
        src={headerIcon}
        alt={"ranking-icon"}
      />
      <div className={styles.rowTitle}>
        <div className={styles.rowName}>{t("Ranking")}</div>
        <div className={styles.rowInfo}>{`${t("Top")} ${
          overallRankingList.length
        } ${t("members")}`}</div>
      </div>
    </div>
  );

  const InfoMessage = () => (
    <div className={styles.messageRow}>
      <img className={styles.messageIcon} src={infoIcon} alt={"info-icon"} />
      <div className={styles.messageText}>
        The points are being recalculated.
      </div>
    </div>
  );

  const UserRanking = () => (
    <div className={styles.userRanking}>
      <img className={styles.avatarIcon} src={avatarIcon} alt={"user-icon"} />
      <div className={styles.playerName}>{user.name ?? user.nickname}</div>
      <div className={styles.playerPoints}>{userPoints}</div>
      <div className={styles.playerRank}>{userRanking}</div>
    </div>
  );

  const RankingList = () => (
    <div className={styles.list}>
      <div className={styles.listHeader}>
        <div>{t("Members")}</div>
        <div className={styles.playersPointsHeader}>{t("Points")}</div>
        <div className={styles.playerRank}>{t("Rank")}</div>
      </div>
      <div className={styles.separator} />
      {overallRankingList &&
        [...overallRankingList]
          .sort((a, b) => a.position - b.position)
          .map((player) => (
            <div className={styles.playerRow} key={player.profileId}>
              <img
                className={styles.avatarIcon}
                src={avatarIcon}
                alt={"user-icon"}
              />
              <div className={styles.playerName}>
                {player.profileModel?.name}
              </div>
              <div className={styles.playerPoints}>{player.points}</div>
              <div className={styles.playerRank}>{player.position}</div>
            </div>
          ))}
    </div>
  );

  const EmptyListCard = () => (
    <div className={styles.emptyListContainer}>
      <img
        className={styles.emptyListImage}
        src={noMembersImage}
        alt={"empty-list-img"}
      />
      <div className={styles.emptyListTitle}>{t("No ranking yet.")}</div>
      <div className={styles.emptyListDescription}>
        {t(
          "Once the European Championship starts, all users that played the Predictor Game will rank in here."
        )}
      </div>
    </div>
  );

  const Select = () => (
    <div className={styles.emptyListContainer} style={{ padding: "15px" }}>
      <p className={styles.selectLabel}>Choose ranking:</p>
      <select className={styles.selectElement}>
        <option>Overall</option>
        <option>Group stage</option>
        <option>Knock-out stage</option>
      </select>
    </div>
  );

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <RankingHeader />
      {/* <Select /> */}
      {overallRankingList.length > 1 ? (
        <>
          {/* <InfoMessage/> */}
          <UserRanking />
          <RankingList />
        </>
      ) : (
        <EmptyListCard />
      )}
      {showToast && <Toast message={toastMessage} />}
    </div>
  );
};

export default RankingFragment;
