import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import IdMappingService from "../../../IdMapping/IdMappingService";
import ActivityService from "../../Activity/Service/ActivityService";
import MiniGamesValidator from "../../MiniGames/Validator/MiniGamesValidator";
import PollsFilters from "../Models/Polls/Filters/PollsFilters";
import PollBasicModel from "../Models/Polls/PollBasicModel";

export default class VotingService {
  readonly idMappingService: IdMappingService = null;
  readonly idSchema: string = null;
  readonly errorHandlingMode: string = null;
  readonly validator: MiniGamesValidator = null;
  readonly activityService: ActivityService = null;

  constructor(config: SDKConfigurationModel) {
    this.idSchema = config.idSchema;
    this.errorHandlingMode = config.errorHandlingMode;
    this.idMappingService = new IdMappingService(config);
    this.validator = new MiniGamesValidator(config);
    this.activityService = new ActivityService(config);
  }

  public finalizePollsFilters = async (filters: PollsFilters) => {
    if (
      this.idSchema !== IdSchemaEnum.NATIVE &&
      filters.entityIds &&
      filters.entityIds.length > 0
    ) {
      this.validator.validateFilters(filters);
      const idsObj = await this.idMappingService.getEntityIdsBySchemaId(
        Array.from(filters.entityIds),
        filters.entityType,
        IdSchemaEnum.NATIVE
      );
      filters.entityIds = idsObj[filters.entityType];
    }
  };

  public remapTags = async (object: PollBasicModel, fromSchema, toSchema) => {
    if (
      this.idSchema != IdSchemaEnum.NATIVE &&
      object.context &&
      object.context.tags &&
      object.context.tags.length
    ) {
      const copyTags = JSON.parse(JSON.stringify(object.context.tags));
      const remapIdsTags = await this.activityService.remapTagsIds(
        copyTags,
        fromSchema,
        toSchema
      );
      //@ts-ignore
      object.context.tags = remapIdsTags;
    }
  };
}
