import React, { useState, useEffect } from 'react';

import styles from './CommonPredictions.module.css';
import { MatchStatus } from '../../../../Utils';

const CommonPredictions = ({ match, scorePredictions, userPrediction, onScoreSelected }) => {
  const [topPredictions, setTopPredictions] = useState([])

  const canAddPrediction = match.status.type !== MatchStatus.FINISHED && match.status.type !== MatchStatus.IN_PROGRESS;
  
  useEffect(() => {
    const predictionsCount = Object.values(scorePredictions).reduce((a, b) => a + b, 0);
    const topThreePredictions = Object.keys(scorePredictions)
      .map((key) => { 
        return { 
          homeTeamScore: Number(key.split(':')[0]), 
          awayTeamScore: Number(key.split(':')[1]), 
          percentage: Math.round((scorePredictions[key]/predictionsCount) * 100),
          isOwn: key === userPrediction?.value,
        } 
      })
      .sort((a, b) => b.percentage - a.percentage)
      .slice(0, 3);

    setTopPredictions(topThreePredictions)
  }, [scorePredictions, userPrediction])
  
  return (
    <div className={styles.container}>
      {topPredictions.map((prediction, index) => 
        <button 
          key={index}
          className={[styles.scoreButton, prediction.isOwn ? styles.selectedButton : ''].join(' ')}
          onClick={() => onScoreSelected(topPredictions[index])}
          disabled={!canAddPrediction}
        >
          <div className={[styles.score, prediction.isOwn ? styles.selectedText : ''].join(' ')}>
            {`${prediction.homeTeamScore}-${prediction.awayTeamScore}`}
          </div>
          <div className={[styles.percentage, prediction.isOwn ? styles.selectedText : ''].join(' ')}>
            {`${prediction.percentage}%`}
          </div>
        </button>
      )}
    </div>
  )
}

export default CommonPredictions;