import MainFiltersBQ from "../../../../Global/Models/Filters/MainFiltersBQ";

export default class PrivateLeaguePostsFilters extends MainFiltersBQ {
  public reportsCount?: number = null;

  constructor(filters: any) {
    super(filters);

    this.reportsCount = filters.reportsCount ? filters.reportsCount : null;
  }

  public constructMainFiltersUrl? = () => {
    let url = super.constructMainFiltersUrl();

    if (this.reportsCount) {
      url += `&reports_count=${this.reportsCount}`;
    }

    return url;
  };
}
