import ValidationItemModel from "./ValidationItemModel";

export default class ConfigValidationModel {
    apiKey: ValidationItemModel = null;
    environment: ValidationItemModel = null;
    idSchema: ValidationItemModel = null;
    clientId: ValidationItemModel = null;
    lang: ValidationItemModel = null;
    errorHandlingMode: ValidationItemModel = null;
    authProvider: any = {
        getIdToken: new ValidationItemModel(),
        logout: new ValidationItemModel()
    };
    isValid: boolean = false;
}