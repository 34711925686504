export const typeConvert = (type: string) => {
    switch (type) {
        case 'competitions':
            return 'competition';
        case 'players':
        case 'relatedPlayer':
        case 'squad':
            return 'player';
        case 'teams':
        case 'participants':
        case 'homeTeam':
        case 'awayTeam':
        case 'timeline':
        case 'teamId':
            return 'team'
        default:
            return type;
    }
};
