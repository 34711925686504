interface IPostsPagination {
  totalItems: number;
  nextPageStartsAfter: string;
  lastCommentCreateTimestamp: string;
}
export default class PostsMetaModel {
  public pagination: IPostsPagination = {
    totalItems: 0,
    nextPageStartsAfter: "",
    lastCommentCreateTimestamp: "",
  };
}
