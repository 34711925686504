import {IRemapper} from "../../../../../Global/IRemapper";
import PlayerShortModel from "../PlayerShortModel";

export default class PlayerShortRemapper implements IRemapper<PlayerShortModel> {

    fromResponse(response: any): PlayerShortModel {
        try {
            const model = new PlayerShortModel();
            model.id = response.id;
            model.name = response.name;
            model.position = response.position;
            model.shirtNumber = response.shirt_number;
            model.assets = response.assets;
            model.type = response.type;

            return model;
        } catch (e) {
            console.warn("There was a problem remapping the player from timeline", e);
            throw e;
        }
    };

}