import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import PredictionRequestModel from "../Namespaces/Predictor/Models/Predictions/PredictionRequestModel";
import PredictionRemapper from "../Namespaces/Predictor/Models/Predictions/PredictionRemapper";
import PredictionResponseModel from "../Namespaces/Predictor/Models/Predictions/PredictionResponseModel";
import MatchSummaryRemapper from "../Namespaces/Predictor/Models/Summary/MatchSummaryRemapper";
import MatchSummaryModel from "../Namespaces/Predictor/Models/Summary/MatchSummaryModel";
import { initDisableCache, isDisabledCache, setBaseUrl } from "../Global/Helper";
import PredictionsFilters from "../Namespaces/Predictor/Models/Predictions/PredictionsFilters";
import GamesFilters from "../Namespaces/TopX/Models/Games/GamesFilters";
import GamesRemapper from "../Namespaces/TopX/Models/Games/GamesRemapper";
import IdMappingService from "../IdMapping/IdMappingService";
import TopXPredictionRequestModel from "../Namespaces/TopX/Models/Prediction/TopXPredictionRequestModel";
import PaginationModel from "../Global/Models/Pagination/PaginationModel";
import MetaRemapper from "../Global/Models/Pagination/Remapper/MetaRemapper";
import MainFiltersBQ from "../Global/Models/Filters/MainFiltersBQ";
import ContestWinners from "../Namespaces/TopX/Models/Games/Winners/ContestWinners";
import GameByIdModel from "../Namespaces/TopX/Models/Games/GameByIdModel";
import Https from "./Https";

export default class PredictorHttps extends Https {
    private predictionRemapper: PredictionRemapper = null;
    private matchSummaryRemapper: MatchSummaryRemapper = null;
    private gamesRemapper: GamesRemapper = null;
    private metaRemapper: MetaRemapper = null;

    constructor(config: SDKConfiguraitonModel, idMapping: IdMappingService) {
        super(config, setBaseUrl(config.environment, "predictor"));
        this.predictionRemapper = new PredictionRemapper(config, idMapping);
        this.matchSummaryRemapper = new MatchSummaryRemapper(config, idMapping);
        this.gamesRemapper = new GamesRemapper();
        this.metaRemapper = new MetaRemapper();
    }

    public makeFootballPrediction = async (prediction: PredictionRequestModel | TopXPredictionRequestModel): Promise<PredictionResponseModel> => {
        const url = `/predictions${this.apiSignInUrl}`;
        const warnMessage = "There was a problem making POST request";
        const method = "POST";
        const data = await this.fetchWithAuth(url, warnMessage, method, this.predictionRemapper.remapRequestBody(prediction));

        return await this.predictionRemapper.remapResponseObjBody(data);
    };

    public getMatchSummary = async (matchId: string, disableCache: boolean): Promise<MatchSummaryModel> => {
        const url = isDisabledCache(disableCache) ?
            `/predictions/summary/${matchId}${this.apiSignInUrl}${initDisableCache()}` : `/predictions/summary/${matchId}${this.apiSignInUrl}`;
        const warnMessage = `There was a problem fetching predictions for match: ${matchId}`;
        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.matchSummaryRemapper.remapResponse(data);
    };

    public deleteFootballPrediction = async (predictionId: string): Promise<boolean> => {
        const url = `/predictions/${predictionId}${this.apiSignInUrl}`;
        const warnMessage = `There was a problem deleting your prediction: ${predictionId}`;
        const method = "DELETE";
        await this.fetchWithAuth(url, warnMessage, method);

        return true;
    };

    public getMyPredictions = async (filters?: PredictionsFilters): Promise<PaginationModel> => {
        let myPredictionsPaginationModel = new PaginationModel();
        let url = `/predictions${this.apiSignInUrl}`;
        const warnMessage = "There was a problem fetching own predictions";

        if (filters) {
            url += `${filters.constructFiltersUrl()}`;
        }

        const data = await this.fetchWithAuth(url, warnMessage);
        myPredictionsPaginationModel.meta = this.metaRemapper.remapMetaData(data.meta);
        myPredictionsPaginationModel.data = await this.predictionRemapper.remapResponseArrayBody(data.data);

        return myPredictionsPaginationModel;
    };

    public getMyPredictionsNoRemap = async (filters?: PredictionsFilters): Promise<any> => {
        const url = `/predictions${this.apiSignInUrl}${filters.constructFiltersUrl()}`
        const warnMessage = "There was a problem fetching own predictions";

        return await this.fetchWithAuth(url, warnMessage);
    };

    public getUserPredictions = async (userId: string, disableCache: boolean, filters?: PredictionsFilters): Promise<PaginationModel> => {
        let userPredictionsPaginationModel = new PaginationModel();
        let url = isDisabledCache(disableCache) ?
            `/predictions/users/${userId}${this.apiSignInUrl}${initDisableCache()}` :
            `/predictions/users/${userId}${this.apiSignInUrl}`;
        const warnMessage = `There was a problem fetching predictions for user: ${userId}`;

        if (filters) {
            url += `${filters.constructFiltersUrl()}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);
        userPredictionsPaginationModel.meta = this.metaRemapper.remapMetaData(data.meta);
        userPredictionsPaginationModel.data = await this.predictionRemapper.remapResponseArrayBody(data.data);

        return userPredictionsPaginationModel;
    };

    public getUserPredictionsNoRemap = async (userId: string, filters?: PredictionsFilters): Promise<any> => {
        const url = `/predictions/users/${userId}${this.apiSignInUrl}${filters.constructFiltersUrl()}`;
        const warnMessage = `There was a problem fetching predictions for user: ${userId}`;

        return await this.fetchWithoutAuth(url, warnMessage);
    };

    public getGames = async (filters: GamesFilters, disableCache?: boolean): Promise<PaginationModel> => {
        const url = isDisabledCache(disableCache) ?
            `/games/${this.apiSignInUrl}${filters.constructFiltersUrl()}${initDisableCache()}` : `/games/${this.apiSignInUrl}${filters.constructFiltersUrl()}`;
        const warnMessage = "There was a problem fetching the games list";
        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.gamesRemapper.remapPaginatedGames(data);
    };

    public getGameById = async (gameId: string, disableCache: boolean): Promise<GameByIdModel> => {
        const url = isDisabledCache(disableCache) ? `/games/${gameId}${this.apiSignInUrl}${initDisableCache()}` : `/games/${gameId}${this.apiSignInUrl}`;
        const warnMessage = `There was a problem fetching the following game: ${gameId}`;
        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.gamesRemapper.remapGameByIdResponse(data);
    };

    public getGamePredictions = async (gameId: string, disableCache: boolean, filters?: PredictionsFilters): Promise<PaginationModel> => {
        let gamePredictionsPaginationModel = new PaginationModel();
        let url = isDisabledCache(disableCache) ?
            `/games/${gameId}/predictions${this.apiSignInUrl}${initDisableCache()}` : `/games/${gameId}/predictions${this.apiSignInUrl}`;
        const warnMessage = `There was a problem fetching the predictions for game: ${gameId}`;

        if (filters) {
            url += `${filters.constructFiltersUrl()}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);
        gamePredictionsPaginationModel.meta = this.metaRemapper.remapMetaData(data.meta);
        gamePredictionsPaginationModel.data = await this.predictionRemapper.remapResponseArrayBody(data.data);

        return gamePredictionsPaginationModel;
    }

    public getUserGameEditions = async (filters: GamesFilters, disableCache: boolean): Promise<PaginationModel> => {
        let userGameEditionsPagination: PaginationModel = null;
        const url = isDisabledCache(disableCache) ?
            `/games/${this.apiSignInUrl}${filters.constructFiltersUrl()}${initDisableCache()}` : `/games/${this.apiSignInUrl}${filters.constructFiltersUrl()}`;
        const warnMessage = "There was a problem fetching the games list";
        const data = await this.fetchWithoutAuth(url, warnMessage);
        data.data.forEach((resp: any) => {
            if (resp.type === "TOP_X") {
                userGameEditionsPagination = this.gamesRemapper.remapPaginatedUserTopXEditions(data);
            } else if (resp.type === "MATCH_QUIZ") {
                userGameEditionsPagination = this.gamesRemapper.remapPaginatedUserMathQuizEditions(data);
            }
        });

        return userGameEditionsPagination;
    };

    public getGameResults = async (gameId: string, disableCache: boolean, filters?: MainFiltersBQ): Promise<PaginationModel> => {
        let url = isDisabledCache(disableCache) ?
            `/games/${gameId}/results/${this.apiSignInUrl}${initDisableCache()}` : `/games/${gameId}/results/${this.apiSignInUrl}`;
        const warnMessage = `There was a problem fetching the results for the following game: ${gameId}`;

        if (filters) {
            url += `${filters.constructMainFiltersUrl()}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.gamesRemapper.remapPaginatedGameResults(data)
    };

    public getMarketResultsForGame = async (gameId: string, disableCache: boolean) => {
        let url = `/games/${gameId}/correct-results/${this.apiSignInUrl}`;
        const warnMessage = `There was a problem fetching the markets correct results for the following game: ${gameId}`;

        if (isDisabledCache(disableCache)) {
            url += `${initDisableCache()}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.gamesRemapper.remapGameMarketsResults(data.data);
    };

    public getContestWinners = async (gameId: string, disableCache?: boolean): Promise<ContestWinners> => {
        let url = `/winners/${this.apiSignInUrl}&contest_id=${gameId}`;
        const warnMessage = `There was a problem fetching winners for the following game: ${gameId}`;

        if (disableCache) {
            url += initDisableCache();
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.gamesRemapper.remapContestWinners(data);
    };

    public getPredictionById = async (predictionId: string): Promise<PredictionResponseModel> => {
        let url = `/predictions/${predictionId}${this.apiSignInUrl}`;
        const warnMessage = `There was a problem fetching info for the following prediction: ${predictionId}`;
        const data = await this.fetchWithoutAuth(url, warnMessage);
        const remappedPrediction = await this.predictionRemapper.remapResponseArrayBody([data.data]);

        return remappedPrediction[0];
    };

    public getPredictionsByIds = async (predictionIds: string[]): Promise<PredictionResponseModel[]> => {
        let url = `/predictions/by-ids${this.apiSignInUrl}&prediction_ids=${predictionIds.join(",")}`;
        const warnMessage = "There was a problem fetching predictions";
        const data = await this.fetchWithoutAuth(url, warnMessage);

        return await this.predictionRemapper.remapResponseArrayBody(data.data);
    };
}