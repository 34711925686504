import React from 'react';

import { useTranslation } from 'react-i18next';

import chevronRight from '../../../../assets/chevron-right.png';
import cup from '../../../../assets/league.png';

import styles from './MyLeagues.module.css';

const MyLeagues = ({ leagues, onSelect }) => {
  const { t } = useTranslation();

  return (
    <>
      {leagues?.length > 0 &&
        <div className={styles.container}>
          <div className={styles.header}>{t('My leagues')}</div>
          <div className={styles.list}>
            {leagues?.map((league) => {
              const members = league.membersCount || league.members?.length;

              return (
                <div className={styles.row} key={league.id} onClick={() => onSelect(league)}>
                  <img className={styles.rowIcon} src={cup} alt={'cup-icon'}/>
                  <div className={styles.rowTitle}>
                    <div className={styles.rowName}>{league.name}</div>
                    <div className={styles.rowInfo}>{`${members} ${members > 1 ? t('members') : t('member')}`}</div>
                  </div>
                  <img className={styles.rowChevron} src={chevronRight} alt={'chevron-right-icon'}/>
                </div>
              )
            })}
          </div>
        </div>
      }
    </>
  )
}

export default MyLeagues;