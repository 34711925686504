import BrandingModel from "../../../../Global/Models/Branding/BrandingModel";
import ImagesModel from "../../../../Global/Models/Images/ImagesModel";
import ContextModel from "../../../Activity/Models/RequestBody/Context/ContextModel";
import PollOptionModel from "./PollOptionModel";

export default class PollBasicModel {
  public id: string = null;
  public title: string = null;
  public description: string = null;
  public type: string = null;
  public images: ImagesModel = new ImagesModel();
  public status: string = null;
  public totalVotes: number = null;
  public flags: string[] = [];
  public customFields: Record<string, string> = null;
  public branding: BrandingModel = null;
  public embedCode: string = null;
  public labels: Record<string, string> = null;
  public adContent: string = null;
  public context: ContextModel = null;
  public options: PollOptionModel[] = [];
  public createdAt: string = null;
  public updatedAt: string = null;
}
