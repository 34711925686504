import { EntityTypeEnum } from "../../../Global/Enums/EntityTypeEnum";
import { MarketEnum } from "../../Predictor/Enums/MarketEnum";
import AchievementsStats from "../../Profile/Models/Stats/AchievementsStats";
import DiscussionStats from "../../Profile/Models/Stats/DiscussionStats";
import PredictionsStats from "../../Profile/Models/Stats/PredictionsStats";
import ProfileStatsModel from "../../Profile/Models/Stats/ProfileStatsModel";
import SinglePredictionsBreakdown from "../../Profile/Models/Stats/SinglePredictionsBreakdown";
import SinglePredictionsStats from "../../Profile/Models/Stats/SinglePredictionsStats";
import SuccessRatePercent from "../../Profile/Models/Stats/SuccessRatePercent";
import SummarizedBreakdown from "../../Profile/Models/Stats/SummarizedBreakdown";
import ActivityResponseBody from "../Models/ActivityResponseBody";
import PaginationActivities from "../Models/PaginationActivities";

export default class ActivityRemapper {
  public remapSingleResponse = (response: any) => {
    const model = new ActivityResponseBody();

    model.id = response.id;
    model.profileId = response.profile_id;
    model.property = response.property;
    model.value = response.value;
    model.action = response.action;
    model.context.content = response.context.content;
    model.context.tags = response.context.tags;
    model.context.campaign = response.context.campaign;
    model.points = response.points;

    return model;
  };

  public remapActivitiesResponse = (response: any) => {
    const model = new PaginationActivities();

    model.meta.pagination.currentPage = response.meta.pagination.current_page;
    model.meta.pagination.itemsPerPage =
      response.meta.pagination.items_per_page;
    model.meta.pagination.numberOfPages =
      response.meta.pagination.number_of_pages;
    model.meta.pagination.totalItems = response.meta.pagination.total_items;
    model.data = response.data.map((data: any) =>
      this.remapSingleResponse(data)
    );

    return model;
  };

  public remapStatsResponse = (response: any) => {
    const model = new ProfileStatsModel();

    model.profileId = response.profile_id;
    model.tier = response.tier;
    model.points = response.points;
    model.predictionsMade = response.predictions_made;
    model.successRates.overallPercent = response.success_rates.overall_percent;
    model.successRates.byFootballCompetition = this.constructNewRecord(
      response.success_rates.by_competition
    );
    model.successRates.byFootballTeam = this.constructNewRecord(
      response.success_rates.by_team
    );
    model.successRates.byFootballMarket = this.constructNewRecord(
      response.success_rates.by_market
    );
    model.predictions = response.predictions ? this.remapPredictionsStats(response.predictions) : null;
    model.tiers = response.tiers ? this.remapAchievementsStats(response.tiers) : null;
    model.badges = response.badges ? this.remapAchievementsStats(response.badges) : null;
    model.discussions = response.discussions ? this.remapDiscussionStats(response.discussions) : null;

    return model;
  };

  private constructNewRecord = (
    entity: any
  ): Record<string, SuccessRatePercent> => {
    /*
      Record<Keys, Type>:
        Constructs an object type whose property keys are string and whose property values are SuccessRatePercent.
        This utility can be used to map the properties of a type to another type.
      */
    let newRecord: Record<string, SuccessRatePercent>;

    if (Object.entries(entity).length) {
      Object.entries(entity).forEach(([key, value]) => {
        newRecord = {
          ...newRecord,
          [key]: this.remapSuccessRatePercent(value),
        };

        //@ts-ignore
        if (MarketEnum[key]) {
          delete newRecord[key].model;
        }
      });
    } else {
      return entity;
    }

    return newRecord;
  };

  private remapSuccessRatePercent = (response: any) => {
    const newSuccessRatePercent = new SuccessRatePercent();

    newSuccessRatePercent.successRatePercent = response.success_rate_percent;

    return newSuccessRatePercent;
  };

  private remapPredictionsStats = (predictionsStats: any) => {
    const model = new PredictionsStats();

    model.single = this.remapSinglePredictionsStats(predictionsStats.single);
    model.topX = predictionsStats.top_x;
    model.matchQuiz = predictionsStats.match_quiz;

    return model;
  };

  private remapSinglePredictionsStats = (singlePredictionsStats: any) => {
    const model = new SinglePredictionsStats();

    model.predictionsMade = singlePredictionsStats.predictions_made;
    model.correct = singlePredictionsStats.correct;
    model.points = singlePredictionsStats.points;
    model.breakdown = this.remapBreakdownSinglePredictionsStats(
      singlePredictionsStats.breakdown
    );

    return model;
  };

  private remapBreakdownSinglePredictionsStats = (breakdown: any) => {
    if (breakdown && Object.keys(breakdown).length) {
      const summarizedBreakdown = new SummarizedBreakdown();
      const breakdownCompetitions: SinglePredictionsBreakdown[] = [];
      const breakdownTeams: SinglePredictionsBreakdown[] = [];

      Object.keys(breakdown).forEach((key) => {
        const model = new SinglePredictionsBreakdown();

        model.entityId = key;
        model.entityType = breakdown[key].entity_type;
        model.entitySource = breakdown[key].entity_source;
        model.predictionsMade = breakdown[key].predictions_made;
        model.correct = breakdown[key].correct;
        model.points = breakdown[key].points;

        if (model.entityType === EntityTypeEnum.COMPETITION) {
          breakdownCompetitions.push(model);
        } else if (model.entityType === EntityTypeEnum.TEAM) {
          breakdownTeams.push(model);
        }
      });

      summarizedBreakdown.footballCompetitions = breakdownCompetitions;
      summarizedBreakdown.footballTeams = breakdownTeams;

      return summarizedBreakdown;
    }

    return new SummarizedBreakdown();
  };

  private remapAchievementsStats = (achiemeventsStats: any[]) => {
    return achiemeventsStats.map((stats: any) => {
      const model = new AchievementsStats();

      model.id = stats.id;
      model.achievedAt = stats.achieved_at;

      return model;
    });
  };

  private remapDiscussionStats = (discussionStats: any) => {
    const model = new DiscussionStats();

    model.totalPoints = discussionStats.total_points;
    model.postPoints = discussionStats.post_points;
    model.postsMade = discussionStats.posts_made;
    model.reactionPoints = discussionStats.reaction_points;
    model.reactionCount = discussionStats.reaction_count;

    return model;
  };
}
