export interface StandardFansUnitedExceptionInterface {
  code: number;
  status: string;
  message: string;
}

export default class StandardFansUnitedException {
  error: StandardFansUnitedExceptionInterface = {
    code: 0,
    status: "",
    message: "",
  };

  constructor(code: number, status: string, message: string) {
    this.error.code = code;
    this.error.status = status;
    this.error.message = message;
  }
}
