import MetaProperties from "../../../../Global/Models/Pagination/MetaProperties";
import BaseRemapper from "../../../../Global/Remapper/BaseRemapper";
import ContextModel from "../../../Activity/Models/RequestBody/Context/ContextModel";
import TagsModel from "../../../Activity/Models/RequestBody/Context/TagsModel";
import PollBasicModel from "../Polls/PollBasicModel";
import PollFullModel from "../Polls/PollFullModel";
import PollVoteBasicModel from "../Polls/PollVoteBasicModel";
import PollVoteFullModel from "../Polls/PollVoteFullModel";
import PollsModel from "../Polls/PollsModel";
import PollsUserVotesModel from "../Polls/PollsUserVotesModel";

export default class PollRemapper extends BaseRemapper {
  public remapPolls = (data: any) => {
    const model = new PollsModel();
    model.meta.pagination = this.remapMetaPagination(data.meta.pagination);
    model.data = data.data.map((value) => this.remapPoll(value));

    return model;
  };

  public remapPollById = (data: any) => {
    const model = new PollFullModel();

    model.id = data.id;
    model.title = data.title;
    model.description = data.description;
    model.type = data.type;
    model.images = data.images;
    model.status = data.status;
    model.flags = data.flags;
    model.totalVotes = data.total_votes;
    model.customFields = data.custom_fields;
    model.branding = data.branding ? this.remapBranding(data.branding) : null;
    model.embedCode = data.embed_code;
    model.labels = data.labels;
    model.rules = data.rules;
    model.adContent = data.ad_content;
    model.options = data.options;
    model.context = data.context
      ? this.remapContext(data.context)
      : data.context;
    model.createdAt = data.created_at;
    model.updatedAt = data.updated_at;

    return model;
  };

  public remapUserVote = (data: any) => {
    const model = new PollVoteBasicModel();

    model.id = data.id;
    model.pollId = data.poll_id;
    model.optionId = data.option_id;
    model.userId = data.user_id;

    return model;
  };

  public remapPollsVotes = (data: any) => {
    const model = new PollsUserVotesModel();

    model.meta.pagination = this.remapMetaPagination(data.meta.pagination);
    model.data = data.data.map((value) => this.remapPollVote(value));

    return model;
  };

  public remapPollVote = (data: any) => {
    const model = new PollVoteFullModel();

    model.id = data.id;
    model.pollId = data.poll_id;
    model.optionId = data.option_id;
    model.userId = data.user_id;

    return model;
  };

  private remapMetaPagination = (pagination: any) => {
    const model = new MetaProperties();

    model.itemsPerPage = pagination.items_per_page;
    model.nextPageStartsAfter = pagination.next_page_starts_after;

    return model;
  };

  private remapPoll = (data: any) => {
    const model = new PollBasicModel();

    model.id = data.id;
    model.title = data.title;
    model.description = data.description;
    model.type = data.type;
    model.images = data.images;
    model.status = data.status;
    model.flags = data.flags;
    model.totalVotes = data.total_votes;
    model.customFields = data.custom_fields;
    model.branding = data.branding ? this.remapBranding(data.branding) : null;
    model.embedCode = data.embed_code;
    model.labels = data.labels;
    model.adContent = data.ad_content;
    model.options = data.options;
    model.context = data.context
      ? this.remapContext(data.context)
      : data.context;
    model.createdAt = data.created_at;
    model.updatedAt = data.updated_at;

    return model;
  };

  private remapContext = (data: any) => {
    const model = new ContextModel();

    model.content = data.content;
    model.tags = data.tags
      ? data.tags.map((value) => {
          const newTag = new TagsModel();

          newTag.id = value.id;
          newTag.type = value.type;
          newTag.source = value.source;

          return newTag;
        })
      : null;
    model.campaign = data.campaign;

    return model;
  };
}
