import ProfileModel from "../ProfileModel";
import ProfileCountryModel from "../ProfileCountryModel";

export default class ProfileRemapper {

    public profilesFromResponse = (response: any): ProfileModel[] => {
        if (response) {
            return response.map((profResp: any) => this.fullProfileFromResponse(profResp));
        } else {
            return null;
        }
    };

    public fullProfileFromResponse = (response: any): ProfileModel => {
        const model: ProfileModel = new ProfileModel();

        model.id = response.id;
        model.name = response.name;
        model.nickname = response.nickname;
        model.avatar = response.avatar;
        model.gender = response.gender;
        model.country = response.country as ProfileCountryModel;
        model.birthDate = response.birth_date;
        model.verified = response.verified;
        model.staffMember = response.staff_member;
        model.interests = response.interests ? response.interests : [];
        model.followingCount = response.following_count;
        model.followersCount = response.followers_count;

        return model;
    };

}
