import BrandingModel from "../../../../Global/Models/Branding/BrandingModel";
import ImagesModel from "../../../../Global/Models/Images/ImagesModel";
import MiniGamesContext from "../MiniGamesContext";
import EitherOrPointsModel from "./EitherOrPointsModel";

export default class EitherOrBasicModel {
  public id: string = "";
  public title: string = "";
  public description: string = "";
  public images: ImagesModel = new ImagesModel();
  public flags: string[] = [];
  public status: string = "";
  public winningCondition: string = "";
  public lives: number = 0;
  public time: number = 0;
  public points: EitherOrPointsModel[] = [];
  public customFields: Record<string, string> = null;
  public branding: BrandingModel = null;
  public labels: Record<string, string> = null;
  public adContent: string = "";
  public context: MiniGamesContext = null;
  public type: string = "";
  public createdAt: string = "";
  public updatedAt: string = "";
}
