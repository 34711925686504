import ImagesModel from "../../../../Global/Models/Images/ImagesModel";
import FixturesGamesModel from "../Fixtures/FixturesGamesModel";
import RelatedEntity from "../../../../Global/Models/Related/RelatedEntity";
import TimeTiebreaker from "./TimeTiebreaker";
import BrandingModel from "../../../../Global/Models/Branding/BrandingModel";

export default class GamesListModel {
    public id: string = null;
    public title: string = null;
    public description: string = null;
    public type: string = null;
    public status: string = null;
    public scheduleOpenAt: string = null;
    public predictionsCutoff: string = null;
    public fixtures: FixturesGamesModel[] = [];
    public tiebreaker: TimeTiebreaker = new TimeTiebreaker();
    public participantsCount: number = 0;
    public related: RelatedEntity[] = null;
    public excludedProfileIds: string[] = null;
    public rules: string = null;
    public labels: Record<string, string> = null;
    public customFields: Record<string, string> = null;
    public branding: BrandingModel = null;
    public flags: string[] = null;
    public images: ImagesModel = new ImagesModel();
    public createdAt: string = null;
    public updatedAt: string = null;

}