export interface ISpecificGames {
  gameId: string;
  gameType: string;
  gameModel: any;
}

export default class GamesBadgeRequirements {
  public participationCount: number;
  public points: number;
  public specificGames: ISpecificGames[];
  public fromDate: string;
  public toDate: string;
}
