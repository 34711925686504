import MatchBasicModel from "../../../Football/Models/Match/MatchBasicModel";
import { MarketEnum } from "../../Enums/MarketEnum";
import FixturesPredictionModel from "./FixturesPredictionModel";
import ResultModel from "./ResultModel";

export default class FixturesResponseModel {
    public market: MarketEnum = null;
    public matchId: string = null;
    public matchType: string = null;
    public matchModel: MatchBasicModel = null;
    public prediction: FixturesPredictionModel = new FixturesPredictionModel();
    public result: ResultModel = null;
}