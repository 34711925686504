import SDKConfigurationModel from "../Configurator/Models/SDKConfiguraitonModel";
import IdMappingHttps from "../Https/IdMappingHttps";
import LocalCacheInstanceFactory from "../Global/Factories/LocalInstanceFactory";
import { LocalCacheInterface } from "../Global/Interfaces/GlobalInterfaces";

export default class IdMappingFacade {
    private localCache: LocalCacheInterface = null;
    private https: IdMappingHttps = null;

    constructor(configuration: SDKConfigurationModel) {
        this.localCache = new LocalCacheInstanceFactory().createLocalCacheInstance(configuration.idSchema);
        this.https = new IdMappingHttps(configuration);
    }

    getEntitiesByIds = async (idsObj: any, configSchema?: string, toSchema?: string) => {
        await this.setTopIdMappings();

        try {
            const checkedIdsObj = this.localCache.checkForExistingIdObjs(idsObj, configSchema);
            const splitIdObjs = this.splitForRequestAndExisting(checkedIdsObj);
            const retrievedIdObjs = await this.https.getEntitiesByIdObjs(splitIdObjs.request, configSchema);
            this.localCache.addIdMappings(retrievedIdObjs);

            return this.localCache.getIdsByType(idsObj, configSchema, toSchema);
        } catch (e) {
            console.warn('There was a problem retrieving the entity ids ', e);
            return Promise.resolve([]);
        }
    };

    public getEntityById = async (ids: string | string[], type: string, schemaId: string) => {
        const entity = this.localCache.getEntity(ids, type, schemaId);

        if (entity) {
            return entity;
        }

        const requestedEntities = await this.requestEntity(ids, type, schemaId);
        this.localCache.addIdMappings(requestedEntities);
        return requestedEntities;
    };

    /**
     * Checks ids if they exist in the local storage, this function requires the already checked ids object
     * @param checkedIdsObj
     */
    private splitForRequestAndExisting = (checkedIdsObj: any) => {
        let toRequestIdsObj = {};
        let existingIdsObj = {};

        Object.keys(checkedIdsObj).forEach((key: string) => {
            //@ts-ignore
            toRequestIdsObj[key] = checkedIdsObj[key].filter((checkedId: any) => !checkedId.exists).map((checkedId: any) => checkedId.id);
            //@ts-ignore
            existingIdsObj[key] = checkedIdsObj[key].filter((checkedId: any) => checkedId.exists).map((checkedId: any) => checkedId.id);
        });

        return {existing: existingIdsObj, request: toRequestIdsObj};
    };

    private requestEntity = async (ids: string | string[], type: string, schemaId: string) => {
        try {
            ids = Array.isArray(ids) ? ids : [ids];
            return await this.https.getEntitiesByIds(type, ids, schemaId);
        } catch (e) {
            console.warn(`There was a problem retrieving the requested entity: ${e}`);
            return null;
        }
    };

    /**
     * Fetches top id mappings and store them in local storage whether they don't exist or their
     * expiration time has passed
     */
    private setTopIdMappings = async () => {
        const now = Math.floor(Date.now() / 1000);
        const expiration = this.localCache.getExpiration("football", "ids");

        if (expiration > now) {
            return Promise.resolve();
        }

        const topIdMappings = await this.https.getTopEntities();
        this.localCache.addTopIdMappings(topIdMappings);
    };

}
