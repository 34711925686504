import {IRemapper} from "../../../../../Global/IRemapper";
import FootballCountryRemapper from "../../Country/Remappers/FootballCountryRemapper";
import PlayerFullModel from "../PlayerFullModel";
import TeamBasicRemapper from "../../Team/Remappers/TeamBasicRemapper";
import CompetitionBasicRemapper from "../../Competition/Remappers/CompetitionBasicRemapper";

export default class PlayerFullRemapper implements IRemapper<PlayerFullModel> {
    private countryRemapper: FootballCountryRemapper = null;
    private teamRemapper: TeamBasicRemapper = null;
    private competitionRemapper: CompetitionBasicRemapper = null;

    constructor() {
        this.countryRemapper = new FootballCountryRemapper();
        this.teamRemapper = new TeamBasicRemapper();
        this.competitionRemapper = new CompetitionBasicRemapper();
    }

    fromResponse(response: any): PlayerFullModel {
        try {
            const model = new PlayerFullModel();
            model.id = response.id;
            model.firstName = response.first_name ? response.first_name : null;
            model.lastName = response.last_name ? response.last_name : null;
            model.name = response.name ? response.name : null;
            model.birthDate = response.birth_date;
            model.country = this.countryRemapper.countryFromResponse(response.country);
            model.assets = response.assets;
            model.teams = response.teams ? response.teams.map((team: any) => this.teamRemapper.fromResponse(team)) : [];
            model.position = response.position;
            model.isDeleted = response.is_deleted;
            model.competitions = response.competitions ? response.competitions.map((comp: any) => this.competitionRemapper.fromResponse(comp)) : [];

            return model;
        } catch (e) {
            console.warn("There was a problem remapping player response", e);
            throw e;
        }
    };

}