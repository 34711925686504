import React from 'react';

import checkmark from '../../assets/checkmark.png';

import styles from './Toast.module.css';

const Toast = ({ message }) => {
  return (
    <div className={[styles.container, message.type === 'error' ? styles.error : ''].join(' ')}>
      {message.type === 'success' && <img src={checkmark} className={styles.icon} alt='checkmark'/>}
      {message.text}
    </div>
  )
};

export default Toast;