import { ErrorStatuses } from "./ErrorStatuses";
import { StandardFansUnitedExceptionInterface } from "./StandardFansUnitedException";

export interface DefaultFansUnitedExceptionInterface {
  error: StandardFansUnitedExceptionInterface;
}

export default class FansUnitedSdkFetchException {
  data: DefaultFansUnitedExceptionInterface = {
    error: { code: 0, status: "", message: "" },
  };
  status: number = 0;
  statusText: string = "";
  headers: Headers = null;

  constructor(response: Response, error: StandardFansUnitedExceptionInterface) {
    this.data.error.code = error.code;
    this.data.error.status = error.status;
    this.data.error.message = error.message;
    this.status = response.status;
    this.statusText = response.statusText;
    this.headers = response.headers;
  }

  public errorMessage = () => {
    return this.data.error.message;
  };
}
