import ActionsModel from "../Actions/ActionsModel";
import RewardsModelV2 from "../Rewards/RewardsModelV2";

export default class LoyaltyConfigModelV2 {
  public enabled: boolean = false;
  public clientId: string = null;
  public conditions: { list: string[] } = { list: null };
  public actions: ActionsModel = new ActionsModel();
  public rewards: RewardsModelV2 = new RewardsModelV2();
}
