import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import {
  initDisableCache,
  isDisabledCache,
  setBaseUrl,
} from "../Global/Helper";
import ClassicQuizParticipationBodyModel from "../Namespaces/MiniGames/Models/ClassicQuiz/ClassicQuizParticipationBodyModel";
import MiniGamesFilters from "../Namespaces/MiniGames/Models/Filters/MiniGamesFilters";
import ClassicQuizRemapper from "../Namespaces/MiniGames/Models/Remappers/ClassicQuizRemapper";
import Https from "./Https";
import EitherOrRemapper from "../Namespaces/MiniGames/Models/Remappers/EitherOrRemapper";
import EitherOrParticipationRequestModel from "../Namespaces/MiniGames/Models/EitherOr/Participation/EitherOrParticipationRequestModel";
import EitherOrParticipationRequestFullModel from "../Namespaces/MiniGames/Models/EitherOr/Participation/EitherOrParticipationRequestFullModel";

export default class MiniGamesHttps extends Https {
  private classicQuizRemapper: ClassicQuizRemapper = null;
  private eitherOrRemapper: EitherOrRemapper = null;

  constructor(config: SDKConfiguraitonModel) {
    super(config, setBaseUrl(config.environment, "miniGames"));
    this.classicQuizRemapper = new ClassicQuizRemapper();
    this.eitherOrRemapper = new EitherOrRemapper();
  }

  public getClassicQuizzes = async (
    filters?: MiniGamesFilters,
    disableCache?: boolean
  ) => {
    let url = `/classic-quizzes${this.apiSignInUrl}`;

    if (filters) {
      url += filters.constructMainFiltersUrl();
    }

    url += isDisabledCache(disableCache) ? initDisableCache() : "";
    const warnMessage = "There was a problem with fetching classic quizzes";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.classicQuizRemapper.remapClassicQuizzes(data);
  };

  public getClassicQuizById = async (
    classicQuizId: string,
    includeCorrectOptionIds?: boolean,
    disableCache?: boolean
  ) => {
    let url = `/classic-quizzes/${classicQuizId}${this.apiSignInUrl}`;

    if (includeCorrectOptionIds) {
      url += `&include_correct_options=${includeCorrectOptionIds}`;
    }

    if (isDisabledCache(disableCache)) {
      url += initDisableCache();
    }

    const warnMessage = "There was a problem with fetching classic quiz by ID";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.classicQuizRemapper.remapClassicQuizById(data.data);
  };

  public participateInClassicQuiz = async (
    classicQuizId: string,
    questions: ClassicQuizParticipationBodyModel[],
    includeCorrectOptionIds?: boolean
  ) => {
    const url = `/classic-quizzes/${classicQuizId}/participate${this.apiSignInUrl}`;
    const warnMessage = `There was a problem with participating in the following classic quiz: ${classicQuizId}`;
    const method = "POST";
    const body = {
      questions:
        this.classicQuizRemapper.remapClassicQuizParticipationBody(questions),
      include_correct_options: !!includeCorrectOptionIds,
    };
    const data = await this.fetchWithAuth(url, warnMessage, method, body);

    return this.classicQuizRemapper.remapClassicQuizParticipation(data.data);
  };

  public getMyClassicQuizParticipations = async (classicQuizIds: string[]) => {
    const url = `/classic-quizzes/participations/${
      this.apiSignInUrl
    }&classic_quiz_ids=${classicQuizIds ? classicQuizIds.join(",") : ""}`;
    const warnMessage = `There was a problem with fetching user's own Classic Quiz participations`;
    const data = await this.fetchWithAuth(url, warnMessage);

    return this.classicQuizRemapper.remapUserClassicQuizParticipations(
      data.data
    );
  };

  public getEitherOrs = async (
    filters?: MiniGamesFilters,
    disableCache?: boolean
  ) => {
    let url = `/either-or${this.apiSignInUrl}`;

    if (filters) {
      url += filters.constructMainFiltersUrl();
    }

    url += isDisabledCache(disableCache) ? initDisableCache() : "";
    const warnMessage = "There was a problem with fetching list of Either/Ors";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.eitherOrRemapper.remapEitherOrs(data);
  };

  public getEitherOrById = async (
    eitherOrId: string,
    disableCache?: boolean
  ) => {
    let url = `/either-or/${eitherOrId}${this.apiSignInUrl}`;

    if (isDisabledCache(disableCache)) {
      url += initDisableCache();
    }

    const warnMessage = "There was a problem with fetching Either/Or by ID";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.eitherOrRemapper.remapEitherOrById(data.data);
  };

  public getMyEitherOrStats = async (eitherOrId: string) => {
    const url = `/either-or/${eitherOrId}/own-stats${this.apiSignInUrl}`;
    const warnMessage =
      "There was a problem with fetching Either/Or own statistics";
    const data = await this.fetchWithAuth(url, warnMessage);

    return this.eitherOrRemapper.remapEitherOrOwnStats(data.data);
  };

  public getEitherOrResults = async (
    eitherOrId: string,
    disableCache?: boolean
  ) => {
    const url = `/either-or/${eitherOrId}/results${this.apiSignInUrl}${
      isDisabledCache(disableCache) ? initDisableCache() : ""
    }`;
    const warnMessage = "There was a problem with fetching Either/Or results";
    const data = await this.fetchWithAuth(url, warnMessage);

    return this.eitherOrRemapper.remapEitherOrResults(data.data);
  };

  public participateInEitherOr = async (
    eitherOrId: string,
    requestBody: EitherOrParticipationRequestFullModel | null
  ) => {
    const url = `/either-or/${eitherOrId}/participate${this.apiSignInUrl}`;
    const warnMessage = "There was a problem with participating in Either/Or";
    const method = "POST";
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return this.eitherOrRemapper.remapEitherOrParticipation(data.data);
  };
}
