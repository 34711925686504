import { configureStore } from "@reduxjs/toolkit";

import { 
  userReducer,
  predictorReducer,
  leaguesReducer,
  rankingReducer,
  badgesReducer,
} from './redux';

const store = configureStore({
  reducer: {
    predictor: predictorReducer,
    leagues: leaguesReducer,
    user: userReducer,
    ranking: rankingReducer,
    badges: badgesReducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({ thunk: {}, serializableCheck: false, }),
});

export default store;