import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      //Menu
      'Play': 'Play',
      'Ranking': 'Ranking',
      'Prizes': 'Prizes',
      'Rules': 'Rules',
      'Leagues': 'Leagues',
      'Badges': 'Badges',

      //Weekdays
      'Mon': 'Mon',
      'Tue': 'Tue',
      'Wed': 'Wed',
      'Thu': 'Thu',
      'Fri': 'Fri',
      'Sat': 'Sat',
      'Sun': 'Sun',
      'Today': 'Today',

      //Play
      'Predict every European Championship result': 'Predict every European Championship result',
      'Which team will come out on top? Who will net the first goal? Test your prediction skills now!': 'Which team will come out on top? Who will net the first goal? Test your prediction skills now!',
      'Read how to play': 'Read how to play',
      'View statistics': 'View statistics',
      'Statistics': 'Statistics',
      'Get bonus points': 'Get bonus points',
      'Result at half time': 'Result at half time',
      'Over/Under 8.5 corners': 'Over/Under 8.5 corners',
      'Player to score the first goal': 'Player to score the first goal',
      'Last 5 matches of each team': 'Last 5 matches of each team',
      'Guess right to score': 'Guess right to score',
      'Save': 'Save',
      'Guess over/under to score': 'Guess over/under to score',
      'yes': 'yes',
      'no': 'no',
      'over': 'over',
      'under': 'under',
      'Guess the player to score': 'Guess the player to score',
      'Forwards': 'Forwards',
      'Midfielders' : 'Midfielders',
      'Defenders': 'Defenders',
      'Goalkeepers': 'Goalkeepers',
      'Prediction saved': 'Prediction saved',
      'Share': 'Share',

      //Ranking
      'Overall ranking': 'Overall ranking',
      'members': 'members',
      'No ranking yet.': 'No ranking yet.',
      'Once the European Championship starts, all users that played the Predictor Game will rank in here.': 'Once the European Championship starts, all users that played the Predictor Game will rank in here.',
      'League': 'League',
      'Rank': 'Rank',
      '[Country] Top Predictor': '[Country] Top Predictor', //here
      
      //Prizes
      'Win up to €500.00 in free bets deposited directly into your Betway account!': 'Win up to €500.00 in free bets deposited directly into your Betway account!',
      '1st = €500.00': '1st = €500.00',
      '2nd = €200.00': '2nd = €200.00',
      '3rd = €100.00': '3rd = €100.00',
      'The player who finishes at the top of the leaderboard at the end of Euro 2024 will win': 'The player who finishes at the top of the leaderboard at the end of Euro 2024 will win',
      '(or their local currency equivalent) in free bets deposited directly into their Betway account!': '(or their local currency equivalent) in free bets deposited directly into their Betway account!',
      'The person in 2nd will win': 'The person in 2nd will win',
      'in free bets and third place will win': 'in free bets and third place will win',
      '(or their local currency equivalent) in free bets deposited into their Betway account.': '(or their local currency equivalent) in free bets deposited into their Betway account.',
      'Please note that if there is a tie for 1st, 2nd or 3rd, the participants in those positions will go in a draw to determine the winner of each prize.': 'Please note that if there is a tie for 1st, 2nd or 3rd, the participants in those positions will go in a draw to determine the winner of each prize.',
      'Winners will have their Betway accounts credited within 7 days of the completion of the tournament, or will be contacted within 7 days.': 'Winners will have their Betway accounts credited within 7 days of the completion of the tournament, or will be contacted within 7 days.',
      'You must have a valid active Betway account prior to participating in the contest.': 'You must have a valid active Betway account prior to participating in the contest.',
      'For more information please see the full': 'For more information please see the full',
      'Terms & Conditions': 'Terms & Conditions',

      //Rules
      'How to play the Predictor Game?': 'How to play the Predictor Game?',
      'The rules are:': 'The rules are:',
      'You need to guess the correct score of matches in Euro 2024. Each prediction earns points.': 'You need to guess the correct score of matches in Euro 2024. Each prediction earns points.',
      'You earn 50 points for getting the correct score. But if you don\'t get it quite right, you can still earn points:': 'You earn 50 points for getting the correct score. But if you don\'t get it quite right, you can still earn points:',
      '5 points for guessing the winner correctly (1X2)': '5 points for guessing the winner correctly (1X2)',
      '5 points for getting the home goals correct': '5 points for getting the home goals correct',
      '5 points for getting the away goals correct': '5 points for getting the away goals correct',
      '5 points for guessing the goal difference correct': '5 points for guessing the goal difference correct',
      'You can also earn bonus points on the other markets:': 'You can also earn bonus points on the other markets:',
      '10 points for getting the halftime result (1X2) correct': '10 points for getting the halftime result (1X2) correct',
      '15 points for getting the number of corners correct': '15 points for getting the number of corners correct',
      '20 points for getting the first goalscorer correct': '20 points for getting the first goalscorer correct',
      'You can make changes to your predictions at any point, up until 15 minutes before kick off.': 'You can make changes to your predictions at any point, up until 15 minutes before kick off.',
      
      //Leagues
      'Compete against your friends or join public leagues!': 'Compete against your friends or join public leagues!',
      'Who\'s got the best prediction skills? Create or join a league to find out!': 'Who\'s got the best prediction skills? Create or join a league to find out!',
      'Create a league': 'Create a league',
      'Enter league name': 'Enter league name',
      'Create league': 'Create league',
      'Join a league': 'Join a league',
      'Enter invitation code': 'Enter invitation code',
      'Join league': 'Join league',
      'My leagues': 'My leagues',
      'member': 'member',
      'Members': 'Members',
      'Points': 'Points',
      'Your friends can join your league by using this code:': 'Your friends can join your league by using this code:',
      'Invitation code copied': 'Invitation code copied',
      'Edit': 'Edit',
      'Delete': 'Delete',
      'Leave': 'Leave',
      'Edit league': 'Edit league',
      'Your prediction points and ranking will be deleted.': 'Your prediction points and ranking will be deleted.',
      'All the history and members will be permanently deleted.': 'All the history and members will be permanently deleted.',
      'Yes, leave': 'Yes, leave',
      'Yes, delete': 'Yes, delete',
      'Cancel': 'Cancel',
      'Ban': 'Ban',
      
      //Badges
      'Newbie Predictor': 'Newbie Predictor',
      'Top Predictor': 'Top Predictor',
      'Master Predictor': 'Master Predictor',
      'You\'ve made your first prediction!': 'You\'ve made your first prediction!',
      'You\'ve reached 500 points!': 'You\'ve reached 500 points!',
      'You\'ve reached 1500 points!': 'You\'ve reached 1500 points!',
      'You\'ve reached 150 points on the team predictions!': 'You\'ve reached 150 points on the team predictions!',
      'Earned when the user makes their first prediction.': 'Earned when the user makes their first prediction.',
      'Earned when the user gets 500 points on their predictions.': 'Earned when the user gets 500 points on their predictions.',
      'Earned when the user gets 1500 points on their predictions.': 'Earned when the user gets 1500 points on their predictions.',

      //Teams
      'Albania': 'Albania',
      'Austria': 'Austria',
      'Belgium': 'Belgium',
      'Croatia': 'Croatia',
      'Czech Republic': 'Czech Republic',
      'Denmark': 'Denmark',
      'England': 'England',
      'France': 'France',
      'Georgia': 'Georgia',
      'Germany': 'Germany',
      'Hungary': 'Hungary',
      'Italy': 'Italy',
      'Netherlands': 'Netherlands',
      'Poland': 'Poland',
      'Portugal': 'Portugal',
      'Romania': 'Romania',
      'Scotland': 'Scotland',
      'Serbia': 'Serbia',
      'Slovakia': 'Slovakia',
      'Slovenia': 'Slovenia',
      'Spain': 'Spain',
      'Switzerland': 'Switzerland',
      'Turkey': 'Turkey',
      'Ukraine': 'Ukraine',
    }
  },
  es: {
    translation: {
      //Menu
      'Play': 'Jugar',
      'Ranking': 'Clasificación',
      'Prizes': 'Premios',
      'Rules': 'Reglas',
      'Leagues': 'Ligas',
      'Badges': 'Escudos',

      //Weekdays
      'Mon': 'Lu',
      'Tue': 'Ma',
      'Wed': 'Mi',
      'Thu': 'Ju',
      'Fri': 'Vi',
      'Sat': 'Sa',
      'Sun': 'Do',
      'Today': 'Hoy',

      //Play
      'Predict every European Championship result': 'Pronostica cada resultado de la Eurocopa',
      'Which team will come out on top? Who will net the first goal? Test your prediction skills now!': '¿Qué equipo ganará? ¿Quién marcará el primer gol? ¡Pon a prueba tus habilidades como pronosticador!',
      'Read how to play': 'Lee cómo se juega',
      'View statistics': 'Descubre estadísticas',
      'Statistics': 'Estadísticas',
      'Get bonus points': 'Consigue puntos extra',
      'Result at half time': 'Resultado al descanso',
      'Over/Under 8.5 corners': 'Más/menos 8.5 saques de esquina',
      'Player to score the first goal': 'Marca el primer gol',
      'Last 5 matches of each team': 'Últimos 5 partidos de cada equipo',
      'Guess right to score': 'Adivina el resultado exacto',
      'Save': 'Guardar',
      'Guess over/under to score': 'Adivina más/menos resultado',
      'yes': 'Sí',
      'no': 'No',
      'over': 'más',
      'under': 'menos',
      'Guess the player to score': 'Adivina el jugador que marca',
      'Forwards': 'Delanteros',
      'Midfielders' : 'Centrocampistas',
      'Defenders': 'Defensas',
      'Goalkeepers': 'Porteros',
      'Prediction saved': 'Pronóstico guardada',
      'Share': 'Compartir',

      //Ranking
      'Overall ranking': 'Clasificación total',
      'members': 'participantes',
      'No ranking yet.': 'No es un clasificación todavía',
      'Once the European Championship starts, all users that played the Predictor Game will rank in here.': 'Cuando la Eurocopa comience, todos los concursantes del juego El Pronosticador aparecerán en esta clasificació.',
      'League': 'Liga',
      'Rank': 'Posición',
      '[Country] Top Predictor': '[Country] Pronosticador Top', //here
      
      //Prizes
      'Win up to €500.00 in free bets deposited directly into your Betway account!': 'Gana hasta 500.00 euros en Free Bets que se añadirán directamente a tu cuenta de Betway',
      '1st = €500.00': '1º = €500.00',
      '2nd = €200.00': '2º = €200.00',
      '3rd = €100.00': '3º = €100.00',
      'The player who finishes at the top of the leaderboard at the end of Euro 2024 will win': '¡El jugador que termine en lo más alto de la clasificación al final de la Eurocopa 2024 ganará',
      '(or their local currency equivalent) in free bets deposited directly into their Betway account!': '(o su equivalente en la moneda local) en Free Bets depositadas directamente en su cuenta de Betway!',
      'The person in 2nd will win': 'El 2º ganará ',
      'in free bets and third place will win': 'en Free Bets y el 3º',
      '(or their local currency equivalent) in free bets deposited into their Betway account.': '(o su equivalente en la moneda local) en Free Bets que se añadirán directamente en su cuenta de Betway',
      'Please note that if there is a tie for 1st, 2nd or 3rd, the participants in those positions will go in a draw to determine the winner of each prize.': 'Por favor, tenga en cuenta que si hay un empate entre 1º, 2º o 3º, se realizará un sorteo para determinar los ganadores de cada premio.',
      'Winners will have their Betway accounts credited within 7 days of the completion of the tournament, or will be contacted within 7 days.': 'Los ganadores tendrán sus premios disponibles en sus cuentas de Betway dentro de los 7 días posteriores a la finalización del torneo, o serán contactados dentro de los 7 días.',
      'You must have a valid active Betway account prior to participating in the contest.': 'Deberás tener una cuenta de Betway correctamente validada antes de poder participar en el concurso.',
      'For more information please see the full': 'Para más información, diríjase a los',
      'Terms & Conditions': 'Términos y condiciones',

      //Rules
      'How to play the Predictor Game?': '¿Cómo jugar al juego de El Pronosticador?',
      'The rules are:': 'Las reglas son:',
      'You need to guess the correct score of matches in Euro 2024. Each prediction earns points.': 'Tienes que adivinar el resultado exacto de los partidos de la Euro 2024. Cada pronóstico acertado suma puntos',
      'You earn 50 points for getting the correct score. But if you don\'t get it quite right, you can still earn points:': 'Obtienes 50 puntos por acertar el resultado exacto. Pero si no lo adivinas del todo, aún puedes ganar puntos:',
      '5 points for guessing the winner correctly (1X2)': '5 puntos por acertar correctamente el ganador (1X2)',
      '5 points for getting the home goals correct': '5 puntos por acertar los goles del equipo local',
      '5 points for getting the away goals correct': '5 puntos por acertar los goles del equipo visitante',
      '5 points for guessing the goal difference correct': '5 puntos por acertar la diferencia de goles',
      'You can also earn bonus points on the other markets:': 'También puedes ganar puntos adicionales en otros mercados:',
      '10 points for getting the halftime result (1X2) correct': '10 puntos por acertar el resultado al descanso (1X2)',
      '15 points for getting the number of corners correct': '15 puntos por acertar el número de córners',
      '20 points for getting the first goalscorer correct': '20 puntos por acertar el primer goleador',
      'You can make changes to your predictions at any point, up until 15 minutes before kick off.': 'Puedes realizar cambios en tus predicciones en cualquier momento, hasta 15 minutos antes del inicio.',
      
      //Leagues
      'Compete against your friends or join public leagues!': '¡Compite contra tus amigos o únete a ligas públicas!',
      'Who\'s got the best prediction skills? Create or join a league to find out!': '¿Quién tiene las mejores habilidades como pronosticador? ¡Crea o únete a una liga para descubrirlo!',
      'Create a league': 'Crea una liga',
      'Enter league name': 'Introduce un nombre para la liga',
      'Create league': 'Crea la liga',
      'Join a league': 'Únete a una liga',
      'Enter invitation code': 'Introduce un código de invitación',
      'Join league': 'Únete a la liga',
      'My leagues': 'Mis ligas',
      'member': 'participante',
      'Members': 'Participantes',
      'Points': 'Puntos',
      'Your friends can join your league by using this code:': 'Tus amigos pueden unirse a tu liga con este código:',
      'Invitation code copied': 'Código de invitación copiado',
      'Edit': 'Editar',
      'Delete': 'Borrar',
      'Leave': 'Dejar',
      'Edit league': 'Editar liga',
      'Your prediction points and ranking will be deleted.': 'Tus puntos de predicciones y tu ranking serán borrados.',
      'All the history and members will be permanently deleted.': 'Todo el historial y los miembros serán borrados permanentemente.',
      'Yes, leave': 'Sí, dejar',
      'Yes, delete': 'Sí, borrar',
      'Cancel': 'Cancelar',
      'Ban': 'Prohibir',

      //Badges
      'Newbie Predictor': 'Pronosticador Novato',
      'Top Predictor': 'Pronosticador Top',
      'Master Predictor': 'Pronosticador Maestro',
      'You\'ve made your first prediction!': '¡Has hecho tu primer pronóstico!',
      'You\'ve reached 500 points!': '¡Has alcanzado 500 puntos!',
      'You\'ve reached 1500 points!': '¡Has alcanzado 1500 puntos!',
      'You\'ve reached 150 points on the team predictions!': '¡Has alcanzado 150 puntos en predicciones de equipo!',
      'Earned when the user makes their first prediction.': 'Obtenido cuando el concursante hace su primera predicción.',
      'Earned when the user gets 500 points on their predictions.': 'Obtenido cuando el concursante obtiene 500 puntos en sus predicciones.',
      'Earned when the user gets 1500 points on their predictions.': 'Obtenido cuando el concursante obtiene 1500 puntos en sus predicciones.',
      'Albania Master Predictor': 'Albania Pronosticador Maestro',
      'Austria Master Predictor': 'Austria Pronosticador Maestro',
      'Belgium Master Predictor': 'Bélgica Pronosticador Maestro',
      'Croatia Master Predictor': 'Croacia Pronosticador Maestro',
      'Czech Republic Master Predictor': 'República Checa Pronosticador Maestro',
      'Denmark Master Predictor': 'Dinamarca Pronosticador Maestro',
      'England Master Predictor': 'Inglaterra Pronosticador Maestro',
      'France Master Predictor': 'Francia Pronosticador Maestro',
      'Georgia Master Predictor': 'Georgia Pronosticador Maestro',
      'Germany Master Predictor': 'Alemania Pronosticador Maestro',
      'Hungary Master Predictor': 'Hungría Pronosticador Maestro',
      'Italy Master Predictor': 'Italia Pronosticador Maestro',
      'Netherlands Master Predictor': 'Países Bajos Pronosticador Maestro',
      'Poland Master Predictor': 'Polonia Pronosticador Maestro',
      'Portugal Master Predictor': 'Portugal Pronosticador Maestro',
      'Romania Master Predictor': 'Rumanía Pronosticador Maestro',
      'Scotland Master Predictor': 'Escocia Pronosticador Maestro',
      'Serbia Master Predictor': 'Serbia Pronosticador Maestro',
      'Slovakia Master Predictor': 'Eslovaquia Pronosticador Maestro',
      'Slovenia Master Predictor': 'Eslovenia Pronosticador Maestro',
      'Spain Master Predictor': 'España Pronosticador Maestro',
      'Switzerland Master Predictor': 'Suiza Pronosticador Maestro',
      'Turkey Master Predictor': 'Turquía Pronosticador Maestro',
      'Ukraine Master Predictor': 'Ucrania Pronosticador Maestro',

      //Teams
      'Albania': 'Albania',
      'Austria': 'Austria',
      'Belgium': 'Bélgica',
      'Croatia': 'Croacia',
      'Czech Republic': 'República Checa',
      'Denmark': 'Dinamarca',
      'England': 'Inglaterra',
      'France': 'Francia',
      'Georgia': 'Georgia',
      'Germany': 'Alemania',
      'Hungary': 'Hungría',
      'Italy': 'Italia',
      'Netherlands': 'Países Bajos',
      'Poland': 'Polonia',
      'Portugal': 'Portugal',
      'Romania': 'Rumanía',
      'Scotland': 'Escocia',
      'Serbia': 'Serbia',
      'Slovakia': 'Eslovaquia',
      'Slovenia': 'Eslovenia',
      'Spain': 'España',
      'Switzerland': 'Suiza',
      'Turkey': 'Turquía',
      'Ukraine': 'Ucrania',
    }
  }
};

i18n.use(initReactI18next).init({ resources, lng: "en" });

export default i18n;