import { ActionsEnum } from "../../Enums/ActionsEnum";
import ActivityFilters from "./ActivityFilters";

export default class OwnActivityFilters extends ActivityFilters {
  public action?: ActionsEnum = null;

  constructor(filters: any) {
    super(filters);

    this.action = filters.action ? filters.action : null;
  }

  public constructFilterUrl?() {
    let url = "";

    if (this.action) {
      url += "&";
      url += `action=${this.action}`;
    }

    if (this.page) {
      url += "&";
      url += `page=${this.page}`;
    }

    if (this.limit) {
      url += "&";
      url += `limit=${this.limit}`;
    }

    return url;
  }
}
