import DiscussionModel from "../DiscussionModel";
import PostsMetaModel from "../PostsMetaModel";
import PostByIdBasicModel from "../PostByIdBasicModel";
import PostsDiscussionBasicModel from "../PostsDiscussionBasicModel";
import ReactionModel from "../ReactionModel";
import VersionsModel from "../VersionsModel";
import ReportModel from "../ReportModel";
import PostByIdFullModel from "../PostByIdFullModel";
import PostsFullModel from "../PostsFullModel";
import MetaModel from "../../../../Global/Models/Pagination/MetaModel";
import PostsModel from "../PostsModel";
import PostsCountDiscussionModel from "../PostsCountDiscussionModel";

export default class DiscussionRemapper {
  public remapToDiscussions = (response: any) => {
    response.meta = this.remapToMeta(response.meta);
    response.data = response.data.map((v) => this.remapToDiscussionById(v));

    return response;
  };

  public remapToDiscussionById = (response: any) => {
    const model = new DiscussionModel();

    model.id = response.id;
    model.label = response.label;
    model.status = response.status;
    model.deleted = response.deleted;
    model.deletedAt = response.deleted_at;
    model.deletedBy = response.deleted_by;
    model.discussionType = response.discussion_type;
    model.moderationType = response.moderation_type;
    model.postsCount = response.posts_count;
    model.pinnedPosts = response.pinned_posts;
    model.lastPostId = response.last_post_id;
    model.customFields = response.custom_fields;
    model.context = response.context;
    model.createdAt = response.created_at;
    model.updatedAt = response.updated_at;

    return model;
  };

  public remapToPostsForDiscussion = (
    response: any,
    typeModel: "basic" | "full"
  ) => {
    const model = new PostsDiscussionBasicModel();
    const fullModel = new PostsFullModel();

    model.meta = this.remapToPostMeta(response.meta);
    model.data = response.data.map((value) => this.remapToPostByIdBasic(value));

    fullModel.meta = this.remapToPostMeta(response.meta);
    fullModel.data = response.data.map((value) =>
      this.remapToPostByIdFull(value)
    );

    return typeModel === "basic" ? model : fullModel;
  };

  public remapToPosts = (response: any) => {
    const model = new PostsModel();

    model.meta = this.remapToMeta(response.meta);
    model.data = response.data.map((value) => this.remapToPostByIdBasic(value));

    return model;
  };

  public remapToPostMeta = (meta: any) => {
    const model = new PostsMetaModel();

    model.pagination.totalItems = meta.pagination.total_items;
    model.pagination.nextPageStartsAfter =
      meta.pagination.next_page_starts_after;
    model.pagination.lastCommentCreateTimestamp =
      meta.pagination.last_comment_create_timestamp;

    return model;
  };

  private remapToMeta = (meta: any) => {
    const model = new MetaModel();

    model.pagination.itemsPerPage = meta.pagination.items_per_page;
    model.pagination.nextPageStartsAfter =
      meta.pagination.next_page_starts_after;

    return model;
  };

  public remapToPostByIdBasic = (response: any) => {
    const model = new PostByIdBasicModel();

    model.id = response.id;
    model.userId = response.user_id;
    model.discussionId = response.discussion_id;
    model.content = response.content;
    model.anonymousNickname = response.anonymous_nickname;
    model.reactionsCount = response.reactions_count;
    model.replyId = response.reply_id;
    model.reactions = response.reactions.length
      ? response.reactions.map((value) => this.remapReactionsPost(value))
      : response.reactions;
    model.repliesCount = response.replies_count;
    model.reports = response.reports.length
      ? response.reports.map((value) => this.remapReportPost(value))
      : response.reports;
    model.reportsCount = response.reports_count;
    model.versions = response.versions.length
      ? response.versions.map((value) => this.remapVersionsPost(value))
      : response.versions;
    model.privatePost = response.private_post;
    model.deleted = response.deleted;
    model.deletedAt = response.deleted_at;
    model.deletedBy = response.deleted_by;
    model.moderated = response.moderated;
    model.moderatedAt = response.moderated_at;
    model.moderatedBy = response.moderated_by;
    model.moderatedReason = response.moderated_reason;
    model.createdAt = response.created_at;
    model.updatedAt = response.updated_at;

    return model;
  };

  public remapToPostByIdFull = (response: any) => {
    const model = new PostByIdFullModel();

    model.id = response.id;
    model.userId = response.user_id;
    model.discussionId = response.discussion_id;
    model.content = response.content;
    model.anonymousNickname = response.anonymous_nickname;
    model.reactionsCount = response.reactions_count;
    model.replyId = response.reply_id;
    model.reactions = response.reactions.length
      ? response.reactions.map((value) => this.remapReactionsPost(value))
      : response.reactions;
    model.repliesCount = response.replies_count;
    model.reports = response.reports.length
      ? response.reports.map((value) => this.remapReportPost(value))
      : response.reports;
    model.reportsCount = response.reports_count;
    model.versions = response.versions.length
      ? response.versions.map((value) => this.remapVersionsPost(value))
      : response.versions;
    model.privatePost = response.private_post;
    model.deleted = response.deleted;
    model.deletedAt = response.deleted_at;
    model.deletedBy = response.deleted_by;
    model.moderated = response.moderated;
    model.moderatedAt = response.moderated_at;
    model.moderatedBy = response.moderated_by;
    model.moderatedReason = response.moderated_reason;
    model.createdAt = response.created_at;
    model.updatedAt = response.updated_at;

    return model;
  };

  public remapToPostCount = (response: any) => {
    return response.map((v) => {
      const model = new PostsCountDiscussionModel();

      model.id = v.id;
      model.postsCount = v.posts_count;

      return model;
    });
  };

  private remapReactionsPost = (response: any) => {
    const model = new ReactionModel();

    model.type = response.type;
    model.userIds = response.user_ids;
    model.reactionCount = response.reaction_count;

    return model;
  };

  private remapVersionsPost = (response: any) => {
    const model = new VersionsModel();

    model.content = response.content;
    model.updatedAt = response.updated_at;

    return model;
  };

  private remapReportPost = (response: any) => {
    const model = new ReportModel();

    model.userId = response.user_id;
    model.reason = response.reason;
    model.reasonDetails = response.reason_details;

    return model;
  };
}
