import CompetitionBasicModel from "./CompetitionBasicModel";

export default class CompetitionFilters {
    public countryId?: string = null;
    public name?: string = null;
    public gender?: string = null;
    public type?: string = null;
    public sortField?: string = null;
    public sortOrder?: string = null;
    public competitionIds?: string[] = null;

    constructor(filters: any) {
        this.countryId = filters.countryId ? `${filters.countryId}` : null;
        this.name = filters.name ? filters.name : null;
        this.gender = filters.gender ? filters.gender : null;
        this.type = filters.type ? filters.type : null;
        this.sortField = filters.sortField ? `${filters.sortField}` : null;
        this.sortOrder = filters.sortOrder ? `${filters.sortOrder}` : null;
        this.competitionIds = filters.competitionIds ? filters.competitionIds : null;
    }

    public constructFilterUrl = () => {
        let url = '';
        if (this.countryId) {
            url = url + '&';
            url = url + `country_id=${this.countryId}`;
        }
        if (this.gender) {
            url = url + '&';
            url = url + `gender=${this.gender}`;
        }
        if (this.type) {
            url = url + '&';
            url = url + `type=${this.type}`;
        }
        if (this.sortField) {
            url = url + '&';
            url = url + `sort_field=${this.sortField}`;
        }
        if (this.sortOrder) {
            url = url + '&';
            url = url + `sort_order=${this.sortOrder}`;
        }
        if (this.name) {
            url = url + '&';
            url = url + `name=${this.name}`;
        }
        if (this.competitionIds && this.competitionIds.length > 0) {
            url = url + '&';
            url = url + `competition_ids=${this.competitionIds.join(",")}`;
        }

        return url;
    };

    public competitionSearchFilter? = (competitions: CompetitionBasicModel[]) => {
        try {
            const filteredCompetitions = this.filter(competitions);
            if (this.sortField || this.sortOrder) {
                const sortedCompetitions = this.sort(filteredCompetitions);
                return sortedCompetitions;
            }
            return filteredCompetitions;
        } catch (e) {
            console.warn('There was a problem filtering the local competitions', e);
            return [];
        }

    };

    private filter? = (competitions: CompetitionBasicModel[]) => {
        const entitiesToSearchWith = this.entitiesToSearchWith();

        return competitions.filter((competition: CompetitionBasicModel) => {
            let isTruthy = true;
            entitiesToSearchWith.forEach((searchEntity: string) => {
                if (searchEntity !== 'sortOrder' && searchEntity !== 'sortField') {
                    if (searchEntity === 'countryId') {
                        isTruthy &&= competition.country.id === this[searchEntity];
                    } else if (searchEntity === 'name') {
                        isTruthy &&= competition.name.toLocaleLowerCase().includes(this[searchEntity].toLocaleLowerCase());
                    } else if (searchEntity === "competitionIds") {
                        isTruthy &&= this[searchEntity].some(id => competition.id === id);
                    } else {
                        //@ts-ignore
                        isTruthy &&= competition[searchEntity].toLocaleLowerCase() === this[searchEntity].toLocaleLowerCase();
                    }
                }
            });
            return isTruthy;
        });
    };

    private entitiesToSearchWith? = () => {
        const entitiesToSearchWith = [];

        if (this.countryId) {
            entitiesToSearchWith.push('countryId');
        }
        if (this.name) {
            entitiesToSearchWith.push('name');
        }
        if (this.gender) {
            entitiesToSearchWith.push('gender');
        }
        if (this.type) {
            entitiesToSearchWith.push('type');
        }
        if (this.sortField) {
            entitiesToSearchWith.push("sortField");
        }
        if (this.sortOrder) {
            entitiesToSearchWith.push("sortOrder");
        }
        if (this.competitionIds) {
            entitiesToSearchWith.push("competitionIds")
        }

        return entitiesToSearchWith;
    };

    private sort? = (competitions: CompetitionBasicModel[]) => {
        let sortedCompetitions: any[] = [];

        if (this.sortField === "name") {
            sortedCompetitions = competitions.sort((a, b) => {
            if (this.sortOrder && this.sortOrder === "desc") {
                return b.name.localeCompare(a.name);
            } else {
                return a.name.localeCompare(b.name);
            }
            });
        } else if (this.sortField === "country") {
            sortedCompetitions = competitions.sort((a, b) => {
                if (this.sortOrder && this.sortOrder === "desc") {
                    return b.country.name.localeCompare(a.country.name);
                } else {
                    return a.country.name.localeCompare(b.country.name);
                }
            });
        } else if (this.sortField === "scope") {
            competitions.forEach((competition: CompetitionBasicModel) => {
                return sortedCompetitions[this.competitionIds.indexOf(competition.id)] = competition;
            });
        } else if (!this.sortField && this.sortOrder === "desc") {
            sortedCompetitions = competitions.sort((a, b) => {
                return b.id.localeCompare(a.id);
            });
        }

        return sortedCompetitions;
    }
}