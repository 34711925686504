import LoyaltyFilters from "./LoyaltyFilters";

export default class LeagueLeaderboardFilters extends LoyaltyFilters {
  templateId?: string = null;

  constructor(filters: any) {
    super(filters);
    this.templateId = filters?.templateId || null;
  }

  public constructFiltersUrl?(): string {
    let url = super.constructFiltersUrl();

    if (this.templateId) {
      url += `&template_id=${this.templateId}`;
    }

    return url;
  }
}
