export enum LangEnum {
  EN = "en",
  BG = "bg",
  RO = "ro",
  EL = "el", // Stands for Greek
  SK = "sk",
  PT = "pt",
  SR = "sr",
  HU = "hu",
  SV = "sv",
  ES = "es",
  FR = "fr",
  NL = "nl",
  DE = "de",
  IT = "it",
}
