import { FiltersInterface } from "../../../../Global/Interfaces/GlobalInterfaces";

export default class ChallengeFilters implements FiltersInterface {
  public limit?: number = null;
  public page?: number = null;
  public challengeId?: string = null;
  public templateId?: string = null;
  public sortOrder?: string = null;

  constructor(filters: any) {
    this.limit = filters && filters.limit ? filters.limit : null;
    this.page = filters && filters.page ? filters.page : null;
    this.challengeId =
      filters && filters.challengeId ? filters.challengeId : null;
    this.templateId = filters && filters.templateId ? filters.templateId : null;
    this.sortOrder = filters && filters.sortOrder ? filters.sortOrder : null;
  }

  constructFiltersUrl? = () => {
    let url = "";

    if (this.limit) {
      url += `&limit=${this.limit}`;
    }

    if (this.page) {
      url += `&page=${this.page}`;
    }

    if (this.challengeId) {
      url += `&league_id=${this.challengeId}`;
    }

    if (this.templateId) {
      url += `&template_id=${this.templateId}`;
    }

    if (this.sortOrder) {
      url += `&sort_order=${this.sortOrder}`;
    }

    return url;
  };
}
