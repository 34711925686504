import MetaFootballModel from "../MetaFootballModel";

export default class FootballMetaRemapper {
    public remapMetaProp = (meta: any) => {
        const metaFootballModel = new MetaFootballModel();

        metaFootballModel.pagination.currentPage = meta.pagination.current_page;
        metaFootballModel.pagination.itemsPerPage = meta.pagination.items_per_page;
        metaFootballModel.pagination.totalItems = meta.pagination.total_items;
        metaFootballModel.pagination.numberOfPages = meta.pagination.number_of_pages;

        return metaFootballModel;
    }
}