import MainFiltersBQ from "../../../../Global/Models/Filters/MainFiltersBQ";

export default class MiniGamesFilters extends MainFiltersBQ {
  flags?: string[] = null;
  status?: string = null;
  entityIds?: string[] = null;
  entityType?: string = null;
  sort?: string = null;
  groupByStatus?: boolean = null;

  constructor(filters: any) {
    super(filters);
    this.flags = filters && filters.flags ? filters.flags : null;
    this.status = filters && filters.status ? filters.status : null;
    this.entityIds = filters && filters.entityIds ? filters.entityIds : null;
    this.entityType = filters && filters.entityType ? filters.entityType : null;
    this.sort = filters && filters.sort ? filters.sort : null;
    this.groupByStatus = filters && filters.groupByStatus !== undefined ? filters.groupByStatus : null;
  }

  public constructMainFiltersUrl?() {
    let url = super.constructMainFiltersUrl();

    if (this.flags && this.flags.length > 0) {
      url += `&flags=${this.flags.join(",")}`;
    }

    if (this.status) {
      url += `&status=${this.status}`;
    }

    if (this.entityIds && this.entityIds.length > 0) {
      url += `&entity_ids=${this.entityIds}`;
    }

    if (this.sort) {
      url += `&sort=${this.sort}`;
    }

    if (this.groupByStatus !== null) {
      url += `&group_by_status=${this.groupByStatus}`;
    }

    return url;
  }
}
