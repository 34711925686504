import {IRemapper} from "../../../../../Global/IRemapper";
import CompetitionFullModel from "../CompetitionFullModel";
import FootballCountryRemapper from "../../Country/Remappers/FootballCountryRemapper";
import TeamBasicModel from "../../Team/TeamBasicModel";
import TeamBasicRemapper from "../../Team/Remappers/TeamBasicRemapper";

export default class CompetitionFullRemapper implements IRemapper<CompetitionFullModel> {

  private countryRemapper: FootballCountryRemapper = null;
  private teamBasicRemapper: TeamBasicRemapper = null;

  constructor() {
    this.countryRemapper = new FootballCountryRemapper();
    this.teamBasicRemapper = new TeamBasicRemapper();
  }

  fromResponse(response: any): CompetitionFullModel {
    try {
      const model = new CompetitionFullModel();
      model.id = response.id;
      model.country = this.countryRemapper.countryFromResponse(response.country);
      model.gender = response.gender;
      model.assets = response.assets;
      model.type = response.type
      model.name = response.name;
      model.participants = response.participants.map((participant: TeamBasicModel) => this.teamBasicRemapper.fromResponse(participant));

      return model;
    } catch (e) {
      console.warn("There was a problem remapping the competition response", e);
      throw e;
    }
  };

}