import {ID_TYPES} from "./Constants/Constants";
import ProfileModel from "../Namespaces/Profile/Models/ProfileModel";
import FootballCountryModel from "../Namespaces/Football/Models/Country/FootballCountryModel";
import ProfileCountryModel from "../Namespaces/Profile/Models/ProfileCountryModel";
import CompetitionBasicModel from "../Namespaces/Football/Models/Competition/CompetitionBasicModel";
import CompetitionFilters from "../Namespaces/Football/Models/Competition/CompetitionFilters";
import ProfileStatsModel from "../Namespaces/Profile/Models/Stats/ProfileStatsModel";
import IdMappingModel from "../IdMapping/Models/IdMappingModel";
import { LocalCacheInterface } from "./Interfaces/GlobalInterfaces";

const NAME = 'FansUnited'
const FANS_UNITED_INITIAL_SCHEMA_V1: any = {
    football: {
        ids: [],
        countries: [],
        competitions: [],
        expirations: {
            ids: 0
        }
    },
    profile: {
        info: {},
        statistics: {},
        badges: null, // API can return empty badges list so to be sure that we already fetched from there we will set the initial value to null.
        countries: [],
        expirations: {
            profile: 0,
            statistics: 0,
            badges: 0
        }
    },
    client: {
        features: {},
        expirations: {
            features: 0
        }
    }
};

export type ExpirationType = "profile" | "statistics" | "badges" | "features" | "ids";
export default class LocalStorage implements LocalCacheInterface {
    private static instance: LocalStorage;
    private idSchema: string = '';
    readonly expirationProfileTimeout: number = 600; // 10 minutes
    readonly expirationBadgesTimeout: number = 3600; // 1 hour
    readonly expirationClientFeaturesTimeout: number = 14_400; // 4 hours
    readonly expirationFootballIdsTimeout: number = 604_800; // 7 days

    private constructor(idSchema: string) {
        this.idSchema = idSchema;
    }

    public static getInstance(idSchema: string): LocalStorage {
        if (!LocalStorage.instance) {
            LocalStorage.instance = new LocalStorage(idSchema);
        }

        return LocalStorage.instance;
    };

    public clearData = () => {
        this.setData(FANS_UNITED_INITIAL_SCHEMA_V1);
    };

    public getEntityById = (type: string = '', id: string = ''): any => {
        return this.getData().football.ids.find((idObj: any) => {
            //@ts-ignore
            const idType = ID_TYPES[this.idSchema];
            //@ts-ignore
            return idObj && `${idObj[idType]}` === id && idObj.resource === type;
        })
    };

    public getEntity = (ids: string | string[], type: string, schema: string) => {
        if (Array.isArray(ids)) {
            return this.getData().football.ids.filter((idObj: any) => {
                //@ts-ignore
                const idType = ID_TYPES[schema];
                //@ts-ignore
                return idObj && ids.includes(`${idObj[idType]}`) && idObj.resource === type;
            })
        } else {
            return this.getData().football.ids.find((idObj: any) => {
                //@ts-ignore
                const idType = ID_TYPES[schema];
                //@ts-ignore
                return idObj && `${idObj[idType]}` === ids && idObj.resource === type;
            })
        }
    }

    private getEntityByNativeId = (type: string = '', id: string = ''): any => {
        return this.getData().football.ids.find((idObj: any) => {
            //@ts-ignore
            return idObj && `${idObj.id}` === id && idObj.resource === type;
        })
    };

    /**
     * Returns the ids as object {id: <entity id>, exists: true/false};
     * @param idsObj
     * @param toSchema
     */
    public checkForExistingIdObjs = (idsObj: any, toSchema: string) => {
        //@ts-ignore
        const schemaId = ID_TYPES[toSchema];
        let idsChecked = {};

        Object.keys(idsObj).forEach((key: string) => {
            const idsByResource = idsObj[key];
            const foundObjIds = this.getEntity(idsByResource, key, toSchema);
            const foundIds = foundObjIds.map((objId: any) => objId[schemaId]);
            const missingIds = idsObj[key].filter((id: string) => !foundIds.includes(id));
            //@ts-ignore
            idsChecked[key] = idsByResource.map((id: string) => !missingIds.includes(id) ? {id: id, exists: true} : {id: id, exists: false})
        });

        return idsChecked;
    };

    public getIdsByType = (typeIdsObj: any, configSchema: string, toSchema: string) => {
        //@ts-ignore
        const toSchemaId = ID_TYPES[toSchema];
        //@ts-ignore
        const configSchemaId = ID_TYPES[configSchema];
        let result = {};

        Object.keys(typeIdsObj).forEach((key: string) => {
            const objIds = typeIdsObj[key];
            const ids: string[] = [];
            this.getData().football.ids.forEach((idObj: any) => {
                objIds.forEach((id: string, idx: number) => {
                    if (id === idObj[configSchemaId] && key === idObj.resource) {
                        ids[idx] = idObj[toSchemaId];
                    }
                });
            });
            //@ts-ignore
            result[key] = ids;
        });
        return result;
    };

    public addIdMapping = (idMapping: any) => {
        const fansUnited = this.getData();
        fansUnited.football.ids.push(idMapping);
        this.setData(fansUnited);
    };

    public addIdMappings = (idMappings: any[]) => {
        const fansUnited = this.getData();
        let ids = fansUnited.football.ids;
        ids = [...ids, ...idMappings];
        fansUnited.football.ids = ids;

        this.setData(fansUnited);
    };

    /**
     * When we have already fetched the top id mappings, remove the old matches and store the new ones
     * @param topIdMappings Top id mappings from Id Mapping API
     */
    public addTopIdMappings = (topIdMappings: IdMappingModel[]) => {
        const fansUnited = this.getData();

        if (fansUnited.football.ids.length) {
            let ids = JSON.parse(JSON.stringify(fansUnited.football.ids));
            ids = ids.filter((idMapping: IdMappingModel) => idMapping.resource !== "match");
            topIdMappings = topIdMappings.filter((idMapping: IdMappingModel) => idMapping.resource === "match");
            ids = [...ids, ...topIdMappings];
            fansUnited.football.ids = ids;
        } else {
            fansUnited.football.ids = topIdMappings;
        }

        fansUnited.football.expirations.ids = Math.floor(Date.now() / 1000) + this.expirationFootballIdsTimeout; // Set 7 days expiration timestamp

        this.setData(fansUnited);
    }

    public setOwnProfile = (profile: ProfileModel) => {
        const fansUnited = this.getData();
        fansUnited.profile.info = profile;
        fansUnited.profile.expirations.profile = Math.floor(Date.now() / 1000) + this.expirationProfileTimeout; // set 10 minutes profile exparation timestamp
        this.setData(fansUnited);
    };

    public getOwnProfile = () => {
        const fansUnited = this.getData();
        return fansUnited.profile.info;
    };

    public getProfileCountries = () => {
        const fansUnited = this.getData();
        return fansUnited.profile.countries;
    };

    public setProfileCountries = (countries: ProfileCountryModel[]) => {
        const fansUnited = this.getData();
        fansUnited.profile.countries = countries;
        this.setData(fansUnited);
    };

    public getProfileStats = () => {
        const fansUnited = this.getData();

        return fansUnited.profile.statistics;
    };

    public setProfileStats = (stats: ProfileStatsModel) => {
        const fansUnited = this.getData();
        fansUnited.profile.statistics = stats;
        fansUnited.profile.expirations.statistics = Math.floor(Date.now() / 1000) + this.expirationProfileTimeout; // set 10 minutes stats exparation timestamp
        this.setData(fansUnited);
    };

    public getProfileBadges = () => {
        const fansUnited = this.getData();

        return fansUnited.profile.badges;
    };

    public setProfileBadges = (badges: string[]) => {
        const fansUnited = this.getData();
        fansUnited.profile.badges = badges;
        fansUnited.profile.expirations.badges = Math.floor(Date.now() / 1000) + this.expirationBadgesTimeout; // set 1 hour badges exparation timestamp
        this.setData(fansUnited);
    };

    public getFootballCountries = () => {
        const fansUnited = this.getData();
        return fansUnited.football.countries;
    };

    public setFootballCountries = (countries: FootballCountryModel[]) => {
        const fansUnited = this.getData();
        fansUnited.football.countries = countries;
        this.setData(fansUnited);
    };

    public getCompetitions = (filters?: CompetitionFilters) => {
        const fansUnited = this.getData();

        if (filters) {
            return filters.competitionSearchFilter(fansUnited.football.competitions);
        } else {
            return fansUnited.football.competitions;
        }
    };

    public setCompetitions = (competitions: CompetitionBasicModel[]) => {
        const fansUnited = this.getData();
        const newCompetitions = [...fansUnited.football.competitions];
        newCompetitions.push(...competitions);
        fansUnited.football.competitions = this.makeCompetitionsUnique(newCompetitions);
        this.setData(fansUnited);
    };

    public getCompetitionsLength = (): number => {
        const { football } = this.getData();
        return football.competitions.length;
    };

    public getClientFeatures = () => {
        const fansUnited = this.getData();

        return fansUnited.client.features;
    };

    public setClientFeatures = (features: {[key: string]: any}) => {
        const fansUnited = this.getData();
        const newFeatures = JSON.parse(JSON.stringify(features));
        fansUnited.client.expirations.features = Math.floor(Date.now() / 1000) + this.expirationClientFeaturesTimeout; // set 4 hours client features exparation timestamp
        fansUnited.client.features = newFeatures;
        this.setData(fansUnited);
    };

    public deleteClientFeatures = () => {
        const fansUnited = this.getData();
        const copyData = JSON.parse(JSON.stringify(fansUnited));
        copyData.client = {
            features: {},
            expirations: {
                features: 0
            }
        };
        this.setData(copyData);
    };

    public getExpiration = (namespace: string, type: ExpirationType): number => {
        this.initialNewSchema(namespace, "expirations");
        const fansUnited = this.getData();

        switch (type) {
            case 'profile':
                return fansUnited.profile.expirations.profile;
            case "statistics":
                return fansUnited.profile.expirations.statistics;
            case "badges":
                return fansUnited.profile.expirations.badges;
            case "features":
                return fansUnited.client.expirations.features;
            case "ids":
                return fansUnited.football.expirations.ids;

        }
    };

    /**
     * The purpose of this method is to avoid the posibility of having same competitions store in Local Storage.
     * @param competitions Cached competitions.
     * @returns Unique competitions.
     */
    private makeCompetitionsUnique = (competitions: CompetitionBasicModel[]) => {
        const set = new Set();

        return competitions.filter((competition: CompetitionBasicModel) => {
            const unique = !set.has(competition.id);
            set.add(competition.id);
            return unique;
        });
    };

    private getData = () => {
        if (!localStorage.getItem(NAME)) {
            this.setData(FANS_UNITED_INITIAL_SCHEMA_V1);
        }

        return JSON.parse(localStorage.getItem(NAME));
    };

    private setData = (data: any) => {
        localStorage.setItem(NAME, JSON.stringify(data));
    };

    /**
     * Guarantee that the new local storage schema is set
     */
    private initialNewSchema = (namespace: string, newField: string) => {
        const fansUnited = this.getData();

        if (!fansUnited || !fansUnited[namespace] || !fansUnited[namespace][newField]) {
            this.setData(FANS_UNITED_INITIAL_SCHEMA_V1);
        }
    };
}
