import React from 'react';

import { useTranslation } from 'react-i18next';

import closeIcon from '../../assets/close.png';
import editIcon from '../../assets/edit.png';
import deleteIcon from '../../assets/delete.png';

import styles from './ActionSheet.module.css';

const ActionSheet = ({ isOpen, onClose, onEdit, onDelete, title }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className={styles.background} onClick={onClose}>
      <div className={styles.container} onClick={e => e.stopPropagation()}>
        <img src={closeIcon} className={styles.closeButton} onClick={onClose} alt='close-icon'/>
        <div className={styles.title}>{title}</div>
        <div className={styles.optionButton} onClick={onEdit}>
          <img src={editIcon} className={styles.optionIcon} alt='edit-icon'/>
          <div className={styles.optionTitle}>{t('Edit')}</div>
        </div>
        <div className={styles.separator}/>
        <div className={styles.optionButton} onClick={onDelete}>
          <img src={deleteIcon} className={styles.optionIcon} alt='delete-icon'/>
          <div className={styles.optionTitle}>{t('Delete')}</div>
        </div>
      </div>
    </div>
  );
}

export default ActionSheet;