import { BadgeAssets } from "../BadgesValue";

export default class BadgesValueV2 {
  public id: string;
  public label: string;
  public description: string;
  public flags: string[];
  public assets: BadgeAssets = { mainImageUrl: "" };
  public enabled: boolean;
}
