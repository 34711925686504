import MetaModel from "../../../../Global/Models/Pagination/MetaModel";
import AcceptChallengeModel from "../../../Challenges/Models/AcceptChallengeModel";
import MetaFootballModel from "../../../Football/Models/Pagination/MetaFootballModel";
import AcceptPrivateLeagueModel from "../AcceptPrivateLeagueModel";
import PrivateLeagueFullModel from "../PrivateLeagueFullModel";
import PrivateLeagueBasicModel from "../PrivateLeagueModel";
import PrivateLeaguePredictionModel from "../PrivateLeaguePredictionModel";
import PrivateLeaguePredictionsModel from "../PrivateLeaguePredictionsModel";

export default class PrivateLeagueRemapper {
  public remapToPrivateLeagues = (response: any) => {
    response.meta = this.remapMetaPrivateLeagues(response.meta.pagination);
    response.data = response.data.map((league) =>
      this.remapToPrivateLeagueById(league)
    );

    return response;
  };

  private remapMetaPrivateLeagues = (pagination: any) => {
    const model = new MetaModel();

    model.pagination.itemsPerPage = pagination.items_per_page;
    model.pagination.nextPageStartsAfter = pagination.next_page_starts_after;

    return model;
  };

  public remapToPrivateLeagueById = (response: any) => {
    const model = new PrivateLeagueFullModel();

    model.id = response.id;
    model.name = response.name;
    model.description = response.description;
    model.type = response.type;
    model.members = response.members;
    model.administrators = response.administrators;
    model.banned = response.banned;
    model.invites = response.invites;
    model.membersCount = response.members_count;
    model.usersCanInviteUsers = response.users_can_invite_users;
    model.invitationCode = response.invitation_code;
    model.templateId = response.template_id;
    model.pastTemplates = response.past_templates;
    model.pinnedPosts = response.pinned_posts;
    model.scoringStartsAt = response.scoring_starts_at;

    return model;
  };

  public remapToMyPrivateLeagues = (response: any) => {
    const model = new PrivateLeagueBasicModel();

    model.id = response.id;
    model.name = response.name;
    model.description = response.description;
    model.type = response.type;
    model.administrators = response.administrators;
    model.banned = response.banned;
    model.invites = response.invites;
    model.membersCount = response.members_count;
    model.usersCanInviteUsers = response.users_can_invite_users;
    model.invitationCode = response.invitation_code;
    model.templateId = response.template_id;
    model.pastTemplates = response.past_templates;
    model.pinnedPosts = response.pinned_posts;
    model.scoringStartsAt = response.scoring_starts_at;

    return model;
  };

  public remapToAcceptPrivateLeague = (response: any) => {
    const model = new AcceptPrivateLeagueModel();

    model.profileId = response.profile_id;
    model.leagueId = response.league_id;
    model.message = response.message;

    return model;
  };

  public remapToAcceptChallenge = (response: any) => {
    const model = new AcceptChallengeModel();

    model.profileId = response.profile_id;
    model.challengeId = response.league_id;
    model.message = response.message;

    return model;
  };

  public remapPredictions = (response: any): PrivateLeaguePredictionsModel => {
    response.meta = this.remapMeta(response.meta);
    response.data = this.remapPredictionsData(response.data);

    return response;
  };

  private remapMeta = (meta: any) => {
    const model = new MetaFootballModel();

    model.pagination.currentPage = meta.pagination.current_page;
    model.pagination.itemsPerPage = meta.pagination.items_per_page;
    model.pagination.numberOfPages = meta.pagination.number_of_pages;
    model.pagination.totalItems = meta.pagination.total_items;

    return model;
  };

  private remapPredictionsData = (data: any[]) => {
    return data.map((value) => {
      const model = new PrivateLeaguePredictionModel();

      model.predictionId = value.prediction_id;
      model.profileId = value.profile_id;

      return model;
    });
  };
}
