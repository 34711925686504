import ProfileCountryModel from "./ProfileCountryModel";
import InterestModel from "./InterestModel";

export default class ProfileModel {
    public id: string = null;
    public name: string = null;
    public nickname: string = null;
    public avatar: string = null;
    public gender: string = null;
    public country: ProfileCountryModel = null;
    public birthDate: string = null;
    public verified: boolean = false;
    public staffMember: boolean = false;
    public interests: InterestModel[] = [];
    public followingCount: number = 0;
    public followersCount: number = 0;
}
