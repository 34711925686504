export enum MarketEnum {
  FT_1X2 = "FT_1X2",
  HT_1X2 = "HT_1X2",
  BOTH_TEAMS_SCORE = "BOTH_TEAMS_SCORE",
  DOUBLE_CHANCE = "DOUBLE_CHANCE",
  HT_FT = "HT_FT",
  PLAYER_SCORE = "PLAYER_SCORE",
  PLAYER_YELLOW_CARD = "PLAYER_YELLOW_CARD",
  PLAYER_RED_CARD = "PLAYER_RED_CARD",
  RED_CARD_MATCH = "RED_CARD_MATCH",
  PENALTY_MATCH = "PENALTY_MATCH",
  PLAYER_SCORE_FIRST_GOAL = "PLAYER_SCORE_FIRST_GOAL",
  CORNERS_MATCH = "CORNERS_MATCH",
  CORRECT_SCORE = "CORRECT_SCORE",
  CORRECT_SCORE_ADVANCED = "CORRECT_SCORE_ADVANCED",
  CORRECT_SCORE_HT = "CORRECT_SCORE_HT",
  PLAYER_SCORE_HATTRICK = "PLAYER_SCORE_HATTRICK",
  PLAYER_SCORE_TWICE = "PLAYER_SCORE_TWICE",
  OVER_GOALS_0_5 = "OVER_GOALS_0_5",
  OVER_GOALS_1_5 = "OVER_GOALS_1_5",
  OVER_GOALS_2_5 = "OVER_GOALS_2_5",
  OVER_GOALS_3_5 = "OVER_GOALS_3_5",
  OVER_GOALS_4_5 = "OVER_GOALS_4_5",
  OVER_GOALS_5_5 = "OVER_GOALS_5_5",
  OVER_GOALS_6_5 = "OVER_GOALS_6_5",
  OVER_CORNERS_6_5 = "OVER_CORNERS_6_5",
  OVER_CORNERS_7_5 = "OVER_CORNERS_7_5",
  OVER_CORNERS_8_5 = "OVER_CORNERS_8_5",
  OVER_CORNERS_9_5 = "OVER_CORNERS_9_5",
  OVER_CORNERS_10_5 = "OVER_CORNERS_10_5",
  OVER_CORNERS_11_5 = "OVER_CORNERS_11_5",
  OVER_CORNERS_12_5 = "OVER_CORNERS_12_5",
  OVER_CORNERS_13_5 = "OVER_CORNERS_13_5",
}

export const MarketsOverGoals = [
  MarketEnum.OVER_GOALS_0_5,
  MarketEnum.OVER_GOALS_1_5,
  MarketEnum.OVER_GOALS_2_5,
  MarketEnum.OVER_GOALS_3_5,
  MarketEnum.OVER_GOALS_4_5,
  MarketEnum.OVER_GOALS_5_5,
  MarketEnum.OVER_GOALS_6_5,
];

export const MarketsOverCorners = [
  MarketEnum.OVER_CORNERS_6_5,
  MarketEnum.OVER_CORNERS_7_5,
  MarketEnum.OVER_CORNERS_8_5,
  MarketEnum.OVER_CORNERS_9_5,
  MarketEnum.OVER_CORNERS_10_5,
  MarketEnum.OVER_CORNERS_11_5,
  MarketEnum.OVER_CORNERS_12_5,
  MarketEnum.OVER_CORNERS_13_5,
];

export const playerMarkets = [
  MarketEnum.PLAYER_RED_CARD,
  MarketEnum.PLAYER_SCORE,
  MarketEnum.PLAYER_SCORE_FIRST_GOAL,
  MarketEnum.PLAYER_SCORE_HATTRICK,
  MarketEnum.PLAYER_SCORE_TWICE,
  MarketEnum.PLAYER_YELLOW_CARD,
];

export const endMarkMarkets = [
  MarketEnum.FT_1X2,
  MarketEnum.HT_1X2,
  MarketEnum.HT_FT,
  MarketEnum.DOUBLE_CHANCE,
  MarketEnum.CORNERS_MATCH,
  MarketEnum.CORRECT_SCORE,
  MarketEnum.CORRECT_SCORE_HT,
  MarketEnum.CORRECT_SCORE_ADVANCED
];

export const playerMarketNobodyPrediction = MarketEnum.PLAYER_SCORE_FIRST_GOAL;
