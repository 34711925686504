import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import {
  initDisableCache,
  isDisabledCache,
  setBaseUrl,
} from "../Global/Helper";
import MainFiltersBQ from "../Global/Models/Filters/MainFiltersBQ";
import PollsFilters from "../Namespaces/Voting/Models/Polls/Filters/PollsFilters";
import PollRemapper from "../Namespaces/Voting/Models/Remappers/PollRemapper";
import Https from "./Https";

export default class VotingHttps extends Https {
  private pollRemapper: PollRemapper = null;

  constructor(config: SDKConfiguraitonModel) {
    super(config, setBaseUrl(config.environment, "voting"));
    this.pollRemapper = new PollRemapper();
  }

  public getPolls = async (filters: PollsFilters, disableCache?: boolean) => {
    let url = `/polls${this.apiSignInUrl}`;

    if (filters) {
      url += filters.constructMainFiltersUrl();
    }

    if (isDisabledCache(disableCache)) {
      url += initDisableCache();
    }

    const warnMessage = "There was a problem fetching polls";
    const response = await this.fetchWithoutAuth(url, warnMessage);

    return this.pollRemapper.remapPolls(response);
  };

  public getPollById = async (pollId: string, disableCache?: boolean) => {
    let url = `/polls/${pollId}${this.apiSignInUrl}`;

    if (isDisabledCache(disableCache)) {
      url += initDisableCache();
    }

    const warnMessage =
      "There was a problem fetching the following poll: " + pollId;
    const response = await this.fetchWithoutAuth(url, warnMessage);

    return this.pollRemapper.remapPollById(response.data);
  };

  public voteForPoll = async (pollId: string, optionId: string) => {
    const url = `/polls/${pollId}/votes${this.apiSignInUrl}`;
    const method = "PUT";
    const requestBody = { option_id: optionId };
    const warnMessage =
      "There was a problem voting for the following poll: " + pollId;
    const response = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return this.pollRemapper.remapUserVote(response.data);
  };

  public deleteVoteForPoll = async (pollId: string) => {
    const url = `/polls/${pollId}/votes${this.apiSignInUrl}`;
    const method = "DELETE";
    const warnMessage =
      "There was a problem deleting vote for the following poll: " + pollId;
    await this.fetchWithAuth(url, warnMessage, method);

    return true;
  };

  public getMyPollVote = async (pollId: string) => {
    const url = `/polls/${pollId}/votes${this.apiSignInUrl}`;
    const warnMessage =
      "There was a problem fetching user's own vote for the following poll: " +
      pollId;
    const response = await this.fetchWithAuth(url, warnMessage);

    return this.pollRemapper.remapPollVote(response.data);
  };

  public getMyPollsVotes = async (filters: MainFiltersBQ) => {
    let url = `/polls/votes${this.apiSignInUrl}`;

    if (filters) {
      url += filters.constructMainFiltersUrl();
    }

    const warnMessage = "There was a problem fetching user's votes for polls";
    const response = await this.fetchWithAuth(url, warnMessage);

    return this.pollRemapper.remapPollsVotes(response);
  };
}
