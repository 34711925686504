import { initDisableCache, limitIds, setBaseUrl } from "../Global/Helper";
import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import FootballCountryModel from "../Namespaces/Football/Models/Country/FootballCountryModel";
import FootballCountryRemapper from "../Namespaces/Football/Models/Country/Remappers/FootballCountryRemapper";
import CompetitionFilters from "../Namespaces/Football/Models/Competition/CompetitionFilters";
import CompetitionBasicModel from "../Namespaces/Football/Models/Competition/CompetitionBasicModel";
import CompetitionFullModel from "../Namespaces/Football/Models/Competition/CompetitionFullModel";
import CompetitionBasicRemapper from "../Namespaces/Football/Models/Competition/Remappers/CompetitionBasicRemapper";
import CompetitionFullRemapper from "../Namespaces/Football/Models/Competition/Remappers/CompetitionFullRemapper";
import TeamBasicModel from "../Namespaces/Football/Models/Team/TeamBasicModel";
import TeamFullModel from "../Namespaces/Football/Models/Team/TeamFullModel";
import TeamBasicRemapper from "../Namespaces/Football/Models/Team/Remappers/TeamBasicRemapper";
import TeamFullRemapper from "../Namespaces/Football/Models/Team/Remappers/TeamFullRemapper";
import MatchFullModel from "../Namespaces/Football/Models/Match/MatchFullModel";
import MatchBasicRemapper from "../Namespaces/Football/Models/Match/Remappers/MatchBasicRemapper";
import MatchFullRemapper from "../Namespaces/Football/Models/Match/Remappers/MatchFullRemapper";
import TopPlayerModel from "../Namespaces/Football/Models/Player/TopPlayerModel";
import PlayerFullModel from "../Namespaces/Football/Models/Player/PlayerFullModel";
import TopPlayerRemapper from "../Namespaces/Football/Models/Player/Remappers/TopPlayerRemapper";
import PlayerBasicRemapper from "../Namespaces/Football/Models/Player/Remappers/PlayerBasicRemapper";
import PlayerFullRemapper from "../Namespaces/Football/Models/Player/Remappers/PlayerFullRemapper";
import SearchRemapper from "../Namespaces/Football/Models/Search/SearchRemapper";
import TeamFilters from "../Namespaces/Football/Models/Team/TeamFilters";
import MatchFilters from "../Namespaces/Football/Models/Match/MatchFilters";
import PlayerFilters from "../Namespaces/Football/Models/Player/PlayerFilters";
import SearchFilters from "../Namespaces/Football/Models/Search/SearchFilters";
import SearchModel from "../Namespaces/Football/Models/Search/SearchModel";
import FootballPaginationModel from "../Namespaces/Football/Models/Pagination/FootballPaginationModel";
import FootballMetaRemapper from "../Namespaces/Football/Models/Pagination/Remapper/FootballMetaRemapper";
import Https from "./Https";
import FootballService from "../Namespaces/Football/Service/FootballService";

export default class FootballHttps extends Https {
    private limitIds: number = 200;
    private countryRemapper: FootballCountryRemapper = null;
    private competitionBasicRemapper: CompetitionBasicRemapper = null;
    private competitionFullRemapper: CompetitionFullRemapper = null;
    private teamBasicRemapper: TeamBasicRemapper = null;
    private teamFullRemapper: TeamFullRemapper = null;
    private matchBasicRemapper: MatchBasicRemapper = null;
    private matchFullRemapper: MatchFullRemapper = null;
    private playerBasicRemapper: PlayerBasicRemapper = null;
    private playerFullRemapper: PlayerFullRemapper = null;
    private topPlayerRemapper: TopPlayerRemapper = null;
    private searchRemapper: SearchRemapper = null;
    private footballMetaRemapper: FootballMetaRemapper = null;
    private footballService: FootballService = null;

    constructor(config: SDKConfiguraitonModel) {
        super(config, setBaseUrl(config.environment, "football"));
        this.apiSignInUrl = config.lang !== "en" ? this.apiSignInUrl + `&lang=${config.lang}` : this.apiSignInUrl;
        this.countryRemapper = new FootballCountryRemapper();
        this.competitionBasicRemapper = new CompetitionBasicRemapper();
        this.competitionFullRemapper = new CompetitionFullRemapper();
        this.teamBasicRemapper = new TeamBasicRemapper();
        this.teamFullRemapper = new TeamFullRemapper();
        this.matchBasicRemapper = new MatchBasicRemapper();
        this.matchFullRemapper = new MatchFullRemapper();
        this.playerBasicRemapper = new PlayerBasicRemapper();
        this.playerFullRemapper = new PlayerFullRemapper();
        this.topPlayerRemapper = new TopPlayerRemapper();
        this.searchRemapper = new SearchRemapper();
        this.footballMetaRemapper = new FootballMetaRemapper();
        this.footballService = new FootballService(config);
    }

    public getCountries = async (disableCache: boolean): Promise<FootballCountryModel[]> => {
        const warnMessage = "There was a problem with the football countries request";
        let url = `/countries${this.apiSignInUrl}`;
        const timestampCache = Date.now();

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.countryRemapper.countriesFromResponse(data.data);
    };

    /**
     * Makes request for fetching competitions from Football API with set filters. When ids exceed the limit in URL the requests are splited.
     * @param filters
     * @returns List of competitions with concrete filters. If no filters are passed we retrieve list of competitions sorted by ids.
     */

    public getCompetitions = async (filters?: CompetitionFilters, disableCache?: boolean): Promise<CompetitionBasicModel[]> => {
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with football competitions request";
        disableCache = !disableCache ? false : disableCache;

        if (filters && filters.competitionIds && filters.competitionIds.length > this.limitIds - 1) {
            let competitionsArrays: any = [];
            const limitedIds = limitIds(filters.competitionIds, this.limitIds);
            for (const ids of limitedIds) {
                filters.competitionIds = ids;
                let url = `/competitions${this.apiSignInUrl}${filters.constructFilterUrl()}`;

                if (disableCache) {
                    url += `&disable_cache=${timestampCache}`;
                }

                const data = await this.fetchWithoutAuth(url, warnMessage);
                competitionsArrays.push(data.data.map((competition: any) => this.competitionBasicRemapper.fromResponse(competition)));
            }
            //Merge an array of arrays
            const competitions = Array.prototype.concat.apply([], competitionsArrays);

            return competitions;
        } else {
            let url = `/competitions${this.apiSignInUrl}`;

            if (filters) {
                url += `${filters.constructFilterUrl()}`;
            }

            if (disableCache) {
                url +=`&disable_cache=${timestampCache}`;
                }

            const data = await this.fetchWithoutAuth(url, warnMessage);

            return data.data.map((competition: any) => this.competitionBasicRemapper.fromResponse(competition));
        }
    };

    public getCompetitionById = async (id: string, disableCache: boolean): Promise<CompetitionFullModel> => {
        let url = `/competitions/${id}${this.apiSignInUrl}`;
        const warnMessage = "There was a problem with football competition by ID request";
        const timestampCache = Date.now();

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.competitionFullRemapper.fromResponse(data.data);
    };

    public getTopCompetitions = async (disableCache: boolean): Promise<CompetitionBasicModel[]> => {
        const warnMessage = "There was a problem with top football competitions request";
        let url = `/competitions/top${this.apiSignInUrl}`;
        const timestampCache = Date.now();

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return data.data.map((resp: any) => this.competitionBasicRemapper.fromResponse(resp));
    };

    /**
     * Makes request for fetching teams from Football API with set filters. When ids exceed the limit in URL the requests are splited.
     * @param filters
     * @returns List of teams with concrete filters. If no filters are passed we retrieve list of teams sorted by ids.
     */

    public getTeams = async (filters?: TeamFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with football teams request";

        if (filters && filters.teamIds && filters.teamIds.length > this.limitIds) {
            let teamsArrays: any = [];
            const limitedIds = limitIds(filters.teamIds, this.limitIds);
            for (const ids of limitedIds) {
                filters.teamIds = ids;
                let url = `/teams${this.apiSignInUrl}${filters.constructFilterUrl()}`;

                if (disableCache) {
                    url += `disable_cache=${timestampCache}`;
                }

                const data = await this.fetchWithoutAuth(url, warnMessage);
                teamsArrays.push(data.data.map((team: any) => this.teamBasicRemapper.fromResponse(team)));
            }
            //Merge an array of arrays
            const teams = Array.prototype.concat.apply([], teamsArrays);

            return teams;
        } else {
            let teamsPaginationModel = new FootballPaginationModel();
            let url = `/teams${this.apiSignInUrl}`;

            if (filters) {
                url += `${filters.constructFilterUrl()}`;
            }

            if (disableCache) {
                url +=`&disable_cache=${timestampCache}`;
            }

            const data = await this.fetchWithoutAuth(url, warnMessage);
            teamsPaginationModel.meta = this.footballMetaRemapper.remapMetaProp(data.meta);
            teamsPaginationModel.data = data.data.map((team: any) => this.teamBasicRemapper.fromResponse(team));

            return teamsPaginationModel;
        }
    };

    public getTeamById = async (id: string, disableCache: boolean): Promise<TeamFullModel> => {
        const warnMessage = "There was a problem with football team by ID request";
        let url = `/teams/${id}${this.apiSignInUrl}`;
        const timestampCache = Date.now();

        if (disableCache) {
            url += `disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.teamFullRemapper.fromResponse(data.data);
    };

    public getTopTeams = async (disableCache: boolean): Promise<TeamBasicModel[]> => {
        let url = `/teams/top${this.apiSignInUrl}`;
        const warnMessage = "There was a problem with top football teams request";
        const timestampCache = Date.now();

        if (disableCache) {
            url += `$disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return data.data ? data.data.map((team: any) => this.teamBasicRemapper.fromResponse(team)) : [];
    };

    public getNextMatchForTeam = async (id: string, disableCache: boolean): Promise<MatchFullModel> => {
        let url = `/teams/${id}/next-match${this.apiSignInUrl}`;
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with next match for team request";

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.matchFullRemapper.fromResponse(data.data);
    };

    public getPrevMatchForTeam = async (id: string, disableCache: boolean): Promise<MatchFullModel> => {
        let url = `/teams/${id}/previous-match${this.apiSignInUrl}`;
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with previous match for team request";

        if (disableCache) {
            url += `disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.matchFullRemapper.fromResponse(data.data);
    };

    /**
     * Makes request for fetching players from Football API with set filters. When ids exceed the limit in URL the requests are splited.
     * @param filters
     * @returns List of players with concrete filters. If no filters are passed we retrieve list of players sorted by ids.
     */

    public getPlayers = async (filters?: PlayerFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with football players request";

        if (filters && filters.playerIds && filters.playerIds.length > this.limitIds - 1) {
            let playersArrays: any = [];
            const limitedIds = limitIds(filters.playerIds, this.limitIds);

            for (const ids of limitedIds) {
                filters.playerIds = ids;
                let url = `/players${this.apiSignInUrl}${filters.constructFilterUrl()}`;

                if (disableCache) {
                    url += `&disable_cache=${timestampCache}`;
                }

                const data = await this.fetchWithoutAuth(url, warnMessage);
                playersArrays.push(data.data.map((resp: any) => this.teamBasicRemapper.fromResponse(resp)));
            }
            //Merge an array of arrays
            const players = Array.prototype.concat.apply([], playersArrays);

            return players;
        } else {
            let playersPaginationModel = new FootballPaginationModel();
            let url = `/players${this.apiSignInUrl}`;

            if (filters) {
                url += `${filters.constructFilterUrl()}`;
            }

            if (disableCache) {
                url +=`&disable_cache=${timestampCache}`;
            }

            const data = await this.fetchWithoutAuth(url, warnMessage);
            playersPaginationModel.meta = this.footballMetaRemapper.remapMetaProp(data.meta);
            playersPaginationModel.data = data.data.map((player: any) => this.playerBasicRemapper.fromResponse(player));

            return playersPaginationModel;
        }
    };

    public getPlayerById = async (id: string, disableCache: boolean): Promise<PlayerFullModel> => {
        let url = `/players/${id}${this.apiSignInUrl}`;
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with football player by ID request";

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.playerFullRemapper.fromResponse(data.data);
    };

    public getTopPlayers = async (disableCache: boolean): Promise<TopPlayerModel[]> => {
        let url = `/players/top${this.apiSignInUrl}`;
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with top football players request";

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return data.data.map((player: any) => this.topPlayerRemapper.fromResponse(player));
    };

    public getNextMatchForPlayer = async (id: string, disableCache: boolean): Promise<MatchFullModel> => {
        let url = `/players/${id}/next-match${this.apiSignInUrl}`;
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with next match for player request";

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.matchFullRemapper.fromResponse(data.data);
    };

    public getPrevMatchForPlayer = async (id: string, disableCache: boolean): Promise<MatchFullModel> => {
        let url = `/players/${id}/previous-match${this.apiSignInUrl}`;
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with previous match for player request";

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.matchFullRemapper.fromResponse(data.data);
    };

    public getMatchById = async (id: string, disableCache: boolean): Promise<MatchFullModel> => {
        let url = `/matches/${id}${this.apiSignInUrl}`;
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with match by ID request";

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.matchFullRemapper.fromResponse(data.data);
    };

    public getMatches = async (filters?: MatchFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        let matchesPaginationModel = new FootballPaginationModel();
        const warnMessage = "There was a problem with football matches request";

        if (filters && filters.matchIds && filters.matchIds.length > this.limitIds) {
            let matchesArrays: any = [];
            const limitedIds = limitIds(filters.matchIds, this.limitIds);
            for (const ids of limitedIds) {
                filters.matchIds = ids;
                // Avoid applying pagination to Football API when batching requests
                const avoidPagination = true;
                let url = `/matches${this.apiSignInUrl}${filters.constructFilterUrl(avoidPagination)}&limit=${ids.length}`;

                if (disableCache) {
                    url += initDisableCache();
                }

                const data = await this.fetchWithoutAuth(url, warnMessage);
                matchesArrays.push(data.data.map((match: any) => this.matchBasicRemapper.fromResponse(match)));
            }
            //Merge an array of arrays
            let matches = Array.prototype.concat.apply([], matchesArrays);
            matchesPaginationModel.meta = this.footballService.setMetaDataBatchRequest(matches.length, filters.limit || 20, filters.page || 1);
            matchesPaginationModel.data = this.footballService.finalizeMatchesDataBatchRequest(matches, filters)

            return matchesPaginationModel;
        } else {
            let url = `/matches${this.apiSignInUrl}`;

            if (filters) {
                url += `${filters.constructFilterUrl()}`;
            }

            if (disableCache) {
                url += initDisableCache();
            }

            const data = await this.fetchWithoutAuth(url, warnMessage);
            matchesPaginationModel.meta = this.footballMetaRemapper.remapMetaProp(data.meta);
            matchesPaginationModel.data = data.data.map((match: any) => this.matchBasicRemapper.fromResponse(match));

            return matchesPaginationModel;
        }
    };

    public search = async (filters: SearchFilters, disableCache: boolean): Promise<SearchModel> => {
        let url = `/search${this.apiSignInUrl}${filters.constructFilterUrl()}`;
        const timestampCache = Date.now();
        const warnMessage = "There was a problem with football search request";

        if (disableCache) {
            url += `&disable_cache=${timestampCache}`;
        }

        const data = await this.fetchWithoutAuth(url, warnMessage);

        return this.searchRemapper.fromResponse(data.data);
    };
}
