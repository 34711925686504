import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import { ActionsEnum } from "./Enums/ActionsEnum";
import ActivityFacade from "./Facade/ActivityFacade";
import ActivityResponseBody from "./Models/ActivityResponseBody";
import PaginationActivities from "./Models/PaginationActivities";
import CampaignModel from "./Models/RequestBody/Context/CampaignModel";
import ContentModel from "./Models/RequestBody/Context/ContentModel";
import TagsModel from "./Models/RequestBody/Context/TagsModel";
import UserActivityFilters from "./Models/Filters/UserActivityFilters";
import OwnActivityFilters from "./Models/Filters/OwnActivityFilters";

export default class Activity {
    private activityFacade: ActivityFacade;

    constructor(config: SDKConfigurationModel) {
        this.activityFacade = new ActivityFacade(config);
    }

    public add = async (action: ActionsEnum, tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        return await this.activityFacade.add(action, tags, content, campaign);
    }

    public delete = async (activityId: string): Promise<boolean> => {
        return await this.activityFacade.delete(activityId);
    }

    public like = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        return await this.activityFacade.like(tags, content, campaign);
    }

    public dislike = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        return await this.activityFacade.dislike(tags, content, campaign);
    }

    public addPageview = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        return await this.activityFacade.addPageview(tags, content, campaign);
    }

    public addContentConsumed = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        return await this.activityFacade.addContentConsumed(tags, content, campaign);
    }

    public addShare = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        return await this.activityFacade.addShare(tags, content, campaign);
    }

    public addComment = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        return await this.activityFacade.addComment(tags, content, campaign);
    }

    public addClickAd = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        return await this.activityFacade.addClickAd(tags, content, campaign);
    }

    public addConversion = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        return await this.activityFacade.addConversion(tags, content, campaign);
    }

    public getOwn = async (filters?: OwnActivityFilters, disableCache?: boolean): Promise<PaginationActivities> => {
        return await this.activityFacade.getOwn(filters, disableCache);
    }

    public getForUser = async (userId: string, filters?: UserActivityFilters, disableCache?: boolean): Promise<PaginationActivities> => {
        return await this.activityFacade.getForUser(userId, filters, disableCache);
    }
}