import jwtDecode from "jwt-decode";
import SDKConfigurationModel from "../Configurator/Models/SDKConfiguraitonModel";
import { ErrorHandlingModeType } from "../Configurator/Types/ConfiguratorTypes";
import { ErrorCodes } from "../Exception/ErrorCodes";
import { ErrorStatuses } from "../Exception/ErrorStatuses";
import { ErrorMessages } from "../Global/Messages/Messages";
import FansUnitedSdkFetchException, {
  DefaultFansUnitedExceptionInterface,
} from "../Exception/FansUnitedSdkFetchException";
import StandardFansUnitedException, {
  StandardFansUnitedExceptionInterface,
} from "../Exception/StandardFansUnitedException";

export default class Https {
  protected baseUrl: string = null;
  protected apiSignInUrl: string = null;
  protected jwtToken: string = null;
  protected idSchema: string = null;
  protected retryRequest: boolean = false;
  protected headers: Record<string, string> = null;
  protected authHeaders: Record<string, string> = null;
  private errorHandlingMode: ErrorHandlingModeType = null;

  constructor(configuration: SDKConfigurationModel, baseUrl: string) {
    this.baseUrl = baseUrl;
    this.apiSignInUrl = `?key=${configuration.apiKey}&client_id=${configuration.clientId}`;
    this.jwtToken = configuration.authProvider.getIdToken();
    this.errorHandlingMode = configuration.errorHandlingMode;
    this.idSchema = configuration.idSchema;
    this.headers = { "Content-Type": "application/json" };
    this.authHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${configuration.authProvider.getIdToken()}`,
    };
  }

  protected fetchWithoutAuth = async (url: string, warnMessage: string) => {
    const response = await fetch(this.baseUrl + url, { headers: this.headers });
    const data = await response.json();

    if (!response.ok) {
      const fansUnitedException = this.transformFetchException(response, data);
      console.warn(warnMessage, fansUnitedException);
      throw fansUnitedException;
    }

    return data;
  };

  protected fetchWithoutAuthAdditionalHeaders = async (
    url: string,
    warnMessage: string,
    headers: HeadersInit
  ) => {
    const response = await fetch(this.baseUrl + url, {
      headers: { ...this.headers, ...headers },
    });
    const data = await response.json();
    if (!response.ok) {
      const fansUnitedException = this.transformFetchException(response, data);
      console.warn(warnMessage, fansUnitedException);
      throw fansUnitedException;
    }
    return data;
  };

  protected fetchWithAuth = async (
    url: string,
    warnMessage: string,
    method?: string,
    requestBody?: any
  ) => {
    this.validateJWTToken();
    let response: Response = null;
    let data: any = null;

    // We send request body only for POST/PATCH and sometimes for DELETE (unfollow profile ids) CRUD operations
    if (method && requestBody) {
      response = await fetch(this.baseUrl + url, {
        method,
        headers: this.authHeaders,
        body: JSON.stringify(requestBody),
      });
    } else if (method && !requestBody) {
      response = await fetch(this.baseUrl + url, {
        method,
        headers: this.authHeaders,
      });
    } else {
      response = await fetch(this.baseUrl + url, { headers: this.authHeaders });
    }

    // For DELETE CRUD operation API doesn't return any response body, so response.json() will fail.
    if (method !== "DELETE") {
      data = await response.json();
    }

    if (!response.ok) {
      if (
        (response.status === 401 || response.status === 403) &&
        !this.retryRequest
      ) {
        this.retryRequest = true;
        await this.fetchWithAuth(url, warnMessage, method, requestBody);
      }

      this.retryRequest = false;
      const fansUnitedException = this.transformFetchException(
        response,
        data ? data : await response.json()
      );
      console.warn(warnMessage, fansUnitedException);
      throw fansUnitedException;
    }

    this.retryRequest = false;

    return data;
  };

  protected fetchWithAuthNewBaseURL = async (
    baseURL: string,
    url: string,
    warnMessage: string,
    method?: string,
    requestBody?: any
  ) => {
    this.validateJWTToken();
    let response: Response = null;
    let data: any = null;

    // We send request body only for POST/PATCH and sometimes for DELETE (unfollow profile ids) CRUD operations
    if (method && requestBody) {
      response = await fetch(baseURL + url, {
        method,
        headers: this.authHeaders,
        body: JSON.stringify(requestBody),
      });
    } else if (method && !requestBody) {
      response = await fetch(baseURL + url, {
        method,
        headers: this.authHeaders,
      });
    } else {
      response = await fetch(baseURL + url, { headers: this.authHeaders });
    }

    // For DELETE CRUD operation API doesn't return any response body, so response.json() will fail.
    if (method !== "DELETE") {
      data = await response.json();
    }

    if (!response.ok) {
      if (
        (response.status === 401 || response.status === 403) &&
        !this.retryRequest
      ) {
        this.retryRequest = true;
        await this.fetchWithAuthNewBaseURL(
          baseURL,
          url,
          warnMessage,
          method,
          requestBody
        );
      }
      this.retryRequest = false;
      const fansUnitedException = this.transformFetchException(
        response,
        data ? data : await response.json()
      );
      console.warn(warnMessage, fansUnitedException);
      throw fansUnitedException;
    }

    this.retryRequest = false;

    return data;
  };

  /**
   * Returns the concrete exception depending on errorHandlingMode property
   * @param response Response body from Fans United API.
   */
  protected transformFetchException = (
    response: Response,
    data: DefaultFansUnitedExceptionInterface | null
  ): StandardFansUnitedException | FansUnitedSdkFetchException => {
    // Fans United APIs return no response body on 401 response
    if (!data && response.status === ErrorCodes.UNAUTHORIZED) {
      if (this.errorHandlingMode === "standard") {
        return new StandardFansUnitedException(
          ErrorCodes.UNAUTHORIZED,
          ErrorStatuses.UNAUTHORIZED,
          ErrorMessages.INVALID_TOKEN
        );
      }

      const error: StandardFansUnitedExceptionInterface = {
        code: ErrorCodes.UNAUTHORIZED,
        status: ErrorStatuses.UNAUTHORIZED,
        message: ErrorMessages.INVALID_TOKEN,
      };

      return new FansUnitedSdkFetchException(response, error);
    }

    const { code, status, message } = data.error;

    if (this.errorHandlingMode === "standard") {
      return new StandardFansUnitedException(code, status, message);
    }

    return new FansUnitedSdkFetchException(response, data.error);
  };

  protected validateJWTToken = () => {
    let decodeToken: any = null;
    try {
      decodeToken = jwtDecode(this.jwtToken) as {
        [key: string]: any;
      };
    } catch (error) {
      // Throws only when invalid token is provided.
      if (this.errorHandlingMode === "default") {
        const response: Response = {
          status: ErrorCodes.UNAUTHORIZED,
          statusText: error.message,
          //@ts-ignore
          headers: {},
        };
        const data: StandardFansUnitedExceptionInterface = {
          code: ErrorCodes.UNAUTHORIZED,
          status: ErrorStatuses.INVALID_TOKEN,
          message: ErrorMessages.INVALID_TOKEN,
        };
        throw new FansUnitedSdkFetchException(response, data);
      } else if (this.errorHandlingMode === "standard") {
        throw new StandardFansUnitedException(
          ErrorCodes.UNAUTHORIZED,
          ErrorStatuses.INVALID_TOKEN,
          ErrorMessages.INVALID_TOKEN
        );
      }
    }
    const isExpired = Date.now() > decodeToken.exp * 1000;

    if (isExpired) {
      if (this.errorHandlingMode === "default") {
        const response: Response = {
          status: ErrorCodes.UNAUTHORIZED,
          statusText: "",
          //@ts-ignore
          headers: {},
        };
        const data: StandardFansUnitedExceptionInterface = {
          code: ErrorCodes.UNAUTHORIZED,
          status: ErrorStatuses.INVALID_TOKEN,
          message: ErrorMessages.EXPIRED_TOKEN,
        };
        throw new FansUnitedSdkFetchException(response, data);
      } else if (this.errorHandlingMode === "standard") {
        throw new StandardFansUnitedException(
          ErrorCodes.UNAUTHORIZED,
          ErrorStatuses.INVALID_TOKEN,
          ErrorMessages.EXPIRED_TOKEN
        );
      }
    }
  };
}
