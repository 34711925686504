import NodeCache from "node-cache";
import { LocalCacheInterface } from "./Interfaces/GlobalInterfaces";
import ProfileModel from "../Namespaces/Profile/Models/ProfileModel";
import IdMappingModel from "../IdMapping/Models/IdMappingModel";
import CompetitionBasicModel from "../Namespaces/Football/Models/Competition/CompetitionBasicModel";
import FootballCountryModel from "../Namespaces/Football/Models/Country/FootballCountryModel";
import ProfileStatsModel from "../Namespaces/Profile/Models/Stats/ProfileStatsModel";
import ProfileCountryModel from "../Namespaces/Profile/Models/ProfileCountryModel";
import { ID_TYPES } from "./Constants/Constants";
import { ExpirationType } from "./LocalStorage";
import CompetitionFilters from "../Namespaces/Football/Models/Competition/CompetitionFilters";

enum NodeCacheKeys {
  PROFILE = "profile",
  PROFILE_BADGES = "profile_badges",
  PROFILE_STATS = "profile_stats",
  PROFILE_COUNTRIES = "profile_countries",
  IDS = "ids",
  CLIENT = "client",
  FOOTBALL_COMPETITIONS = "football_competitions",
  FOOTBALL_COUNTIRES = "football_countries",
}

/**
 * TODO: As for now all data that is fetched from the memory cache has to be cast to concrete type.
 * There is an open PR with a solution to set the value type of the NodeCache class.
 * https://github.com/node-cache/node-cache/issues/273
 */
export default class ServerSideCache implements LocalCacheInterface {
  private static instance: ServerSideCache;
  private static nodeCache: NodeCache = new NodeCache();
  private readonly profileTTL: number = 600;
  private readonly clientTTL: number = 14_400;
  private readonly profileBadgesTTL: number = 3600;
  private idMappingsExpiration: number = 0;
  private idSchema: string = "";

  private constructor(idSchema: string) {}

  public static getInstance(idSchema: string): ServerSideCache {
    if (!ServerSideCache.instance) {
      ServerSideCache.instance = new ServerSideCache(idSchema);
      ServerSideCache.nodeCache.options.useClones = false;
    }

    return ServerSideCache.instance;
  }

  clearData = () => ServerSideCache.nodeCache.flushAll();

  getEntityById = (type: string, id: string) => {
    const idMappings = ServerSideCache.nodeCache.get(
      NodeCacheKeys.IDS
    ) as IdMappingModel[];

    if (!idMappings) return null;

    return idMappings.find((idObj: IdMappingModel) => {
      const idType = ID_TYPES[this.idSchema];

      return idObj && `${idObj[idType]}` === id && idObj.resource === type;
    });
  };

  getEntity = (ids: string | string[], type: string, schema: string) => {
    const idMappings = ServerSideCache.nodeCache.get(
      NodeCacheKeys.IDS
    ) as IdMappingModel[];
    if (idMappings) return Array.isArray(ids) ? [] : null;

    if (Array.isArray(ids)) {
      return idMappings.filter((idObj: IdMappingModel) => {
        const idType = ID_TYPES[schema];

        return (
          idObj && ids.includes(`${idObj[idType]}`) && idObj.resource === type
        );
      });
    } else {
      return idMappings.find((idObj: IdMappingModel) => {
        const idType = ID_TYPES[schema];

        return idObj && `${idObj[idType]}` === ids && idObj.resource === type;
      });
    }
  };

  checkForExistingIdObjs = (idsObj: any, toSchema: string) => {
    const schemaId = ID_TYPES[toSchema];
    let idsChecked = {};

    Object.keys(idsObj).forEach((key: string) => {
      const idsByResource = idsObj[key];
      const foundObjIds = this.getEntity(
        idsByResource,
        key,
        toSchema
      ) as IdMappingModel[];
      const foundIds = foundObjIds.map(
        (objId: IdMappingModel) => objId[schemaId]
      );
      const missingIds = idsObj[key].filter(
        (id: string) => !foundIds.includes(id)
      );
      idsChecked[key] = idsByResource.map((id: string) =>
        !missingIds.includes(id)
          ? { id: id, exists: true }
          : { id: id, exists: false }
      );
    });

    return idsChecked;
  };

  getIdsByType = (typeIdsObj: any, configSchema: string, toSchema: string) => {
    const toSchemaId = ID_TYPES[toSchema];
    const configSchemaId = ID_TYPES[configSchema];
    const idMappings = ServerSideCache.nodeCache.get(
      NodeCacheKeys.IDS
    ) as IdMappingModel[];
    let result = {};

    if (!idMappings) return null;

    Object.keys(typeIdsObj).forEach((key: string) => {
      const objIds = typeIdsObj[key];
      const ids: string[] = [];

      idMappings.forEach((idObj: IdMappingModel) => {
        objIds.forEach((id: string, idx: number) => {
          if (id === idObj[configSchemaId] && key === idObj.resource) {
            ids[idx] = idObj[toSchemaId];
          }
        });
      });

      result[key] = ids;
    });

    return result;
  };

  addIdMapping = (idMapping: IdMappingModel) => {
    const ids = ServerSideCache.nodeCache.get(
      NodeCacheKeys.IDS
    ) as IdMappingModel[];
    ids.push(idMapping);
    ServerSideCache.nodeCache.set(NodeCacheKeys.IDS, ids);
  };

  addIdMappings = (idMappings: IdMappingModel[]) => {
    let ids = ServerSideCache.nodeCache.get(
      NodeCacheKeys.IDS
    ) as IdMappingModel[];
    ids = [...ids, ...idMappings];
    ServerSideCache.nodeCache.set(NodeCacheKeys.IDS, ids);
  };

  addTopIdMappings = (topIdMappings: IdMappingModel[]) => {
    const idMappings = ServerSideCache.nodeCache.get(
      NodeCacheKeys.IDS
    ) as IdMappingModel[];
    const idMappingsExpiration = 604_800;

    if (idMappings) {
      let ids = JSON.parse(JSON.stringify(idMappings));
      ids = ids.filter(
        (idMapping: IdMappingModel) => idMapping.resource !== "match"
      );
      topIdMappings = topIdMappings.filter(
        (idMapping: IdMappingModel) => idMapping.resource === "match"
      );
      ids = [...ids, ...topIdMappings];
      ServerSideCache.nodeCache.set(NodeCacheKeys.IDS, ids);
    } else {
      ServerSideCache.nodeCache.set(NodeCacheKeys.IDS, topIdMappings);
    }

    this.idMappingsExpiration =
      Math.floor(Date.now() / 1000) + idMappingsExpiration; // Set 7 days expiration timestamp
  };

  setOwnProfile = (profile: ProfileModel) =>
    ServerSideCache.nodeCache.set(
      NodeCacheKeys.PROFILE,
      profile,
      this.profileTTL
    );

  getOwnProfile = (): ProfileModel => {
    return ServerSideCache.nodeCache.get(NodeCacheKeys.PROFILE) as ProfileModel;
  };

  getProfileCountries = () => {
    return ServerSideCache.nodeCache.get(
      NodeCacheKeys.PROFILE_COUNTRIES
    ) as ProfileCountryModel[];
  };

  setProfileCountries = (profileCountries: ProfileCountryModel[]) =>
    ServerSideCache.nodeCache.set(
      NodeCacheKeys.PROFILE_COUNTRIES,
      profileCountries
    );

  getProfileStats = () => {
    return ServerSideCache.nodeCache.get(
      NodeCacheKeys.PROFILE_STATS
    ) as ProfileStatsModel;
  };

  setProfileStats = (profileStats: ProfileStatsModel) =>
    ServerSideCache.nodeCache.set(
      NodeCacheKeys.PROFILE_STATS,
      profileStats,
      this.profileTTL
    );

  getProfileBadges = () => {
    return ServerSideCache.nodeCache.get(
      NodeCacheKeys.PROFILE_BADGES
    ) as string[];
  };

  setProfileBadges = (badges: string[]) => {
    ServerSideCache.nodeCache.set(
      NodeCacheKeys.PROFILE_BADGES,
      badges,
      this.profileBadgesTTL
    );
  };

  getFootballCountries = () => {
    return ServerSideCache.nodeCache.get(
      NodeCacheKeys.FOOTBALL_COUNTIRES
    ) as FootballCountryModel[];
  };

  setFootballCountries = (footballCountries: FootballCountryModel[]) =>
    ServerSideCache.nodeCache.set(
      NodeCacheKeys.FOOTBALL_COUNTIRES,
      footballCountries
    );

  getCompetitions = (filters?: CompetitionFilters) => {
    const footballCompetitions = ServerSideCache.nodeCache.get(
      NodeCacheKeys.FOOTBALL_COMPETITIONS
    ) as CompetitionBasicModel[];

    if (!footballCompetitions) return null

    if (filters) {
      return filters.competitionSearchFilter(footballCompetitions);
    }

    return footballCompetitions;
  };

  setCompetitions = (competitions: CompetitionBasicModel[]) =>
    ServerSideCache.nodeCache.set(
      NodeCacheKeys.FOOTBALL_COMPETITIONS,
      competitions
    );

  getCompetitionsLength = () => {
    const footballCompetitions = ServerSideCache.nodeCache.get(
      NodeCacheKeys.FOOTBALL_COMPETITIONS
    ) as CompetitionBasicModel[];

    return footballCompetitions ? footballCompetitions.length : 0;
  };

  getClientFeatures = () => {
    return ServerSideCache.nodeCache.get(NodeCacheKeys.CLIENT);
  };

  setClientFeatures = (features: Object) =>
    ServerSideCache.nodeCache.set(
      NodeCacheKeys.CLIENT,
      features,
      this.clientTTL
    );

  deleteClientFeatures = () =>
    ServerSideCache.nodeCache.del(NodeCacheKeys.CLIENT);

  getExpiration = (namespace: string, type: ExpirationType) => {
    const key = this.getKeyFromExpirationType(type);

    if (key === NodeCacheKeys.IDS) {
      return this.idMappingsExpiration;
    }

    return ServerSideCache.nodeCache.getTtl(key);
  };

  private getKeyFromExpirationType = (type: ExpirationType) => {
    switch (type) {
      case "profile":
        return NodeCacheKeys.PROFILE;
      case "statistics":
        return NodeCacheKeys.PROFILE_STATS;
      case "badges":
        return NodeCacheKeys.PROFILE_BADGES;
      case "features":
        return NodeCacheKeys.CLIENT;
      case "ids":
        return NodeCacheKeys.IDS;
    }
  };
}
