import { ActionsEnum } from "../../Enums/ActionsEnum";
import CampaignModel from "./Context/CampaignModel";
import ContentModel from "./Context/ContentModel";
import ContextModel from "./Context/ContextModel";
import TagsModel from "./Context/TagsModel";

export default class CreateActivityRequestBody {
    public action: ActionsEnum = null;
    public context: ContextModel = new ContextModel();

    constructor(action: ActionsEnum, tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel) {
        this.action = action;
        this.context.tags = tags;

        if (content) {
            this.context.content = content;
        }

        if (campaign) {
            this.context.campaign = campaign;
        }
    }
}