import IValidator from "./IValidator";
import {ValidationResponseEnum} from "../Enums/ValidationResponseEnum";
import {ValidationMessageEnum} from "../Enums/ValidationMessageEnum";
import {LogEnum} from "../Enums/LogEnum";
import SDKConfigurationModel from "../Models/SDKConfiguraitonModel";
import MessageLogModel from "../Models/MessageLogModel";
import ValidationItemModel from "../Models/ValidationItemModel";

export default class ApiKeyValidator implements IValidator {

    public validate(config: SDKConfigurationModel): ValidationItemModel {
        const validation = this.validateField(config.apiKey);
        const message = this.generateMessage(validation);

        return {validation, message};
    }

    private validateField = (apiKey: string) => {
        let validation: ValidationResponseEnum = null;

        if (apiKey !== undefined && apiKey !== null && apiKey.length > 0) {
            validation = ValidationResponseEnum.VALID_VALUE;
        } else {
            validation = ValidationResponseEnum.MISSING_VALUE;
        }

        return validation;
    };

    private generateMessage = (validation: ValidationResponseEnum): MessageLogModel => {
        let text: ValidationMessageEnum = null;
        let type: LogEnum = LogEnum.ERROR;

        if (validation === ValidationResponseEnum.MISSING_VALUE) {
            text = ValidationMessageEnum.API_KEY;
        }
        if (validation === ValidationResponseEnum.VALID_VALUE) {
            type = null;
        }


        return {text: text, type: type};
    };
}