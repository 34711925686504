import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import add from "../../../../assets/add.png";
import arrowDown from "../../../../assets/arrow-down.png";
import arrowUp from "../../../../assets/arrow-up.png";
import wrong from "../../../../assets/cancel.png";
import correct from "../../../../assets/checkmark.png";
import draw from "../../../../assets/draw.png";

import { MarketTitle, MarketType, MatchStatus } from "../../../../Utils";
import styles from "./BonusFooter.module.css";

const BonusFooter = ({ match, markets, onMarketSelected }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const canAddPrediction =
    match.status.type !== MatchStatus.FINISHED &&
    match.status.type !== MatchStatus.IN_PROGRESS;

  const points = markets
    .map((market) => market.result)
    .filter(
      (result) => result?.status === "SETTLED" || result?.status === "RESETTLED"
    )
    .map((settledResult) => settledResult?.points)
    ?.reduce((a, b) => a + b, 0);

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  const renderPrediction = (market) => {
    const hasResult =
      market.result?.status === "SETTLED" ||
      market.result?.status === "RESETTLED";
    const isCorrect = hasResult && market.result?.outcome === "CORRECT";

    switch (market.type) {
      case MarketType.HT_1X2:
        const logo =
          market.prediction === "1"
            ? match.homeTeam.assets.logo
            : market.prediction === "2"
            ? match.awayTeam.assets.logo
            : draw;
        return (
          <div className={styles.iconContainer}>
            <img className={styles.rowIcon} src={logo} alt={"team-icon"} />
            {hasResult && (
              <img
                className={styles.statusIcon}
                src={isCorrect ? correct : wrong}
                alt={"status-icon"}
              />
            )}
          </div>
        );
      case MarketType.OVER_CORNERS_8_5:
        return (
          <div className={styles.iconContainer}>
            <div className={styles.resultText}>
              {market.prediction === true ? t("over") : t("under")}
            </div>
            {hasResult && (
              <img
                className={styles.statusIcon}
                src={isCorrect ? correct : wrong}
                alt={"status-icon"}
              />
            )}
          </div>
        );
      case MarketType.PLAYER_SCORE_FIRST_GOAL:
        return (
          <div className={styles.iconContainer}>
            <div className={styles.resultText}>{market.prediction?.name}</div>
            {hasResult && (
              <img
                className={styles.statusIcon}
                src={isCorrect ? correct : wrong}
                alt={"status-icon"}
              />
            )}
          </div>
        );
      default:
        break;
    }
  };

  const Rows = ({ markets }) => (
    <div className={styles.rowsContainer}>
      {markets.map((market) => {
        const hasPrediction = market.prediction !== null;

        const rowStyleClasses = [styles.row];
        hasPrediction
          ? rowStyleClasses.push(styles.rowApplied)
          : !canAddPrediction
          ? rowStyleClasses.push(styles.rowDisabled)
          : rowStyleClasses.push("");

        return (
          <div
            key={market.type}
            className={rowStyleClasses.join(" ")}
            onClick={() => (canAddPrediction ? onMarketSelected(market) : null)}
          >
            <div className={styles.rowText}>{t(MarketTitle[market.type])}</div>
            {hasPrediction ? (
              renderPrediction(market)
            ) : (
              <img className={styles.rowIcon} src={add} alt={"add-icon"} />
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <div>
      {points >= 0 && !canAddPrediction && (
        <div className={styles.points}>{`+${points}Pts`}</div>
      )}
      <div className={styles.container}>
        <div className={styles.button} onClick={toggle}>
          <span className={styles.text}>{t("Get bonus points")}</span>
          <img
            src={isExpanded ? arrowUp : arrowDown}
            className={styles.icon}
            alt={`toggle-icon`}
          />
        </div>
      </div>
      {isExpanded && (
        <Rows
          markets={markets.filter(
            (market) => market.type !== MarketType.CORRECT_SCORE_ADVANCED
          )}
        />
      )}
    </div>
  );
};

export default BonusFooter;
