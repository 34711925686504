import FootballHttps from "../../../Https/FootballHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import TeamBasicModel from "../Models/Team/TeamBasicModel";
import TeamFullModel from "../Models/Team/TeamFullModel";
import MatchFullModel from "../Models/Match/MatchFullModel";
import TeamFilters from "../Models/Team/TeamFilters";
import FootballPaginationModel from "../Models/Pagination/FootballPaginationModel";
import FootballService from "../Service/FootballService";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import { sortArrayInAscOrder } from "../../../Global/Helper";

export default class TeamsFacade {
    private footballService: FootballService = null;
    private https: FootballHttps = null;
    private idMapping: IdMappingService = null;

    constructor(config: SDKConfigurationModel, https: FootballHttps, idMapping: IdMappingService) {
        this.idMapping = idMapping;
        this.https = https;
        this.footballService = new FootballService(config);
    }

    public getTeamsIdMapping = async (filters?: TeamFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        try {
            let isEmpty = false;
            if (filters) {
                filters = new TeamFilters(filters);
                if (filters.countryId) {
                    const countryId = await this.idMapping.getEntityIdsBySchemaId([`${filters.countryId}`], "country", "native");
                    if (countryId.country.length === 0) {
                        isEmpty = true;
                    }
                    filters.countryId = countryId.country[0];
                }
                if (filters.scope) {
                    const competitionIds = await this.idMapping.getEntityIdsBySchemaId(filters.scope, "competition", "native");
                    if (competitionIds.competition.length === 0) {
                        isEmpty = true;
                    }
                    filters.scope = competitionIds.competition;
                }
                if (filters.teamIds) {
                    const teamIds = await this.idMapping.getEntityIdsBySchemaId(filters.teamIds, "team", "native");
                    if (teamIds.team.length === 0) {
                        isEmpty = true;
                    }
                    filters.teamIds = teamIds.team;
                }
            }
            if (isEmpty) {
                //@ts-ignore
                return [];
            }
            const teams = await this.getTeams(filters, disableCache);
            teams.data = await this.idMapping.remapEntities(teams.data, 'team');

            return teams;
        } catch (e) {
            console.warn(`There was a problem browsing all teams`, e);
            throw e;
        }
    };

    public getTeamsMapWithNativeIds = async (teamIds: string[]): Promise<Map<string, TeamBasicModel>> => {
        let teamsMap = new Map<string, TeamBasicModel>();
        const limit = teamIds.length;
        const filters = new TeamFilters({ teamIds, limit });
        const { data } = await this.getTeams(filters);
        data.sort((a: TeamBasicModel, b: TeamBasicModel) => a.id.localeCompare(b.id));
        const sortedTeamIds = sortArrayInAscOrder(teamIds);
        const remappedTeams = await this.idMapping.remapEntities(data, "team");

        sortedTeamIds.forEach((id: string, idx: number) => teamsMap.set(id, remappedTeams[idx]));

        return teamsMap;
    };

    private getTeams = async (filters?: TeamFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.https.getTeams(filters, disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving all teams`, e);
            throw e;
        }
    };

    public getTeamByIdIdMapping = async (id: string, disableCache?: boolean): Promise<TeamFullModel> => {
        try {
            const nativeId = await this.idMapping.getEntityIdsBySchemaId([id], 'team', 'native');
            id = nativeId['team'][0];
            const team = await this.getTeamById(id, disableCache);
            return await this.idMapping.remapEntities(team, 'team');
        } catch (e) {
            console.warn(`There was a problem browsing the following team:${id}`, e);
            throw e;
        }
    };

    private getTeamById = async (id: string, disableCache?: boolean): Promise<TeamFullModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.https.getTeamById(id, disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving the following team: ${id}`, e);
            throw e;
        }
    };

    public getTopTeamsIdMapping = async (disableCache?: boolean): Promise<TeamBasicModel[]> => {
        try {
            const topTeams = await this.getTopTeams(disableCache);
            return await this.idMapping.remapEntities(topTeams, 'team');
        } catch (e) {
            console.warn('There was a problem browsing the top teams', e);
            throw e;
        }
    };

    private getTopTeams = async (disableCache?: boolean): Promise<TeamBasicModel[]> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.https.getTopTeams(disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving the top teams`, e);
            throw e;
        }
    };

    public getNextMatchForTeamIdMapping = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        try {
            const nativeId = await this.idMapping.getEntityIdsBySchemaId([id], 'team', 'native');
            id = nativeId['team'][0];
            const match = await this.getNextMatchForTeam(id, disableCache);
            return await this.idMapping.remapEntities(match, 'match');
        } catch (e) {
            console.warn(`There was a problem browsing the next match for the following team:${id}`, e);
            throw e;
        }
    };

    private getNextMatchForTeam = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;
            const nextMatchForTeam = await this.https.getNextMatchForTeam(id, disableCache);

            return await this.footballService.setAvailableMarketsForMatch(nextMatchForTeam) as MatchFullModel;
        } catch (e) {
            console.warn(`There was a problem retrieving the next match for the following team: ${id}`, e);
            throw e;
        }
    };

    public getPrevMatchForTeamIdMapping = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        try {
            const nativeId = await this.idMapping.getEntityIdsBySchemaId([id], 'team', 'native');
            id = nativeId['team'][0];
            const match = await this.getPrevMatchForTeam(id, disableCache);
            return await this.idMapping.remapEntities(match, 'match');
        } catch (e) {
            console.warn(`There was a problem browsing the previous match for the following team:${id}`, e);
            throw e;
        }
    };

    private getPrevMatchForTeam = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;
            const prevMatchForTeam = await this.https.getPrevMatchForTeam(id, disableCache);

            return await this.footballService.setAvailableMarketsForMatch(prevMatchForTeam) as MatchFullModel;
        } catch (e) {
            console.warn(`There was a problem retrieving the previous match for the following team: ${id}`, e);
            throw e;
        }
    };
}
