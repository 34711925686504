import MatchBasicModel from "../../../Football/Models/Match/MatchBasicModel";
import MatchStatus from "../../../Football/Models/Match/Status/MatchStatus";
import { MarketEnum } from "../../../Predictor/Enums/MarketEnum";
import PredictionTopXModel from "../Prediction/PredictionTopXModel";

export default class FixturesTopXModel {
    public matchId: string = null;
    public matchType: string = null;
    public market: MarketEnum = null;
    public matchModel: MatchBasicModel = null;
    public matchStatus: MatchStatus = new MatchStatus();
    public prediction: PredictionTopXModel = new PredictionTopXModel();
}