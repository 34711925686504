import FixturesGamesModel from "../Fixtures/FixturesGamesModel";
import FixturesMatchQuizModel from "../../../MatchQuiz/Models/Fixtures/FixturesMatchQuizModel";
import FixturesTopXModel from "../Fixtures/FixturesTopXModel";
import GameModel from "./GameModel";
import GameResultsModel from "./GameResultsModel";
import GamesListModel from "./GamesListModel";
import GamesMatchQuizListModel from "../../../MatchQuiz/Models/Games/GamesMatchQuizListModel";
import GamesTopXListModel from "./GamesTopXListModel"
import TiebreakerModel from "./TiebreakerModel";
import PaginationModel from "../../../../Global/Models/Pagination/PaginationModel";
import GameMarketsResults, { MarketsResults, TieBreakersType, GoldenGoalsType } from "../../../MatchQuiz/Models/GameMarketsResults/GameMarketsResults";
import { endMarkMarkets, MarketEnum, playerMarkets } from "../../../Predictor/Enums/MarketEnum";
import TimeTiebreaker from "./TimeTiebreaker";
import RelatedEntity from "../../../../Global/Models/Related/RelatedEntity";
import ContestWinners from "./Winners/ContestWinners";
import UserListWinners from "./Winners/UserListWinners";
import MatchStatus from "../../../Football/Models/Match/Status/MatchStatus";
import GameByIdModel from "./GameByIdModel";
import BaseRemapper from "../../../../Global/Remapper/BaseRemapper";

export default class GamesRemapper extends BaseRemapper {

    public remapPaginatedGames = (paginatedGames: any) => {
        let paginatedGamesModel = new PaginationModel();

        paginatedGamesModel.meta.pagination.nextPageStartsAfter = paginatedGames.meta.pagination.next_page_starts_after;
        paginatedGamesModel.meta.pagination.itemsPerPage = paginatedGames.meta.pagination.items_per_page;
        paginatedGamesModel.data = paginatedGames.data.map((game: any) => this.remapGamesListResponse(game));

        return paginatedGamesModel;
    };

    public remapGamesListResponse = (gamesList: any) => {
        let gamesListModel = new GamesListModel();

        gamesListModel.id = gamesList.id;
        gamesListModel.title = gamesList.title;
        gamesListModel.description = gamesList.description;
        gamesListModel.type = gamesList.type;
        gamesListModel.status = gamesList.status;
        gamesListModel.scheduleOpenAt = gamesList.schedule_open_at;
        gamesListModel.predictionsCutoff = gamesList.predictions_cutoff;
        gamesListModel.fixtures = gamesList.fixtures.map((fixture: any) => this.remapFixturesResponse(fixture));
        gamesListModel.tiebreaker = gamesList.tiebreaker ? this.remapTimeTiebreaker(gamesList.tiebreaker.time_tiebreaker) : null;
        gamesListModel.participantsCount = gamesList.participants_count;
        gamesListModel.related = gamesList.related ? this.remapRelated(gamesList.related) : null;
        gamesListModel.excludedProfileIds = gamesList.excluded_profile_ids;
        gamesListModel.rules = gamesList.rules;
        gamesListModel.labels = gamesList.labels
        gamesListModel.customFields = gamesList.custom_fields;
        gamesListModel.branding = gamesList.branding ? this.remapBranding(gamesList.branding) : null;
        gamesListModel.flags = gamesList.flags;
        gamesListModel.images = gamesList.images;
        gamesListModel.createdAt = gamesList.created_at;
        gamesListModel.updatedAt = gamesList.updated_at;

        return gamesListModel;
    };

    public remapGameByIdResponse = (gameById: any) => {
        const gameByIdModel = new GameByIdModel();

        gameByIdModel.id = gameById.id;
        gameByIdModel.title = gameById.title;
        gameByIdModel.description = gameById.description;
        gameByIdModel.type = gameById.type;
        gameByIdModel.status = gameById.status;
        gameByIdModel.scheduleOpenAt = gameById.schedule_open_at;
        gameByIdModel.predictionsCutoff = gameById.predictions_cutoff;
        gameByIdModel.fixtures = gameById.fixtures.map((fixture: any) => this.remapFixturesResponse(fixture));
        gameByIdModel.tiebreaker = gameById.tiebreaker ? this.remapTimeTiebreaker(gameById.tiebreaker.time_tiebreaker) : null;
        gameByIdModel.participantsCount = gameById.participants_count;
        gameByIdModel.related = gameById.related ? this.remapRelated(gameById.related) : null;
        gameByIdModel.excludedProfileIds = gameById.excluded_profile_ids;
        gameByIdModel.rules = gameById.rules;
        gameByIdModel.flags = gameById.flags;
        gameByIdModel.adContent = gameById.ad_content;
        gameByIdModel.labels = gameById.labels;
        gameByIdModel.customFields = gameById.custom_fields;
        gameByIdModel.branding = gameById.branding ? this.remapBranding(gameById.branding) : null;
        gameByIdModel.images = gameById.images;
        gameByIdModel.createdAt = gameById.created_at;
        gameByIdModel.updatedAt = gameById.updated_at;

        return gameByIdModel;
    };

    private remapFixturesResponse = (fixtures: any) => {
        let fixturesModel = new FixturesGamesModel();

        fixturesModel.matchId = fixtures.match_id;
        fixturesModel.matchType = fixtures.match_type;
        fixturesModel.market = fixtures.market;
        fixturesModel.matchStatus.type = fixtures.match_status ? fixtures.match_status.type : null;
        fixturesModel.matchStatus.subType = fixtures.match_status ? fixtures.match_status.sub_type : null;

        return fixturesModel;
    };

    public remapPaginatedUserTopXEditions = (userGameEditions: any) => {
        let paginatedGamesModel = new PaginationModel();

        paginatedGamesModel.meta.pagination.nextPageStartsAfter = userGameEditions.meta.pagination.next_page_starts_after;
        paginatedGamesModel.meta.pagination.itemsPerPage = userGameEditions.meta.pagination.items_per_page;
        paginatedGamesModel.data = userGameEditions.data.map((game: any) => this.remapUserTopXEditionsResponse(game));

        return paginatedGamesModel;
    };

    public remapUserTopXEditionsResponse = (gamesList: any) => {
        let gamesListModel = new GamesTopXListModel();

        gamesListModel.id = gamesList.id;
        gamesListModel.title = gamesList.title;
        gamesListModel.description = gamesList.description;
        gamesListModel.type = gamesList.type;
        gamesListModel.status = gamesList.status;
        gamesListModel.predictionsCutoff = gamesList.predictions_cutoff;
        gamesListModel.scheduleOpenAt = gamesList.schedule_open_at;
        gamesListModel.fixtures = gamesList.fixtures.map((fixture: any) => this.remapTopXFixturesResponse(fixture));
        gamesListModel.tiebreaker = gamesList.tiebreaker ? this.remapTimeTiebreaker(gamesList.tiebreaker.time_tiebreaker) : null;
        gamesListModel.participantsCount = gamesList.participants_count;
        gamesListModel.related = gamesList.related ? this.remapRelated(gamesList.related) : null;
        gamesListModel.excludedProfileIds = gamesList.excluded_profile_ids;
        gamesListModel.rules = gamesList.rules;
        gamesListModel.flags = gamesList.flags;
        gamesListModel.images = gamesList.images;
        gamesListModel.branding = gamesList.branding ? this.remapBranding(gamesList.branding) : null;
        gamesListModel.createdAt = gamesList.created_at;
        gamesListModel.updatedAt = gamesList.updated_at;

        return gamesListModel;
    };

    private remapTopXFixturesResponse = (fixtures: any) => {
        let fixturesModel = new FixturesTopXModel();

        fixturesModel.matchId = fixtures.match_id;
        fixturesModel.matchType = fixtures.match_type;
        fixturesModel.market = fixtures.market;
        fixturesModel.matchStatus.type = fixtures.match_status ? fixtures.match_status.type : null;
        fixturesModel.matchStatus.subType = fixtures.match_status ? fixtures.match_status.sub_type : null;

        return fixturesModel;
    };

    public remapPaginatedUserMathQuizEditions = (userGameEditions: any) => {
        let paginatedGamesModel = new PaginationModel();

        paginatedGamesModel.meta.pagination.nextPageStartsAfter = userGameEditions.meta.pagination.next_page_starts_after;
        paginatedGamesModel.meta.pagination.itemsPerPage = userGameEditions.meta.pagination.items_per_page;
        paginatedGamesModel.data = userGameEditions.data.map((game: any) => this.remapUserMatchQuizEditionsResponse(game));

        return paginatedGamesModel;
    };

    public remapUserMatchQuizEditionsResponse = (gamesList: any) => {
        let gamesListModel = new GamesMatchQuizListModel();

        gamesListModel.id = gamesList.id;
        gamesListModel.title = gamesList.title;
        gamesListModel.description = gamesList.description;
        gamesListModel.type = gamesList.type;
        gamesListModel.status = gamesList.status;
        gamesListModel.predictionsCutoff = gamesList.predictions_cutoff;
        gamesListModel.scheduleOpenAt = gamesList.schedule_open_at;
        gamesListModel.fixtures = gamesList.fixtures.map((fixture: any) => this.remapMatchQuizFixturesResponse(fixture));
        gamesListModel.tiebreaker = gamesList.tiebreaker ? this.remapTimeTiebreaker(gamesList.tiebreaker.time_tiebreaker) : null;
        gamesListModel.participantsCount = gamesList.participants_count;
        gamesListModel.related = gamesList.related ? this.remapRelated(gamesList.related) : null;
        gamesListModel.excludedProfileIds = gamesList.excluded_profile_ids;
        gamesListModel.rules = gamesList.rules;
        gamesListModel.flags = gamesList.flags;
        gamesListModel.images = gamesList.images;
        gamesListModel.branding = gamesList.branding ? this.remapBranding(gamesList.branding) : null;
        gamesListModel.createdAt = gamesList.created_at;
        gamesListModel.updatedAt = gamesList.updated_at;

        return gamesListModel;
    };

    private remapMatchQuizFixturesResponse = (fixtures: any) => {
        let fixturesModel = new FixturesMatchQuizModel();

        fixturesModel.matchId = fixtures.match_id;
        fixturesModel.matchType = fixtures.match_type;
        fixturesModel.market = fixtures.market;
        fixturesModel.matchStatus.type = fixtures.match_status ? fixtures.match_status.type : null;
        fixturesModel.matchStatus.subType = fixtures.match_status ? fixtures.match_status.sub_type : null;

        return fixturesModel;
    };

    public remapPaginatedGameResults = (gameResultsResponse: any) => {
        let paginatedGameResults = new PaginationModel();

        paginatedGameResults.meta.pagination.nextPageStartsAfter = gameResultsResponse.meta.pagination.next_page_starts_after;
        paginatedGameResults.meta.pagination.itemsPerPage = gameResultsResponse.meta.pagination.items_per_page;
        paginatedGameResults.data = gameResultsResponse.data.map((gameResult: any) => this.remapGameResults(gameResult));

        return paginatedGameResults;
    };

    public remapGameResults = (gameResults: any) => {
        let gameModel = new GameModel();

        gameModel.userId = gameResults.user_id;
        gameModel.points = gameResults.points ? gameResults.points : 0;
        gameModel.results = gameResults.results.map((result: any) => this.remapResults(result));
        gameModel.tiebreaker = gameResults.tiebreaker ? this.remapTiebreaker(gameResults.tiebreaker) : null;

        return gameModel;
    };

    public remapGameMarketsResults = (gameMarketsResultsResponse: any): GameMarketsResults  => {
        const gameMarketsResults = new GameMarketsResults();

        gameMarketsResults.gameId = gameMarketsResultsResponse.game_id;
        gameMarketsResults.results = this.remapMarketsResults(gameMarketsResultsResponse.results);
        gameMarketsResults.tieBreakers = gameMarketsResultsResponse.tie_breakers ? this.remapTieBreakers(gameMarketsResultsResponse.tie_breakers) : null;

        return gameMarketsResults;
    };

    public remapContestWinners = (contestWinnersResponse: any) => {
        const contestWinners = new ContestWinners();

        contestWinners.contestId = contestWinnersResponse.contest_id;
        contestWinners.contestType = contestWinnersResponse.contest_type;
        contestWinners.description = contestWinnersResponse.description;
        contestWinners.userList = contestWinnersResponse.user_list ? contestWinnersResponse.user_list.map((value: any) => this.remapUserListWinners(value)) : null;

        return contestWinners;
    };

    private remapUserListWinners = (userList: any) => {
        const userListModel = new UserListWinners();

        userListModel.position = userList.position;
        userListModel.profileId = userList.profile_id;
        userListModel.note = userList.note;
        userListModel.tags = userList.tags;

        return userListModel;
    };

    private remapTieBreakers = (tieBreakersResponse: any) => {
        let tieBreakers: TieBreakersType = {
            goldenGoals: []
        };

        tieBreakers.goldenGoals = tieBreakersResponse.golden_goals.map((response: any) => {
            const model: GoldenGoalsType = {
                minute: response.minute,
                matchId: response.match_id,
                matchModel: null,
                playerId: response.player_id,
                playerModel: null,
                goalType: response.goal_type
            }

            return model;
        })

        return tieBreakers;
    }

    private remapMarketsResults = (results: any[]): MarketsResults[] => {
        let newResults: MarketsResults[] = [];

        results.forEach((result: any) => {
            const newResult: MarketsResults = {
                matchId: result.match_id,
                market: result.market,
                result: this.remapMarketResult(result.market, result.result),
                matchModel: null,
                playerModels: [],
                matchStatus: this.remapMarketsResultsMatchStatus(result.match_status)
            };

            newResults.push(this.deleteUnusefulField(newResult));
        })

        return newResults;
    };

    private remapMarketsResultsMatchStatus = (resultMatchStatus: any) => {
        const matchStatus = new MatchStatus();

        if (resultMatchStatus) {
            matchStatus.type = resultMatchStatus.type;
            matchStatus.subType = resultMatchStatus.sub_type;
        }

        return matchStatus;
    };

    private remapMarketResult = (market: MarketEnum, marketResult: any[]): string[] | string | boolean => {
        if (endMarkMarkets.includes(market)) {
            return marketResult[0] as string;
        }

        if (playerMarkets.includes(market)) {
            return marketResult as string[];
        }

        if (marketResult[0] === "true") {
            return true;
        }

        if (marketResult[0] === "false") {
            return false;
        }
    };

    private deleteUnusefulField = (marketsResults: MarketsResults): MarketsResults => {
        if (!playerMarkets.includes(marketsResults.market) || this.isPlayerMarketWithNoResult(marketsResults.market, marketsResults.result as string[])) {
            delete marketsResults.playerModels;
        }

        return marketsResults;
    };

    private isPlayerMarketWithNoResult = (market: MarketEnum, result: string[]): boolean => playerMarkets.includes(market) && result.length  === 0;

    private remapResults = (results: any) => {
        let gameResultsModel = new GameResultsModel();

        gameResultsModel.matchId = results.match_id;
        gameResultsModel.outcome = results.outcome;

        return gameResultsModel;
    };

    private remapTiebreaker = (tiebreaker: any) => {
        let tiebreakerModel = new TiebreakerModel();

        tiebreakerModel.goldenGoal = tiebreaker.golden_goal;

        return tiebreakerModel;
    };

    private remapTimeTiebreaker = (timeTiebreaker: boolean) => {
        const timeTiebreakerModel = new TimeTiebreaker();

        timeTiebreakerModel.timeTiebreaker = timeTiebreaker;

        return timeTiebreakerModel;
    };

    private remapRelated = (related: any[]) => {
        return related.map((value: {[key: string]: string}) => {
            const model = new RelatedEntity();

            model.entityId = value.entity_id;
            model.entityType = value.entity_type;

            return model;
        });
    };
}