import { useEffect, useState } from 'react';
import { FansUnitedSDK } from 'fansunited-sdk-esm';

import { useSelector } from 'react-redux';

import { API_KEY, CLIENT_ID } from '../api/constants';
import { getUserToken } from '../redux/user';
import { getUserLanguage } from '../redux/user/selectors';

function getTokenProvider(tokenId) {
  this.getIdToken = function () {
    return tokenId;
  };

  this.logout = function () {
    console.log('LOGOUT');
  };
}

const useFansUnitedSDK = () => {
  const [fansUnitedSDK, setFansUnitedSDK] = useState(null);

  const tokenId = useSelector(getUserToken);
  const language = useSelector(getUserLanguage);

  useEffect(() => {
    if (!tokenId) return;

    setFansUnitedSDK(
      FansUnitedSDK({
        apiKey: API_KEY,
        environment: 'prod',
        clientId: CLIENT_ID,
        lang: language,
        idSchema: 'native',
        errorHandlingMode: 'default',
        authProvider: new getTokenProvider(tokenId),
      }),
    );
  }, [tokenId]);

  return fansUnitedSDK;
};

export default useFansUnitedSDK;