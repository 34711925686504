import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './TabsNavigation.module.css';

const TabsNavigation = ({ onTabSelect, selectedTab }) => {
	const [isCentered, setIsCentered] = useState(false);

	const { t } = useTranslation();

	const pages = [t('Play'), t('Ranking'), t('Prizes'), t('Rules'), t('Leagues'), t('Badges')];

  useEffect(() => {
    if (!isCentered && document.getElementById(selectedTab)) {
      setIsCentered(true);
      document.getElementById(selectedTab)?.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'center' });
    }
  });
  
  const handleTabSelect = (tab) => {
    onTabSelect(tab);
    document.getElementById(tab).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  };

	return (
		<div className={styles.container}>
			{pages.map((page, index) => (
				<div 
					key={index} 
					id={index}
					className={[styles.tab, selectedTab === index ? styles.tabSelected : ''].join(' ')} 
					onClick={() => handleTabSelect(index)}>
						{page}
				</div>
			))}
		</div>
	);
}

export default TabsNavigation;