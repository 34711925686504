import { MarketEnum } from "../../../Enums/MarketEnum";
import { HalfTimeFullTimeEnum } from "../../../Enums/ValueEnums";
import ValueHalfTimeFullTime from "../../Value/ValueHalfTimeFullTime";
import FixturesModel from "../FixturesModel";

export default class HalfTimeFullTimeFixtureModel implements FixturesModel {
    public matchId: string = null;
    public market: MarketEnum = MarketEnum.HT_FT;
    public matchType: string = "FOOTBALL";
    public prediction: ValueHalfTimeFullTime = null;

    constructor(matchId: string, value: HalfTimeFullTimeEnum) {
        this.matchId = matchId;
        this.prediction = new ValueHalfTimeFullTime();
        this.prediction.value = value;
    }
}