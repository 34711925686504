import {IRemapper} from "../../../../../Global/IRemapper";
import StatsModel from "./StatsModel";
import {StatType} from "../StatType";

export default class StatsRemapper implements IRemapper<StatsModel>{

    fromResponse(response: any): StatsModel {
        try {
            const model = new StatsModel();
            model.corners = this.statEntityFromResponse(response.corners);
            model.cornersFirstHalf = this.statEntityFromResponse(response.corners_1h);
            model.cornersSecondHalf = this.statEntityFromResponse(response.corners_2h);
            model.cornersExtraTime = this.statEntityFromResponse(response.corners_et);
            model.crosses = this.statEntityFromResponse(response.crosses);
            model.crossesFirstHalf = this.statEntityFromResponse(response.crosses_1h);
            model.crossesSecondHalf = this.statEntityFromResponse(response.crosses_2h);
            model.crossesExtraTime = this.statEntityFromResponse(response.crosses_et);
            model.offside = this.statEntityFromResponse(response.offside);
            model.offsideFirstHalf = this.statEntityFromResponse(response.offside_1h);
            model.offsideSecondHalf = this.statEntityFromResponse(response.offside_2h);
            model.offsideExtraTime = this.statEntityFromResponse(response.offside_et);
            model.shotsOn = this.statEntityFromResponse(response.shots_on);
            model.shotsOnFirstHalf = this.statEntityFromResponse(response.shots_on_1h);
            model.shotsOnSecondHalf = this.statEntityFromResponse(response.shots_on_2h);
            model.shotsOnExtraTime = this.statEntityFromResponse(response.shots_on_et);
            model.throwIn = this.statEntityFromResponse(response.throw_in);
            model.throwInFirstHalf = this.statEntityFromResponse(response.throw_in_1h);
            model.throwInSecondHalf = this.statEntityFromResponse(response.throw_in_2h);
            model.throwInExtraTime = this.statEntityFromResponse(response.throw_in_et);
            model.redCards = this.statEntityFromResponse(response.red_cards);
            model.redCardsFirstHalf = this.statEntityFromResponse(response.red_cards_1h);
            model.redCardsSecondHalf = this.statEntityFromResponse(response.red_cards_2h);
            model.redCardsExtraTime = this.statEntityFromResponse(response.red_cards_et);
            model.shotsOff = this.statEntityFromResponse(response.shots_off);
            model.shotsOffFirstHalf = this.statEntityFromResponse(response.shots_off_1h);
            model.shotsOffSecondHalf = this.statEntityFromResponse(response.shots_off_2h);
            model.shotsOffExtraTime = this.statEntityFromResponse(response.shots_off_et);
            model.goalKicks = this.statEntityFromResponse(response.goal_kicks);
            model.goalKicksFirstHalf = this.statEntityFromResponse(response.goal_kicks_1h);
            model.goalKicksSecondHalf = this.statEntityFromResponse(response.goal_kicks_2h);
            model.goalKicksExtraTime = this.statEntityFromResponse(response.goal_kicks_et);
            model.possession = this.statEntityFromResponse(response.possession);
            model.possessionFirstHalf = this.statEntityFromResponse(response.possession_1h);
            model.possessionSecondHalf = this.statEntityFromResponse(response.possession_2h);
            model.possessionExtraTime = this.statEntityFromResponse(response.possession_et);
            model.treatments = this.statEntityFromResponse(response.treatments);
            model.treatmentsFirstHalf = this.statEntityFromResponse(response.treatments_1h);
            model.treatmentsSecondHalf = this.statEntityFromResponse(response.treatments_2h);
            model.treatmentsExtraTime = this.statEntityFromResponse(response.treatments_et);
            model.yellowCards = this.statEntityFromResponse(response.yellow_cards);
            model.yellowCardsFirstHalf = this.statEntityFromResponse(response.yellow_cards_1h);
            model.yellowCardsSecondHalf = this.statEntityFromResponse(response.yellow_cards_2h);
            model.yellowCardsExtraTime = this.statEntityFromResponse(response.yellow_cards_et);
            model.shotsBlocked = this.statEntityFromResponse(response.shots_blocked);
            model.shotsBlockedFirstHalf = this.statEntityFromResponse(response.shots_blocked_1h);
            model.shotsBlockedSecondHalf = this.statEntityFromResponse(response.shots_blocked_2h);
            model.shotsBlockedExtraTime = this.statEntityFromResponse(response.shots_blocked_et);
            model.substitutions = this.statEntityFromResponse(response.substitutions);
            model.substitutionsFirstHalf = this.statEntityFromResponse(response.substitutions_1h);
            model.substitutionsSecondHalf = this.statEntityFromResponse(response.substitutions_2h);
            model.substitutionsExtraTime = this.statEntityFromResponse(response.substitutions_et);
            model.counterAttacks = this.statEntityFromResponse(response.counter_attacks);
            model.counterAttacksFirstHalf = this.statEntityFromResponse(response.counter_attacks_1h);
            model.counterAttacksSecondHalf = this.statEntityFromResponse(response.counter_attacks_2h);
            model.counterAttacksExtraTime = this.statEntityFromResponse(response.counter_attacks_et);
            model.foulsCommitted = this.statEntityFromResponse(response.fouls_committed);
            model.foulsCommittedFirstHalf = this.statEntityFromResponse(response.fouls_committed_1h);
            model.foulsCommittedSecondHalf = this.statEntityFromResponse(response.fouls_committed_2h);
            model.foulsCommittedExtraTime = this.statEntityFromResponse(response.fouls_committed_et);

            return model;
        } catch (e) {
            console.warn("There was a problem remapping the stats", e);
            throw e;
        }
    }

    private statEntityFromResponse = (entity: any): StatType => {
        return { homeTeam: entity ? entity.home_team : null, awayTeam: entity ? entity.away_team : null};
    };

}