import {IRemapper} from "../../../../../Global/IRemapper";
import LineupsModel from "./LineupsModel";
import PlayerShortRemapper from "../../Player/Remappers/PlayerShortRemapper";

export default class LineupsRemapper implements IRemapper<LineupsModel> {
    private playerRemapper: PlayerShortRemapper = null;

    constructor() {
        this.playerRemapper = new PlayerShortRemapper();
    }

    fromResponse(response: any): LineupsModel {
        try {
            const model: LineupsModel = new LineupsModel();
            model.homeTeam.players = response.home_team && response.home_team.players ? response.home_team.players.map((player: any) => this.playerRemapper.fromResponse(player)) : [];
            model.awayTeam.players = response.away_team && response.away_team.players ? response.away_team.players.map((player: any) => this.playerRemapper.fromResponse(player)) : [];

            return model;
        } catch (e) {
            console.warn("There was a problem remapping the lineups", e);
            throw e;
        }
    };

}