import MiniGamesFilters from "../Filters/MiniGamesFilters";

export default class EitherOrFilters extends MiniGamesFilters {
  eitherOrIds?: string[] = null;

  constructor(filters: any) {
    super(filters);
    this.eitherOrIds =
      filters && filters.eitherOrIds ? filters.eitherOrIds : null;
  }

  public constructMainFiltersUrl?() {
    let url = super.constructMainFiltersUrl();

    if (this.eitherOrIds && this.eitherOrIds.length > 0) {
      url += `&either_or_ids=${this.eitherOrIds.join(",")}`;
    }

    return url;
  }
}
