import IValidator from "./IValidator";
import {ValidationResponseEnum} from "../Enums/ValidationResponseEnum";
import {ValidationMessageEnum} from "../Enums/ValidationMessageEnum";
import {LogEnum} from "../Enums/LogEnum";
import SDKConfigurationModel from "../Models/SDKConfiguraitonModel";
import MessageLogModel from "../Models/MessageLogModel";
import ValidationItemModel from "../Models/ValidationItemModel";

export default class ClientIdValidator implements IValidator {

    public validate(config: SDKConfigurationModel): ValidationItemModel {
        const validation = this.validateField(config.clientId);
        const message = this.generateMessage(validation);

        return {validation, message};
    }

    private validateField = (clientId: string) => {
        let validation: ValidationResponseEnum = null;

        if (clientId !== undefined && clientId !== null && clientId.length > 0) {
            validation = ValidationResponseEnum.VALID_VALUE;
        } else {
            validation = ValidationResponseEnum.MISSING_VALUE;
        }

        return validation;
    };

    private generateMessage = (validation: ValidationResponseEnum): MessageLogModel => {
        let text: ValidationMessageEnum = null;
        let type: LogEnum = LogEnum.ERROR;

        if (validation === ValidationResponseEnum.MISSING_VALUE) {
            text = ValidationMessageEnum.CLIENT_ID;
        }
        if (validation === ValidationResponseEnum.VALID_VALUE) {
            text = null;
            type = null;
        }


        return {text: text, type: type};
    };
}