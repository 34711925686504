/**
 * Main filter type for API endpoints who use BigQuery and return paginated responses
 */
export default class MainFiltersBQ {
  public limit?: number;
  public startAfter?: string;

  constructor(filters: any) {
    this.limit = filters && filters.limit ? filters.limit : null;
    this.startAfter = filters && filters.startAfter ? filters.startAfter : null;
  }

  public constructMainFiltersUrl? (): string {
    let url = "";

    if (this.limit) {
      url += "&";
      url += `limit=${this.limit}`;
    }
    if (this.startAfter) {
      url += "&";
      url += `start_after=${this.startAfter}`;
    }

    return url;
  };
}
