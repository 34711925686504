import ChallengeCreateModel from "../../Challenges/Models/ChallengeCreateModel";
import PrivateLeagueCreateModel from "../Models/Request/Create/PrivateLeagueCreateModel";
import PrivateLeagueParamCreateBody from "../Models/PrivateLeagueParamCreateBody";
import PrivateLeagueParamUpdateBody from "../Models/PrivateLeagueParamUpdateBody";
import PrivateLeagueUpdateModel from "../Models/Request/Update/PrivateLeagueUpdateModel";
import ChallengeUpdateModel from "../../Challenges/Models/ChallengeUpdateModel";

export default class PrivateLeaguesFactory {
  public createRequestBodyFromParam = (
    param: PrivateLeagueParamCreateBody,
    typeLeague: "PRIVATE" | "ONE_VS_ONE"
  ) => {
    const model =
      typeLeague === "PRIVATE"
        ? new PrivateLeagueCreateModel()
        : new ChallengeCreateModel();

    model.name = param.name;
    model.description = param.description;
    model.invitation_code = param.invitationCode;
    model.template_id = param.templateId;
    model.users_can_invite_users = param.usersCanInviteUsers;

    if (param.administrators) {
      model.administrators = param.administrators;
    } else {
      delete model.administrators;
    }

    if (param.scoringStartsAt) {
      model.scoring_starts_at = param.scoringStartsAt;
    } else {
      delete model.scoring_starts_at;
    }

    return model;
  };

  public createUpdateRequestBodyFromParam = (
    param: PrivateLeagueParamUpdateBody,
    typeLeague: "PRIVATE" | "ONE_VS_ONE"
  ) => {
    const model =
      typeLeague === "PRIVATE"
        ? new PrivateLeagueUpdateModel()
        : new ChallengeUpdateModel();

    model.name = param.name;
    model.description = param.description;
    model.invitation_code = param.invitationCode;
    model.template_id = param.templateId;
    model.users_can_invite_users = param.usersCanInviteUsers;
    model.administrators = param.administrators;
    model.pinned_posts = param.pinnedPosts;
    model.scoring_starts_at = param.scoringStartsAt;

    return model;
  };
}
