import ProfileCountryModel from "../Models/ProfileCountryModel";
import ProfileHttps from "../../../Https/ProfileHttps";
import ProfileFacade from "../Facade/ProfileFacade";
import FollowingModel from "../Models/Following/FollowingModel";
import InterestModel from "../Models/InterestModel";
import ProfileModel from "../Models/ProfileModel";
import FullnessProfile from "../Models/FullnessProfile/FullnessProfile";
import FollowFilters from "../Models/FollowFilters";
import PaginationModel from "../../../Global/Models/Pagination/PaginationModel";
import ProfileStatsModel from "../Models/Stats/ProfileStatsModel";
import ActivityHttps from "../../../Https/ActivityHttps";
import ProfileService from "../Service/ProfileService";
import SDKIdsRemapper from "../../../Remapper/SDKIdsRemapper";
import { ErrorHandlingModeType } from "../../../Configurator/Types/ConfiguratorTypes";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import { ErrorMessages } from "../../../Global/Messages/Messages";
import { LocalCacheInterface } from "../../../Global/Interfaces/GlobalInterfaces";

export default class OwnProfileBuilder {
    private idsRemapper: SDKIdsRemapper = null;
    private profileHttps: ProfileHttps = null;
    private profileService: ProfileService = null;
    private localCache: LocalCacheInterface = null;
    private profileFacade: ProfileFacade = null;
    private ownProfile: ProfileModel = null;
    private modified: boolean = false;
    private expireAt: number = 0;
    private promises: Promise<any>[] = [];
    private isFetching: boolean = false;
    private errorHandlingMode: ErrorHandlingModeType = null;

    constructor(idsRemapper: SDKIdsRemapper, localCache: LocalCacheInterface, profileHttps: ProfileHttps, activityHttps: ActivityHttps,
        errorHandlingMode: ErrorHandlingModeType) {
        this.idsRemapper = idsRemapper;
        this.localCache = localCache;
        this.profileHttps = profileHttps;
        this.errorHandlingMode = errorHandlingMode;
        this.profileService = new ProfileService();
        this.profileFacade = new ProfileFacade(localCache, profileHttps, activityHttps);
    }

    private getOwnProfile = async () => {
        while (this.isFetching) {
            console.log("Another thread is fetching profile...");
            await this.delay(100);
        }

        if (this.modified && this.ownProfile) {
            console.log("Timestamp is less than 10 minutes OR profile is modifying ");
            return this.ownProfile;
        }

        const now = Math.floor(Date.now() / 1000);

        if (!this.ownProfile || this.expireAt < now) {
            this.isFetching = true;
            this.expireAt = Math.floor(Date.now() / 1000) + 600; // 10 minutes in seconds

            try {
                const profile = await this.profileFacade.getOwn();
                this.setOwnProfile(profile);
            } catch (e) {
                this.isFetching = false;
                throw e;
            }

            this.modified = false;
            this.isFetching = false;

            return this.ownProfile;
        }

        return this.ownProfile;
    };

    private delay = (milliseconds: number) => {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    };

    private setOwnProfile = (ownProfile: ProfileModel) => {
        this.ownProfile = ownProfile;
    };

    public getInfo = async (): Promise<ProfileModel> => {
        const profile = await this.getOwnProfile();
        const profileCopy = JSON.parse(JSON.stringify(profile));
        delete profileCopy.interests;

        return profileCopy;
    };

    public showInterests = async (): Promise<InterestModel[]> => {
        const ownProfile = await this.getOwnProfile();
        const ownProfileCopy = JSON.parse(JSON.stringify(ownProfile));

        return await this.idsRemapper.remapInterests(ownProfileCopy.interests);
    };

    public showFullInterests = async () => {
        const ownProfile = await this.getOwnProfile();
        const ownProfileCopy = JSON.parse(JSON.stringify(ownProfile));

        return await this.idsRemapper.showFullInterests(ownProfileCopy.interests);
    };

    public getFollowers = async (filters?: FollowFilters): Promise<PaginationModel> => {
        return await this.profileFacade.getFollowers(filters);
    };

    public getFollowing = async (filters?: FollowFilters): Promise<PaginationModel> => {
        return await this.profileFacade.getFollowing(filters);
    };

    public follow = async (profiles: string[]): Promise<FollowingModel[]> => {
        const followingResponse = await this.profileFacade.follow(profiles);
        const newProfile = await this.profileFacade.updateOwnProfile();
        this.setOwnProfile(newProfile);

        return followingResponse;
    };

    public unfollow = async (profiles: string[]): Promise<boolean> => {
        const unfollowSuccess = await this.profileFacade.unfollow(profiles);
        const newProfile = await this.profileFacade.updateOwnProfile();
        this.setOwnProfile(newProfile);

        return unfollowSuccess;
    };

    public getStats = async (): Promise<ProfileStatsModel> => {
        /*
        TODO: Improvement. Store the models in LocalStorage and update them only if we have a new success rate for new entity.
        That way we will avoid making always a request for GET /teams when user refreshes his profile.
        */
        const profileStats =  await this.profileFacade.getStats();

        return await this.idsRemapper.remapProfileStats(profileStats);
    };

    public getBadges = async (disableCache?: boolean): Promise<string[]> => {
        return await this.profileFacade.getBadges(disableCache);
    };

    private addInterestPromise = async (interest: InterestModel): Promise<OwnProfileBuilder> => {
        this.modified = true;
        const copyInterest = { ...interest };
        await this.getOwnProfile();
        let localInterest: InterestModel = null;

        if (interest.source !== "custom") {
            localInterest = await this.idsRemapper.remapInterestToNative(copyInterest);
        } else {
            localInterest = copyInterest;
        }

        const newInterests = this.ownProfile.interests.filter((profileInterest: InterestModel) => {
            return (profileInterest.id !== localInterest.id || profileInterest.type !== localInterest.type)
            || profileInterest.source !== localInterest.source;
        });

        this.ownProfile.interests = [...newInterests];
        this.ownProfile.interests.push(localInterest);

        return this;
    };

    public addInterest = (interest: InterestModel): OwnProfileBuilder => {
        this.promises.push(this.addInterestPromise(interest));

        return this;
    };

    private removeInterestPromise = async (interest: InterestModel): Promise<OwnProfileBuilder> => {
        this.modified = true;
        const copyInterest = {...interest};
        await this.getOwnProfile();
        let localInterest: InterestModel = null;

        if (interest.source !== "custom") {
            localInterest = await this.idsRemapper.remapInterestToNative(copyInterest);
        } else {
            localInterest = copyInterest;
        }

        this.ownProfile.interests = this.ownProfile.interests.filter((profileInterest: InterestModel) => {
            return (profileInterest.id !== localInterest.id || profileInterest.type !== localInterest.type)
                || profileInterest.source !== localInterest.source;
        });

        return this;
    };

    public removeInterest = (interest: InterestModel): OwnProfileBuilder => {
        this.promises.push(this.removeInterestPromise(interest));

        return this;
    };

    private setBirthdatePromise = async (birthDate?: string): Promise<OwnProfileBuilder> => {
        this.modified = true;
        await this.getOwnProfile();

        if (birthDate) {
            this.ownProfile.birthDate = birthDate;
        } else {
            this.ownProfile.birthDate = null;
        }

        return this;
    };

    public setBirthdate = (birthDate?: string): OwnProfileBuilder => {
        this.promises.push(this.setBirthdatePromise(birthDate));

        return this;
    };

    private setGenderPromise = async (gender: string): Promise<OwnProfileBuilder> => {
        this.modified = true;
        await this.getOwnProfile();

        if (gender === "male" || gender === "female") {
            this.ownProfile.gender = gender;
        } else {
            this.ownProfile.gender = "unspecified";
        }

        return this;
    };

    public setGender = (gender: string): OwnProfileBuilder => {
        this.promises.push(this.setGenderPromise(gender));

        return this;
    };

    private setNicknamePromise = async (nickname: string): Promise<OwnProfileBuilder> => {
        this.modified = true;
        await this.getOwnProfile();
        this.ownProfile.nickname = nickname;

        return this;
    };

    public setNickname = (nickname?: string): OwnProfileBuilder => {
        this.promises.push(this.setNicknamePromise(nickname));

        return this;
    };

    private setNamePromise = async (name: string): Promise<OwnProfileBuilder> => {
        this.modified = true;
        await this.getOwnProfile();
        this.ownProfile.name = name;

        return this;
    };

    public setName = (name: string): OwnProfileBuilder => {
        this.promises.push(this.setNamePromise(name));

        return this;
    };

    private setCountryPromise = async (countryId?: string): Promise<OwnProfileBuilder> => {
        this.modified = true;
        await this.getOwnProfile();

        if (countryId && !this.ownProfile.country) {
            this.ownProfile.country = new ProfileCountryModel();
            this.ownProfile.country.id = countryId;
        } else if (countryId && this.ownProfile.country) {
            this.ownProfile.country.id = countryId;
        } else {
            this.ownProfile.country = null;
        }

        return this;
    };

    public setCountry = (countryId?: string): OwnProfileBuilder => {
        this.promises.push(this.setCountryPromise(countryId));

        return this;
    };

    public showFullnessProfile = async (): Promise<FullnessProfile> => {
        const profile = await this.getOwnProfile();
        const profileCopy = JSON.parse(JSON.stringify(profile));

        return this.profileFacade.showFullnessProfile(profileCopy);
    };

    private setAvatarPromise = async (avatar: string): Promise<OwnProfileBuilder> => {
        this.modified = true;
        await this.getOwnProfile();

        if (this.profileService.isAvatarValidURL(avatar)) {
            this.ownProfile.avatar = avatar;
        } else {
            if (this.errorHandlingMode === "default") {
                throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_AVATAR, ErrorMessages.INVALID_AVATAR);
            } else if (this.errorHandlingMode === "standard") {
                throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_AVATAR, ErrorMessages.INVALID_AVATAR);
            }
        }

        return this;
    };

    public setAvatar = (avatar: string): OwnProfileBuilder => {
        this.promises.push(this.setAvatarPromise(avatar));

        return this;
    };

    public update = async (): Promise<ProfileModel> => {
        if (this.promises.length === 0) {
            return this.ownProfile;
        }

        await Promise.all(this.promises);
        const oldProfile = await this.profileFacade.getOwn();
        const newProfile = await this.getOwnProfile();
        const profileToPatch = this.profileService.extractUpdatedProps(oldProfile, newProfile);

        this.modified = false;
        this.expireAt = 0;

        if (Object.keys(profileToPatch).length) {
            const patchedProfile = await this.profileHttps.updateProfile(profileToPatch);
            const copyPatchedProfile = JSON.parse(JSON.stringify(patchedProfile));
            this.setOwnProfile(copyPatchedProfile);
            this.localCache.setOwnProfile(copyPatchedProfile);
            delete patchedProfile.interests;

            return patchedProfile;
        }

        const profile = JSON.parse(JSON.stringify(this.ownProfile));
        delete profile.interests;

        return profile;
    };
}