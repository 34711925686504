import { MarketEnum } from "../../../Enums/MarketEnum";
import ValueCorners from "../../Value/ValueCorners";
import FixturesModel from "../FixturesModel";

export default class CornersFixtureModel implements FixturesModel {
    public matchId: string = null;
    public market: MarketEnum = MarketEnum.CORNERS_MATCH;
    public matchType: string = "FOOTBALL";
    public prediction: ValueCorners = null;

    constructor(matchId: string, value: number) {
        this.matchId = matchId;
        this.prediction = new ValueCorners();
        this.prediction.value = value;
    }
}