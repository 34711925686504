import { MarketTitle, MarketType } from "../../../../Utils";

export const getModalInfo = (market) => {
  switch (market?.type) {
    case MarketType.HT_1X2:
      return { title: MarketTitle[market.type], subtitle: 'Guess right to score', points: market?.points };
    case MarketType.OVER_CORNERS_8_5:
      return { title: MarketTitle[market.type], subtitle: 'Guess over/under to score', points: market?.points };
    case MarketType.PLAYER_SCORE_FIRST_GOAL:
      return { title: MarketTitle[market.type], subtitle: 'Guess the player to score', points: market?.points };
    default:
      break;
  }
}