export default class SearchFilters {
    public entities: string[] = null;
    public query: string = null;
    public scope: string[] = null;
    
    constructor(filters: any) {
        this.entities = filters.entities ? filters.entities : null;
        this.query = filters.query ? filters.query : null;
        this.scope = filters.scope ? filters.scope : null;
    }

    public constructFilterUrl = () => {
        let url = '';
        if (this.entities) {
            url = url + '&';
            url = url + `entities=${this.entities.join(",")}`;
        }
        if (this.query) {
            url = url + '&';
            url = url + `q=${this.query}`;
        }
        if (this.scope) {
            url = url + '&';
            url = url + `scope=${this.scope.join(",")}`;
        }

        return url;
    };

}