import {IRemapper} from "../../../../../Global/IRemapper";
import FootballCountryRemapper from "../../Country/Remappers/FootballCountryRemapper";
import TopPlayerModel from "../TopPlayerModel";

export default class TopPlayerRemapper implements IRemapper<TopPlayerModel> {
    private countryRemapper: FootballCountryRemapper = null;

    constructor() {
        this.countryRemapper = new FootballCountryRemapper();
    }

    fromResponse(response: any): TopPlayerModel {
        try {
            const model = new TopPlayerModel();
            model.id = response.id;
            model.firstName = response.first_name ? response.first_name : null;
            model.lastName = response.last_name ? response.last_name : null;
            model.name = response.name ? response.name : null;
            model.birthDate = response.birth_date;
            model.country = this.countryRemapper.countryFromResponse(response.country);
            model.assets = response.assets;
            model.position = response.position;

            return model;
        } catch (e) {
            console.warn("There was a problem remapping top player response", e);
            throw e;
        }
    };

}