import FixturesResponseModel from "./FixturesResponseModel";
import PlayerFixtureModel from "./Markets/PlayerFixtureModel";
import ResultModel from "./ResultModel";
import CorrectScoreFixtureModel from "./Markets/CorrectScoreFixtureModel";
import OverCornersFixtureModel from "./Markets/OverCornersFixtureModel";
import OverGoalsFixtureModel from "./Markets/OverGoalsFixtureModel";
import MatchFacade from "../../../Football/Facades/MatchFacade";
import PlayerFacade from "../../../Football/Facades/PlayerFacade";
import PlayerBasicModel from "../../../Football/Models/Player/PlayerBasicModel";
import PredictorService from "../../Service/PredictorService";

export default class FixturesRemapper {
    private matchFacade: MatchFacade = null;
    private playerFacade: PlayerFacade = null;
    private predictorService: PredictorService = null;

    constructor(matchFacade: MatchFacade, playerFacade: PlayerFacade, predictorService: PredictorService) {
        this.matchFacade = matchFacade;
        this.playerFacade = playerFacade;
        this.predictorService = predictorService
    }

    /**
     * All models have property value and API expects to be prediction.
     * - For markets linked with players request body has player_id property.
     * - For markets OVER_GOALS and OVER_CORNERS the target is included in property market. Example: (OVER_GOALS_0.5).
     * The market is split by "_" and last index is passed to property target.
     * - For CORRECT_SCORE market value in model is "int:int" and API expects goals_home:int, goals_away:int. The string is split by ":".
     * First index is goals_home and second index is goals_away.
     * @param fixture
     * @returns Remmaped fixture request body.
     */

    public remapFixturesRequest = (fixture: any) => {
        let fixturesRequest: any = {};

        fixturesRequest.match_id = fixture.matchId;
        fixturesRequest.match_type = "FOOTBALL";

        if (fixture instanceof PlayerFixtureModel) {
            fixturesRequest.market = fixture.market;
            fixturesRequest.prediction = fixture.prediction.value;
            fixturesRequest.player_id = fixture.prediction.playerId;
        } else if (fixture instanceof OverCornersFixtureModel || fixture instanceof OverGoalsFixtureModel) {
            fixturesRequest.market = fixture.market;
            fixturesRequest.prediction = fixture.prediction.value
        } else if (fixture instanceof CorrectScoreFixtureModel) {
            fixturesRequest.market = fixture.market;
            const goals = fixture.prediction.value.split(":");
            fixturesRequest.goals_home = Number(goals[0]);
            fixturesRequest.goals_away = Number(goals[1]);
        } else {
            fixturesRequest.market = fixture.market;
            fixturesRequest.prediction = fixture.prediction.value;
        }

        return fixturesRequest;

    };

    /**
     * Remap each fixture from response body API. The steps are:
     * - Sort the array in ascending order (for TOP_X or MATCH_QUIZ)
     * - Make request to GET /matches endpoint in Football API with all match ids
     * - Make request to GET /players endpoint in Football API only when we have property player_id from response body.
     * - Remap properties to camel case.
     * @param responses
     * @returns Remmaped fixture response body.
     */

    public remapFixturesResponse = async (responses: any[]) => {
        const ownGoal = "OWN_GOAL"
        let matchIds: string[] = [];
        let playerIds: string[] = [];
        let playersMap: any = null;
        let remappedFixtures: FixturesResponseModel[] = [];

        if (responses.length > 1) {
            responses.sort((a: any, b: any) => {
                return a.match_id.localeCompare(b.match_id);
            });
        }

        for (const fixture of responses) {
            matchIds.push(fixture.match_id);
            if (fixture.player_id && !playerIds.includes(fixture.player_id)) {
                playerIds.push(fixture.player_id);
            }
        }

        const matches = await this.matchFacade.getMatchesMapWithNativeIds(matchIds);
        playerIds.sort((a: string, b: string) => a.localeCompare(b));

        if (playerIds && playerIds.length > 0 && !playerIds.includes(ownGoal)) {
            playersMap = await this.playerFacade.getPlayersMapWithNativeIds(playerIds);
        }

        for (const fixture of responses) {
            let fixturesResponse = new FixturesResponseModel();

            if (fixture.target) {
                //@ts-ignore
                fixturesResponse.market = this.predictorService.convertMarketForResponseModel(fixture.market, fixture.target);
            } else {
                fixturesResponse.market = fixture.market;
            }

            fixturesResponse.matchId = matches[fixture.match_id].id;
            fixturesResponse.matchType = fixture.match_type;
            fixturesResponse.matchModel = matches ? matches[fixture.match_id] : null;
            delete fixturesResponse.matchModel.availableMarkets;

            if (fixture.goals_home !== undefined && fixture.goals_away !== undefined) {
                fixturesResponse.prediction.value = fixture.goals_home.toString() + ":" + fixture.goals_away.toString();
            } else {
                fixturesResponse.prediction.value = fixture.prediction;
            }

            fixturesResponse.prediction.playerId = fixture.player_id ? fixture.player_id : null;

            if (fixturesResponse.prediction.playerId && fixturesResponse.prediction.playerId !== ownGoal) {
                fixturesResponse.prediction.playerModel = playersMap[fixture.player_id];
                fixturesResponse.prediction.playerId = playersMap[fixture.player_id].id;
            }

            fixturesResponse.result = this.remapResult(fixture.result);

            remappedFixtures.push(fixturesResponse);
        }

        return remappedFixtures;
    };

    public remapResult = (result: any) => {
        let resultResponse = new ResultModel();

        resultResponse.settledAt = result.settled_at;
        resultResponse.resettledAt = result.resettled_at;
        resultResponse.status = result.status;
        resultResponse.outcome = result.outcome;
        resultResponse.points = result.points;

        return resultResponse;
    };

}