import {IRemapper} from "../../../../../Global/IRemapper";
import TimelineModel from "./TimelineModel";
import PlayerShortRemapper from "../../Player/Remappers/PlayerShortRemapper"

export default class TimelineRemapper implements IRemapper<TimelineModel> {
    private playerRemapper: PlayerShortRemapper = null;

    constructor() {
        this.playerRemapper = new PlayerShortRemapper();
    }

    fromResponse(response: any): TimelineModel {
        try {
            const model = new TimelineModel();
            model.minute = response.minute;
            model.type = response.type;
            model.teamId = response.team_id;
            model.player = this.playerRemapper.fromResponse(response.player);
            model.relatedPlayer = response.related_player ? this.playerRemapper.fromResponse(response.related_player) : null;
            model.updatedAt = response.updated_at;

            return model;
        } catch (e) {
            console.warn("There was a problem remapping timeline", e);
            throw e;
        }
    };

}