import { MarketEnum } from "../../../Enums/MarketEnum";
import ValueCorrectScore from "../../Value/ValueCorrectScore";
import FixturesModel from "../FixturesModel";

export default class CorrectScoreFixtureModel implements FixturesModel {
    public matchId: string = null;
    public market: MarketEnum = null;
    public matchType: string = "FOOTBALL";
    public prediction: ValueCorrectScore = null;

    constructor(matchId: string, market: MarketEnum, value: string) {
        this.matchId = matchId;
        this.market = market;
        this.prediction = new ValueCorrectScore();
        this.prediction.value = value;
    }

}