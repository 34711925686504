import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import IdMappingService from "../../IdMapping/IdMappingService";
import FootballHttps from "../../Https/FootballHttps";
import FootballFacade from "./Facades/FootballFacade";
import FootballCountryModel from "../Football/Models/Country/FootballCountryModel";
import CompetitionBasicModel from "./Models/Competition/CompetitionBasicModel";
import CompetitionFullModel from "./Models/Competition/CompetitionFullModel";
import TeamBasicModel from "./Models/Team/TeamBasicModel";
import TeamFullModel from "./Models/Team/TeamFullModel";
import MatchFullModel from "./Models/Match/MatchFullModel";
import TopPlayerModel from "./Models/Player/TopPlayerModel";
import PlayerFullModel from "./Models/Player/PlayerFullModel";
import SearchModel from "./Models/Search/SearchModel";
import CompetitionFilters from "./Models/Competition/CompetitionFilters";
import TeamFilters from "./Models/Team/TeamFilters";
import MatchFilters from "./Models/Match/MatchFilters";
import PlayerFilters from "./Models/Player/PlayerFilters";
import SearchFilters from "./Models/Search/SearchFilters";
import FootballPaginationModel from "./Models/Pagination/FootballPaginationModel";
import LocalCacheInstanceFactory from "../../Global/Factories/LocalInstanceFactory";
import { LocalCacheInterface } from "../../Global/Interfaces/GlobalInterfaces";


export default class Football {
    private https: FootballHttps = null;
    private idMapping: IdMappingService = null;
    private localCache: LocalCacheInterface = null;
    public footballFacade: FootballFacade = null;

    constructor(config: SDKConfigurationModel) {
        this.https = new FootballHttps(config);
        this.idMapping = new IdMappingService(config);
        this.localCache = new LocalCacheInstanceFactory().createLocalCacheInstance(config.idSchema);
        this.footballFacade = new FootballFacade(config, this.localCache, this.https, this.idMapping);
    }

    public getCountries = async (disableCache?: boolean): Promise<FootballCountryModel[]> => {
        const countriesResponse = await this.footballFacade.getCountries(disableCache);

        return countriesResponse;
    };

    public getCompetitions = async (filters?: CompetitionFilters, disableCache?: boolean): Promise<CompetitionBasicModel[]> => {
        const competitionsResponse = await this.footballFacade.getCompetitions(filters, disableCache);

        return competitionsResponse;
    };

    public getCompetitionById = async (id: string, disableCache?: boolean): Promise<CompetitionFullModel> => {
        const competitionResponse = await this.footballFacade.getCompetitionById(id, disableCache)

        return competitionResponse;
    };

    public getTopCompetitions = async (disableCache?: boolean): Promise<CompetitionBasicModel[]> => {
        const topCompetitionResponse = await this.footballFacade.getTopCompetitions(disableCache);

        return topCompetitionResponse;
    };

    public getTeams = async (filters?: TeamFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        const teamsResponse = await this.footballFacade.getTeams(filters, disableCache);

        return teamsResponse;
    };

    public getTeamById = async (id: string, disableCache?: boolean): Promise<TeamFullModel> => {
        const teamResponse = await this.footballFacade.getTeamById(id, disableCache);

        return teamResponse;
    };

    public getTopTeams = async (disableCache?: boolean): Promise<TeamBasicModel[]> => {
        const topTeamsResponse = await this.footballFacade.getTopTeams(disableCache);

        return topTeamsResponse;
    };

    public getNextMatchForTeam = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        const nextMatchForTeamResponse = await this.footballFacade.getNextMatchForTeam(id, disableCache);

        return nextMatchForTeamResponse;
    };

    public getPrevMatchForTeam = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        const prevMatchForTeamResponse = await this.footballFacade.getPrevMatchForTeam(id, disableCache);

        return prevMatchForTeamResponse;
    };

    public getPlayers = async (filters?: PlayerFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        const playersResponse = await this.footballFacade.getPlayers(filters, disableCache);

        return playersResponse;
    };

    public getPlayerById = async (id: string, disableCache?: boolean): Promise<PlayerFullModel> => {
        const playerResponse = await this.footballFacade.getPlayerById(id, disableCache);

        return playerResponse;
    };

    public getTopPlayers = async (disableCache?: boolean): Promise<TopPlayerModel[]> => {
        const topPlayersResponse = await this.footballFacade.getTopPlayers(disableCache);

        return topPlayersResponse;
    };

    public getNextMatchForPlayer = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        const nextMatchForPlayerResponse = await this.footballFacade.getNextMatchForPlayer(id, disableCache);

        return nextMatchForPlayerResponse;
    };

    public getPrevMatchForPlayer = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        const prevMatchForPlayerResponse = await this.footballFacade.getPrevMatchForPlayer(id, disableCache);

        return prevMatchForPlayerResponse;
    };

    public getMatches = async (filters?: MatchFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        const matchesResponse = await this.footballFacade.getMatches(filters, disableCache);

        return matchesResponse;
    };

    public getMatchById = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        const matchResponse = await this.footballFacade.getMatchById(id, disableCache);

        return matchResponse;
    };

    public search = async (filters: SearchFilters, disableCache?: boolean): Promise<SearchModel> => {
        const searchResponse = await this.footballFacade.search(filters, disableCache);

        return searchResponse;
    };

}
