import {typeConvert} from "./TraversalHelper";

export default class TreeTraversalExtract {
    private extractedObjIds: any = {};

    public traverse = (obj: any, type: string) => {
        type = typeConvert(type);
        if (Array.isArray(obj)) {
            this.traverseArrExtract(obj, type);
        } else if (obj && typeof obj === 'object') {
            this.traverseObjExtract(obj, type);
        }
        return this.extractedObjIds;

    };

    private traverseArrExtract = (objs: any[], type: string) => {
        if (objs && objs.length > 0) {
            objs.forEach((obj: any) => {
                this.traverseObjExtract(obj, type)
            });
        }
    };

    private traverseObjExtract = (obj: any, type: string) => {
        if (obj && Object.keys(obj).length > 0) {
            Object.keys(obj).forEach((key: string) => {
                const value = obj[key];
                if (key === 'id' || key === 'teamId') {
                    //create object if it doesn't exist
                    if (!this.extractedObjIds.hasOwnProperty(type)) {
                        this.extractedObjIds[type] = [];
                    }

                    if (value) {
                        this.extractedObjIds[type].push(value);
                    }
                } else if (obj[key]) {
                    this.traverse(obj[key], key);
                }
            });
        }
    };
}
