import { TEMPLATE_ID } from "./constants";

export const getConfigRequest = (fansUnitedSDK) => {
  return fansUnitedSDK.loyalty.getConfigV2(true);
}

export const getTemplateRequest = (fansUnitedSDK) => {
  return fansUnitedSDK.loyalty.getTemplateById(TEMPLATE_ID);
}

export const getMatchesRequest = (fansUnitedSDK, competitionId, fromDate, toDate, limit = 500) => {
  const options = {
    competitionIds: [competitionId],
    fromDate: `${fromDate}T08:00:00Z`, 
    toDate: `${toDate}T08:00:00Z`, 
    limit, 
    sortField: 'date', 
    sortOrder: 'asc',
  };

  return fansUnitedSDK.football.getMatches(options);
};

export const getUserPredictionsRequest = (fansUnitedSDK) => {
  return fansUnitedSDK.predictor.getMyPredictions({ limit: 500 });
}

export const getMatchSummaryRequest = (fansUnitedSDK, matchId) => {
  return fansUnitedSDK.predictor.getMatchSummary(matchId, true);
}

export const makePredictionRequest = (fansUnitedSDK, matchId, market, prediction, playerId) => {
  return fansUnitedSDK.predictor.makeFootballPrediction(matchId, market, prediction, playerId)
}