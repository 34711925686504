import CorrectScoreFixtureModel from "../../../Predictor/Models/Fixtures/Markets/CorrectScoreFixtureModel";
import TiebreakerModel from "../Games/TiebreakerModel";

export default class TopXPredictionRequestModel {
    public gameInstanceId: string = null;
    public fixtures: CorrectScoreFixtureModel[] = [];
    public tiebreaker?: TiebreakerModel = null;

    constructor(topXPrediction: any) {
        this.gameInstanceId = topXPrediction.gameInstanceId;

        topXPrediction.fixtures.forEach((fixture: CorrectScoreFixtureModel) => {
            return this.fixtures.push(new CorrectScoreFixtureModel(fixture.matchId, fixture.market, fixture.prediction.value));
        });

        this.tiebreaker = topXPrediction.tiebreaker ? topXPrediction.tiebreaker : null;
    }
}