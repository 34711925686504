import { FiltersInterface } from "../../../../Global/Interfaces/GlobalInterfaces";

export default class TemplateFilters implements FiltersInterface {
    public flags?: string = null;
    public status?: string = null;
    public type?: string = null;
    public activeFrom?: string = null;
    public activeTo?: string = null;
    public createdFrom?: string = null;
    public createdTo?: string = null;
    public updatedFrom?: string = null;
    public updatedTo?: string = null;

    constructor(filters: any) {
        this.flags = filters.flags ? filters.flags : null;
        this.status = filters.status ? filters.status : null;
        this.type = filters.type ? filters.type : null;
        this.activeFrom = filters.activeFrom ? filters.activeFrom : null;
        this.activeTo = filters.activeTo ? filters.activeTo : null;
        this.createdFrom = filters.createdFrom ? filters.createdFrom : null;
        this.createdTo = filters.createdTo ? filters.createdTo : null;
        this.updatedFrom = filters.updatedFrom ? filters.updatedFrom : null;
        this.updatedTo = filters.updatedTo ? filters.updatedTo : null;
    }

    public constructFiltersUrl? (): string {
        let url = '';

        if (this.flags) {
            url += '&';
            url += `flags=${this.flags}`;
        }
        if (this.status) {
            url += '&';
            url += `status=${this.status}`;
        }
        if (this.type) {
            url += '&';
            url += `type=${this.type}`;
        }
        if (this.activeFrom) {
            url += '&';
            url += `active_from=${this.activeFrom}`;
        }
        if (this.activeTo) {
            url += '&';
            url += `active_to=${this.activeTo}`;
        }
        if (this.createdFrom) {
            url += '&';
            url += `created_from=${this.createdFrom}`;
        }
        if (this.createdTo) {
            url += '&';
            url += `created_to=${this.createdTo}`;
        }
        if (this.updatedFrom) {
            url += '&';
            url += `updated_from=${this.updatedFrom}`;
        }
        if (this.updatedTo) {
            url += '&';
            url += `updated_to=${this.updatedTo}`;
        }

        return url;
    };
}