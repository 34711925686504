import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import {requestChunkBuild, setBaseUrl, transformIdsToBeUnique} from "../Global/Helper";
import IdMappingModel from "../IdMapping/Models/IdMappingModel";
import Https from "./Https";

const types: any = {
    player: 'players',
    team: 'teams',
    homeTeam: 'teams',
    awayTeam: 'teams',
    country: 'countries',
    competition: 'competitions',
    match: 'matches'
}

export default class IdMappingHttps extends Https {
    constructor(config: SDKConfiguraitonModel) {
        super(config, setBaseUrl(config.environment, "idMapping"));
    }

    getEntityById = async (type: string, id: string) => {
        const url = `/football${this.apiSignInUrl}&input_provider=${this.idSchema}&${types[type]}=${id}`;
        const warnMessage = `There was a problem with id mapping request for the following id: ${id}`;

        return await this.fetchWithoutAuth(url, warnMessage);
    };


    getEntityByIdAndSchema = async (type: string, id: string, schemaId: string) => {
        const url = `/football${this.apiSignInUrl}&input_provider=${schemaId}&${types[type]}=${id}`;
        const warnMessage = `There was a problem with id mapping request for the following id: ${id}`;

        return await this.fetchWithoutAuth(url, warnMessage);
    };

    getEntityIdsByIdsAndSchema = async (type: string, ids: string[], schemaId: string) => {
        const url = `/football${this.apiSignInUrl}&input_provider=${schemaId}&${types[type]}=${ids.join(',')}`;
        const warnMessage = `There was a problem with id mapping request for the following ids: ${ids.join(",")}`;

        return await this.fetchWithoutAuth(url, warnMessage);
    };

    public getEntitiesByIds = async (type: string, ids: string[], idSchema: string) => {
        ids.sort((a: string, b: string) => a.localeCompare(b));
        const requestChunks = requestChunkBuild(ids);
        let result: any[] = [];
        for (const ids of requestChunks) {
            const url = `/football${this.apiSignInUrl}&input_provider=${idSchema}&${types[type]}=${ids.join(',')}`;
            const warnMessage = `There was a problem with id mapping request for the following ids :${ids.join(",")}`;
            const data = await this.fetchWithoutAuth(url, warnMessage);
            result = [...result, ...data.data];
        }

        return result;
    };

    /**
     * API has set a maximum size for url - it can have in total 250 ids. Before making request to API (Id Mapping), the ids are combined and if they are more than 249
     * they are splited in separated chunk arrays. Then it makes verification for the chunks:
     * - For more than one chunk, we create new object @var newIdsObj with type properties and value ids. Then with the new object we get the keys and values and make a request.
     * Same goes for rest of the chunks.
     * - For only one chunk we use the @var entityIdsObj and make a single request with all types and ids.
     * @param entityIdsObj
     * @param schemaId  -- Optional
     * @returns Entities ids
     */

    public getEntitiesByIdObjs = async (entityIdsObj: any, schemaId?: string): Promise<any[]> => {
        let allIds: string[] = [];
        let entityMaxLengthIdsObj: any = {};
        let results: any[] = [];


        Object.keys(entityIdsObj).forEach((key: string) => {
            allIds.push.apply(allIds, entityIdsObj[key]);
        });

        const uniqueIds = transformIdsToBeUnique(allIds);
        const requestChunks = requestChunkBuild(uniqueIds);

        for (const chunk of requestChunks) {
            let maxUrlLength = "";
            Object.keys(entityIdsObj).forEach((key: string) => {
                entityMaxLengthIdsObj[key] = [];
                const intersection = chunk.filter((id:string) => entityIdsObj[key].includes(id));
                if (intersection && intersection.length > 0) {
                    entityMaxLengthIdsObj[key] = intersection;
                }
            })

            Object.keys(entityMaxLengthIdsObj).forEach((key: string) => {
                if (entityMaxLengthIdsObj[key].length > 0) {
                    entityMaxLengthIdsObj[key].sort((a: string, b: string) => a.localeCompare(b));
                    maxUrlLength += '&';
                    maxUrlLength += `${types[key]}=${entityMaxLengthIdsObj[key].join(",")}`;
                }
            });

            if (maxUrlLength && maxUrlLength.length > 0) {
                const url = `/football${this.apiSignInUrl}&input_provider=${schemaId ? schemaId : 'native'}${maxUrlLength}`;
                const warnMessage = "There was a problem with id mapping request";
                const data = await this.fetchWithoutAuth(url, warnMessage);
                results.push(data.data);
            } else {
                results.push([]);
            }
        }

        const response = Array.prototype.concat.apply([], results);
        return response;
    };

    public getTopEntities = async (): Promise<IdMappingModel[]> => {
        const url = `/football/top${this.apiSignInUrl}`;
        const warnMessage = "There was a problem with top id mappings request";
        const data = await this.fetchWithoutAuth(url, warnMessage);

        return data.data;
    };

}
