import { format } from 'date-fns';

export const isSameDay = (firstDate, secondDate) => {
  return firstDate.getDate() === secondDate.getDate() && firstDate.getMonth() === secondDate.getMonth();
}

export const parsedDate = (stringDate) => {
  const matchDate = stringDate ? new Date(stringDate) : new Date();
  return format(matchDate, 'dd.MM.yyyy kk:mm')
}

export const getCalendarMatches = (matchesData) => {
  const calendar = {};

  const dates = [...new Set(matchesData.map((match) => new Date(match.kickoffAt).setHours(0, 0, 0, 0)))];

  dates.map(date => 
    calendar[date] = { 
      value: date,
      dayString: isSameDay(new Date(), new Date(date)) ? 'Today' : format(date, 'EEE dd').split(' ')[0],
      dateString: format(date, 'EEE dd').split(' ')[1],
      matches: matchesData.filter(match => isSameDay(new Date(match.kickoffAt), new Date(date)))
        .sort((a, b) => new Date(a.kickoffAt) - new Date(b.kickoffAt))
    }
  )

  return calendar;
}

export const getInitialDate = (calendarMatches) => {
  if (Object.keys(calendarMatches).length > 0 && !calendarMatches[new Date().setHours(0, 0, 0, 0)]) {
    const datesWithMatches = Object.keys(calendarMatches).map((key) => calendarMatches[key].value).sort()
    const currentOrNearestDate = datesWithMatches.find((date) => new Date(date) >= new Date())

    return (new Date(currentOrNearestDate))
  }

  return new Date();
}

export const MarketType = Object.freeze({
  HT_1X2: 'HT_1X2',
  OVER_CORNERS_8_5: 'OVER_CORNERS_8_5',
  PLAYER_SCORE_FIRST_GOAL: 'PLAYER_SCORE_FIRST_GOAL',
  CORRECT_SCORE_ADVANCED: 'CORRECT_SCORE_ADVANCED',
});

export const MarketTitle = Object.freeze({
  HT_1X2: 'Result at half time',
  OVER_CORNERS_8_5: 'Over/Under 8.5 corners',
  PLAYER_SCORE_FIRST_GOAL: 'Player to score the first goal',
});

export const MatchStatus = Object.freeze({
  FINISHED: 'finished',
  IN_PROGRESS: 'inprogress',
  NOT_STARTED: 'notstarted',
});

export const getMarketPredictions = (markets, predictions) => {
  const currentPredictions = []
  
  markets.forEach((market) => {
    switch (market.type) {
      case MarketType.CORRECT_SCORE_ADVANCED:
        const scorePrediction = predictions.filter((prediction) => prediction.market === market.type)[0]
        currentPredictions.push({
          'type': market.type, 
          'prediction': scorePrediction?.prediction.value ?? null, 
          'result': scorePrediction?.result ?? null,
          'points': market.points})
        break;
      case MarketType.HT_1X2:
        const htPrediction = predictions.filter((prediction) => prediction.market === market.type)[0]
        currentPredictions.push({
          'type': market.type, 
          'prediction': htPrediction?.prediction.value ?? null, 
          'result': htPrediction?.result ?? null,
          'points': market.points})
        break;
      case MarketType.OVER_CORNERS_8_5:
        const cornersPrediction = predictions.filter((prediction) => prediction.market === market.type)[0]
        currentPredictions.push({
          'type': market.type, 
          'prediction': cornersPrediction?.prediction.value ?? null, 
          'result': cornersPrediction?.result ?? null,
          'points': market.points})
        break;
      case MarketType.PLAYER_SCORE_FIRST_GOAL:
        const playerPrediction = predictions.filter((prediction) => prediction.market === market.type)[0]
        currentPredictions.push({
          'type': market.type, 
          'prediction': playerPrediction?.prediction.playerModel ?? null, 
          'result': playerPrediction?.result ?? null,
          'points': market.points})
        break;
      default:
        break;
    }
  })

  return currentPredictions;
}