import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import {
  ErrorHandlingModeType,
  IdSchemaType,
} from "../../../Configurator/Types/ConfiguratorTypes";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import { ErrorMessages } from "../../../Global/Messages/Messages";
import ClientHttps, { FeatureConfigType } from "../../../Https/ClientHttps";
import DiscussionsHttps from "../../../Https/DiscussionsHttps";
import ActivityService from "../../Activity/Service/ActivityService";
import DiscussionsFactory from "../Factory/DiscussionsFactory";
import DiscussionsConfigModel from "../Models/ClientFeatures/DiscussionsConfigModel";
import CreatePostParamBody from "../Models/CreatePostParamBody";
import DiscussionModel from "../Models/DiscussionModel";
import DiscussionsFilters from "../Models/Filters/DiscussionsFilters";
import PostsFilters from "../Models/Filters/PostsFilters";
import TopDiscussionsFilters from "../Models/Filters/TopDiscussionsFilters";
import PostByIdFullModel from "../Models/PostByIdFullModel";
import DiscussionsService from "../Service/DiscussionsService";
import { ReactionPostType, ReportReasonType } from "../Types/types";

export default class DiscussionsFacade {
  private idSchema: IdSchemaType = null;
  private errorHandlingMode: ErrorHandlingModeType = null;
  private https: DiscussionsHttps = null;
  private clientHttps: ClientHttps = null;
  private activityService: ActivityService = null;
  private service: DiscussionsService = null;
  private factory: DiscussionsFactory = null;

  constructor(config: SDKConfigurationModel) {
    this.idSchema = config.idSchema;
    this.errorHandlingMode = config.errorHandlingMode;
    this.https = new DiscussionsHttps(config);
    this.clientHttps = new ClientHttps(config);
    this.activityService = new ActivityService(config);
    this.service = new DiscussionsService(config);
    this.factory = new DiscussionsFactory();
  }

  public getAll = async (
    filters?: DiscussionsFilters,
    disableCache?: boolean
  ) => {
    if (filters) {
      filters = new DiscussionsFilters(filters);
    }

    const response = await this.https.getAll(filters, disableCache);

    for (let value of response.data) {
      await this.remapTags(value, IdSchemaEnum.NATIVE, this.idSchema);
    }

    return response;
  };

  public getById = async (discussionId: string) => {
    const discussion = await this.https.getById(discussionId);
    await this.remapTags(discussion, IdSchemaEnum.NATIVE, this.idSchema);

    return discussion;
  };

  public createPost = async (
    discussionId: string,
    body: CreatePostParamBody
  ) => {
    const copyBody = JSON.parse(JSON.stringify(body));
    await this.remapTags(copyBody, this.idSchema, IdSchemaEnum.NATIVE);
    const requestBody = this.factory.createRequestBodyFromParam(copyBody);

    return await this.https.createPost(discussionId, requestBody);
  };

  public getPostById = async (postId: string) => {
    const response = await this.https.getPostById(postId);
    const finalModel = await this.service.finalizePostFullModel([response]);

    return finalModel[0];
  };

  public updatePost = async (postId: string, content: string) => {
    return await this.https.updatePost(postId, content);
  };

  public deletePost = async (postId: string) => {
    return await this.https.deletePost(postId);
  };

  public getPosts = async (discussionId: string, filters?: PostsFilters) => {
    if (filters) {
      filters = new PostsFilters(filters);
    }

    const response = await this.https.getPosts(discussionId, filters);
    response.data = await this.service.finalizePostFullModel(
      response.data as PostByIdFullModel[]
    );

    return response;
  };

  public getPostReplies = async (postId: string, filters?: PostsFilters) => {
    if (filters) {
      filters = new PostsFilters(filters);
    }

    const response = await this.https.getPostReplies(postId, filters);
    response.data = await this.service.finalizePostFullModel(
      response.data as PostByIdFullModel[]
    );

    return response;
  };

  public reactToPost = async (postId: string, reaction: ReactionPostType) => {
    return await this.https.reactToPost(postId, reaction);
  };

  public reportPost = async (
    postId: string,
    reason: ReportReasonType,
    reasonDetails: string
  ) => {
    return await this.https.reportPost(postId, reason, reasonDetails);
  };

  public getOwnPosts = async (filters?: PostsFilters) => {
    if (filters) {
      filters = new PostsFilters(filters);
    }

    return await this.https.getOwnPosts(filters);
  };

  public getUserPosts = async (
    userId: string,
    filters?: PostsFilters,
    disableCache?: boolean
  ) => {
    if (filters) {
      filters = new PostsFilters(filters);
    }

    return await this.https.getUserPosts(userId, filters, disableCache);
  };

  public getConfig = async () => {
    return (await this.clientHttps.getConfig(
      FeatureConfigType.DISCUSSIONS
    )) as DiscussionsConfigModel;
  };

  public getPostsCount = async (
    discussionIds: string[],
    disableCache: boolean
  ) => {
    if (
      !discussionIds ||
      !Array.isArray(discussionIds) ||
      !discussionIds.length
    ) {
      if (this.errorHandlingMode === "standard") {
        throw new StandardFansUnitedException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_DISCUSSION_IDS,
          ErrorMessages.INVALID_DISCUSSION_IDS
        );
      }

      throw new FansUnitedSdkException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_DISCUSSION_IDS,
        ErrorMessages.INVALID_DISCUSSION_IDS
      );
    }

    return await this.https.getPostsCount(discussionIds, disableCache);
  };

  public getTopDiscussions = async (
    filters: TopDiscussionsFilters,
    disableCache: boolean
  ) => {
    if (filters) {
      filters = new TopDiscussionsFilters(filters);
      await this.service.finalizeTopDiscussionsFilters(filters);
    }

    const response = await this.https.getTopDiscussions(filters, disableCache);

    for (let value of response.data) {
      await this.remapTags(value, IdSchemaEnum.NATIVE, this.idSchema);
    }

    return response;
  };

  private remapTags = async (
    object: DiscussionModel | CreatePostParamBody,
    fromSchema,
    toSchema
  ) => {
    if (
      this.idSchema != IdSchemaEnum.NATIVE &&
      object.context &&
      object.context.tags &&
      object.context.tags.length
    ) {
      const copyTags = JSON.parse(JSON.stringify(object.context.tags));
      const remapIdsTags = await this.activityService.remapTagsIds(
        copyTags,
        fromSchema,
        toSchema
      );
      object.context.tags = remapIdsTags;
    }
  };
}
