import { LocalCacheInterface } from "../Interfaces/GlobalInterfaces";
import LocalStorage from "../LocalStorage";
import ServerSideCache from "../ServerSideCache";

export default class LocalCacheInstanceFactory {
  public createLocalCacheInstance = (idSchema: string): LocalCacheInterface => {
    if (typeof window === "undefined") {
      return ServerSideCache.getInstance(idSchema);
    }

    return LocalStorage.getInstance(idSchema);
  };
}
