import BrandingModel from "../../../../Global/Models/Branding/BrandingModel";
import ImagesModel from "../../../../Global/Models/Images/ImagesModel";
import RelatedEntity from "../../../../Global/Models/Related/RelatedEntity";
import TimeTiebreaker from "../../../TopX/Models/Games/TimeTiebreaker";
import FixturesMatchQuizModel from "../Fixtures/FixturesMatchQuizModel";

export default class GamesMatchQuizListModel {
    public id: string = null;
    public title: string = null;
    public description: string = null;
    public type: string = null;
    public status: string = null;
    public outcome: string = null;
    public predictionsCutoff: string = null;
    public fixtures: FixturesMatchQuizModel[] = [];
    public tiebreaker: TimeTiebreaker = new TimeTiebreaker();
    public predictionId: string = null;
    public participantsCount: number = 0;
    public related: RelatedEntity[] = null;
    public excludedProfileIds: string[] = null;
    public rules: string = null;
    public flags: string[] = null;
    public points: number = null;
    public createdAt: string = null;
    public updatedAt: string = null;
    public predictionsMadeAt: string = null;
    public images: ImagesModel = null;
    public scheduleOpenAt: string = null;
    public branding: BrandingModel = null;
}