import FootballCountryModel from "../Country/FootballCountryModel";
import TeamBasicModel from "../Team/TeamBasicModel";

export default class CompetitionFullModel {
    public id: string = '';
    public country: FootballCountryModel = null;
    public gender: string = '';
    public assets: {} = null;
    public type: string = '';
    public name: string = '';
    public participants: TeamBasicModel[] = [];
}