import AutomaticModerationModel from "../../../Discussions/Models/ClientFeatures/AutomaticModerationModel";
import DiscussionsConfigModel from "../../../Discussions/Models/ClientFeatures/DiscussionsConfigModel";
import RequirementModel from "../../../Loyalty/Models/Badges/RequirementModel";
import LoyaltyConfigModel from "../../../Loyalty/Models/ClientFeatures/LoyaltyConfigModel";
import PointsModel from "../../../Loyalty/Models/Points/PointsModel";
import RewardsModel from "../../../Loyalty/Models/Rewards/RewardsModel";
import MatchQuizConfigModel from "../../../MatchQuiz/Models/Config/MatchQuizConfigModel";
import TopXConfigModel from "../../../TopX/Models/Config/TopXConfigModel";
import PredictorConfigModel from "./PredictorConfigModel";
import UserDataConfigModel from "./UserDataConfigModel";
import BadgesModelV2 from "../../../Loyalty/Models/Badges/v2/BadgesModelV2";
import PredictorBadgesValue from "../../../Loyalty/Models/Badges/v2/Predictor/PredictorBadgesValue";
import GameBadgesValue from "../../../Loyalty/Models/Badges/v2/Game/GameBadgesValue";
import GamesBadgesValue from "../../../Loyalty/Models/Badges/v2/Games/GamesBadgesValue";
import FootballBadgesValue from "../../../Loyalty/Models/Badges/v2/Football/FootballBadgesValue";
import MiniGamesBadgesValue from "../../../Loyalty/Models/Badges/v2/MiniGames/MiniGamesBadgesValue";
import DiscussionsBadgesValue from "../../../Loyalty/Models/Badges/v2/Discussions/DiscussionsBadgesValue";
import PotmBadgesValue from "../../../Loyalty/Models/Badges/v2/Voting/PotmBadgesValue";
import RewardsModelV2 from "../../../Loyalty/Models/Rewards/RewardsModelV2";
import LoyaltyConfigModelV2 from "../../../Loyalty/Models/ClientFeatures/LoyaltyConfigModelV2";
import BadgesModel from "../../../Loyalty/Models/Badges/BadgesModel";
import BadgesValue from "../../../Loyalty/Models/Badges/BadgesValue";
import GeneralBadgesValue from "../../../Loyalty/Models/Badges/GeneralBadgesValue";
import GeneralRequirementModel from "../../../Loyalty/Models/Badges/GeneralRequirementModel";

export default class FeatureConfigRemapper {
  public remapPredictorConfig = (response: any) => {
    let model = new PredictorConfigModel();

    model.enabled = response.enabled;
    model.clientId = response.client_id;
    model.fullCoverageCompetitions = response.full_coverage_competitions;
    model.markets = response.markets;
    model.successRateScopes = response.success_rate_scopes;
    model.userDataConfig = response.user_data_config
      ? this.remapUserDataConfig(response.user_data_config)
      : null;

    return model;
  };

  public remapTopXConfig = (response: any) => {
    let model = new TopXConfigModel();

    model.enabled = response.enabled;
    model.clientId = response.client_id;
    model.minFixtures = response.min_fixtures;
    model.maxFixtures = response.max_fixtures;
    model.competitionsWhitelist = response.competitions_whitelist;

    return model;
  };

  public remapMatchQuizConfig = (response: any) => {
    let model = new MatchQuizConfigModel();

    model.enabled = response.enabled;
    model.clientId = response.client_id;
    model.defaultMarkets = response.default_markets;
    model.competitionsWhitelist = response.competitions_whitelist;

    return model;
  };

  public remapLoyaltyConfig = (response: any): LoyaltyConfigModel => {
    const configModel = new LoyaltyConfigModel();

    configModel.enabled = response.enabled;
    configModel.clientId = response.client_id;
    configModel.conditions.list = response.conditions.list;
    configModel.actions.value = response.actions.value;
    configModel.rewards = this.remapRewards(response.rewards);

    return configModel;
  };

  public remapLoyaltyNewConfig = (response: any): LoyaltyConfigModelV2 => {
    const configModel = new LoyaltyConfigModelV2();

    configModel.enabled = response.enabled;
    configModel.clientId = response.client_id;
    configModel.conditions.list = response.conditions.list;
    configModel.actions.value = response.actions.value;
    configModel.rewards = this.remapNewRewards(response.rewards);

    return configModel;
  };

  public remapDiscussionsConfig = (response: any): DiscussionsConfigModel => {
    const configModel = new DiscussionsConfigModel();

    configModel.enabled = response.enabled;
    configModel.clientId = response.client_id;
    configModel.automaticModeration = response.automatic_moderation
      ? this.remapAutomaticModeration(response.automatic_moderation)
      : null;

    return configModel;
  };

  private remapAutomaticModeration = (response: any) => {
    const model = new AutomaticModerationModel();

    model.enabled = response.enabled;
    model.moderateLinks = response.moderate_links;
    model.linksWhitelist = response.links_whitelist;
    model.moderatorProfileId = response.moderator_profile_id;
    model.dictionary = response.dictionary;

    return model;
  };

  private remapNewRewards = (rewards: any) => {
    const rewardsModel = new RewardsModelV2();

    rewardsModel.points = this.remapPoints(rewards.points);
    rewardsModel.tiers = rewards.tiers;
    rewardsModel.badges = this.remapNewBadges(rewards.badges);

    return rewardsModel;
  };

  private remapRewards = (rewards: any) => {
    const rewardsModel = new RewardsModel();

    rewardsModel.points = this.remapPoints(rewards.points);
    rewardsModel.tiers = rewards.tiers;
    rewardsModel.badges = this.remapBadges(rewards.badges);

    return rewardsModel;
  };

  private remapPoints = (points: any): PointsModel => {
    const pointsModel = new PointsModel();

    pointsModel.general = points.general;
    pointsModel.discussionPost = points.discussion_post;
    pointsModel.predictor = points.predictor;
    pointsModel.activity = points.activity;
    pointsModel.topX = points.top_x;
    pointsModel.matchQuiz = points.match_quiz;

    return pointsModel;
  };

  private remapBadges = (badges: any): BadgesModel => {
    const badgesModel = new BadgesModel();

    badgesModel.general = badges.general
      ? this.remapGeneralBadgeValues(badges.general)
      : [];
    badgesModel.predictor = this.remapBadgeValues(badges.predictor);
    badgesModel.topX = this.remapBadgeValues(badges.top_x);
    badgesModel.matchQuiz = this.remapBadgeValues(badges.match_quiz);

    return badgesModel;
  };

  private remapNewBadges = (badges: any): BadgesModelV2 => {
    const badgesModel = new BadgesModelV2();

    badgesModel.predictor = this.remapBadgeValuesPredictor(badges.predictor);
    badgesModel.topX = this.remapBadgeValuesGame(badges.top_x);
    badgesModel.matchQuiz = this.remapBadgeValuesGame(badges.match_quiz);

    /**
     * Badges who are stored in Local Storage will have old structure for a while.
     */

    if (badges.games) {
      badgesModel.games = this.remapBadgeValuesGames(badges.games);
    }

    if (badges.football) {
      badgesModel.football = this.remapBadgeValuesFootball(badges.football);
    }

    if (badges.classic_quizzes) {
      badgesModel.classicQuizzes = this.remapBadgeValuesMiniGames(
        badges.classic_quizzes
      );
    }

    if (badges.either_or) {
      badgesModel.eitherOr = this.remapBadgeValuesMiniGames(badges.either_or);
    }

    if (badges.discussions) {
      badgesModel.discussions = this.remapBadgeValuesDiscussions(
        badges.discussions
      );
    }

    if (badges.voting && badges.voting.potm) {
      badgesModel.voting.potm = this.remapBadgeValuesVoting(badges.voting.potm);
    }

    if (badges.voting && badges.voting.poll) {
      badgesModel.voting.poll = this.remapBadgeValuesVoting(badges.voting.poll);
    }

    return badgesModel;
  };

  private remapBadgeValuesPredictor = (badgeValues: any[]) => {
    return badgeValues.map((value: any) => {
      const badgeValue = new PredictorBadgesValue();

      badgeValue.id = value.id;
      badgeValue.enabled = value.enabled;
      badgeValue.label = value.label;
      badgeValue.description = value.description;
      badgeValue.flags = value.flags;

      if (value.assets) {
        badgeValue.assets.mainImageUrl = value.assets.main_image_url;
      } else {
        badgeValue.assets = value.assets;
      }

      if (value.requirements) {
        badgeValue.requirements.predictionsNumber =
          value.requirements.predictions_number;
        badgeValue.requirements.correctPredictions =
          value.requirements.correct_predictions;
        badgeValue.requirements.points = value.requirements.points;
        badgeValue.requirements.fromDate = value.requirements.from_date;
        badgeValue.requirements.toDate = value.requirements.to_date;
      }

      return badgeValue;
    });
  };

  private remapBadgeValuesGame = (badgeValues: any[]) => {
    return badgeValues.map((value: any) => {
      const badgeValue = new GameBadgesValue();

      badgeValue.id = value.id;
      badgeValue.enabled = value.enabled;
      badgeValue.label = value.label;
      badgeValue.description = value.description;
      badgeValue.flags = value.flags;

      if (value.assets) {
        badgeValue.assets.mainImageUrl = value.assets.main_image_url;
      } else {
        badgeValue.assets = value.assets;
      }

      if (value.requirements) {
        badgeValue.requirements.participationCount =
          value.requirements.participation_count;
        badgeValue.requirements.points = value.requirements.points;
        badgeValue.requirements.fromDate = value.requirements.from_date;
        badgeValue.requirements.toDate = value.requirements.to_date;
      }

      return badgeValue;
    });
  };

  private remapBadgeValuesGames = (badgeValues: any[]) => {
    return badgeValues.map((value: any) => {
      const badgeValue = new GamesBadgesValue();

      badgeValue.id = value.id;
      badgeValue.enabled = value.enabled;
      badgeValue.label = value.label;
      badgeValue.description = value.description;
      badgeValue.flags = value.flags;

      if (value.assets) {
        badgeValue.assets.mainImageUrl = value.assets.main_image_url;
      } else {
        badgeValue.assets = value.assets;
      }

      if (value.requirements) {
        badgeValue.requirements.participationCount =
          value.requirements.participation_count;
        badgeValue.requirements.points = value.requirements.points;
        badgeValue.requirements.fromDate = value.requirements.from_date;
        badgeValue.requirements.toDate = value.requirements.to_date;
        badgeValue.requirements.specificGames = value.requirements
          .specific_games
          ? this.remapSpecificGamesRequirement(
              value.requirements.specific_games
            )
          : null;
      }

      return badgeValue;
    });
  };

  private remapSpecificGamesRequirement = (specificGames: any[]) => {
    return specificGames.map((game: any) => {
      return {
        gameId: game.game_id,
        gameType: game.game_type,
        gameModel: null
      };
    });
  };

  private remapBadgeValuesFootball = (badgeValues: any[]) => {
    return badgeValues.map((value: any) => {
      const badgeValue = new FootballBadgesValue();

      badgeValue.id = value.id;
      badgeValue.enabled = value.enabled;
      badgeValue.label = value.label;
      badgeValue.description = value.description;
      badgeValue.flags = value.flags;

      if (value.assets) {
        badgeValue.assets.mainImageUrl = value.assets.main_image_url;
      } else {
        badgeValue.assets = value.assets;
      }

      if (value.requirements) {
        badgeValue.requirements.predictionsNumber =
          value.requirements.predictions_number;
        badgeValue.requirements.correctPredictions =
          value.requirements.correct_predictions;
        badgeValue.requirements.points = value.requirements.points;
        badgeValue.requirements.entityId = value.requirements.entity_id;
        badgeValue.requirements.entityType = value.requirements.entity_type;
      }

      return badgeValue;
    });
  };

  private remapBadgeValuesMiniGames = (badgeValues: any[]) => {
    return badgeValues.map((value: any) => {
      const badgeValue = new MiniGamesBadgesValue();

      badgeValue.id = value.id;
      badgeValue.enabled = value.enabled;
      badgeValue.label = value.label;
      badgeValue.description = value.description;
      badgeValue.flags = value.flags;

      if (value.assets) {
        badgeValue.assets.mainImageUrl = value.assets.main_image_url;
      } else {
        badgeValue.assets = value.assets;
      }

      if (value.requirements) {
        badgeValue.requirements.participationCount =
          value.requirements.participation_count;
        badgeValue.requirements.fromDate = value.requirements.from_date;
        badgeValue.requirements.toDate = value.requirements.to_date;
      }

      return badgeValue;
    });
  };

  private remapBadgeValuesDiscussions = (badgeValues: any[]) => {
    return badgeValues.map((value: any) => {
      const badgeValue = new DiscussionsBadgesValue();

      badgeValue.id = value.id;
      badgeValue.enabled = value.enabled;
      badgeValue.label = value.label;
      badgeValue.description = value.description;
      badgeValue.flags = value.flags;

      if (value.assets) {
        badgeValue.assets.mainImageUrl = value.assets.main_image_url;
      } else {
        badgeValue.assets = value.assets;
      }

      if (value.requirements) {
        badgeValue.requirements.totalDiscussionPoints =
          value.requirements.total_discussion_points;
        badgeValue.requirements.postsCount = value.requirements.posts_count;
        badgeValue.requirements.postPoints = value.requirements.post_points;
        badgeValue.requirements.reactionsCount =
          value.requirements.reactions_count;
      }

      return badgeValue;
    });
  };

  private remapBadgeValuesVoting = (badgeValues: any[]) => {
    return badgeValues.map((value: any) => {
      const badgeValue = new PotmBadgesValue();

      badgeValue.id = value.id;
      badgeValue.enabled = value.enabled;
      badgeValue.label = value.label;
      badgeValue.description = value.description;
      badgeValue.flags = value.flags;

      if (value.assets) {
        badgeValue.assets.mainImageUrl = value.assets.main_image_url;
      } else {
        badgeValue.assets = value.assets;
      }

      if (value.requirements) {
        badgeValue.requirements.points = value.requirements.points;
        badgeValue.requirements.voteCount = value.requirements.vote_count;
        badgeValue.requirements.entityId = value.requirements.entity_id;
        badgeValue.requirements.entityType = value.requirements.entity_type;
      }

      return badgeValue;
    });
  };

  private remapBadgeValues = (badgeValues: any[]) => {
    return badgeValues.map((value: any) => {
      const badgeValue = new BadgesValue();

      badgeValue.id = value.id;
      badgeValue.label = value.label;
      badgeValue.enabled = value.enabled;

      if (value.assets) {
        badgeValue.assets.mainImageUrl = value.assets.main_image_url;
      } else {
        badgeValue.assets = value.assets;
      }

      badgeValue.requirements = this.remapRequirements(value.requirements);

      return badgeValue;
    });
  };

  private remapRequirements = (badgesRequirements: any): RequirementModel => {
    const requirementModel = new RequirementModel();

    requirementModel.points = badgesRequirements.points;
    requirementModel.gameParticipationCount =
      badgesRequirements.game_participation_count;
    requirementModel.entityId = badgesRequirements.entity_id;
    requirementModel.entityType = badgesRequirements.entity_type;
    requirementModel.predictionsMade = badgesRequirements.predictions_made;
    requirementModel.correctPredictions =
      badgesRequirements.correct_predictions;

    return requirementModel;
  };

  private remapUserDataConfig = (response: any) => {
    const model = new UserDataConfigModel();

    model.storeIp = response.store_ip;
    model.storeDeviceId = response.store_device_id;
    model.storeAgent = response.store_agent;

    return model;
  };

  private remapGeneralBadgeValues = (badgeValues: any[]) => {
    return badgeValues.map((value: any) => {
      const badgeValue = new GeneralBadgesValue();

      badgeValue.id = value.id;
      badgeValue.label = value.label;
      badgeValue.enabled = value.enabled;

      if (value.assets) {
        badgeValue.assets.mainImageUrl = value.assets.main_image_url;
      } else {
        badgeValue.assets = value.assets;
      }

      badgeValue.requirements = this.remapGeneralRequirements(
        value.requirements
      );

      return badgeValue;
    });
  };

  private remapGeneralRequirements = (
    badgesRequirements: any
  ): GeneralRequirementModel => {
    const requirementModel = new GeneralRequirementModel();

    requirementModel.points = badgesRequirements.points;
    requirementModel.gameParticipationCount =
      badgesRequirements.game_participation_count;
    requirementModel.entityId = badgesRequirements.entity_id;
    requirementModel.entityType = badgesRequirements.entity_type;
    requirementModel.predictionsMade = badgesRequirements.predictions_made;
    requirementModel.correctPredictions =
      badgesRequirements.correct_predictions;
    requirementModel.postPoints = badgesRequirements.post_points;
    requirementModel.postsCount = badgesRequirements.posts_count;
    requirementModel.reactionsCount = badgesRequirements.reactions_count;
    requirementModel.totalDiscussionPoints =
      badgesRequirements.total_discussion_points;

    return requirementModel;
  };
}
