import LeagueLeaderboardMetaModel from "../Meta/LeagueLeaderboardMetaModel";
import LoyaltyMeta from "../Meta/LoyaltyMeta";
import LeaderboardModel from "./LeaderboardModel";

export default class LeaderboardRemapper {

    public remapResponse = (response: any) => {
        response.meta = this.remapMeta(response.meta);
        response.data = this.remapData(response.data);

        return response;
    };

    public remapLeagueLeaderboardResponse = (response: any) => {
        response.meta = this.remapLeagueLeaderboardMeta(response.meta);
        response.data = this.remapData(response.data);

        return response;
    };

    private remapMeta = (metaResponse: any) => {
        let metaModel = new LoyaltyMeta();

        metaModel.rankType = metaResponse.rank_type;
        metaModel.fromDate = metaResponse.from_date;
        metaModel.toDate = metaResponse.to_date;
        metaModel.pagination.currentPage = metaResponse.pagination.current_page;
        metaModel.pagination.itemsPerPage = metaResponse.pagination.items_per_page;
        metaModel.pagination.totalItems = metaResponse.pagination.total_items;
        metaModel.pagination.numberOfPages = metaResponse.pagination.number_of_pages;

        return metaModel;
    };

    private remapLeagueLeaderboardMeta = (metaResponse: any) => {
        let metaModel = new LeagueLeaderboardMetaModel();

        metaModel.templateId = metaResponse.template_id;
        metaModel.privateLeagueId = metaResponse.private_league_id;
        metaModel.fromDate = metaResponse.from_date;
        metaModel.toDate = metaResponse.to_date;
        metaModel.pagination.currentPage = metaResponse.pagination.current_page;
        metaModel.pagination.itemsPerPage = metaResponse.pagination.items_per_page;
        metaModel.pagination.totalItems = metaResponse.pagination.total_items;
        metaModel.pagination.numberOfPages = metaResponse.pagination.number_of_pages;

        return metaModel;
    };

    private remapData = (dataResponse: any[]) => {
        let leaderboardTemplate: LeaderboardModel[] = [];

        dataResponse.forEach((leaderboard: any) => {
            let dataModel = new LeaderboardModel();

            dataModel.position = leaderboard.position;
            dataModel.profileId = leaderboard.profile_id;
            dataModel.points = leaderboard.points;
            dataModel.predictionsMade = leaderboard.predictions_made;

            leaderboardTemplate.push(dataModel);
        });

        return leaderboardTemplate;
    };
}