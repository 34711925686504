import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import {
  initDisableCache,
  isDisabledCache,
  setBaseUrl,
} from "../Global/Helper";
import PrivateLeagueCreateModel from "../Namespaces/PrivateLeagues/Models/Request/Create/PrivateLeagueCreateModel";
import PrivateLeagueUpdateModel from "../Namespaces/PrivateLeagues/Models/Request/Update/PrivateLeagueUpdateModel";
import PrivateLeagueRemapper from "../Namespaces/PrivateLeagues/Models/Remappers/PrivateLeagueRemapper";
import Https from "./Https";
import { LeagueType } from "../Namespaces/PrivateLeagues/Types/types";
import MainFiltersBQ from "../Global/Models/Filters/MainFiltersBQ";
import PrivateLeaguesModel from "../Namespaces/PrivateLeagues/Models/PrivateLeaguesModel";
import PrivateLeaguePostsFilters from "../Namespaces/PrivateLeagues/Models/Filters/PrivateLeaguePostsFilters";
import PrivateLeaguePredictionsFilters from "../Namespaces/PrivateLeagues/Models/Filters/PrivateLeaguePredictionsFilters";
import DiscussionRemapper from "../Namespaces/Discussions/Models/Remapper/DiscussionRemapper";
import MyLeaguesFilters from "../Namespaces/PrivateLeagues/Models/Filters/MyLeaguesFilters";
import MyChallengesFilters from "../Namespaces/Challenges/Models/Filters/MyChallengesFilters";

export default class PrivateLeaguesHttps extends Https {
  private remapper: PrivateLeagueRemapper = null;
  private discussionsRemapper: DiscussionRemapper = null;
  private discussionsBaseURL: string = null;

  constructor(config: SDKConfiguraitonModel) {
    super(config, setBaseUrl(config.environment, "privateLeagues"));
    this.discussionsBaseURL = setBaseUrl(config.environment, "discussions");
    this.remapper = new PrivateLeagueRemapper();
    this.discussionsRemapper = new DiscussionRemapper();
  }

  public create = async (
    requestBody: PrivateLeagueCreateModel,
    type: LeagueType
  ) => {
    const url = "/leagues" + this.apiSignInUrl;
    const endOfWarnMessage =
      type === "PRIVATE_LEAGUE"
        ? "creating private league"
        : "issuing challenge";
    const warnMessage = `There was a problem ${endOfWarnMessage}`;
    const method = "POST";
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return this.remapper.remapToPrivateLeagueById(data.data);
  };

  public update = async (
    leagueId: string,
    requestBody: PrivateLeagueUpdateModel,
    type: LeagueType
  ) => {
    const url = `/leagues/${leagueId}` + this.apiSignInUrl;
    const endOfWarnMessage =
      type === "PRIVATE_LEAGUE" ? "private league" : "challenge";
    const warnMessage = `There was a problem updating ${endOfWarnMessage}`;
    const method = "PUT";
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return this.remapper.remapToPrivateLeagueById(data.data);
  };

  public updateLeagueTemplate = async (
    leagueId: string,
    newTemplateId: string,
    oldTemplateName: string,
    type: LeagueType
  ) => {
    const url = `/leagues/${leagueId}/template` + this.apiSignInUrl;
    const endOfWarnMessage =
      type === "PRIVATE_LEAGUE" ? "private league" : "challenge";
    const warnMessage = `There was a problem updating template for the ${endOfWarnMessage}`;
    const method = "POST";
    const requestBody = {
      new_template_id: newTemplateId,
      past_template_name: oldTemplateName,
    };
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return this.remapper.remapToPrivateLeagueById(data.data);
  };

  public getById = async (
    leagueId: string,
    type: LeagueType,
    disableCache?: boolean
  ) => {
    let url = `/leagues/${leagueId}` + this.apiSignInUrl;

    if (isDisabledCache(disableCache)) {
      url += initDisableCache();
    }

    const endOfWarnMessage =
      type === "PRIVATE_LEAGUE" ? "private league" : "challenge";
    const warnMessage = `There was a problem getting ${endOfWarnMessage}`;
    const data = await this.fetchWithAuth(url, warnMessage);

    return this.remapper.remapToPrivateLeagueById(data.data);
  };

  public getMyLeagues = async (
    filters: MyLeaguesFilters | MyChallengesFilters,
    type: LeagueType
  ) => {
    let url = "/leagues" + this.apiSignInUrl + filters.constructFiltersUrl();

    const endOfWarnMessage =
      type === "PRIVATE_LEAGUE" ? "private leagues" : "challenges";
    const warnMessage = `There was a problem getting user's ${endOfWarnMessage}`;
    const data = await this.fetchWithAuth(url, warnMessage);

    return data.data.map((value) =>
      this.remapper.remapToMyPrivateLeagues(value)
    );
  };

  public delete = async (leagueId: string, type: LeagueType) => {
    const url = `/leagues/${leagueId}` + this.apiSignInUrl;
    const endOfWarnMessage =
      type === "PRIVATE_LEAGUE" ? "private league" : "challenge";
    const warnMessage = `There was a problem deleting ${endOfWarnMessage}`;
    const method = "DELETE";
    await this.fetchWithAuth(url, warnMessage, method);

    return true;
  };

  public invite = async (
    leagueId: string,
    profiles: string[]
  ): Promise<string[]> => {
    const url = `/membership/invite/${leagueId}` + this.apiSignInUrl;
    const warnMessage = `There was a problem inviting the following users: ${profiles.join(
      ","
    )}`;
    const method = "POST";
    const requestBody = { profiles };
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return data.data;
  };

  public deleteInvitation = async (leagueId: string, profiles: string[]) => {
    const url = `/membership/invite/${leagueId}` + this.apiSignInUrl;
    const warnMessage = `There was a problem deleting invitation for the following users: ${profiles.join(
      ","
    )}`;
    const method = "DELETE";
    const requestBody = { profiles };
    await this.fetchWithAuth(url, warnMessage, method, requestBody);

    return true;
  };

  public accept = async (
    leagueId: string,
    isAccepting: boolean,
    type: LeagueType
  ) => {
    const url =
      `/membership/accept/${leagueId}` +
      this.apiSignInUrl +
      `&accept=${isAccepting ? "ACCEPT" : "REJECT"}`;
    const endOfWarnMessage =
      type === "PRIVATE_LEAGUE" ? "private league" : "challenge";
    const warnMessage = `There was a problem ${
      isAccepting ? "accepting" : "rejecting"
    } invitation for ${endOfWarnMessage}: ${leagueId}`;
    const method = "POST";
    const data = await this.fetchWithAuth(url, warnMessage, method);

    return type === "PRIVATE_LEAGUE"
      ? this.remapper.remapToAcceptPrivateLeague(data.data)
      : this.remapper.remapToAcceptChallenge(data.data);
  };

  public ban = async (
    leagueId: string,
    profiles: string[]
  ): Promise<string[]> => {
    const url = `/membership/ban/${leagueId}` + this.apiSignInUrl;
    const warnMessage = `There was a problem banning the following users: ${profiles.join(
      ","
    )}`;
    const method = "POST";
    const requestBody = { profiles };
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return data.data;
  };

  public unban = async (
    leagueId: string,
    profiles: string[]
  ): Promise<string[]> => {
    const url = `/membership/unban/${leagueId}` + this.apiSignInUrl;
    const warnMessage = `There was a problem unbanning the following users: ${profiles.join(
      ","
    )}`;
    const method = "POST";
    const requestBody = { profiles };
    const data = await this.fetchWithAuth(
      url,
      warnMessage,
      method,
      requestBody
    );

    return data.data;
  };

  public join = async (invitationCode: string) => {
    const url =
      `/membership/join` +
      this.apiSignInUrl +
      `&invitation_code=${invitationCode}`;
    const warnMessage = `There was a problem joining league`;
    const method = "POST";
    const data = await this.fetchWithAuth(url, warnMessage, method);

    return this.remapper.remapToAcceptPrivateLeague(data.data);
  };

  public leave = async (
    leagueId: string,
    type: LeagueType
  ): Promise<string[]> => {
    const url = `/membership/leave/${leagueId}` + this.apiSignInUrl;
    const endOfWarnMessage = type === "PRIVATE_LEAGUE" ? "league" : "challenge";
    const warnMessage = `There was a problem leaving the following ${endOfWarnMessage}: ${leagueId}`;
    const method = "POST";
    const data = await this.fetchWithAuth(url, warnMessage, method);

    return data.data;
  };

  public getInvitations = async (
    type: LeagueType,
    filters?: MainFiltersBQ
  ): Promise<PrivateLeaguesModel> => {
    const url =
      "/membership/invitations" +
      this.apiSignInUrl +
      filters.constructMainFiltersUrl();
    const endOfWarnMessage =
      type === "PRIVATE_LEAGUE" ? "leagues" : "challenges";
    const warnMessage = `There was a problem getting invitations for ${endOfWarnMessage}`;
    const data = await this.fetchWithAuth(url, warnMessage);

    return this.remapper.remapToPrivateLeagues(data);
  };

  public getReportedPosts = async (
    leagueId: string,
    filters?: PrivateLeaguePostsFilters
  ) => {
    let url = `/discussions/${leagueId}/posts/reported${this.apiSignInUrl}`;
    const warnMessage = `There was a problem fetching posts for the following: ${leagueId}`;

    if (filters) {
      url += filters.constructMainFiltersUrl();
    }

    const data = await this.fetchWithAuthNewBaseURL(
      this.discussionsBaseURL,
      url,
      warnMessage
    );

    return this.discussionsRemapper.remapToPostsForDiscussion(data, "full");
  };

  public getPredictions = async (
    leagueId: string,
    filters: PrivateLeaguePredictionsFilters
  ) => {
    let url = `/leagues/${leagueId}/predictions` + this.apiSignInUrl;
    const warnMessage = `There was a problem getting predictions for private league: ${leagueId}`;

    if (filters) {
      url += filters.constructFiltersUrl();
    }

    const data = await this.fetchWithAuth(url, warnMessage);

    return this.remapper.remapPredictions(data);
  };

  public moderatePost = async (postId: string, moderationReason: string) => {
    let url = `/posts/${postId}/admin/moderate${this.apiSignInUrl}`;
    const warnMessage = `There was a problem moderating the following post: ${postId}`;
    const method = "POST";
    const requestBody = { moderation_reason: moderationReason };
    const data = await this.fetchWithAuthNewBaseURL(
      this.discussionsBaseURL,
      url,
      warnMessage,
      method,
      requestBody
    );

    return this.discussionsRemapper.remapToPostByIdBasic(data.data);
  };
}
