import HighestSuccessRateModel from "./HighestSuccessRateModel";

export default class HighestSuccessRateRemapper {
  public remapHighestSuccessRate = (
    response: any[]
  ): HighestSuccessRateModel[] => {
    return response.map((highestSuccessRate: any) => {
      const model = new HighestSuccessRateModel();

      model.position = highestSuccessRate.position;
      model.points = highestSuccessRate.points;
      model.predictionsMade = highestSuccessRate.predictions_made;
      model.profileId = highestSuccessRate.profile_id;
      model.successRatePercent = highestSuccessRate.success_rate_percent;

      return model;
    });
  };
}
