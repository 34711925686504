import FootballHttps from "../../../Https/FootballHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import CountriesFacade from "./CountriesFacade";
import CompetitionsFacade from "./CompetitionsFacade";
import TeamsFacade from "./TeamsFacade";
import TeamBasicModel from "../Models/Team/TeamBasicModel";
import TeamFullModel from "../Models/Team/TeamFullModel";
import CompetitionBasicModel from "../Models/Competition/CompetitionBasicModel";
import CompetitionFullModel from "../Models/Competition/CompetitionFullModel";
import FootballCountryModel from "../Models/Country/FootballCountryModel";
import MatchFullModel from "../Models/Match/MatchFullModel";
import PlayerFacade from "./PlayerFacade";
import TopPlayerModel from "../Models/Player/TopPlayerModel";
import PlayerFullModel from "../Models/Player/PlayerFullModel";
import SearchModel from "../Models/Search/SearchModel";
import MatchFacade from "./MatchFacade";
import SearchFacade from "./SearchFacade";
import CompetitionFilters from "../Models/Competition/CompetitionFilters";
import TeamFilters from "../Models/Team/TeamFilters";
import MatchFilters from "../Models/Match/MatchFilters";
import PlayerFilters from "../Models/Player/PlayerFilters";
import SearchFilters from "../Models/Search/SearchFilters";
import FootballPaginationModel from "../Models/Pagination/FootballPaginationModel";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import PlayerBasicModel from "../Models/Player/PlayerBasicModel";
import { LocalCacheInterface } from "../../../Global/Interfaces/GlobalInterfaces";

export default class FootballFacade {
    private countriesFacade: CountriesFacade = null;
    public competitionsFacade: CompetitionsFacade = null;
    public teamsFacade: TeamsFacade = null;
    public playerFacade: PlayerFacade = null;
    private matchFacade: MatchFacade = null;
    private searchFacade: SearchFacade = null;

    constructor(config: SDKConfigurationModel, localCache: LocalCacheInterface, https: FootballHttps, idMapping: IdMappingService) {
        this.countriesFacade = new CountriesFacade(localCache, https, idMapping);
        this.competitionsFacade = new CompetitionsFacade(localCache, https, idMapping);
        this.teamsFacade = new TeamsFacade(config, https, idMapping);
        this.playerFacade = new PlayerFacade(config, https, idMapping);
        this.matchFacade = new MatchFacade(config, https, idMapping);
        this.searchFacade = new SearchFacade(https, idMapping);
    }

    public getCountries = async (disableCache?: boolean): Promise<FootballCountryModel[]> => {
        return this.countriesFacade.getCountriesWithIdMapping(disableCache);
    };

    public getCompetitions = async (filters?: CompetitionFilters, disableCache?: boolean): Promise<CompetitionBasicModel[]> => {
        return this.competitionsFacade.getCompetitionsIdMapping(filters, disableCache);
    };

    public getCompetitionById = async (id: string, disableCache?: boolean): Promise<CompetitionFullModel> => {
        return this.competitionsFacade.getCompetitionByIdIdMapping(id, disableCache);
    };

    public getTopCompetitions = async (disableCache?: boolean): Promise<CompetitionBasicModel[]> => {
        return this.competitionsFacade.getTopCompetitionsIdMapping(disableCache);
    };

    public getCompetitionsMapWithNativeIds = async (competitionIds: string[]): Promise<Map<string, CompetitionBasicModel>> => {
        return await this.competitionsFacade.getCompetitionsMapWithNativeIds(competitionIds);
    };

    public getTeams = async (filters?: TeamFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        return this.teamsFacade.getTeamsIdMapping(filters, disableCache);
    };

    public getTeamById = async (id: string, disableCache?: boolean): Promise<TeamFullModel> => {
        return this.teamsFacade.getTeamByIdIdMapping(id, disableCache);
    };

    public getTopTeams = async (disableCache?: boolean): Promise<TeamBasicModel[]> => {
        return this.teamsFacade.getTopTeamsIdMapping(disableCache);
    };

    public getNextMatchForTeam = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        return this.teamsFacade.getNextMatchForTeamIdMapping(id, disableCache);
    };

    public getPrevMatchForTeam = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        return this.teamsFacade.getPrevMatchForTeamIdMapping(id, disableCache);
    };

    public getTeamsMapWithNativeIds = async (teamIds: string[]): Promise<Map<string, TeamBasicModel>> => {
        return await this.teamsFacade.getTeamsMapWithNativeIds(teamIds);
    };

    public getPlayers = async (filters?: PlayerFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        return this.playerFacade.getPlayersIdMapping(filters, disableCache);
    };

    public getPlayerById = async (id: string, disableCache?: boolean): Promise<PlayerFullModel> => {
        return this.playerFacade.getPlayerIdMapping(id, disableCache);
    };

    public getTopPlayers = async (disableCache?: boolean): Promise<TopPlayerModel[]> => {
        return this.playerFacade.getTopPlayersIdMapping(disableCache);
    };

    public getNextMatchForPlayer = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        return this.playerFacade.getNextMatchForPlayerIdMapping(id, disableCache);
    };

    public getPrevMatchForPlayer = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        return this.playerFacade.getPrevMatchForPlayerIdMapping(id, disableCache);
    };

    public getPlayersMapWithNativeIds = async (playerIds: string[]): Promise<Map<string, PlayerBasicModel>> => {
        return this.playerFacade.getPlayersMapWithNativeIds(playerIds);
    };

    public getMatches = async (filters?: MatchFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        return this.matchFacade.getMatchesIdMapping(filters, disableCache);
    };

    public getMatchesMapWithNativeIds = async (matchIds: string[]) => {
        return this.matchFacade.getMatchesMapWithNativeIds(matchIds);
    };

    public getMatchById = async (id: string, disableCache?: boolean): Promise<MatchFullModel> => {
        return this.matchFacade.getMatchIdMapping(id, disableCache);
    };

    public search = async (filters: SearchFilters, disableCache?: boolean): Promise<SearchModel> => {
        return this.searchFacade.searchIdMapping(filters, disableCache);
    };

}
