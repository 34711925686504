import { createSlice } from "@reduxjs/toolkit";
import {
  banLeagueUsersRequest,
  createPrivateLeagueRequest,
  deletePrivateLeagueRequest,
  getLeagueByIdRequest,
  getLeagueLeaderboardRequest,
  getLeagueMembersByIdsRequest,
  getMyLeaguesStandingsRequest,
  getUserLeaguesRequest,
  joinPrivateLeagueRequest,
  leavePrivateLeagueRequest,
  updatePrivateLeagueRequest,
} from "../../api/leaguesService";

const leaguesSlice = createSlice({
  name: "Leagues",
  initialState: {
    leagues: [],
  },
  reducers: {
    setLeagues(state, action) {
      state.leagues = action.payload;
    },
    createLeague(state, action) {
      state.leagues.unshift(action.payload);
    },
    joinLeague(state, action) {
      state.leagues.unshift(action.payload);
    },
    leaveLeague(state, action) {
      state.leagues = state.leagues.filter(
        (league) => league.id !== action.payload
      );
    },
    updateLeague(state, action) {
      state.leagues = state.leagues.map((league) => {
        return league.id === action.payload.id ? { ...action.payload } : league;
      });
    },
    deleteLeague(state, action) {
      state.leagues = state.leagues.filter(
        (league) => league.id !== action.payload
      );
    },
    setMembers(state, action) {
      state.members = action.payload;
    },
    setLeaderboards(state, action) {
      state.leaderboards = action.payload;
    },
    setStandings(state, action) {
      state.standings = action.payload;
    },
  },
});

export const {
  setLeagues,
  createLeague,
  joinLeague,
  leaveLeague,
  updateLeague,
  deleteLeague,
  setMembers,
  setLeaderboards,
  setStandings,
} = leaguesSlice.actions;

export const getLeaguesRequest = (fansUnitedSDK) => async (dispatch) => {
  try {
    const leaguesResponse = await getUserLeaguesRequest(fansUnitedSDK);
    if (!leaguesResponse) return;

    dispatch(setLeagues(leaguesResponse));

    const leaguesStandingsResponse = await getMyLeaguesStandingsRequest(
      fansUnitedSDK
    );
    dispatch(setStandings(leaguesStandingsResponse.data));
  } catch (error) {
    throw error;
  }
};

export const getLeagueInfoRequest =
  (fansUnitedSDK, leagueId) => async (dispatch) => {
    try {
      const leagueInfoResponse = await getLeagueByIdRequest(
        fansUnitedSDK,
        leagueId
      );
      if (!leagueInfoResponse) return;

      const leagueMembersResponse = await getLeagueMembersByIdsRequest(
        fansUnitedSDK,
        leagueInfoResponse.members
      );
      if (!leagueMembersResponse) return;

      const leagueLeaderboardsResponse = await getLeagueLeaderboardRequest(
        fansUnitedSDK,
        leagueId
      );

      return {
        leagueInfo: leagueInfoResponse,
        members: leagueMembersResponse,
        leaderboard: leagueLeaderboardsResponse.data,
      };
    } catch (error) {
      throw error;
    }
  };

export const createLeagueRequest =
  (fansUnitedSDK, name, description) => async (dispatch) => {
    try {
      const newLeagueResponse = await createPrivateLeagueRequest(
        fansUnitedSDK,
        name,
        description
      );
      if (!newLeagueResponse) return;
      dispatch(createLeague(newLeagueResponse));

      return newLeagueResponse;
    } catch (error) {
      throw error;
    }
  };

export const joinLeagueRequest =
  (fansUnitedSDK, invitationCode) => async (dispatch) => {
    try {
      const joinLeagueResponse = await joinPrivateLeagueRequest(
        fansUnitedSDK,
        invitationCode
      );
      if (!joinLeagueResponse.leagueModel) return;
      dispatch(joinLeague(joinLeagueResponse.leagueModel));

      return joinLeagueResponse;
    } catch (error) {
      throw error;
    }
  };

export const leaveLeagueRequest =
  (fansUnitedSDK, leagueId) => async (dispatch) => {
    try {
      const leaveLeagueResponse = await leavePrivateLeagueRequest(
        fansUnitedSDK,
        leagueId
      );
      if (!leaveLeagueResponse) return;
      dispatch(leaveLeague(leagueId));

      return leaveLeagueResponse;
    } catch (error) {
      throw error;
    }
  };

export const updateLeagueRequest =
  (fansUnitedSDK, league, bannedUsers) => async (dispatch) => {
    try {
      const updateLeagueResponse = await updatePrivateLeagueRequest(
        fansUnitedSDK,
        league
      );
      if (!updateLeagueResponse) return;

      var members = updateLeagueResponse.members;
      var membersCount = updateLeagueResponse.membersCount;

      if (bannedUsers.length > 0) {
        const banUsersResponse = await banLeagueUsersRequest(
          fansUnitedSDK,
          league.id,
          bannedUsers
        );
        if (!banUsersResponse) return;

        members = members.filter(
          (member) => !banUsersResponse.includes(member)
        );
        membersCount =
          membersCount > 0 ? membersCount - banUsersResponse.length : 0;
      }

      dispatch(
        updateLeague({ ...updateLeagueResponse, members, membersCount })
      );

      return updateLeagueResponse;
    } catch (error) {
      throw error;
    }
  };

export const deleteLeagueRequest =
  (fansUnitedSDK, leagueId) => async (dispatch) => {
    try {
      const deleteLeagueResponse = await deletePrivateLeagueRequest(
        fansUnitedSDK,
        leagueId
      );
      if (!deleteLeagueResponse) return;
      dispatch(deleteLeague(leagueId));

      return deleteLeagueResponse;
    } catch (error) {
      throw error;
    }
  };

export default leaguesSlice.reducer;
