import BrandingModel from "../../../../Global/Models/Branding/BrandingModel";
import ImagesModel from "../../../../Global/Models/Images/ImagesModel";
import MiniGamesContext from "../MiniGamesContext";

export default class ClassicQuizBasicModel {
  public id: string = null;
  public title: string = null;
  public description: string = null;
  public type: string = null;
  public images: ImagesModel = new ImagesModel();
  public participationCount: number = null;
  public questionsCount: number = null;
  public status: string = null;
  public flags: string[] = null;
  public customFields: Record<string, string> = null;
  public labels: Record<string, string> = null;
  public branding: BrandingModel = null;
  public time: number = null;
  public averageScore: number = null;
  public perfectScore: number = null;
  public adContent: string = null;
  public context: MiniGamesContext = null;
  public createdAt: string = null;
  public updatedAt: string = null;
}
