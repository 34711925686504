import EitherOrParticipationStepsModel from "./EitherOrParticipationStepsModel";

export default class EitherOrParticipationModel {
  public instanceId: string = "";
  public eitherOrId: string = "";
  public gameStartedAt: string = "";
  public currentStep: number = 0;
  public currentStreak: number = 0;
  public remainingSteps: number = 0;
  public currentLives: number = 0;
  public currentPoints: number = 0;
  public personalBest: number = 0;
  public steps: EitherOrParticipationStepsModel[] = [];
}
