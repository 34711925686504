import MarketsConfigModel from "./MarketsConfigModel";
import SuccessRateScopeModel from "./SuccessRateScopeModel";
import UserDataConfigModel from "./UserDataConfigModel";

export default class PredictorConfigModel {
  public enabled: boolean = false;
  public clientId: string = null;
  public fullCoverageCompetitions: string[] = null;
  public markets: MarketsConfigModel = null;
  public successRateScopes: SuccessRateScopeModel = new SuccessRateScopeModel();
  public userDataConfig: UserDataConfigModel = null;
}
