import {IRemapper} from "../../../../../Global/IRemapper";
import CompetitionBasicModel from "../CompetitionBasicModel";
import FootballCountryRemapper from "../../Country/Remappers/FootballCountryRemapper";

export default class CompetitionBasicRemapper implements IRemapper<CompetitionBasicModel> {
  private countryRemapper: FootballCountryRemapper = null;

  constructor() {
    this.countryRemapper = new FootballCountryRemapper();
  }

  fromResponse(response: any): CompetitionBasicModel {
    try {
      const model = new CompetitionBasicModel();
      model.id = response.id;
      model.country = this.countryRemapper.countryFromResponse(response.country);
      model.gender = response.gender;
      model.assets = response.assets;
      model.type = response.type
      model.name = response.name;

      return model;
    } catch(e) {
      console.warn("There was a problem remapping the competition response", e);
      throw e;
    }
  };

}