export default class MatchFilters {
    public countryIds?: string[] = null;
    public competitionIds?: string[] = null;
    public matchIds?: string[] = null;
    public status?: string = null;
    public teamIds?: string[] = null;
    public fromDate?: string = null;
    public toDate?: string = null;
    public limit?: number = null;
    public page?: number = null;
    public sortField?: string = null;
    public sortOrder?: string = null;

    constructor(filters: any) {
        this.countryIds = filters.countryIds ? filters.countryIds : null;
        this.competitionIds = filters.competitionIds ? filters.competitionIds : null;
        this.matchIds = filters.matchIds ? filters.matchIds : null;
        this.status = filters.status ? filters.status : null;
        this.teamIds = filters.teamIds ? filters.teamIds : null;
        this.fromDate = filters.fromDate ? filters.fromDate : null;
        this.toDate = filters.toDate ? filters.toDate : null;
        this.limit = filters.limit ? filters.limit : null;
        this.page = filters.page ? filters.page : null;
        this.sortField = filters.sortField ? filters.sortField : null;
        this.sortOrder = filters.sortOrder ? filters.sortOrder : null;
    }

    public constructFilterUrl? = (avoidPagination?: boolean) => {
        let url = '';
        if (this.countryIds) {
            url = url + '&';
            url = url + `countries=${this.countryIds.join(",")}`;
        }
        if (this.competitionIds && this.competitionIds.length > 0) {
            url = url + '&';
            url = url + `competitions=${this.competitionIds.join(",")}`;
        }
        if (this.matchIds && this.matchIds.length > 0) {
            url = url + '&';
            url = url + `matches=${this.matchIds.join(",")}`;
        }
        if (this.status) {
            url = url + '&';
            url = url + `status=${this.status}`;
        }
        if (this.teamIds && this.teamIds.length > 0) {
            url = url + '&';
            url = url + `teams=${this.teamIds.join(",")}`;
        }
        if (this.fromDate) {
            url = url + '&';
            url = url + `from_date=${this.fromDate}`;
        }
        if (this.toDate) {
            url = url + '&';
            url = url + `to_date=${this.toDate}`;
        }
        if (this.limit && !avoidPagination) {
            url = url + "&";
            url = url + `limit=${this.limit}`;
        }
        if (this.page && !avoidPagination) {
            url = url + "&";
            url = url + `page=${this.page}`;
        }
        if (this.sortField) {
            url = url + "&";
            url = url + `sort_field=${this.sortField}`;
        }
        if (this.sortOrder) {
            url = url + "&";
            url = url + `sort_order=${this.sortOrder}`;
        }

        return url;
    };

}