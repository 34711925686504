import ContextModel from "../../Activity/Models/RequestBody/Context/ContextModel";

export type DiscussionType = "LEAGUE" | "COMMENT_SECTION";

export type ModerationType = "USER" | "STAFF";

export default class DiscussionModel {
  public id: string = "";
  public label: string = "";
  public discussionType: DiscussionType = "LEAGUE";
  public moderationType: ModerationType = "USER";
  public postsCount: number = 0;
  public pinnedPosts: string[] = [];
  public lastPostId: string = "";
  public status: string = "";
  public deleted: boolean = false;
  public deletedAt: string = "";
  public deletedBy: string = "";
  public customFields: Record<string, string> = null;
  public context: ContextModel = null;
  public createdAt: string = "";
  public updatedAt: string = "";
}
